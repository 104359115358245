import { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
    YEARS_OPTIONS,
    MARITAL_STATUS_OPTIONS,
    YEARS_CURRENT_WORK_OPTIONS,
    YEARS_TOTAL_WORK_OPTIONS,
    NO_OF_LOANS_OPTIONS,
    DEPENDENT_OPTIONS,
} from "../../constants/appConstants";
import map from "lodash/map";
import Link from "@material-ui/core/Link";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { AddRemarksDialogBoxConatiner } from "../static_components";
import { useTranslation } from "react-i18next";

const CustomerInformation = (props) => {
    const {
        classes,
        history,
        getEmploymentTypes,
        employmentTypeList,
        getIndustryType,
        industryTypeList,
        createProfileEmployment,
        clientInfo,
        currentUser,
        updateApplicationStatus
    } = props;

    const { clientId, applicationId } = props.match.params;
    const [employmentTypeId, setEmploymentType] = useState("");
    const [prominentOpen, setprominentOpen] = useState(false);
    const [immediateOpen, setImmediateOpen] = useState(false);
    const [associateOpen, setAssociateOpen] = useState(false);
    const [
        helperTextForEntrustedApplicant,
        setHelperTextForEntrustedApplicant,
    ] = useState("");
    const [helperTextForRelatedTerm, setHelperTextForRelatedTerm] =
        useState("");

    const [errorForEntrustedApplicant, setErrorForEntrustedApplicant] =
        useState(false);
    const [errorForRelatedTerm, setErrorForRelatedTerm] = useState(false);

    const [allValues, setAllValues] = useState({
        maritalStatus: "",
        dependents: "",
        employerName: "",
        monthlyLoanPayment: "",
        industryId: "",
        workingYears: "",
        totalWorkingYears: "",
        totalYearsOfBusiness: "",
        existingLoan: "",
        monthlyIncome: "",
        isEntrustedPPF: "false",
        isNaturealRelatedPPF: "false",
    });
    const [workYearsDisabled, setWorkYearsDisabled] = useState(false);
    const [workingYearsRequired, setWorkingYearsRequired] = useState(true);
    const [totalWorkYearsDisabled, setTotalWorkYearsDisabled] = useState(false);
    const [totalBusinessYearsDisabled, setTotalBusinessYearsDisabled] =
        useState(true);
    const [totalBusinessYearsRequired, setTotalBusinessYearsRequired] =
        useState(false);
    const [totalWorkingYearsRequired, setTotalWorkingYearsRequired] =
        useState(true);
    const [openRemarks, setOpenRemarks] = useState(false);
    const [saveAndExitLink, setSaveAndExitLink] = useState(`/application/${applicationId}/save-application`);
    const [createAction, setCreateAction] = useState(false);
    const { t } = useTranslation()
    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const onChangeRadioGroup = (e, type) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });

        if (type === "entrustedApplicant") {
            setHelperTextForEntrustedApplicant("");
            setErrorForEntrustedApplicant(false);
        } else {
            setErrorForRelatedTerm(false);
            setHelperTextForRelatedTerm("");
        }
    };
    const changeEmploymentType = (e) => {
        if (e.target.value === 3 || e.target.value === 5 || e.target.value === 7 || e.target.value === 6) {
            setAllValues({ ...allValues, workingYears: "" });
            setWorkYearsDisabled(true);
            setWorkingYearsRequired(false);
        } else {
            setWorkYearsDisabled(false);
            setWorkingYearsRequired(true);
        }
        if (e.target.value === 7 || e.target.value === 6) {
            setAllValues({ ...allValues, totalWorkingYears: "" });
            setTotalWorkingYearsRequired(true);
            setTotalWorkYearsDisabled(false);
        }
        if (
            e.target.value === 4 ||
            e.target.value === 8 ||
            e.target.value === 3
        ) {
            setAllValues({ ...allValues, totalWorkingYears: "" });
            setTotalWorkingYearsRequired(false);
            setTotalWorkYearsDisabled(true);
        } else {
            setTotalWorkingYearsRequired(true);
            setTotalWorkYearsDisabled(false);
        }
        if (e.target.value === 3 || e.target.value === 4) {
            setTotalBusinessYearsDisabled(false);
            setTotalBusinessYearsRequired(true);
        } else {
            setAllValues({ ...allValues, totalYearsOfBusiness: "" });
            setTotalBusinessYearsDisabled(true);
            setTotalBusinessYearsRequired(false);
        }
        setEmploymentType(e.target.value);
        getIndustryType(e.target.value);
    };

    const {
        maritalStatus,
        dependents,
        employerName,
        monthlyLoanPayment,
        industryId,
        totalYearsOfBusiness,
        workingYears,
        totalWorkingYears,
        existingLoan,
        monthlyIncome,
        isEntrustedPPF,
        isNaturealRelatedPPF,
    } = allValues;

    const handleSubmit = () => {
        const params = {
            applicationId: applicationId,
            data: {
                note: '',
                viewed: 0,
                status: "Pending",
            },
        };
        updateApplicationStatus(params);
        if (!isEntrustedPPF) {
            setErrorForEntrustedApplicant(true);
            setHelperTextForEntrustedApplicant("Please select an option.");
        }

        if (!isNaturealRelatedPPF) {
            setErrorForRelatedTerm(true);
            setHelperTextForRelatedTerm("Please select an option.");
        }
        if (isNaturealRelatedPPF && isEntrustedPPF) {
            const data = {
                clientId: clientId,
                maritalStatus,
                dependents,
                employmentTypeId,
                industryId,
                workingYears,
                totalWorkingYears,
                totalYearsOfBusiness,
                monthlyIncome,
                isEntrustedPPF,
                isNaturealRelatedPPF,
                existingLoan,
                monthlyLoanPayment,
                employerName
            };
            createProfileEmployment(data);
            setCreateAction(true);
        }
    };

    useEffect(() => {
        if (clientInfo && clientInfo.employment && createAction) {
            history.push(
                `/client/${clientId}/application/${applicationId}/reference`
            );
        }
    }, [clientInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getEmploymentTypes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidNonNegative", (value) => {
            return value >= 0;
        });
    });

    const handleProminentOpen = () => {
        setprominentOpen(true);
    };

    const handleProminentClose = () => {
        setprominentOpen(false);
    };
    const handleImmediateOpen = () => {
        setImmediateOpen(true);
    };

    const handleImmediateClose = () => {
        setImmediateOpen(false);
    };

    const handleAssociateOpen = () => {
        setAssociateOpen(true);
    };

    const handleAssociateClose = () => {
        setAssociateOpen(false);
    };

    const handleSaveExit = () => {
        const params = {
            applicationId: applicationId,
            data: {
                note: '',
                viewed: 0,
                status: "Pending",
            },
        };
        updateApplicationStatus(params);
        if (!isEntrustedPPF) {
            setErrorForEntrustedApplicant(true);
            setHelperTextForEntrustedApplicant("Please select an option.");
        }

        if (!isNaturealRelatedPPF) {
            setErrorForRelatedTerm(true);
            setHelperTextForRelatedTerm("Please select an option.");
        }
        if (isNaturealRelatedPPF && isEntrustedPPF) {
            const data = {
                clientId: clientId,
                maritalStatus,
                dependents,
                employmentTypeId,
                industryId,
                workingYears,
                totalWorkingYears,
                totalYearsOfBusiness,
                monthlyIncome,
                isEntrustedPPF,
                isNaturealRelatedPPF,
                existingLoan,
                monthlyLoanPayment
            };
            setOpenRemarks(true);
            createProfileEmployment(data);
        }
        //history.push(`/application/${applicationId}/save-application`);
    };

    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    {t('CAPTURE_ADDITIONAL_INFO') || "Capture additional information"}
                </Typography>
                <Typography className={classes.titleLabel}>
                    {t('ADDITIONAL_PERSONAL_INFO') || "Additional Personal Info"}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('ADDITIONAL_PERSONAL_INFO')||"Additional Personal Info"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('MARITAL_STATUS') || "Marital status"}
                                            </InputLabel>
                                            <Select
                                                id="maritalStatusOptionInput"
                                                name="maritalStatus"
                                                value={maritalStatus}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    MARITAL_STATUS_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('NO_OF_DEPENDENTS') || "Number of dependants"}
                                            </InputLabel>
                                            <Select
                                                id="dependentsOptionInput"
                                                name="dependents"
                                                value={dependents}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    DEPENDENT_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <p className="textLink">
                                            {t('IS_ENTRUSTED_PPF_SHORT') || "Is the applicant or has the applicant been entrusted with a "}
                                            <Link
                                                id="prominentFunctionPopupOne"
                                                href="#"
                                                onClick={handleProminentOpen}
                                                underline="always"
                                                style={{ color: '#5F56A6' }}
                                            >
                                                {t('PROMINENT_PUBLIC_FUNCTION') || "prominent public function"}
                                            </Link>
                                            ?
                                        </p>
                                        <FormControl
                                            component="fieldset"
                                            error={errorForEntrustedApplicant}
                                        >
                                            <RadioGroup
                                                row
                                                aria-label="isEntrustedPPF"
                                                name="isEntrustedPPF"
                                                value={isEntrustedPPF}
                                                onChange={(e) =>
                                                    onChangeRadioGroup(
                                                        e,
                                                        "entrustedApplicant"
                                                    )
                                                }
                                                style={{ color: '#5F56A6' }}
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio 
                                                        id="yesPPFBtn"/>}
                                                    label="Yes"
                                                    style={{ color: '#5F56A6' }}
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio
                                                        id="noPPFBtn" />}
                                                    label="No"
                                                    style={{ color: '#5F56A6' }}
                                                />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {
                                                    helperTextForEntrustedApplicant
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        <p className="textLink">
                                            {t('IS_RELATED_PPF_SHORT') || "Is the applicant related in terms of "}
                                            <Link style={{ color: '#5F56A6' }}
                                                href="#"
                                                onClick={handleImmediateOpen}
                                                underline="always"
                                            >
                                                {t('IMMEDIATE_FAMILY_MEMBER') || "immediate family member"}
                                            </Link>{" "}
                                            or{" "}
                                            <Link style={{ color: '#5F56A6' }}
                                                href="#"
                                                onClick={handleAssociateOpen}
                                                underline="always"
                                            >
                                                {t('CLOSE_ASSOCIATE') || "close associate"}
                                            </Link>{" "}
                                            {t('IS_RELATED_REMAINING') || "to a natureal person who is or has been entrusted with a "}
                                            <Link
                                                id="prominentFunctionPopupTwo"
                                                style={{ color: '#5F56A6' }}
                                                href="#"
                                                onClick={handleProminentOpen}
                                                underline="always"
                                            >
                                                {t('PROMINENT_PUBLIC_FUNCTION') || "prominent public function"}
                                            </Link>
                                            ?
                                        </p>
                                        <FormControl
                                            component="fieldset"
                                            error={errorForRelatedTerm}
                                            style={{ color: '#5F56A6' }}
                                        >
                                            <RadioGroup
                                                row
                                                aria-label="isNaturealRelatedPPF"
                                                name="isNaturealRelatedPPF"
                                                value={isNaturealRelatedPPF}
                                                onChange={onChangeRadioGroup}
                                                style={{ color: '#5F56A6' }}
                                                required
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio
                                                        id="yesRelatedPPFBtn"/>}
                                                    style={{ color: '#5F56A6' }}
                                                    label={t('YES') || "Yes"}
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio 
                                                        id="noRelatedPPFBtn"/>}
                                                    style={{ color: '#5F56A6' }}
                                                    label={t('NO') || "No"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {helperTextForRelatedTerm}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography className={classes.titleLabel}>
                            {t('EMPLOYMENT_FINANCE') || "Employment & Finance"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('EMPLOYMENT_FINANCE')||"Employment & Finance"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('EMPLOYMENT_TYPE') || "Employment type"}
                                            </InputLabel>
                                            <Select
                                                id="employmentTypeOptionInput"
                                                name="employmentTypeId"
                                                value={employmentTypeId}
                                                onChange={changeEmploymentType}
                                                required
                                            >
                                                {map(
                                                    employmentTypeList,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.id}
                                                        >
                                                            {
                                                                item.employmentType
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('INDUSTRY_BUSINESS_TYPE') || "Industry / Business type"}
                                            </InputLabel>
                                            <Select
                                                id="industryTypeOptionInput"
                                                name="industryId"
                                                value={industryId}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    industryTypeList,
                                                    (item, key) => (
                                                        <MenuItem
                                                            id="industryMenuOption"
                                                            key={key}
                                                            value={item.id}
                                                        >
                                                            {item.industry}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('CURRENT_WORKING_YEARS') || "Working years in current company"}
                                            </InputLabel>
                                            <Select
                                                id="workingYearsOptionInput"
                                                name="workingYears"
                                                value={workingYears}
                                                onChange={changeHandler}
                                                required={workingYearsRequired}
                                                disabled={workYearsDisabled}
                                            >
                                                {map(
                                                    YEARS_CURRENT_WORK_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            id="workingYearsMenu"
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('TOTAL_WORKING_YEARS') || "Total working years"}
                                            </InputLabel>
                                            <Select
                                                id="totalWorkingYearsOptionInput"
                                                name="totalWorkingYears"
                                                value={totalWorkingYears}
                                                onChange={changeHandler}
                                                required={
                                                    totalWorkingYearsRequired
                                                }
                                                disabled={
                                                    totalWorkYearsDisabled
                                                }
                                            >
                                                {map(
                                                    YEARS_TOTAL_WORK_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="employerName"
                                            name="employerName"
                                            label={t('EMPLOYER_COMPANY_NAME') || "Employer &amp; Company name"}
                                            value={employerName}
                                            onChange={changeHandler}
                                            fullWidth
                                            required
                                            InputLabelProps={{ required: false }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('YEARS_OF_BUSINESS') || "Total years of business"}
                                            </InputLabel>
                                            <Select
                                                id="totalYearsOfBusinessOptionInput"
                                                name="totalYearsOfBusiness"
                                                value={totalYearsOfBusiness}
                                                onChange={changeHandler}
                                                disabled={
                                                    totalBusinessYearsDisabled
                                                }
                                                required={
                                                    totalBusinessYearsRequired
                                                }
                                            >
                                                {map(
                                                    YEARS_TOTAL_WORK_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('EXISTING_LOANS') || "Number of existing loans"}
                                            </InputLabel>
                                            <Select
                                                id="existingLoan"
                                                name="existingLoan"
                                                value={existingLoan}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    NO_OF_LOANS_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            id="existingLoanMenu"
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="monthlyLoanPayment"
                                            name="monthlyLoanPayment"
                                            label={t('LOAN_REPAYMENT') || "Total loan repayment per month (৳)"}
                                            value={monthlyLoanPayment}
                                            onChange={changeHandler}
                                            fullWidth
                                            type="number"
                                            validators={[
                                                "isValidNonNegative"
                                            ]}
                                            errorMessages={[
                                                "Please enter a Total loan repayment per month"
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="monthlyIncome"
                                            name="monthlyIncome"
                                            label={t('MONTHLY_INCOME') || "Monthly income (৳)"}
                                            value={monthlyIncome}
                                            onChange={changeHandler}
                                            fullWidth
                                            type="number"
                                            validators={[
                                                "isValidNonNegative",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid monthly income",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <div className={classes.buttonWrapper}>
                            <Button
                                id="saveBtn"
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                onClick={handleSaveExit}
                            >
                                {t('SAVE_AND_EXIT') || "Save &amp; Exit"}
                            </Button>
                            <Button
                                id="nextBtn"
                                style={{
                                    backgroundColor: "#5F56A6"
                                }}
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                type="submit"
                            >
                                {t('NEXT') || "Next"}
                            </Button>
                        </div>
                    </ValidatorForm>
                    <CustomizedProminentDialogs
                        classes={classes}
                        open={prominentOpen}
                        onClose={handleProminentClose}
                    />
                    <CustomizedImmediateDialogs
                        classes={classes}
                        open={immediateOpen}
                        onClose={handleImmediateClose}
                    />
                    <CustomizedAssociateDialogs
                        classes={classes}
                        open={associateOpen}
                        onClose={handleAssociateClose}
                    />
                    <AddRemarksDialogBoxConatiner classes={classes} applicationId={applicationId} open={openRemarks} currentUser={currentUser} link={saveAndExitLink} />
                </Fragment>
            </div>
        </main>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

const CustomizedProminentDialogs = (props) => {
    const { classes, open, onClose } = props;
    const handleSubmit = () => {
        onClose();
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                >
                    Prominent public function
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    Heads of State, heads of government, ministers and deputy or
                    assistant ministers; members of parliaments or of similar
                    legislative bodies; members of the governing bodies of
                    political parties; members of supreme courts, of
                    constitutional courts or of other high-level judicial bodies
                    whose decisions are not subject to further appeal, except in
                    exceptional circumstances; members of courts of auditors or
                    of the boards of central banks; ambassadors, chargés
                    d'affaires and high-ranking officers in the armed forces;
                    members of the administrative, management or supervisory
                    bodies of State owned enterprises; directors, deputy
                    directors and members of the board or equivalent function of
                    an international organisation.
                </DialogContent>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ color: "white", backgroundColor: "#5f56a6" }}
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
const CustomizedImmediateDialogs = (props) => {
    const { classes, open, onClose } = props;
    const handleSubmit = () => {
        onClose();
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                >
                    Immediate family member
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    The spouse,any person considered as equivalent to the spouse
                    ; the children and their spouses or persons considered as
                    equivalent to the spouse ; the parents.
                </DialogContent>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ color: "white", backgroundColor: "#5f56a6" }}
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
const CustomizedAssociateDialogs = (props) => {
    const { classes, open, onClose } = props;
    const handleSubmit = () => {
        onClose();
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                >
                    Close associate
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    -Any natural person who is known to have joint beneficial
                    ownership of legal entities or legal arrangements,or any
                    other close business relations with a natural person who is
                    or who has been entrusted with prominent public functions.
                </DialogContent>
                <DialogContent className={classes.dialogContent}>
                    -Any natural person who has sole beneficial ownership of a
                    legal entity or legal arrangement which is known to have
                    been set up for the benefit de facto of a natural person who
                    is or who has been entrusted with prominent public
                    functions.
                </DialogContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ color: "white", backgroundColor: "#5f56a6" }}
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
const DialogTitle = withStyles(inputStyles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({}))(MuiDialogContent);

export default withStyles(inputStyles)(CustomerInformation);
