import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import ProcessingFeesDialogBox from './ProcessingFeesDialogBox'

const ProcessingFeesDialogBoxContainer = (props: any) => <ProcessingFeesDialogBox {...props} />

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = {
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcessingFeesDialogBoxContainer))
)
