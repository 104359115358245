import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import SelectVehicle from './SelectVehicle'
import get from 'lodash/get'
import { getVehicles, createApplication, getApplicationById, updateApplicationStatus, createDiscount, getDiscount, updateDiscount } from '../../../store/actions/customer'

const SelectVehicleContainerEnquiry = (props: any) => <SelectVehicle {...props} />

const mapStateToProps = (state: any) => {
    return {
        createdUser: state.customer.createdUser,
        isLoadingCreateApplication: state.customer.isLoadingCreateApplication,
        isLoadingGetVehicles: state.customer.isLoadingGetVehicles,
        vehicleList: state.customer.vehicleList || [],
        createdApplication: state.customer.createdApplication,
        currentUser: get(state.auth, 'user'),
        applicationDetail: state.customer.application||{},
        applicationVehicle: get(state.customer.application, 'vehicle') || {},
        discount: get(state.customer.discount,'data') ||{},
    }
}

const mapDispatchToProps = {
    getVehicles,
    createApplication,
    getApplicationById,
    updateApplicationStatus,
    createDiscount,
    getDiscount,
    updateDiscount
}


export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectVehicleContainerEnquiry))
)