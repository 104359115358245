import { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import moment from "moment";
import { getPaymentListById } from "../../store/actions/customer";

const DetailPayment = (props) => {
    const { classes, paymentList, getPaymentListById, t } = props;
    const { applicationId } = props.match.params;
    useEffect(() => {
        getPaymentListById(applicationId);
    }, [applicationId]);
    return (
        <>
            {paymentList && paymentList.length > 0 && (
                <Fragment>
                    {/* <Grid
                        container
                        spacing={4}
                        style={{ marginBottom: "20px" }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ marginLeft: "20px" }}
                        >
                            <Typography variant="body2" gutterBottom>
                                {t('TOTAL_OUTSTANDING_BALANCE')||"Total outstanding balance"}
                            </Typography>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                ৳{paymentList[paymentList.length - 1].balance}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="body2" gutterBottom>
                                {t('CURRENT_OUTSTANDING_BALANCE')||"Current outstanding balance"}
                            </Typography>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                ৳{paymentList[paymentList.length - 1].balance}
                            </Typography>
                        </Grid>
                    </Grid> */}
                    {map(paymentList, (item, key, index) => (
                        <PaymentItem
                            key={key}
                            classes={classes}
                            data={item}
                            index={key + 1}
                            t={t}
                        />
                    ))}
                </Fragment>
            )}
        </>
    );
};

const PaymentItem = (props) => {
    const { classes, data, index, t } = props;

    return (
        <Paper className={classes.paper}>
                <div className={classes.paperContent}>
                    <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.label}
                        style={{ marginBottom: "10px" }}
                    >
                        {t('INSTALLMENT')||"Instalment"}#{index}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('EMI')||"EMI"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.emiAmount}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('FEE')||"Fee"}1
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.fee1}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('FEE')||"Fee"}2
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.fee2}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('FEE')||"Fee"}3
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.fee3}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div
                                style={{
                                    fontWeight: "700",
                                    textTransform: "capitalize",
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('REBATE')||"Rebate"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.rebate}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('DUE_AMOUNT')||"Due Amount"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.dueAmount}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('DUE_DATE')||"Due date"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.dueDate
                                        ? moment(data.dueDate).format(
                                              "DD/MM/YYYY"
                                          )
                                        : ""}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('PAID_AMOUNT')||"Paid Amount"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.paidAmount}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('PAYMENT_DATE')||"Payment date"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.paymentDate
                                        ? moment(data.paymentDate).format(
                                              "DD/MM/YYYY"
                                          )
                                        : ""}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('UPDATED_BY')||"Updated by"}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {data.staffName}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}></Grid>
                </div>
            </Paper>
        // <Link
        //     to={`/application/${data.applicationId}/payment-detail/${data.id}`}
        //     className="noDecorate"
        // >
            
        // </Link>
    );
};

const mapStateToProps = (state) => ({
    paymentList: state.customer.paymentList || [],
});

const mapDispatchToProps = {
    getPaymentListById,
};

export default withStyles(inputStyles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailPayment))
);
