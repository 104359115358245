import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import ApplicationUpdateComponent from "../application_update/ApplicationUpdateComponent";
import ApplicantUpdateComponent from "../application_update/applicant_update/ApplicantUpdateComponent";
import moment from "moment";
import { APP_CONFIGS } from "../../constants/appConstants";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black", // (default alpha is 0.38)
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#6d6d6d",
        },
    },
})(TextField);
const DarkerFormControlLabel = withStyles({
    root: {
        marginRight: 8,
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: "black",
        },
    },
})(FormControlLabel);
const ApplicationDetailApplicant = (props) => {
    const { classes, applicant, status, confirmSubmit, handleChangeTab, 
        createProfileEmployment, createCustomerDocument , updateProfile, updateEmployment, 
        createIdentityDocument, createIncomeProof, createAddressProof, updateIdentityDocument, updateIncomeProof,
        updateAddressProof, updateCustomerData, createAdditionalInfo,
        getAdditionalInfo, updateAdditionalInfo, additionalInfo, isManager, calculateCde,
        isLoadingCalculateCde, t} = props;
    const { address, permanentAddress } = applicant;
    // const sameAddress = address.id === permanentAddress.id
    return (
        <>
        {(status == "Pending" || status=="Inquiry") && (
            <ApplicantUpdateComponent confirmSubmit={confirmSubmit} changeTab={handleChangeTab} createProfileEmployment={createProfileEmployment}
             createCustomerDocument={createCustomerDocument} updateProfile={updateProfile} updateEmployment={updateEmployment} status={status}
             createIdentityDocument={createIdentityDocument} createIncomeProof={createIncomeProof} createAddressProof={createAddressProof}
             updateIdentityDocument={updateIdentityDocument} updateIncomeProof={updateIncomeProof} updateAddressProof={updateAddressProof}
             updateCustomerData={updateCustomerData} createAdditionalInfo={createAdditionalInfo} getAdditionalInfo={getAdditionalInfo}
              updateAdditionalInfo={updateAdditionalInfo} additionalInfo={additionalInfo} calculateCde={calculateCde} isLoadingCalculateCde={isLoadingCalculateCde} t={t}/>
        )}
        {(status !== "Pending" && status !=="Inquiry") && (
            <Fragment>
                <Typography
                    className={classes.titleLabel}>
                     {t('PROFILE')||"Profile"}
            </Typography>
            <CssBaseline />
            <Paper className={classes.paper}>
                {/* <PaperTitle classes={classes} text={t('PROFILE')||"Profile"} /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex" }}
                            alignItems="center"
                            direction="column"
                        >
                            <img
                                src={
                                    applicant &&
                                    applicant.identity &&
                                    applicant.identity.selfPhoto
                                }
                                style={{
                                    width: "180px",
                                    margin: "0px auto",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="salutation"
                                    label={t('SALUTATION')||"Salutation"}
                                    value={applicant.salutation}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="name"
                                label={t('FULL_NAME')||"Full Name(same as ID)"}
                                value={applicant.name}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="birthday"
                                label={t('DOB')||"Date of Birth"}
                                value={moment(applicant.birthday).format('DD/MM/YYYY')}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="gender"
                                    label={t('GENDER')||"Gender"}
                                    value={applicant.gender}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="maritalStatus"
                                label={t('MARITAL_STATUS')||"Marital Status"}
                                value={
                                    applicant.profile &&
                                    applicant.profile.maritalStatus
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="no_of_dependents"
                                    label={t('NO_OF_DEPENDENTS')||"Number of dependants"}
                                    value={
                                        applicant.profile &&
                                        applicant.profile.dependents
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="phone"
                                label={t('MOBILE')||"Mobile Number"}
                                value={applicant.phone}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="home_phone"
                                label={t('HOME_PHONE')||"Home phone number(optional)"}
                                value={additionalInfo.homePhoneNumber}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="office_phone"
                                label={t('OFFICE_PHONE')||"Office phone number(optional)"}
                                value={additionalInfo.officePhoneNumber}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="email"
                                label={t('EMAIL')||"Email address(optional)"}
                                value={additionalInfo.email}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="facebookId"
                                label={t('FACEBOOK_ID')||"FaceBookID(optional)"}
                                value={additionalInfo.facebookId}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography style={{ marginBottom: "8px" }}>
                                {t('IS_ENTRUSTED_PPF_SHORT')||"Is the applicant or has the applicant been entrusted with a "}
                                <Link href="#" underline="always">
                                    {t('PROMINENT_PUBLIC_FUNCTION')||"prominent public function"}
                                </Link>
                                ?
                            </Typography>
                            <DarkerFormControlLabel
                                value="yes"
                                control={
                                    <Radio
                                        checked={false}
                                        value="Yes"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "Yes" }}
                                        disabled
                                    />
                                }
                                label={t('YES')||"Yes"}
                            />
                            <DarkerFormControlLabel
                                value="no"
                                control={
                                    <Radio
                                        checked={true}
                                        value="Yes"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "Yes" }}
                                        disabled
                                    />
                                }
                                label={t('NO')||"No"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography style={{ marginBottom: "8px" }}>
                                {t('IS_RELATED_PPF_SHORT')||"Is the applicant related in terms of "}
                                <Link href="#" underline="always">
                                    {t('IMMEDIATE_FAMILY_MEMBER')||"immediate family member "}
                                </Link>{" "}
                                or{" "}
                                <Link href="#" underline="always">
                                    {t('CLOSE_ASSOCIATE')||"close associate "}
                                </Link>{" "}
                                {t('IS_RELATED_REMAINING')||"to a natureal person who is or has been entrusted with a "}
                                <Link href="#" underline="always">
                                    {t('PROMINENT_PUBLIC_FUNCTION')||"prominent public function"}
                                </Link>
                                ?
                            </Typography>
                            <DarkerFormControlLabel
                                value="yes"
                                control={
                                    <Radio
                                        checked={false}
                                        value="Yes"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "Yes" }}
                                        disabled
                                    />
                                }
                                label={t('YES')||"Yes"}
                            />
                            <DarkerFormControlLabel
                                value="no"
                                control={
                                    <Radio
                                        checked={true}
                                        value="Yes"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "Yes" }}
                                        disabled
                                    />
                                }
                                label={t('NO')||"No"}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t("Customer Interest Optional")}
            </Typography>
            <Paper className={classes.paper}>
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="interestStatus"
                                label={t("Interest Level")}
                                value={
                                    applicant.interestStatus
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="followUpDate"
                                label={t("Follow-up Date")}
                                value={applicant.followUpDate ? moment(applicant.followUpDate).format('DD/MM/YYYY') : ""}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                  className={classes.titleLabel}>
                 {t('ID_PROOF')||"ID Proof"}
             </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle classes={classes} text={t('ID_PROOF')||"ID Proof"} /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="idType"
                                    label={t('ID_TYPE')||"ID type"}
                                    value={
                                        applicant.identity &&
                                        applicant.identity.idType &&
                                        applicant.identity.idType.idType
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="idNumber"
                                label={t('ID_NUMBER')||"ID number"}
                                value={
                                    applicant.identity &&
                                    applicant.identity.idNumber
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ marginBottom: "8px" }}>
                                {t('FRONT_ID')||"Front side of ID"}
                            </Typography>

                            <img
                                src={
                                    applicant.identity &&
                                    applicant.identity.idFrontImg
                                }
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ marginBottom: "8px" }}>
                                {t('BACK_ID')||"Back side of ID"}
                            </Typography>

                            <img
                                src={
                                    applicant.identity &&
                                    applicant.identity.idBackImg
                                }
                                style={{ width: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t('CURRENT_ADDRESS')||"Current Address & Proof"}
            </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle
                    classes={classes}
                    text={t('CURRENT_ADDRESS')||"Current Address & Proof"}
                /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="residential_status"
                                label={t('RESIDENTIAL_STATUS')||"Residential Status"}
                                value={
                                    applicant.address &&
                                    applicant.address.residentalStatus
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="cur_address_years"
                                label={t('YEARS_AT_CURRENT_ADDRESS')||"Years at current address"}
                                value={
                                    applicant.address &&
                                    applicant.address.yearsLiving
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="country"
                                label={t('COUNTRY')||"Country"}
                                value={
                                    applicant.address &&
                                    applicant.address.country
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DarkerDisabledTextField
                                name="postalCode"
                                label={t('POSTAL_CODE')||"Postal code"}
                                value={
                                    applicant.address &&
                                    applicant.address.postalCode
                                }
                                //value={postalCode}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="province"
                                    label={t('DISTRICT_PROVINCE')||"District / Province"}
                                    value={
                                        applicant.address &&
                                        applicant.address.province
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="city"
                                    label={t('CITY_TOWN')||"City / Town"}
                                    value={
                                        applicant.address &&
                                        applicant.address.city
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <DarkerDisabledTextField
                                name="address"
                                label={t('ADDRESS')||"Address"}
                                value={
                                    applicant.address &&
                                    applicant.address.address
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="documentType"
                                    label={t('DOCUMENT_TYPE')||"Proof Document type"}
                                    value={
                                        applicant.addressProof &&
                                        applicant.addressProof.addressProofType
                                            .addressProofType
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.fileContainer}>
                                <Typography
                                    style={{
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                    }}
                                >
                                    <a
                                        href={
                                            applicant.addressProof &&
                                            applicant.addressProof
                                                .addressProofDocument
                                        }
                                        underline="always"
                                    >
                                       {t('VIEW_DOCUMENT')||"View Document"}
                                    </a>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t('PERMANENT_ADDRESS')||"Permanent address"}
            </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle classes={classes} text={t('PERMANENT_ADDRESS')||"Permanent Address"} /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.fileContainer}>
                                <Typography>
                                    {t('SAME_AS_CURRENT_ADDRESS')||"Same as current address?"}
                                </Typography>
                                <div>
                                    <DarkerFormControlLabel
                                        value="yes"
                                        control={
                                            <Radio
                                                checked={
                                                    applicant.address.id ===
                                                    applicant.permanentAddress
                                                        .id
                                                }
                                                value="Yes"
                                                name="radio-button-demo"
                                                inputProps={{
                                                    "aria-label": "Yes",
                                                }}
                                                disabled
                                            />
                                        }
                                        label={t('YES')||"Yes"}
                                    />
                                    <DarkerFormControlLabel
                                        value="no"
                                        control={
                                            <Radio
                                                checked={
                                                    applicant.address.id !==
                                                    applicant.permanentAddress
                                                        .id
                                                }
                                                value="Yes"
                                                name="radio-button-demo"
                                                inputProps={{
                                                    "aria-label": "Yes",
                                                }}
                                                disabled
                                            />
                                        }
                                        label={t('NO')||"No"}
                                    />
                                </div>
                            </div>
                        </Grid>
                        {applicant.address.id !==
                            applicant.permanentAddress.id && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <DarkerDisabledTextField
                                        name="country"
                                        label={t('COUNTRY')||"Country"}
                                        value={
                                            applicant.permanentAddress &&
                                            applicant.permanentAddress.country
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DarkerDisabledTextField
                                        name="postalCode"
                                        label={t('POSTAL_CODE')||"Postal code"}
                                        value={
                                            applicant.permanentAddress &&
                                            applicant.permanentAddress
                                                .postalCode
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="province"
                                            label={t('DISTRICT_PROVINCE')||"District / Province"}
                                            value={
                                                applicant.permanentAddress &&
                                                applicant.permanentAddress
                                                    .province
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="city"
                                            label={t('CITY_TOWN')||"City / Town"}
                                            value={
                                                applicant.permanentAddress &&
                                                applicant.permanentAddress.city
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <DarkerDisabledTextField
                                        name="address"
                                        label={t('ADDRESS')||"Address"}
                                        value={
                                            applicant.permanentAddress &&
                                            applicant.permanentAddress.address
                                        }
                                        disabled
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t('EMPLOYMENT_FINANCE')||"Employment & Finance"}
            </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle classes={classes} text={t('EMPLOYMENT_FINANCE')||"Employment & Finance"} /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="employmentType"
                                    label={t('EMPLOYMENT_TYPE')||"Employment type"}
                                    value={
                                        applicant.employment.employmentType
                                            .employmentType
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="industryType"
                                    label={t('INDUSTRY_BUSINESS_TYPE')||"Industry / Business type"}
                                    value={
                                        applicant.employment.industry.industry
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="workingYears"
                                label={t('CURRENT_WORKING_YEARS')||"Working years in current company"}
                                value={applicant.employment.workingYears}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="totalWorkingYears"
                                label={t('TOTAL_WORKING_YEARS')||"Total working years"}
                                value={applicant.employment.totalWorkingYears}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="noOfLoans"
                                label={t('EXISTING_LOANS')||"Number of existing loans"}
                                value={applicant.noOfLoans}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="loanPayment"
                                label={t('LOAN_REPAYMENT') + " "+`(${APP_CONFIGS.CURRENCY})`}
                                value={applicant.monthlyPayment || applicant.employment.monthlyIncome}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t('INCOME_PROOF')||"Income Proof"}
            </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle classes={classes} text={t('INCOME_PROOF')||"Income Proof"} /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="incomeDocumentType"
                                    label={t('DOCUMENT_TYPE')||"Document Type"}
                                    value={
                                        applicant.incomeProof.incomeProofType
                                            .incomeProofType
                                    }
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="monthlyIncome"
                                    label={t('MONTHLY_INCOME') +" "+ `(${APP_CONFIGS.CURRENCY})`}
                                    value={applicant.incomeProof.monthlyIncome}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <a
                                href={applicant.incomeProof.incomeProofDocument}
                                underline="always"
                                target="_blank"
                            >
                                {t('VIEW_DOCUMENT')||"View Document"}
                            </a>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <Typography
                   className={classes.titleLabel}>
                   {t('EMPLOYER_COMPANY_INFO')||"Employer & Company Info"}
            </Typography>
            <Paper className={classes.paper}>
                {/* <PaperTitle
                    classes={classes}
                    text={t('EMPLOYER_COMPANY_INFO')||"Employer & Company Info (optional)"}
                /> */}
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="employerName"
                                    label={t('EMPLOYER_COMPANY_NAME')||"Employer & Company Name"}
                                    value={applicant.employment.employerName}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <DarkerDisabledTextField
                                    name="businessYears"
                                    label={t('YEARS_OF_BUSINESS')||"Total years of business"}
                                    value={applicant.employment.totalYearsOfBusiness}
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="businessCountry"
                                label={t('COUNTRY')||"Country"}
                                value={applicant.employment.businessCountry}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="businessPostalCode"
                                label={t('POSTAL_CODE')||"Postal Code"}
                                value={applicant.employment.postalCode}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="businessProvine"
                                label={t('DISTRICT_PROVINCE')||"District/Province"}
                                value={applicant.employment.businessProvince}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="businessCity"
                                label={t('CITY_TOWN')||"City/Town"}
                                value={applicant.employment.businessCity}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DarkerDisabledTextField
                                name="businessAddress"
                                label={t('ADDRESS')||"Address"}
                                value={applicant.employment.businessAddress}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Fragment>
    
        )}
        </>       
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(ApplicationDetailApplicant);
