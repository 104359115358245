import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import storage from "../../utils/storage";
import { getCurrentUser } from '../../store/actions/auth'
import get from 'lodash/get'

const AuthListener = props => {
    const { getCurrentUser, currentUser, history, isLoadingLogout } = props
    const token = storage.getToken()
    useEffect(() => {
        if (!currentUser.id && token && !isLoadingLogout) {
            getCurrentUser()
        }
    },[currentUser, token])

    useEffect(() => {
        if (isLoadingLogout && !currentUser.id) {
            storage.clear()
            history.push('/login')
        }
    }, [isLoadingLogout, currentUser])
    return React.cloneElement(props.children)
}

const mapStateToProps = (state) => {
    return {
        isLoadingLogout: state.auth.isLoadingLogout,
        currentUser: get(state.auth, 'user')
    }
}

const mapDispatchToProps = {
    getCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthListener))
