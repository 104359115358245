import CircularProgress from '@material-ui/core/CircularProgress'

const LoaderComponent = (props: any) => {
    const { isLoading } = props
    return (
        <>
            {isLoading && (
                <div className='loader'>
                <CircularProgress />
            </div>
            )}
        </>
        
    );
};

export default LoaderComponent