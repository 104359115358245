import { useState, useEffect } from "react";
import { Box, Typography, AppBar, Toolbar, Button } from "@material-ui/core";
import AddTransactionDialog from "./AddTransactionDialog";
import { transactionRecords } from "./mockData";
import { useStyles } from "./styles";
import TransactionDetail from "./TransactionDetail";
import { useTranslation } from "react-i18next";

const ApplicationDetailTransaction = (props) => {
    const {
        branch,
        createTransction,
        getTransctionsByStringId,
        applicationStringId,
        transactionList,
        transactionInfo,
        applicationLoan,
        updateApplicationStatus,
        applicationId
    } = props;
    const { transactions } = transactionList;
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        getTransctionsByStringId({ applicationStringId });
    }, [getTransctionsByStringId, applicationStringId]);

    useEffect(() => {
        if (transactionInfo) {
            const { payment } = transactionInfo;
            if (payment && payment.id) {
                getTransctionsByStringId({ applicationStringId });
            }
        }
    }, [transactionInfo]);

    useEffect(() => {
        let totalPaid=0;
        if(transactions){
        for (let i=0;i<transactions.length;i++){
            totalPaid+= Math.abs(transactions[i].rec_amt)
        }
        console.log(totalPaid);
        const interest = Number(applicationLoan.totalInterest);
        const loanAmt = Number(applicationLoan.loanAmount);
        if(totalPaid>=loanAmt+interest){
            const params = {
                applicationId: applicationId,
                data: {
                    note: "",
                    viewed: 0,
                    status: "Matured",
                },
            };
    
            updateApplicationStatus(params);
        }
    }
        //Check if total pay is greater than or equla to loan amount & if yes change the status to mature
    },[transactions, applicationLoan])

    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    return (
        <>
            {transactions &&
                transactions.map((record, index) => {
                    return (
                        <TransactionDetail
                            key={index}
                            transactionDate={record.rec_date}
                            transactionAmount={Math.abs(record.rec_amt)}
                            transactionType={record.payment_type}
                            transactionMethod={record.transaction_method}
                        />
                    );
                })}

            <AppBar className={classes.appBar} color="inherit" elevation={5}>
                <Toolbar>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        p="8px"
                    >
                        <Button
                            variant="contained"
                            className={classes.addTransactionBtn}
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            {t('ADD_TRANSACTION')||"Add transaction"}
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <AddTransactionDialog
                handleClose={handleClose}
                open={openDialog}
                applicationStringId={applicationStringId}
                createTransction={createTransction}
                branch={branch}
            />
        </>
    );
};

export default ApplicationDetailTransaction;
