const inputStyles = {
  appBar: {
    position: "relative",
    backgroundColor: "#212121",
  },
  layout: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  menuButton: {
    marginLeft: "auto",
  },
  appTitle: {
    color: "#fff",
  },
  title: {
    fontSize: "20px",
    marginBottom: "25px",
  },
  stepLabel: {
    fontSize: "16px",
    marginTop: "16px",
  },
  paper: {
    boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
    borderRadius: "8px",
    marginTop: "15px",
    marginBottom: "24px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  inlineUpload: {
    display: "flex",
    color: "#212121",
    cursor: "pointer",
  },
  button: {
    marginTop: "28px",
    // marginLeft: theme.spacing(1),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  margin24: {
    marginRight: "24px",
    textTransform: "none",
  },
  formControl: {
    width: "100%",
  },
  mainContent: {
    padding: "32px",
    maxWidth: "768px",
    margin: "0px auto",
  },
  paperLabel: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#212121",
    padding: "16px 0px 8px 32px",
    borderBottom: "1px solid #DCDEDF",
  },
  paperContent: {
    padding: "32px",
  },
  updateBtn: {
    marginRight: "24px",
    textTransform: "none",
    width: "250px",
   backgroundColor:"#5f56a6",
    color:"white"
  },
  cancelBtn: {
    marginRight: "24px",
    textTransform: "none",
    width: "250px",
//    backgroundColor:"#5f56a6",
//     color:"white"
  },
  test: {
    width: "40px !important",
    border: "none",
    borderBottom: "1px solid #0486E2",
    marginRight: "10px",
  },
  blueBox: { 
    background: "#0486e2", 
    paddingLeft: "5px", 
    borderRadius: "5px" ,
    color:'white'
  }
};

export default inputStyles;
