import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import Authentication from './Authentication'
import { login, getEula } from '../../store/actions/auth'
import { updateUserEula } from '../../store/actions/users'

const AuthenticationContainer = (props:any) => <Authentication {...props} />

const mapStateToProps = (state:any) => {
    return {
        isLoadingLogin: state.auth.isLoadingLogin,
        loginError: state.auth.loginError,
        user: state.auth.user,
        userId: get(state.auth.user, 'id'),
        acceptedTerm: get(state.auth.user, 'termAgreement'),
        userRole: get(state.auth.user.outlet, 'role') || "S",
        eulaText: state.auth.eula ? get(state.auth.eula.data, 'text') : '',
        eulaVersion: state.auth.eula ? get(state.auth.eula.data.meta, 'version') : '1.0.0',
        updatedTerm: get(state.user.updatedTermObj, 'termAgreement')
    }
}

const mapDispatchToProps = {
    login,
    getEula,
    updateUserEula
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticationContainer))
)
