import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { getCities } from '../../store/actions/common'
import Guarantor from './Guarantor'
import { createCustomerDocument } from '../../store/actions/customer'
import {getEmploymentTypes, getIndustryType,createGuarantor} from '../../store/actions/customer'

const GuarantorContainer = (props: any) => <Guarantor {...props} />

const mapStateToProps = (state: any) => ({
    cityList: get(state.common.cityList, 'data'),
    employmentTypeList: state.customer.employmentTypeList || [],
    industryTypeList: state.customer.industryTypeList || [],
    guarantorInfo:state.customer.guarantorInfo||'',
    isLoadingCreateGuarantor: state.customer.isLoadingCreateGuarantor,
    currentUser: get(state.auth, 'user')
})

const mapDispatchToProps = {
    createCustomerDocument,
    getCities,
    getEmploymentTypes,
    getIndustryType,
    createGuarantor
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(GuarantorContainer))
)
