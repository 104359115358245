import { Box, Typography, Grid, Paper } from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
import { formatNumber } from "./mockData";
import { APP_CONFIGS } from "../../../constants/appConstants";

const TransactionDetail = (props) => {
    const {
        transactionDate,
        transactionAmount,
        transactionType,
        transactionMethod
    } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" color="textSecondary">Transaction date</Typography>
                                <Typography variant="caption" color="textPrimary">{moment(transactionDate).format("DD/MM/YYYY")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" color="textSecondary">Transaction amount</Typography>
                                <Typography variant="caption" color="error"
                                    style={{
                                        fontWeight: "500"
                                    }}
                                >{`${APP_CONFIGS.CURRENCY}`}{formatNumber(transactionAmount)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" color="textSecondary">Transaction type</Typography>
                                <Typography variant="caption" color="textPrimary">{transactionType}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" color="textSecondary">Transaction method</Typography>
                                <Typography variant="caption" color="textPrimary">{transactionMethod}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default TransactionDetail;
