import { useState, useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { map } from "lodash";
import TextField from "@material-ui/core/TextField";
import find from "lodash/find";
import moment from "moment";
import { RELATIONSHIP_OPTIONS, APP_CONFIGS } from "../../../constants/appConstants";
import ReferenceUpdateComponent from "../../application_update/reference_update/ReferenceUpdateComponent";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black", // (default alpha is 0.38)
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#6d6d6d",
        },
    },
})(TextField);
const ApplicationDetailReference = (props) => {
    const {
        classes,
        referenceList,
        status,
        confirmSubmit,
        handleChangeTab,
        updateApplicationReference,
        createProfileReference,
        getApplicationById,
        getReferenceById,
        calculateCde,
        isLoadingCalculateCde,
        t
    } = props;
    const getRelationShipByValue = (value) => {
        const relationShip = find(RELATIONSHIP_OPTIONS, function (o) {
            return o.value == value;
        });
        return relationShip ? relationShip.label : "";
    };
    return (
        <>
            {(status == "Pending" || status=="Inquiry")&& (
                <ReferenceUpdateComponent
                    handleChangeTab={handleChangeTab}
                    confirmSubmit={confirmSubmit}
                    updateApplicationReference={updateApplicationReference}
                    createProfileReference={createProfileReference}
                    getApplicationById={getApplicationById}
                    getReferenceById={getReferenceById}
                    calculateCde={calculateCde}
                    isLoadingCalculateCde={isLoadingCalculateCde}
                    t={t}
                />
            )}
            {(status !== "Pending" && status !=="Inquiry") && (
                <main className={classes.layout}>
                    
                        <Fragment>
                            <CssBaseline />
                            {map(referenceList, (item, key) => (
                                <>
                                <Typography
                                className={classes.titleLabel}>
                                {t('REFERENCE')+' '+`${key + 1}`}
                            </Typography>
                                <Paper className={classes.paper} key={key}>
                                    {/* <PaperTitle
                                        classes={classes}
                                        text={t('REFERENCE')+`${key + 1}`}
                                    /> */}
                                    <div className={classes.paperContent}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="relationship"
                                                    label={t('RELATIONSHIP')||"Relationship with applicant"}
                                                    value={getRelationShipByValue(
                                                        item.relationship
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} />
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="salutation"
                                                    label={t('SALUTATION')||"Salutation"}
                                                    value={item.salutation}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="name"
                                                    label={t('FULL_NAME')||"Full Name"}
                                                    value={item.name}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DarkerDisabledTextField
                                                    name="birthday"
                                                    label={t('DOB')||"Date of Birth"}
                                                    value={moment(
                                                        item.birthday
                                                    ).format("DD/MM/YYYY")}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DarkerDisabledTextField
                                                    name="gender"
                                                    label={t('GENDER')||"Gender"}
                                                    value={item.gender}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="phone"
                                                    label={t('MOBILE')||"Mobile number"}
                                                    value={item.phone}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="email"
                                                    label={t('EMAIL')||"Email address (optional)"}
                                                    value={item.email}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="country"
                                                    label={t('COUNTRY')||"Country"}
                                                    value={APP_CONFIGS.COUNTRY}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="postalCode"
                                                    label={t('POSTAL_CODE')||"Postal code"}
                                                    value={item.postalCode}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DarkerDisabledTextField
                                                    name="province"
                                                    label={t('DISTRICT_PROVINCE')||"District / Province"}
                                                    value={item.province}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <DarkerDisabledTextField
                                                    name="city"
                                                    label={t('CITY_TOWN')||"City / Town"}
                                                    value={item.city}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <DarkerDisabledTextField
                                                    name="address"
                                                    label={t('ADDRESS')||"Address"}
                                                    value={item.address}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                </>
                            ))}
                        </Fragment>
                   
                </main>
            )}
        </>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(ApplicationDetailReference);
