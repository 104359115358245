import React, {useState, useEffect} from "react";
import { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";

import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import UpdateVehicle from "../application_update/vehicle_update/UpdateVehicle";
import { includes } from "lodash";
import { AppBar, Toolbar, Button ,Box} from "@material-ui/core";
import { APP_CONFIGS } from "../../constants/appConstants";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black", // (default alpha is 0.38)
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#6d6d6d",
        },
    },
})(TextField);
const ApplicationDetailVehicle = (props) => {
    const {
        classes,
        applicationVehicle,
        status,
        handleChangeTab,
        confirmSubmit,
        getApplicationById,
        getVehicleDetails,
        vehicleDetails,
        updateVehicleDetails,
        applicationId,
        applicant,
        createDiscount,
        updateDiscount,
        discount,
        updateApplicationLoan,
        applicationLoan,
        calculateCde,
        isLoadingCalculateCde,
        t
    } = props;

    const [values, setValues] = useState({
        vehicleNo:'',
        chasisNo:''
    });
    const [discountType, setDiscountType] = useState(discount.discountFlat>0? 'FLAT': (discount.discountPercentage>0?'PERCENTAGE':''));
    const [discountValue, setDiscountValue] = useState(discount.discountFlat || discount.discountPercentage || 0.0)
    const [discountedPrice, setDiscountedPrice] = useState(discount.discountedPrice || applicationVehicle.price);
    const {vehicleNo, chasisNo} = values;

    useEffect(() => {
        setValues({
            vehicleNo: vehicleDetails.vehicleNo,
            chasisNo: vehicleDetails.chasisNo
        })
    }, [status, vehicleDetails]);

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    };

    const handleSubmit = () =>{
        const params = {
            applicationId,
            vehicleNo: vehicleNo,
            chasisNo: chasisNo
        }
        updateVehicleDetails(params);
    }

    return (
        <>
            {(status == "Pending" || status == "Inquiry") && (
                <UpdateVehicle
                    applicationVehicle={applicationVehicle}
                    handleChangeTab={handleChangeTab}
                    confirmSubmit={confirmSubmit}
                    getApplicationById={getApplicationById}
                    status={status}
                    applicant={applicant}
                    createDiscount={createDiscount}
                    updateDiscount={updateDiscount}
                    discount={discount}
                    updateApplicationLoan={updateApplicationLoan}
                    applicationLoan={applicationLoan}
                    calculateCde={calculateCde}
                    isLoadingCalculateCde={isLoadingCalculateCde}
                    t={t}
                />
            )}
            {(status !== "Pending" && status !== "Inquiry") && applicationVehicle.model && (
                <Fragment>
                    <Paper className={classes.paper}>
                        <div className={classes.paperContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="model"
                                            label={t('VEHICLE_MODEL')||"Vehicle model"}
                                            value={
                                                applicationVehicle.model.name
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="color"
                                            label={t('VEHICLE_COLOR')||"Vehicle color"}
                                            value={
                                                applicationVehicle.color.name
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="model"
                                            label={t('DISCOUNT_TYPE')||"Discount type"}
                                            value={
                                                discountType==''?'No discount':discountType
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <DarkerDisabledTextField
                                            name="model"
                                            label={"Discount " + (discountType==="FLAT"?`(${APP_CONFIGS.CURRENCY})`:'(%)')}
                                            value={
                                                discountValue
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <DarkerDisabledTextField
                                            name="insuranceType"
                                            label={t('INSURANCE')||"Insurance Type"}
                                            value={
                                                applicationVehicle.insuranceType
                                            }
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DarkerDisabledTextField
                                        name="model"
                                        label={t('VEHICLE_PRICE')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        value={discountedPrice}
                                        disabled
                                    />
                                </Grid>
                                {includes(['Accepted', 'Active', 'Closed'], status) && (
                                    <>
                                    <Grid item xs={12} sm={6}>
                                    <DarkerDisabledTextField
                                        name="vehicleNo"
                                        label={t('VEHICLE_NUMBER')||"Vehicle number"}
                                        value={vehicleNo}
                                        onChange={handleChange}
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <DarkerDisabledTextField
                                        name="chasisNo"
                                        label={t('CHASSIS_NUMBER')||"Chassis number"}
                                        value={chasisNo}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </Paper>
                </Fragment>
            )}
            {status == 'Active' && (
                 <AppBar className={classes.appBar} color="inherit" elevation={5}>
                 <Toolbar>
                     <Box
                         width="100%"
                         display="flex"
                         justifyContent="flex-end"
                         p="8px"
                         className={classes.rm64}
                     >
                         <Button
                             variant="contained"
                             className={classes.actionBtn}
                             color="primary"
                             onClick={handleSubmit}
                         >
                             {t('SAVE_AND_EXIT')||"Save &amp; Exit"}
                         </Button>
                     </Box>
                 </Toolbar>
             </AppBar>
            )}
        </>
    );
};
export default withStyles(inputStyles)(ApplicationDetailVehicle);
