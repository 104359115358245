import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageIcon from '@mui/icons-material/Language';
import LangSelector from "./languageSelector";
import { useTranslation } from 'react-i18next';


const DashboardNavbar = (props) => {
    const { onMobileNavOpen, classes, onLaguageSelectoOpen, user, updateUser } = props
    const {t} = useTranslation();
    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon />
            </IconButton>
                <RouterLink className={classes.appTitle} to="/dashboard">
                    <Typography
                     //sx={{ typography: { sm: 'h6', xs: 'body2' } }}
                     component="span"
                     noWrap={true}
                     style={{fontWeight:'bold'}}
                     >
                        {t('TVS_TITLE') || "TVS Auto Bangladesh"}
                    </Typography>
                </RouterLink>
                <Box sx={{ flexGrow: 1}} />
                <Box>
                    <IconButton  color="inherit">
                    <LangSelector classes={classes} user={user} updateUser={updateUser}/>
                    </IconButton>
                    
                </Box>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
