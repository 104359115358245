const inputStyles = {
    appBar: {
        position: "relative",
        backgroundColor: '#212121'
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    menuButton: {
        marginLeft: "auto"
    },
    appTitle: {
        color: '#fff',
    },
    title: {
        fontSize: '20px',
        fontWeight: '500'
    },
    subTitle: {
        marginBottom: '48px',
        fontSize: '12px',
        marginTop: '8px'
    },
    stepLabel: {
        fontSize: '16px',
        marginTop: '16px'
    },
    paper: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '8px',
        marginBottom: '24px'
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: '28px',
        // marginLeft: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    margin24: {
        marginRight: '24px',
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    mainContent: {
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto',
        paddingTop: '96px'
    },
    boxLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        // padding: '16px 0px 8px 32px',
        // borderBottom: '1px solid #DCDEDF'
    },
    paperContent: {
        padding: '32px'
    },
    actionBtn: {
        textTransform: 'none',
        width: '100%'
    },
    customerCount: {
        fontSize: '32px',
        fontWeight: '700'
    },
    tvsLoading: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    managementBox: {
        borderBottom: '1px solid #DCDEDF'
    },
    applicationStatus: {
        background: '#F8F8F8',
        borderRadius: '4px'
    },
    label:{
        fontSize:13,
        marginTop:5,
        marginLeft:10
    },
    count: {
        fontSize: '32px',
        fontWeight: '700',
        marginLeft:10
    },
    blueCard:{
        backgroundColor:'#d6e4ff'
    },
    grayCard:{
        backgroundColor:"#f8f8f8"
    },
    flexBox: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        margin: '20px 0px'
    }
}

export default inputStyles
