import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { LANGUAGES } from './constants/i18n'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(Backend)
    .use(LanguageDetector)
    .init({
        //lng:'en',
        //whitelist: LANGUAGES,
        //preload: LANGUAGES,
        //lowerCaseLng: true,
        fallbackLng: 'en', // remove when we upgrade react-i18next

        // ns: ['translations'],
        // defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },

        debug: true,

        detection: {
            order: ['localStorage'],
            lookupLocalStorage: 'languageCode'
        },
        // backend: {
        //     loadPath: '/assets/{ns}/{lng}.json',
            
       // },
        react: {
            wait: true,
            // bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            // nsMode: 'default',
            useSuspense: false
        }
    })

export default i18n
