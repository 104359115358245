import { useState, Fragment, useEffect } from "react";
import storage from "../../utils/storage";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import TvsLogo from "../../assets/img/tvs-auto-logo.png";
import { APP_CONFIGS } from "../../constants/appConstants";

import {
    Paper,
    Button,
    Dialog,
    Typography,
    Checkbox,
    FormControlLabel,
    AppBar,
    Toolbar,
    Box,
} from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FooterLogo from "../../assets/img/logo_group.png";
import { FormHelperText } from "@material-ui/core";

const Authentication = (props: any) => {
    const {
        classes,
        login,
        userRole,
        history,
        loginError,
        acceptedTerm,
        getEula,
        eulaVersion,
        eulaText,
        updateUserEula,
        userId,
        updatedTerm
    } = props;

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const token = storage.getToken();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        if (token && token.length > 0) {
            getEula();
        }
    }, [token]);

    useEffect(() => {
        if (token && acceptedTerm && acceptedTerm !== eulaVersion) {
            setOpen(true);
        }
    }, [token, acceptedTerm, eulaVersion]);

    useEffect(() => {
        if (userRole === "S" && token && acceptedTerm == eulaVersion) {
            history.push(`/dashboard`);
        } else if (userRole === "L" && token && acceptedTerm == eulaVersion) {
            history.push(`/dashboard`);
        }
        else if (userRole === "E" && token && acceptedTerm == eulaVersion) {
            history.push(`/management/dashboard`)
        }
        else if (loginError) {
            setShowErrorMessage(true);
        }
    }, [history, loginError, token, userRole, acceptedTerm]);

    const handleNext = () => {
        login({ email, password });
    };

    const handleCloseDialog = () => {
        const data = {
            userId,
            termAgreement: eulaVersion,
        };
        updateUserEula(data);
    };

    useEffect(() => {
        if (updatedTerm) {
            history.push('/dashboard')
        }
    }, [updatedTerm])

    return (
        <main className={classes.layout}>
            <Box mt="120px">
                <img
                    className="authentication--logo"
                    src={TvsLogo}
                    alt="logo"
                />
            </Box>

            <Paper className={classes.paper} elevation={5}>
                <Fragment>
                    <ValidatorForm onSubmit={handleNext}>
                        <TextValidator
                            label="Email address"
                            className={classes.loginField}
                            onChange={handleEmailChange}
                            name="email"
                            validators={["required", "isEmail"]}
                            errorMessages={[
                                "this field is required",
                                "Please enter a valid email",
                            ]}
                            value={email}
                            type="email"
                        />

                        <TextValidator
                            className={classes.loginField}
                            label="Password"
                            onChange={handlePasswordChange}
                            name="password"
                            type="password"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            value={password}
                        />

                        {showErrorMessage && (
                            <FormHelperText error={true}>
                                Incorrect username or password
                            </FormHelperText>
                        )}

                        <div className={classes.loginBtnWrapper}>
                            <Button
                                id="loginBtn"
                                variant="contained"
                                color="primary"
                                className={classes.loginBtn}
                                type="submit"
                                fullWidth
                            >
                                Login
                            </Button>
                        </div>
                    </ValidatorForm>

                    {/* <Box
                        width="100%"
                        textAlign="center"
                        mt="16px"
                        color="#5F56A6"
                        padding="10px"
                    >
                        <Typography>Forgotten password?</Typography>
                    </Box> */}
                </Fragment>
            </Paper>
            <Box
                textAlign="center"
                mt="2px"
                color="#5F56A6"
                padding="0px"
            >
                <Typography>Version {APP_CONFIGS.VERSION_NUMBER}</Typography>
            </Box>

            <AppBar className={classes.appBar} color="inherit" elevation={5}>
                <Toolbar>
                    <Box width="100%" display="flex" justifyContent="center">
                        <Box>
                            <Typography>Powered by</Typography>
                        </Box>

                        <Box ml="8px">
                            <img src={FooterLogo} alt="logo" />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {eulaText && eulaText.length > 0 && (
                <EulaDialogs
                    classes={classes}
                    open={open}
                    text={eulaText}
                    onClose={handleCloseDialog}
                />
            )}
        </main>
    );
};

export default withStyles(inputStyles)(Authentication);

const renderText = (content: any) => {
    return content.split("\n").map((item: any, key: number) => (
        <span key={key}>
            {item}
            <br />
        </span>
    ));
};

const EulaDialogs = (props: any) => {
    const { classes, open, onClose, text } = props;

    const handleClose = () => {
        onClose();
    };

    const [acceptTerm, setAcceptTerm] = useState(true);
    const [termError, setTermError] = useState(false);

    const handleAcceptedChange = (e: any) => {
        setAcceptTerm(!acceptTerm);
        if (acceptTerm) {
            setTermError(false);
        }
    };

    const handleSubmit = () => {
        if (!acceptTerm) {
            setTermError(true);
        } else {
            handleClose();
        }
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle className={classes.dialogTitle}>
                    Terms & Conditions
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {renderText(text)}
                </DialogContent>

                <div className={classes.termChecked}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptTerm}
                                onChange={handleAcceptedChange}
                            />
                        }
                        label="I agree to the above Terms & Conditions"
                    />
                    {termError && (
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            className={classes.errorLabel}
                        >
                            If you don’t agree to it, you cannot log in.
                        </Typography>
                    )}
                </div>

                <div className={classes.dialogAction}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        Submit
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

const DialogTitle = (props: any) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles(() => ({}))(MuiDialogContent);
