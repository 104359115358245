const inputStyles = {
    appBar: {
        position: "relative",
        backgroundColor: '#212121'
    },
    layout: {
        position:"relative",
        marginLeft: "-2rem",
        marginRight: "-2rem",
        backgroundColor:'#EFEFEF'
    },
    menuButton: {
        marginLeft: "auto"
    },
    appTitle: {
        color: '#fff',
    },
    title: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: '32px',
        marginBottom: '24px',
        marginTop:'32px'
    },
    titleLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '32px',
        color: '#212121',
        padding: '0px 0px 16px 4px',
        borderBottom: '1px solid #DCDEDF'
    },
    stepLabel: {
        fontSize: '16px',
        marginTop: '16px'
    },
    paper: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '8px',
        marginBottom: '24px'
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: '28px',
        // marginLeft: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    margin24: {
        marginRight: '24px',
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    mainContent: {
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto',
        paddingTop: '96px'
    },
    paperLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        padding: '16px 0px 8px 32px',
        borderBottom: '1px solid #DCDEDF'
    },
    paperContent: {
        padding: '32px'
    },
    actionBtn: {
        marginRight: '24px',
        textTransform: 'none',
        width: '250px'
    },
    test: {
        width: '40px !important',
        border: 'none',
        borderBottom: '1px solid #0486E2',
        marginRight: '10px'
    },
    label: {
        fontWeight: '300',
        fontSize:'18px'
    },
    labelContent: {
        color: '#212121',
        fontWeight: '300',
        fontSize: '18px'
    },
    paperContentLower: {
        padding: "32px",
        justifyContent: "space-between"
    },
    paperContentColoured: {
        padding: "32px",
        justifyContent: "space-between",
        backgroundColor: "#F2F3FF"
    },
    flexWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogContent: {
        paddingTop: '0px',
        paddingBottom: '32px'
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: "10px"
      },
      radioContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
      },

}

export default inputStyles
