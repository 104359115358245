const inputStyles = {
    appBar: {
        bottom: "0",
        top: "auto",
    },
    appBottomBar: {
        bottom: "0",
        top: "auto",
        maxWidth: "768px",
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    menuButton: {
        marginLeft: "auto",
    },
    appTitle: {
        color: "#fff",
    },
    title: {
        fontSize: "20px",
        marginBottom: "48px",
    },
    stepLabel: {
        fontSize: "16px",
        marginTop: "16px",
    },
    paper: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "8px",
        marginBottom: "24px",
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: "28px",
        // marginLeft: theme.spacing(1),
    },
    saveButton: {
        marginRight: "20px",
        textTransform: "none",
        width: "150px",
    },
    submitButton: {
        marginRight: "20px",
        textTransform: "none",
        width: "150px",
        color: "white",
        backgroundColor: "#5f56a6",
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    margin24: {
        marginRight: "24px",
        textTransform: "none",
    },
    formControl: {
        width: "100%",
    },
    mainContent: {
        padding: "32px",
        maxWidth: "768px",
        margin: "0px auto",
    },
    paperLabel: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#212121",
        padding: "16px 0px 8px 32px",
        borderBottom: "1px solid #DCDEDF",
    },
    paperContent: {
        padding: "32px",
    },
    actionBtn: {
        marginRight: "24px",
        textTransform: "none",
        width: "250px",
    },
    staffActionBtn: {
        textTransform: 'unset',
        width: '158px',
        height: '44px'
    },
    rm64 : {
        paddingRight: '110px'
    },
    label: {
        fontWeight: '300',
        fontSize:'18px'
    },
    labelContent: {
        color: '#212121',
        fontWeight: '300',
        fontSize: '18px'
    },
    paperContentLower: {
        padding: "32px",
        justifyContent: "space-between"
    },
    paperContentColoured: {
        padding: "32px",
        justifyContent: "space-between",
        backgroundColor: "#F2F3FF"
    }
};

export default inputStyles;
