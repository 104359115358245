import { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import Approved from "../../assets/img/approved 1.svg";
import Pending from "../../assets/img/pending 1.svg";
import Enquiry from "../../assets/img/Vector-3.svg";

const CreateApplication = (props) => {
    const {
        classes,
        getCustomers,
        customerCount,
        isLoadingGetCustomers,
        getApplicationInfo,
        applicationInfo,
        clearData,
        isManager,
    } = props;

    const {t} = useTranslation();
    const handleNext = () => {
        const { history } = props;
        history.push("/customer/create");
    };

    useEffect(() => {
        clearData();
        getCustomers();
        getApplicationInfo();
    }, []);
    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h2"
                    variant="h4"
                >
                    {t('DASHBOARD')}
                </Typography>
                <Typography
                    className={classes.subTitle}
                    component="h6"
                    gutterBottom
                >
                    {t('TODAY')} {moment().format('DD/MM/YY')} {moment().format('h:mm a')}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    {!isManager && (
                        <Paper className={classes.paper}>
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <span id="totalCustomerCount" className={classes.customerCount}>
                                            {customerCount}
                                        </span>
                                        &nbsp;
                                        <span
                                            className={
                                                classes.customerCountText
                                            }
                                        >
                                            {t('CUSTOMERS_RECORDED')}
                                        </span>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            id="recordBtn"
                                            style={{ backgroundColor: "#5F56A6" }}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            className={classes.actionBtn}
                                            onClick={handleNext}
                                        >
                                            {t('RECORD_BUTTON')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    )}
                    <Typography
                        className={classes.titleLabel}
                        >
                    {t('LOAN_APPLICATION_STATUS')}
                    </Typography>
                    {/* <Paper className={classes.paperStatus}> */}
                        {/* <PaperTitle
                            classes={classes}
                            text={t('LOAN_APPLICATION_STATUS')}
                        /> */}
                        <div className={classes.paperContentStatus}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className={classes.greyBox}
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=accepted"
                                    >
                                        <Card className={classes.grayCard} id='acceptedCard'>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('ACCEPTED')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Accepted
                                                    ? applicationInfo.Accepted
                                                    : 0}
                                            </Typography>
                                        </Card>
                                    </Link>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className={classes.greyBox}
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=rejected"
                                    >
                                        <Card className={classes.grayCard} id='rejectedCard'>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('REJECTED')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Rejected
                                                    ? applicationInfo.Rejected
                                                    : 0}
                                            </Typography>
                                        </Card>
                                    </Link>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className={classes.greyBox}
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=review"
                                    >
                                        <Card className={classes.grayCard} id='reviewCard'>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('PENDING_TO_REVIEW')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Review
                                                    ? applicationInfo.Review
                                                    : 0}
                                            </Typography>
                                        </Card>
                                    </Link>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=withdrawn"
                                    >
                                        <Card className={classes.grayCard} id='withdrawnCard'>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('WITHDRAWN')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo?.Withdrawn
                                                    ? applicationInfo.Withdrawn
                                                    : 0}
                                            </Typography>
                                        </Card>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{marginTop:"12px"}}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                    
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=approved"
                                    >
                                        <Card className={classes.blueCard}  id='approvedCard'>
                                        <div style={{display: 'flex'}}>
                                        <img
                                            src={Approved}
                                            alt="approved"
                                            //style={{ width: "24px" }}
                                            
                                        />
                                        <div style={{paddingLeft:'4px'}}> 
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('APPROVED')} ({t('FOLLOW_UP')})
                                            </Typography>
                                            
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Approved
                                                    ? applicationInfo.Approved
                                                    : 0}
                                            </Typography>
                                            </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=pending"
                                    >
                                        <Card className={classes.blueCard} id='pendingCard'>
                                        <div style={{display: 'flex'}}>
                                        <img
                                            src={Pending}
                                            alt="pending"
                                            //style={{ width: "24px" }}
                                            
                                        />
                                        <div style={{paddingLeft:'4px'}}>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('PENDING_TO_SUBMIT')} ({t('FOLLOW_UP')})
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Pending
                                                    ? applicationInfo.Pending
                                                    : 0}
                                            </Typography>
                                            </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{marginTop:"12px"}}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Link
                                        className="no-decoration"
                                        to="/applications?filter=inquiry"
                                    >
                                        <Card className={classes.blueCard} id='inquiryCard'>
                                        <div style={{display: 'flex'}}>
                                        <img
                                            src={Enquiry}
                                            alt="enquiry"
                                            //style={{ width: "24px" }}
                                            
                                        />
                                        <div style={{paddingLeft:'4px'}}>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                                className={classes.label}
                                            >
                                                {t('ENQUIRY')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                className={classes.count}
                                            >
                                                {applicationInfo &&
                                                applicationInfo.Inquiry
                                                    ? applicationInfo.Inquiry
                                                    : 0}
                                            </Typography>
                                            </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    {/* </Paper> */}
                    {isManager && (
                        <>
                        <Typography
                        className={classes.titleLabel}
                        style={{paddingTop:'24px'}}
                        >
                    {t('LOAN_STATUS')||"Loan Status"}
                    </Typography>
                        <ManagerApplicationStatus
                            applicationInfo={applicationInfo}
                            classes={classes}
                            t={t}
                        />
                        </>
                    )}
                </Fragment>
            </div>
        </main>
    );
};

const ManagerApplicationStatus = (props) => {
    const { applicationInfo, classes, t } = props;
    return (
        
        
            <div className={classes.paperContentStatus}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Link
                            className="no-decoration"
                            to="/applications?filter=active"
                        >
                            <Card className={classes.grayCard}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('ACTIVE')||"Active"}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    className={classes.count}
                                >
                                    {applicationInfo && applicationInfo.Active
                                        ? applicationInfo.Active
                                        : 0}
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Link
                            className="no-decoration"
                            to="/applications?filter=closed"
                        >
                            <Card className={classes.grayCard}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('CLOSED')||"Closed"}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    className={classes.count}
                                >
                                    {applicationInfo && applicationInfo.Closed
                                        ? applicationInfo.Closed
                                        : 0}
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Link
                            className="no-decoration"
                            to="/applications?filter=matured"
                        >
                            <Card className={classes.grayCard}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Matured"}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    className={classes.count}
                                >
                                    {applicationInfo && applicationInfo.Matured
                                        ? applicationInfo.Matured
                                        : 0}
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        // </Paper>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(CreateApplication);
