import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ApplicationDetailGeneral from "./ApplicationDetailGeneral";
import ApplicationDetailApplicant from "./ApplicationDetailApplicant";
import ApplicationDetailVehicle from "./ApplicationDetailVehicle";
import ApplicationDetailTransaction from "./ApplicationDetailTransaction/ApplicationDetailTransaction";
import ApplicationDetailGuarantor from "./ApplicationDetailGuarantor";
import ApplicationDetailPayment from "./ApplicationDetailPaymentComponent";
import ApplicationDetailLoan from "./ApplicationDetailLoan";
import ApplicationDetailInvoice from "./ApplicationDetailInvoice";
import Grid from "@material-ui/core/Grid";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography, AppBar, Toolbar, Button } from "@material-ui/core";
import ApplicationDetailDocument from "./ApplicationDetailDocumentComponent";
import { ApplicationDetailReference } from "./ApplicationDetailReference";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { includes } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { calculateCde } from "../../store/actions/customer";
import {
    CircularInput,
    CircularTrack,
    CircularProgress,
    CircularThumb,
    useCircularInputContext
  } from "react-circular-input";
import Paper from "@material-ui/core/Paper";
import { APP_CONFIGS, STATUS_COLOR_CODING, STATUS_LABEL } from "../../constants/appConstants";
import TabScrollButton from '@material-ui/core/TabScrollButton';
import DateFnsUtils from "@date-io/date-fns";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        height: "100px",
        backgroundColor: "red",
    },
    resize: {
        fontSize: 50,
    },
});

const MyTabScrollButton = withStyles(theme => ({
    root: {
      width: 30,
      overflow: 'hidden',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0,
      },
    },
  }))(TabScrollButton);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const CdeGauge = (props) => {
    const { cdeInfo, applicationDetail } = props;
    //const { score } = cdeInfo;
    //let score= applicationDetail.cdeScore? applicationDetail.cdeScore : (cdeInfo && cdeInfo.score ? cdeInfo.score : 0)
    let score = cdeInfo && cdeInfo.score ? cdeInfo.score : (applicationDetail.cdeScore? applicationDetail.cdeScore : 0);
    const getColor = () => {
        if (score > 649) {
            return "#39B542";
        } else if (score > 500 && score < 650) {
            return "#ED9807";
        } else {
            return "#E22B16";
        }
    };
    const value = score>0?(score-300)/600 : 0;
    return (
        <CircularInput radius={70} value={value}>
        <CircularTrack  />
        <CircularProgress 
        stroke={`${getColor()}`}/>
        <text x={70} y={70} textAnchor="middle" dy="0.3em">
		 {score>0?score : 'N.A.'}
		</text>
        </CircularInput>
    )
};

const ApplicationDetail = (props) => {
    const {
        classes,
        getApplicationById,
        applicationDetail,
        applicationVehicle,
        applicationLoan,
        getGuarantorsById,
        guarantorList,
        updateApplicationStatus,
        updateInfo,
        customer,
        reference,
        getReferenceById,
        isManager,
        paymentList,
        history,
        status,
        activateApplication,
        activateInfo,
        currentUser,
        updateApplicationLoan,
        updateApplicationReference,
        updateApplicationGuarantor,
        getTransctionsByStringId,
        transactionList,
        createTransction,
        branch,
        transactionInfo,
        totalCost,
        createLoan,
        createProfileReference,
        createGuarantor,
        createProfileEmployment,
        createCustomerDocument,
        addRemarks,
        getRemarks,
        remarksHistory,
        addVehicleDetails,
        getVehicleDetails,
        vehicleDetails,
        updateVehicleDetails,
        updateProfile,
        updateEmployment,
        createIdentityDocument,
        createIncomeProof,
        createAddressProof,
        updateIdentityDocument,
        updateIncomeProof,
        updateAddressProof,
        updateCustomerData,
        createAdditionalInfo,
        getAdditionalInfo,
        updateAdditionalInfo,
        additionalInfo,
        getDocumentList,
        createdApplication,
        documentList,
        createApplication,
        updateDocument,
        cdeInfo,
        createDiscount,
        getDiscount,
        updateDiscount,
        createFees,
        getFees,
        updateFees,
        discount,
        fees,
        isLoadingCalculateCde
    } = props;
    const { applicationId } = props.match.params;
    const {applicationStringId } = applicationDetail
    const [value, setValue] = useState(0);
    const [cloneAction, setCloneAction] = useState(false);
    const [cdeScore,setCdeScore]= useState(0);
    const [cdeBoxOpen, setCdeBoxOpen] = useState(false);
    const [firstDueDate, setFirstDueDate] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
      };
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        getApplicationById(applicationId);
        getGuarantorsById(applicationId);
        getReferenceById(applicationId);
        getRemarks(applicationId);
        getAdditionalInfo(applicationId);
        getDiscount(applicationId);
        getFees(applicationId)
        calculateCde(applicationId);
    }, [applicationId, getApplicationById]);


    useEffect(()=> {
        const { score } = cdeInfo;
        setCdeScore(score);
    },[cdeInfo]);

    useEffect(() => {
        const { status, viewed } = applicationDetail
        if (!isManager && viewed == '0' && includes(['Approved'], status)) {
            updateViewed()
        }
        if (isManager && viewed=='0' && includes(['Accepted', 'Review'], status)) {
            updateViewed()
        }
    }, [isManager, applicationDetail])


    useEffect(() => {
        if (updateInfo && parseInt(updateInfo.id) === parseInt(applicationId)) {
            getApplicationById(applicationId);
        }
    }, [updateInfo]);

    useEffect(() => {
        const { status } = applicationDetail;
        if(includes(['Accepted', 'Active', 'Rejected'], status)){
            getVehicleDetails(applicationId);
        }
        if(includes(['Rejected', 'Withdrawn'], status)){
            getDocumentList(applicationId);
        }
    }, [status])

    const [approveOpen, setApproveOpen] = useState(false);
    const [activateOpen, setActivateOpen] = useState(false);
    const [closeOpen, setCloseOpen] = useState(false);
    const [withdrawlDialogOpen, setWithdrawlDialogOpen] = useState(false)
    const [cloneDialogueOpen, setCloneDialogueOpen] = useState(false);
    const [saveChanges, setSaveChanges] = useState(false);
    const [dateDialogOpen, setDateDialogOpen] = useState(false);

    const updateViewed = () => {
        const params = {
            applicationId: applicationId,
            data: {
                viewed: 1
            },
        };
        updateApplicationStatus(params);
    }

    const handleCloneOpen = () =>{
        setCloneDialogueOpen(true);
    }

    const handleCloneClose = () =>{
        setCloneDialogueOpen(false);
    }

    const handleApproveClose = () => {
        setApproveOpen(false);
    };
    const handleApprove = (value) => {
        const params = {
            applicationId: applicationId,
            data: {
                note: value,
                viewed: 0,
                status: "Approved",
                reviewedBy: currentUser.id
            },
        };
        setApproveOpen(false);
        updateApplicationStatus(params);
        history.push("/dashboard")
    };

    const handleWithdrawl = value => {
        const params = {
            applicationId: applicationId,
            data: {
                note: value,
                viewed: 0,
                status: "Withdrawn",
                //reviewedBy: currentUser.id
            },
        };
        setWithdrawlDialogOpen(false);
        updateApplicationStatus(params);
    }
    const handleTabChange = ()=>{
        
            console.log("Please save the changes");
        
    }
    const [rejectOpen, setRejectOpen] = useState(false);
    const [resubmissionOpen, setResubmissionOpen] = useState(false);

    const handleResubmissionOpen = () =>{
        setResubmissionOpen(true);
    }
    const handleResubmissionClose = () =>{
        setResubmissionOpen(false);
    }

    const handleResubmission = (value) =>{
        let params = {
            applicationId: applicationId,
            data: {
                note: "Resubmission",
                viewed: 0,
                status: "Pending",
                reviewedBy: currentUser.id
            },
        };

        updateApplicationStatus(params);

         params = {
            applicationId: applicationId,
            remarks: value,
            createdBy: currentUser.firstName+" "+currentUser.lastName
        };
         addRemarks(params);

         setResubmissionOpen(false);
         history.push("/dashboard");
    }

    const handleRejectClose = () => {
        setRejectOpen(false);
    };
    const handleReject = (value) => {
        const params = {
            applicationId: applicationId,
            data: {
                note: value,
                viewed: 0,
                status: "Rejected",
                reviewedBy: currentUser.id
            },
        };

        updateApplicationStatus(params);
        setRejectOpen(false);
        history.push("/dashboard")
    };
    const [submitOpen, setSubmitOpen] = useState(false);

    const handleSubmitClickOpen = () => {
        setSubmitOpen(true);
    };
    const handleSubmitClose = () => {
        setSubmitOpen(false);
    };
    const handleActivateClose = () => {
        setActivateOpen(false);
    };

    const saveAndExit = () => {
        history.push("/dashboard");
    };
    const handleAppSubmit = () => {
        const params = {
            applicationId: applicationId,
            data: {
                note: "",
                viewed: 0,
                status: "Review",
            },
        };

        updateApplicationStatus(params);
        setSubmitOpen(false);
        history.push("/dashboard")
    };

    const handleAccept = () => {
        history.push(`/application/${applicationId}/loan-offer`);
    };

    const handleApproveClickOpen = () => {
        setApproveOpen(true);
    };

    const handleRejectClickOpen = () => {
        setRejectOpen(true);
    };

    const handleActivate = (values) => {
        const params = {
            applicationId: applicationId,
            data: {
                viewed: 0,
                status: "Active",
                firstDueDate: new Date(firstDueDate).toString()
            },
        };
        const data = {
            applicationId: applicationId,
            vehicleNo: values.vehicleNo,
            chasisNo: values.chasisNo,
        }
        //console.log("Inside handle activate: "+JSON.stringify(values));
        addVehicleDetails(data);
        setActivateOpen(false);
        //console.log(new Date(firstDueDate));
        activateApplication(params);
        history.push("/dashboard")
    };

    const handleSkip = () =>{
        const params = {
            applicationId: applicationId,
            data: {
                viewed: 0,
                status: "Active",
                firstDueDate: new Date(firstDueDate).toString()
            },
        };
        setActivateOpen(false);
        //console.log(new Date(firstDueDate));
        activateApplication(params);
        history.push("/dashboard")
    }

    
    const submitCloseApp = note => {
        const params = {
            applicationId: applicationId,
            data: {
                note,
                viewed: 0,
                status: "Closed",
            },
        };

        updateApplicationStatus(params);
        setCloseOpen(false)
        changeTab(0)
        history.push("/dashboard")
    }

    const handleActivateOpen = () => {
        setActivateOpen(true);
    };

    const handleDateOpen = () =>{
        setDateDialogOpen(true);
    };

    const handleDateClose = () =>{
        setDateDialogOpen(false);
    }

    const handleDateSubmission = async (date) => {
        setFirstDueDate(date);
        handleDateClose();

        //Skipping the vehicle details submission
        const params = {
            applicationId: applicationId,
            data: {
                viewed: 0,
                status: "Active",
                firstDueDate: new Date(date).toString()
            },
        };
        //setActivateOpen(false);
        //console.log(new Date(firstDueDate));
        activateApplication(params);
        history.push("/dashboard")
    }



    const handleInquiryPurchase = () =>{
        history.push("/client/"+customer.id+"/selectVehicle/"+applicationId+"/continue")
           
    }

    const handleCdeBoxOpen = () => {
        setCdeBoxOpen(true);
    };

    const handleCdeBoxClose = () => {
        setCdeBoxOpen(false);
    };

    useEffect(() => {
        if (activateInfo && activateInfo.data && activateInfo.data.length > 0) {
            getApplicationById(applicationId);
            setValue(7);
        }
    }, [activateInfo]);

    const changeTab = (value) => {
        console.log("Please save the changes")
        setValue(value)
    };

    const confirmSubmit = () => setSubmitOpen(true);

    const handleCloseApp = () => setCloseOpen(true);

    const handleWithdrawlApp = () => {
        setWithdrawlDialogOpen(true)
    }

    const handleClone = async() =>{
        setCloneAction(true);
        const data = {
            customerId: applicationDetail.customerId,
            status: "Pending",
            vehicleId: applicationDetail.vehicleId,
            insuranceType: applicationDetail.insuranceType,
            createdBy: currentUser.id,
        };
        await createApplication(data);
    }

    const cloneRemaining = () =>{
        const newApplicationId = createdApplication.id;
        let data = {
            applicationId: newApplicationId,
            downPayment: parseInt(applicationLoan.downPayment),
            loanAmount: parseInt(applicationLoan.loanAmount),
            loanYears: parseInt(applicationLoan.loanYears),
            processingFee: applicationLoan.processingFee,
            paymentMethod: applicationLoan.paymentMethod,
            bankName: applicationLoan.bankName,
            flatRate: applicationLoan.flatRate,
            reducingRate: applicationLoan.reducingRate,
            monthlyPayment: applicationLoan.monthlyPayment,
            numberOfPayments: applicationLoan.loanYears,
            totalInterest: applicationLoan.totalInterest,
            totalCost: applicationLoan.totalCost,
        };
        console.log(data);
        createLoan(data);
        var params = {
            applicationId: newApplicationId,
            data: {
                cdeScore: applicationDetail.cdeScore,
            },
        };
        updateApplicationStatus(params);
        if (reference !== undefined && reference.length > 0){
            const referenceOne = {
            applicationId: newApplicationId,
            relationship: reference[0]?.relationship,
            salutation: reference[0]?.salutation,
            residentalStatus: reference[0]?.residentalStatus,
            name: reference[0]?.name,
            birthday: reference[0]?.birthday,
            gender: reference[0]?.gender,
            phone: reference[0]?.phone,
            email: reference[0]?.email,
            country: reference[0]?.country,
            postalCode: reference[0]?.postalCode,
            province: reference[0]?.province,
            city: reference[0]?.city,
            address: reference[0]?.address,
            yearsLiving: reference[0]?.yearsLiving,
        };
        const referenceTwo = {
            applicationId: newApplicationId,
            relationship: reference[1]?.relationship,
            salutation: reference[1]?.salutation,
            residentalStatus: reference[1]?.residentalStatus,
            name: reference[1]?.name,
            birthday: reference[1]?.birthday,
            gender: reference[1]?.gender,
            phone: reference[1]?.phone,
            email: reference[1]?.email,
            country: reference[1]?.country,
            postalCode: reference[1]?.postalCode,
            province: reference[1]?.province,
            city: reference[1]?.city,
            address: reference[1]?.address,
            yearsLiving: reference[1]?.yearsLiving,
        };
        createProfileReference({ referenceOne, referenceTwo });
    }

         params = {
            applicationId: newApplicationId,
            homePhoneNumber: additionalInfo.homePhoneNumber || "",
            officePhoneNumber: additionalInfo.officePhoneNumber || "",
            email: additionalInfo.email || "",
            facebookId: additionalInfo.facebookId || ""
        }
         createAdditionalInfo(params)

         if (guarantorList !== undefined && guarantorList.length > 0){
             let data = {
            relationShip: guarantorList[0]?.relationShip,
            salutation: guarantorList[0]?.salutation,
            applicationId: newApplicationId,
            name: guarantorList[0]?.name,
            birthday: guarantorList[0]?.birthday,
            gender: guarantorList[0]?.gender,
            phone: guarantorList[0]?.phone,
            email: guarantorList[0]?.email,
            employmentTypeId: guarantorList[0]?.employmentType?.id,
            industryId: guarantorList[0]?.industry?.id,
            companyName: guarantorList[0]?.companyName,
            idTypeId: guarantorList[0]?.identityDocument?.idTypeId,
            idNumber: guarantorList[0]?.identityDocument?.idNumber,
            selfPhoto: guarantorList[0]?.identityDocument?.selfPhoto,
            selfPhotoUrl: guarantorList[0]?.identityDocument?.selfPhoto,
            idFrontImg: guarantorList[0]?.identityDocument?.idFrontImg,
            idFrontImgUrl: guarantorList[0]?.identityDocument?.idFrontImg,
            idBackImg: guarantorList[0]?.identityDocument?.idBackImg,
            idBackImgUrl: guarantorList[0]?.identityDocument?.idBackImg,
            addressProofTypeId: guarantorList[0]?.addressProofDocument?.addressProofTypeId,
            country:APP_CONFIGS.REGION,
            postalCode: guarantorList[0]?.addressProofDocument?.postalCode,
            province: guarantorList[0]?.addressProofDocument?.province,
            city: guarantorList[0]?.addressProofDocument?.city,
            address: guarantorList[0]?.addressProofDocument?.address,
            addressProof:guarantorList[0]?.addressProofDocument?.addressProofDocument,
            addressProofUrl:guarantorList[0].addressProofDocument?.addressProofDocument,
            identityId: guarantorList[0]?.identityDocument?.idTypeId,
            addressProofId: guarantorList[0]?.addressProofDocument?.addressProofTypeId,
        };
        createGuarantor(data);
    }

        if (documentList !== undefined && documentList.length > 0){
            documentList.forEach(document => {
                let data = {
                    categoryId: document.categoryId,
                    documentTypeId: document.documentTypeId,
                    note: document.note,
                    document: document.documentUrl,
                    status: "active",
                    createdBy: "",
                    applicationId: newApplicationId,
                }
                updateDocument(data);
            })
        }
        history.push('/dashboard')
    }

    useEffect(() => {
        if (
            createdApplication &&
            createdApplication.id &&
            createdApplication.status == "Pending" &&
            cloneAction
        ) {
            cloneRemaining();
        }
    }, [createdApplication]);

    const getColor = (score) => {
        if (score > 649) {
            return "#39B542";
        } else if (score > 500 && score < 650) {
            return "#ED9807";
        } else {
            return "#E22B16";
        }
    };

    return (
        <>
            <main className={classes.layout}>
                <CssBaseline />
                <div className={classes.mainContent}>
                    <div className={classes.contentHeader}>
                        <Typography
                            className={classes.title}
                            component="h3"
                            variant="h4"
                        >
                            {status!=='Inquiry'? t('LOAN_APPLICATION_DETAIL'):t('CUSTOMER_ENQUIRY_DETAIL')}
                        </Typography>


                        {!includes(['Active', 'Withdrawn','Inquiry'], status)  && (
                            <CancelOutlinedIcon
                                color="error"
                                className={classes.closeIcon}
                                onClick={handleWithdrawlApp}
                            />
                        )}
                        {status === 'Active' && (
                            <NotInterestedIcon
                                color="error"
                                className='closeAppIcon'
                                onClick={handleCloseApp}
                            />
                        )}
                        
                    </div>
                    <Typography
                            className={classes.subTitle}
                            component="h6"
                            gutterBottom
                        >
                    {t('LAST_UPDATED_ON') || "Last updated on"} {moment(applicationDetail.updatedAt).format(
                                          "DD/MM/YY, h:mma"
                                      )}
                </Typography>
                
                <Typography
                            className={classes.wrapIcon}
                            variant="subtitle1"
                            component="h6"
                            gutterBottom
                            style={{ color: STATUS_COLOR_CODING[`${status}`]}}
                        >
                    {includes(['Pending','Review','Rejected', 'Withdrawn','Inquiry'], status)  && <ErrorOutlineRoundedIcon style={{verticalAlign:"middle", position: 'relative', top: '3px'}}/>}
                    {includes(['Active', 'Accepted','Approved'], status)  && <CheckCircleOutlineIcon style={{verticalAlign:"middle", position: 'relative', top: '3px'}}/>}
                    {STATUS_LABEL[`${status}`]}
                </Typography>
                { }
                {cdeScore>0 && <Typography
                            className={classes.wrapIcon}
                            variant="subtitle1"
                            component="h6"
                            gutterBottom
                            style={{ color: "#FFA24D", marginLeft:'8px' }}
                        >
                    <TripOriginIcon style={{ color: getColor(cdeScore), verticalAlign:'middle', position: 'relative', top: '3px' }}/>
                    <Link  href="#"
                        onClick={handleCdeBoxOpen} style={{ textDecoration: 'none', color: getColor(cdeScore) }}
                    > {"CDE Score : "+cdeScore}</Link> 
                </Typography>}
                    <Box display="flex" justifyContent="center" width="100%" 
                    //sx={{ flexGrow: 1, border: 1 }} 
                    style={{overflow:'auto', backgroundColor:'#EFEFEF'}}>
                    <AppBar
                        className={classes.tabBarHeader}
                        position="static"
                        color="default"
                        style={{
                            justifyContent:"center" ,width:"100%"
                        }}
                    >
                        <Tabs
                            sx={{
                                variant:{xs : 'scrollable', sm:'fullWidth'},
                                // overflow: 'hidden',
                            }}
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            //justifyContent="centre"
                            //variant="fullWidth"
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            
                        >
                            <Tab
                                className={classes.tabLabel}
                                label={t('GENERAL')||"General"}
                                {...a11yProps(0)}
                                style={{minWidth:"200px",width: '25%',}}
                                onChange={handleTabChange}
                            />
                            <Tab
                                className={classes.tabLabel}
                                label={t('APPLICANT')||"Applicant"}
                                {...a11yProps(1)}
                                style={{minWidth:"100px",width: '25%'}}
                            />
                            <Tab
                                className={classes.tabLabel}
                                label={t('VEHICLE')||"Vehicle"}
                                {...a11yProps(2)}
                                style={{minWidth:"50%",width: '25%'}}
                            />
                            {!includes(['Inquiry'], status) && (<Tab
                                className={classes.tabLabel}
                                label={t('LOAN')||"Loan"}
                                {...a11yProps(3)}
                                style={{minWidth:"50%",width: '25%'}}
                            />)}
                            {!includes(['Inquiry'], status) && (<Tab
                                className={classes.tabLabel}
                                label={t('REFERENCES')||"References"}
                                {...a11yProps(4)}
                                style={{minWidth:"50%",width: '25%'}}
                            />)}
                            {!includes(['Inquiry'], status) && (<Tab
                                className={classes.tabLabel}
                                label={t('GUARANTOR')||"Guarantor"}
                                {...a11yProps(5)}
                                style={{minWidth:"50%",width: '25%'}}
                            />)}
                            {!includes(['Inquiry'], status) && (<Tab
                                className={classes.tabLabel}
                                label={t('DOCUMENTS')||"Documents"}
                                {...a11yProps(6)}
                                style={{minWidth:"50%",width: '25%'}}
                            />)}
                            {includes(['Approved', 'Accepted', 'Active','Closed', 'Matured'], status) && (
                                <Tab
                                    className={classes.tabLabel}
                                    label={t('PAYMENT_SCHEDULE')||"Payment Schedule"}
                                    {...a11yProps(7)}
                                    style={{minWidth:"50%",width: '25%'}}
                                />
                            )}
                            {includes(['Approved', 'Accepted', 'Active','Closed', 'Matured'], status) && (
                                <Tab
                                    className={classes.tabLabel}
                                    label={"Invoice"}
                                    {...a11yProps(8)}
                                    style={{minWidth:"50%",width: '25%'}}
                                />
                            )}
                            {includes(['Active','Closed', 'Matured'], status) && isManager && (
                                <Tab
                                className={classes.tabLabel}
                                label={t('TRANSACTION')||"Transaction"}
                                {...a11yProps(9)}
                                style={{minWidth:"50%",width: '25%'}}
                            />
                            )}
                        </Tabs>
                        <Divider />
                    </AppBar>
                    </Box>

                    <div style={{ marginBottom: "60px", marginTop: "0px" }}>
                    {/* <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                        animateTransitions={true}
                        //disableLazyLoading={true}
                        > */}
                        <TabPanel value={value} index={0}>
                            <ApplicationDetailGeneral
                                applicationId={applicationId}
                                classes={classes}
                                getApplicationById={getApplicationById}
                                updateApplicationStatus={updateApplicationStatus}
                                addRemarks={addRemarks}
                                getRemarks={getRemarks}
                                currentUser={currentUser}
                                remarksHistory={remarksHistory}
                                handleChangeTab={changeTab}
                                confirmSubmit={confirmSubmit}
                                applicant={customer}
                                t={t}
                            />
                        </TabPanel>
                        {customer && customer.id && (
                            <TabPanel value={value} index={1}>
                                <ApplicationDetailApplicant
                                    classes={classes}
                                    applicant={customer}
                                    status={status}
                                    handleChangeTab={changeTab}
                                    confirmSubmit={confirmSubmit}
                                    createProfileEmployment={createProfileEmployment}
                                    createCustomerDocument={createCustomerDocument}
                                    updateProfile={updateProfile}
                                    updateEmployment={updateEmployment}
                                    createIdentityDocument={createIdentityDocument}
                                    createIncomeProof={createIncomeProof}
                                    createAddressProof={createAddressProof}
                                    updateIdentityDocument={updateIdentityDocument}
                                    updateIncomeProof={updateIncomeProof}
                                    updateAddressProof={updateAddressProof}
                                    updateCustomerData={updateCustomerData}
                                    createAdditionalInfo={createAdditionalInfo}
                                    getAdditionalInfo={getAdditionalInfo}
                                    updateAdditionalInfo={updateAdditionalInfo}
                                    additionalInfo={additionalInfo}
                                    isManager={isManager}
                                    calculateCde={calculateCde}
                                    isLoadingCalculateCde={isLoadingCalculateCde}
                                    t={t}
                                />
                            </TabPanel>
                        )}
                        <TabPanel value={value} index={2}>
                            <ApplicationDetailVehicle
                                applicationVehicle={applicationVehicle}
                                status={status}
                                handleChangeTab={changeTab}
                                confirmSubmit={confirmSubmit}
                                getApplicationById={getApplicationById}
                                getVehicleDetails={getVehicleDetails}
                                vehicleDetails={vehicleDetails}
                                updateVehicleDetails={updateVehicleDetails}
                                applicationId={applicationId}
                                applicant={customer}
                                createDiscount={createDiscount}
                                updateDiscount={updateDiscount}
                                discount={discount}
                                applicationLoan={applicationLoan}
                                updateApplicationLoan={updateApplicationLoan}
                                calculateCde={calculateCde}
                                isLoadingCalculateCde={isLoadingCalculateCde}
                                t={t}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <ApplicationDetailLoan
                                applicationLoan={applicationLoan}
                                totalCost1={totalCost}
                                status={status}
                                handleChangeTab={changeTab}
                                confirmSubmit={confirmSubmit}
                                updateApplicationLoan={updateApplicationLoan}
                                createLoan={createLoan}
                                getApplicationById={getApplicationById}
                                createFees={createFees}
                                updateFees={updateFees}
                                getDiscount={getDiscount}
                                discount={discount}
                                fees={fees}
                                t={t}
                                isLoadingCalculateCde={isLoadingCalculateCde}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <ApplicationDetailReference
                                referenceList={reference}
                                status={status}
                                handleChangeTab={changeTab}
                                confirmSubmit={confirmSubmit}
                                updateApplicationReference={updateApplicationReference}
                                createProfileReference={createProfileReference}
                                getApplicationById={getApplicationById}
                                getReferenceById={getReferenceById}
                                calculateCde={calculateCde}
                                isLoadingCalculateCde={isLoadingCalculateCde}
                                t={t}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <ApplicationDetailGuarantor
                                guarantorList={guarantorList}
                                status={status}
                                handleChangeTab={changeTab}
                                confirmSubmit={confirmSubmit}
                                updateApplicationGuarantor={updateApplicationGuarantor}
                                createGuarantor={createGuarantor}
                                getApplicationById={getApplicationById}
                                getGuarantorsById={getGuarantorsById}
                                updateIdentityDocument={updateIdentityDocument}
                                updateAddressProof={updateAddressProof}
                                calculateCde={calculateCde}
                                isLoadingCalculateCde={isLoadingCalculateCde}
                                t={t}
                                
                            />
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            <ApplicationDetailDocument
                            t={t} />
                        </TabPanel>
                        <TabPanel value={value} index={7}>
                            <ApplicationDetailPayment
                                paymentList={paymentList}
                                t={t}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={8}>
                            <ApplicationDetailInvoice
                                applicationId={applicationId}
                                applicationDetail={applicationDetail}
                                
                                applicationVehicle={applicationVehicle}
                                classes={classes}
                                getApplicationById={getApplicationById}
                                fees={fees}
                                t={t}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={9}>
                            <ApplicationDetailTransaction 
                                getTransctionsByStringId={getTransctionsByStringId} 
                                applicationStringId={applicationStringId}
                                transactionList={transactionList}
                                createTransction={createTransction}
                                branch={branch}
                                transactionInfo={transactionInfo}
                                applicationLoan={applicationLoan}
                                updateApplicationStatus={updateApplicationStatus}
                                applicationId={applicationId}
                                t={t}
                            />
                        </TabPanel>
                        {/* </SwipeableViews> */}
                    </div>
                    <ApproveDialogItem
                        classes={classes}
                        onClose={handleApproveClose}
                        handleClose={handleApproveClose}
                        aria-labelledby="customized-dialog-title"
                        open={approveOpen}
                        handleApprove={handleApprove}
                        t={t}
                    />
                    <RejectDialogItem
                        classes={classes}
                        onClose={handleRejectClose}
                        handleClose={handleRejectClose}
                        aria-labelledby="customized-dialog-title"
                        open={rejectOpen}
                        handleApprove={handleReject}
                        t={t}
                    />
                    <ResubmissionDialogItem
                        classes={classes}
                        onClose={handleResubmissionClose}
                        handleClose={handleResubmissionClose}
                        aria-labelledby="customized-dialog-title"
                        open={resubmissionOpen}
                        handleResubmission={handleResubmission}
                        t={t}
                    />
                    <SubmitDialogItem
                        classes={classes}
                        onClose={handleSubmitClose}
                        handleClose={handleSubmitClose}
                        aria-labelledby="customized-dialog-title"
                        open={submitOpen}
                        handleAppSubmit={handleAppSubmit}
                        cdeScore={cdeScore}
                        t={t}
                    />
                    <ActivateDialogItem
                        classes={classes}
                        onClose={handleActivateClose}
                        handleClose={handleActivateClose}
                        handleSkip={handleSkip}
                        aria-labelledby="customized-dialog-title"
                        open={activateOpen}
                        handleActivate={handleActivate}
                        t={t}
                    />
                    <CloseDialogItem
                        classes={classes}
                        onClose={() => setCloseOpen(false)}
                        handleClose={() => setCloseOpen(false)}
                        open={closeOpen}
                        submitCloseApp={submitCloseApp}
                        t={t}
                    />
                    <WithdrawlDialogItem
                        classes={classes}
                        onClose={() => setWithdrawlDialogOpen(false)}
                        handleClose={() => setWithdrawlDialogOpen(false)}
                        open={withdrawlDialogOpen}
                        handleWithdrawl={handleWithdrawl}
                        t={t}
                    />
                    {/* <RemarksDialogItem
                        classes={classes}
                        onClose={handleRejectClose}
                        handleClose={handleRejectClose}
                        aria-labelledby="customized-dialog-title"
                        open={rejectOpen}
                        handleApprove={handleReject}
                    /> */}
                    <DuplicateDialogItem
                        classes={classes}
                        handleClose={handleCloneClose}
                        aria-labelledby="customized-dialog-title"
                        open={cloneDialogueOpen}
                        handleClone={handleClone}
                        t={t}
                    />
                     <CdeDialogItem
                        classes={classes}
                        open={cdeBoxOpen}
                        onClose={handleCdeBoxClose}
                        cdeInfo={cdeInfo}
                        applicationDetail={applicationDetail}
                        t={t}
                    />
                    <ConfirmDateDialogItem
                        classes={classes}
                        open={dateDialogOpen}
                        onClose={handleDateClose}
                        handleSave={handleDateSubmission}
                        t={t}
                    />
                </div>
            </main>
            {status!=="Active" && status !== "Pending" && status !== "Inquiry" &&(
                <ApplicationAdminstration
                    classes={classes}
                    saveAndExit={saveAndExit}
                    handleSubmitClickOpen={handleSubmitClickOpen}
                    handleApproveClickOpen={handleApproveClickOpen}
                    handleRejectClickOpen={handleRejectClickOpen}
                    handleActivateOpen={handleActivateOpen}
                    status={status}
                    isManager={isManager}
                    handleAccept={handleAccept}
                    customer={customer}
                    handleInquiryPurchase={handleInquiryPurchase}
                    handleClone={handleClone}
                    handleCloneOpen={handleCloneOpen}
                    handleDateOpen={handleDateOpen}
                    handleResubmissionOpen={handleResubmissionOpen}
                    t={t}
                />
            )}
        </>
    );
};

const ApplicationAdminstration = (props) => {
    const {
        classes,
        handleActivateOpen,
        handleAccept,
        status,
        isManager,
        handleRejectClickOpen,
        handleApproveClickOpen,
        handleInquiryPurchase,
        handleClone,
        handleCloneOpen,
        handleDateOpen,
        handleResubmissionOpen,
         t
    } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                {status == "Review" && isManager && (
                    <ReviewAction
                        classes={classes}
                        handleRejectClickOpen={handleRejectClickOpen}
                        handleApproveClickOpen={handleApproveClickOpen}
                        handleResubmissionOpen={handleResubmissionOpen}
                        t={t}
                    />
                )}
                {status == "Accepted" && isManager && (
                    <AcceptAction
                        classes={classes}
                        handleActivateOpen={handleActivateOpen}
                        handleDateOpen={handleDateOpen}
                        t={t}
                    />
                )}
                {status == "Approved" && !isManager && (
                    <ApprovedAction
                        classes={classes}
                        handleAccept={handleAccept}
                        t={t}
                    />
                )}
                {status == "Inquiry" && !isManager && (
                    <InquiryAction
                        classes={classes}
                        handleRejectClickOpen={handleRejectClickOpen}
                        handleInquiryPurchase={handleInquiryPurchase}
                        t={t}
                    />
                )}
                {(status == "Rejected" || status=="Withdrawn" )&& (
                    <CloneAction
                        classes={classes}
                        handleClone={handleClone}
                        handleCloneOpen={handleCloneOpen}
                        t={t}
                    />
                )}
            </Toolbar>
        </AppBar>
    );
};

const ApprovedAction = (props) => {
    const { classes, handleAccept, t } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.rm64}
        >
            <Box p={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAccept}
                    >
                        {t('ACCEPT_LOAN_OFFER')||"Accept loan offer"}
                    </Button>
                </Box>
        </Grid>
    );
};

const AcceptAction = (props) => {
    const { classes, handleActivateOpen,handleDateOpen, t } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Grid item xs={12} sm={3}>
                <Box p={1}>
                    <Button
                        variant="contained"
                        className={classes.greenBtn}
                        onClick={handleDateOpen}
                    >
                        {t('ACTIVATE')||"Activate"}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

const ReviewAction = (props) => {
    const { classes, handleApproveClickOpen, handleRejectClickOpen, handleResubmissionOpen, t } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.rm64}
        >
            <Box p={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.resubmissionButton}
                        onClick={handleResubmissionOpen}
                    >
                        {"Pending resubmission"}
                    </Button>
                </Box>
            <Box p={1} >
                    <Button
                        variant="contained"
                        className={classes.rejectButton}
                        onClick={handleRejectClickOpen}
                    >
                        {t('REJECT')||"Reject"}
                    </Button>
                </Box>
                <Box p={1} >
                    <Button
                        variant="contained"
                        className={classes.approveButton}
                        onClick={handleApproveClickOpen}
                    >
                        {t('APPROVE')||"Approve"}
                    </Button>
                </Box>
        </Grid>
    );
};

const InquiryAction = (props) => {
    const { classes, handleInquiryPurchase, handleRejectClickOpen, t} = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.rm64}
        >
            <Box p={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRejectClickOpen}
                    >
                        {t('SAVE_AND_EXIT')||"Save &amp; Exit"}
                    </Button>
                </Box>
                <Box p={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInquiryPurchase}
                    >
                        {t('CONTINUE_PURCHASE')||"Continue with purchase"}
                    </Button>
                </Box>
        </Grid>
    );
};

const CloneAction = (props) => {
    const { classes, handleClone, t, handleCloneOpen } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.rm64}
        >
            <Grid item xs={12} sm={3}
            >
                <Box p={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloneOpen}
                    >
                        {t('DUPLICATE_AS_NEW')||"Duplicate"}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

const UpdateVehicleDetailsAction = (props) => {
    const { classes, handleActivateOpen } = props;
    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Grid item xs={12} sm={3}>
                <Box p={1}>
                    <Button
                        variant="contained"
                        className={classes.greenBtn}
                        onClick={handleActivateOpen}
                    >
                        Save &amp; Exit
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    <Typography component={"span"}>{children}</Typography>
                </div>
            )}
        </div>
    );
};

const WithdrawlDialogItem = (props) => {
    const { classes, t } = props;
    const [value, setValue] = React.useState("Controlled");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleWithdrawl = () => {
        props.handleWithdrawl(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('REASON_OF_WITHDRAWL')||"Reason of withdrawal"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder={t('WRITE_DOWN_REASON_HERE')||"Write down the reason here..."}
                            onChange={handleChange}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleWithdrawl}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};



const CloseDialogItem = (props) => {
    const { classes, submitCloseApp,t } = props;
    const [value, setValue] = useState("Controlled");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleSubmitCloseApp = () => {
        submitCloseApp(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('LOAN_CLOSURE')||"Loan closure"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder={t('WRITE_DOWN_REASON_HERE')||"Write down the reason here..."}
                            onChange={handleChange}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleSubmitCloseApp}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const ApproveDialogItem = (props) => {
    const { classes, t } = props;
    const [value, setValue] = React.useState("Controlled");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleApprove = () => {
        props.handleApprove(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('REASON_OF_APPROVAL')||"Reason of approval"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder={t('WRITE_DOWN_REASON_HERE')||"Write down the reason here..."}
                            onChange={handleChange}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleApprove}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const ActivateDialogItem = (props) => {
    const { classes, handleActivate, handleActivateClose, applicationId, t, handleSkip,open } = props;
    const [value, setValue] = React.useState("Controlled");


    const [allValues, setAllValues] = useState({
       vehicleNo:'',
       chasisNo:''
   });
   const {vehicleNo, chasisNo} = allValues;

   const changeHandler = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.value });
   }

    const submitHandler = (e) => {
        handleActivate(allValues);
       // console.log("Inside submit handler : "+JSON.stringify(allValues));
    }

    const skipHandler = (e) =>{
        handleSkip();
    }
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
           // maxWidth={"xs"}
        >
             <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px", padding: "10px" , marginTop:"10px"}}
                onClose={props.handleClose}
               
            >
                {t('ADD_VEHICLE_DETAILS')||"Add Vehicle details"}
            </DialogTitle>
            <DialogContent>
            <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="vehicleNo"
                            label={t('VEHICLE_NUMBER')||"Vehicle No."}
                            value={vehicleNo}
                            onChange={changeHandler}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="chasisNo"
                            label={t('CHASSIS_NUMBER')||"Chassis No."}
                            value={chasisNo}
                            onChange={changeHandler}
                        />
                        </Grid>
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={skipHandler}
                        >
                            {t('SKIP')||"Skip"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={submitHandler}
                        >
                            {t('ADD')||"Add"}
                        </Button>
                    </div>
                {/* </Grid> */}
            </DialogContent>
        </Dialog>
    );
};

const RejectDialogItem = (props) => {
    const { classes,t } = props;
    const [value, setValue] = React.useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleApprove = () => {
        props.handleApprove(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('REASON_OF_REJECTION')||"Reason of rejection"}
            </DialogTitle>
            <DialogContent>
            <ValidatorForm onSubmit={handleApprove}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextValidator
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            value={value}
                            placeholder={t('WRITE_DOWN_REASON_HERE')||"Write down the reason here..."}
                            onChange={handleChange}
                            validators={[
                                "required"
                            ]}
                            errorMessages={[
                                "Please enter a reason for rejection"
                            ]}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            //onClick={handleApprove}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </div>
                </Grid>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    );
};

const ResubmissionDialogItem = (props) => {
    const { classes,t, handleResubmission } = props;
    const [value, setValue] = React.useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleSubmit = () => {
        handleResubmission(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {"Reason of resubmission"}
            </DialogTitle>
            <DialogContent>
            <ValidatorForm onSubmit={handleSubmit}>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextValidator
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            value={value}
                            placeholder={t('WRITE_DOWN_REASON_HERE')||"Write down the reason here..."}
                            onChange={handleChange}
                            validators={[
                                "required"
                            ]}
                            errorMessages={[
                                "Please enter a reason for resubmission"
                            ]}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            //onClick={handleApprove}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </div>
                </Grid>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    );
};

const SubmitDialogItem = (props) => {
    const { classes, t, cdeScore } = props;
    const [customerChecked, setCustomerChecked] = useState(false);
    const handleCheckedChange = (e) => {
        setCustomerChecked(e.target.checked);
    };

    const handleAppSubmit = () => {
        props.handleAppSubmit();
    };
    return (
       cdeScore>0? (<Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('APPLICATION_SUBMISSION')||"Application Submission"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <div
                            style={{
                                marginBottom: "24px",
                                marginTop: "8px",
                                fontSize: "14px",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={customerChecked}
                                        onChange={handleCheckedChange}
                                    />
                                }
                                label={t('DECLARE_VALID_DOCUMENTS')||"Declare that all documents are verified before submitting."}
                            />
                        </div>
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            disabled={!customerChecked}
                            onClick={handleAppSubmit}
                        >
                            {t('CONFIRM')||"Confirm"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>) : (
            <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {"Submission Error"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                    <div
                            style={{
                                marginBottom: "24px",
                                marginTop: "8px",
                                fontSize: "14px",
                            }}
                        >
                            <Typography>
                                {"Please fill in all the information required in the form"}
                            </Typography>
                        </div>
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={props.handleClose}
                        >
                            {t('CONFIRM')||"Confirm"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
            </Dialog>
        )
    );
};

const DuplicateDialogItem = (props) => {
    const { classes, t, handleClone, handleClose, open } = props;

    const handleAppSubmit = () => {
        handleClose()
        handleClone();
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            {/* <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {t('APPLICATION_SUBMISSION')||"Application Submission"}
            </DialogTitle> */}
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <div
                            style={{
                                marginBottom: "24px",
                                marginTop: "8px",
                                fontSize: "14px",
                            }}
                        >
                            <Typography>
                                {t('DUPLICATE_LOAN_APPLICATION')||"Do you want to duplicate this loan application now?"}
                            </Typography>
                        </div>
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            {t('NO')||"No"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={handleAppSubmit}
                        >
                            {t('YES')||"Yes"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const CdeDialogItem = (props) => {
    const { classes, open, onClose, cdeInfo, applicationDetail,t } = props;
    const handleSubmit = () => {
        onClose();
    };

    const renderCDERecommendation = () => {
        let content = [];
        if (cdeInfo && cdeInfo.critical_comments) {
            for (const [key, value] of Object.entries(
                cdeInfo.critical_comments
            )) {
                content.push(
                    <div key={key}>
                        <p className="f-12 c-grey mb-4">{key}</p>
                        <p className="f-14 mt-0">{value}</p>
                    </div>
                );
            }
        }
        return content;
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                >
                    CDE Info
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                <Paper className={classes.paperGeneral} style={{overflow: 'auto'}}>
                    {/* <Typography
                            //gutterBottom
                            className={classes.paperLabel}
                        >
                            {t('CDE_SCORE')||"CDE Score"}
                        </Typography> */}
                    <div className={classes.paperContent}>
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <CdeGauge cdeInfo={cdeInfo} applicationDetail={applicationDetail}/>
                        </div>
                        <div>
                            {cdeInfo &&
                                cdeInfo.critical_comments &&
                                renderCDERecommendation()}
                        </div>
                        </div>
                    </Paper> 
                </DialogContent>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ color: "white", backgroundColor: "#5f56a6" }}
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

const RemarksDialogItem = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState("Controlled");

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleApprove = () => {
        props.handleApprove(value);
    };
    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={props.handleClose}
            >
                {"Remarks"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder="Write down the remarks here..."
                            onChange={handleChange}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={props.handleClose}
                        >
                            Skip
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleApprove}
                        >
                            Add
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const ConfirmDateDialogItem = (props) => {
    const { classes, open, onClose, handleSave, t } = props;
    const [value, setValue] = React.useState("Controlled");
    const [dueDate,setDueDate] = React.useState('');
    const [maxDate,setMaxDate]= React.useState("");
    const [minDate,setMinDate]= React.useState("");
    const [vehicleDialogOpen, setVehicleDialogOpen] = React.useState(false);

    useEffect(() => {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        let day = new Date().getDate();
        let maxdate = new Date(year, month+1, 28);
        let mindate = new Date(year,month+1, 1);
        setMaxDate(maxdate);
        setMinDate(mindate)
        setDueDate(mindate);
    }, []);

    const handleDateChange = (date) => {
        setDueDate(date);
    };

    const handleConfirm = () =>{
        handleSave(dueDate);
    }


    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            //fullWidth={true}
            maxWidth={"xs"}
        >
             <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px", padding: "10px" , marginTop:"10px"}}
                onClose={onClose}
               
            >
                {"First repayment date"}
            </DialogTitle>
            <DialogContent>
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
            >
                <Grid item xs={12} sm={12} style = {{ padding:'12px', alignItems:'centre' }}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={"Repayment date"}
                        format="dd/MM/yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        name="dueDate"
                        value={dueDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            "aria-label":
                                "change date",
                        }}
                        required
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={handleConfirm}
                        >
                            {t('CONFIRM')}
                        </Button>
                    </div>
            </DialogContent>
        </Dialog>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default withStyles(inputStyles)(ApplicationDetail);
