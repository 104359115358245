import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Gauge from "react-svg-gauge";
import get from "lodash/get";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { getApplicationById, calculateCde } from "../../store/actions/customer";
import { currencyFormat } from "../../utils/helper";
import { AppBar, Link, Toolbar } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {Text, Font, Page, View, Image, Document, StyleSheet, PDFViewer} from '@react-pdf/renderer';
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import Logo from "../../assets/img/TVS_logo.svg";
import { APP_CONFIGS } from "../../constants/appConstants";

const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 400': {
        flexDirection: 'column',
      },
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    footer: {
      fontSize: 12,
      textAlign: 'center',
      marginTop: 15,
      paddingTop: 5,
      borderWidth: 3,
      borderColor: 'gray',
      borderStyle: 'dashed',
      '@media orientation: landscape': {
        marginTop: 10,
      },
    },
  });

const ApplicationDetailInvoice = (props) => {
    const {
        classes,
        applicationDetail,
        applicationId,
        getApplicationById,
        applicationVehicle,
        applicationLoan,
        fees,
        t
    } = props;


    useEffect(()=>{
        getApplicationById(applicationId);
    },[applicationId]);

    const [feesValue,setFeesValue] = useState({
        processingFees: fees.processingFees||0.0,
        earlySettlementFees: fees.earlySettlementFees|0.0,
        additionalCharges: fees.additionalCharges||0.0,
        documentationFees: fees.documentationFees || 0.0,
        stampDutyCharges: fees.stampDutyCharges||0.0,
        loanRebookCharges: fees.loanRebookCharges||0.0,
        chequeBounceCharges: fees.chequeBounceCharges||0.0,
        chequeSwapCharges: fees.chequeSwapCharges||0.0,
        exchangeCharges: fees.exchangeCharges||0.0
    })
    const [visibleFees, setVisibleFees] = useState({
        processingFeeVisible: true,
        earlySettlementFeeVisible: fees.earlySettlementFees > 0.0?true:false,
        additionalChargesVisible: fees.additionalCharges > 0.0?true:false,
        documentationFeesVisible: fees.documentationFees>0.0? true:false,
        stampDutyChargesVisible: fees.stampDutyCharges > 0.0?true:false,
        loanRebookChargesVisible: fees.loanRebookCharges > 0.0?true:false,
        chequeBounceChargesVisible: fees.chequeBounceCharges > 0.0?true:false,
        chequeSwapChargesVisible: fees.chequeSwapCharges > 0.0?true:false,
        exchangeChargesVisible: fees.exchangeCharges > 0.0?true:false
    })

    const {
        processingFeeVisible,
        earlySettlementFeeVisible,
        additionalChargesVisible,
        documentationFeesVisible,
        stampDutyChargesVisible,
        loanRebookChargesVisible,
        chequeBounceChargesVisible,
        chequeSwapChargesVisible,
        exchangeChargesVisible
    } = visibleFees;
    const {
        processingFees,
        earlySettlementFees,
        additionalCharges,
        documentationFees,
        stampDutyCharges,
        loanRebookCharges,
        chequeBounceCharges,
        chequeSwapCharges,
        exchangeCharges
    } = feesValue;
   

    const renderPercentageUtilization = () => {
        const { loanDetails } = applicationDetail;
        const { flatRate, reducingRate } = loanDetails;
        const percent =
            parseFloat((flatRate || reducingRate) * 100).toFixed(1) + "%";
        return percent;
    };
    const renderPercentageUtilizationType = () => {
        const { loanDetails } = applicationDetail;
        const { flatRate, reducingRate } = loanDetails;
        const type = reducingRate > 0 ? "Reducing" : "Flat";
        return type;
    };
    const renderAmount = () => {
        const { loanDetails } = applicationDetail;
        const { loanAmount } = loanDetails;
        return currencyFormat(loanAmount);
    };
    const renderRepaymentAmount = () => {
        const { loanDetails } = applicationDetail;
        const { loanAmount, totalInterest } = loanDetails;
        return currencyFormat(loanAmount+totalInterest);
    };

    const handlePdf = () => {
        const input = document.getElementById("page");
    
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                const imgProps= pdf.getImageProperties(imgData);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
    
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                pdf.save("Invoice.pdf");
            });
    };
    

    return (
        <>
            {applicationDetail && applicationDetail.id && (
                <div>
                <Typography
                    className={classes.titleLabel}>
                    {"Invoice"}
                    <span style={{float:'right',fontSize:'16px'}}><Button style={{
                                    backgroundColor: "#5F56A6", color: "#FFFFFF"
                                }} onClick={handlePdf} variant="contained">Download invoice</Button></span>
                </Typography>
                <div id="page">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperInvoice} style={{overflow: 'auto'}}>
                        <div className={classes.paperContent}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                                <img
                                src={Logo}
                                alt="logo"
                                //style={{ width: "24px" }}
                                
                            />
                        <Typography style={{fontSize:'8px'}}>
                        TVS AUTO BANGLADESH LTD
                        304 Industrial Area (3rd & 4th Floor) 
                        TejgaonI, Dhaka 1208
                        TEL: +880-2-8891600, +880-2-9831001-2
                        www.tvsabl.com


                        </Typography>
                        </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Billed To"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {applicationDetail.customer
                                        ? applicationDetail.customer.name
                                        : ""}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {applicationDetail.customer.phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Invoice Number"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {applicationDetail.applicationStringId}
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Date Issued"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {moment(applicationDetail.updatedAt).format(
                                          "DD/MM/YY"
                                      )}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Due Date"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {moment(applicationDetail.updatedAt).format(
                                          "DD/MM/YY"
                                      )}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Vehicle Details"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {applicationVehicle.model.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Insurance Type"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {applicationVehicle.insuranceType}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Tenure"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {applicationLoan.numberOfPayments} {"months"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Fund utilization rate (%)"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {renderPercentageUtilization()}
                                </Typography>
                            </Grid>
                            </Grid>
                        </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Paper className={classes.paperInvoice} style={{overflow: 'auto'}}>
                        <div className={classes.paperContentNew}>
                        <Typography
                            className={classes.titleLabel}>
                            {"Invoice Details"}
                        </Typography>
                        <Grid container spacing={4}>
                        <Grid item xs={12} sm={8}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {"Description"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="caption"
                                    display="block" gutterBottom
                                    className={classes.label}
                                    style={{textAlign:"right"}}>
                                    {"Amount"} ({APP_CONFIGS.CURRENCY})
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Vehicle Price"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(applicationLoan.totalCost)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Downpayment"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(applicationLoan.downPayment)}
                                </Typography>
                            </Grid>
                
                            <Grid item xs={12} sm={8}>
                                <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Total loan amount"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(applicationLoan.loanAmount)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Total fund utilization amount"}
                                </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(applicationLoan.totalInterest)}
                                </Typography>
                            </Grid>  
                        <Grid item xs={12} sm={8}>
                        <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Total repayment amount"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(applicationLoan.loanAmount + applicationLoan.totalInterest)}
                                </Typography>
                            </Grid>
                           
                            {processingFeeVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Processing fees"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(processingFees)}
                                </Typography>
                            </Grid></>}

                            {documentationFeesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Documentation fees"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(documentationFees)}
                                </Typography>
                            </Grid></>}

                            {earlySettlementFeeVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Early settlement fees"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(earlySettlementFees)}
                                </Typography>
                            </Grid></>}
                            
                            {additionalChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Additional charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(additionalCharges)}
                                </Typography>
                            </Grid></>}

                            {stampDutyChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Stamp duty charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(stampDutyCharges)}
                                </Typography>
                            </Grid></>}

                            {loanRebookChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Loan rebook charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(loanRebookCharges)}
                                </Typography>
                            </Grid></>}

                            {chequeBounceChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Cheque bounce charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(chequeBounceCharges)}
                                </Typography>
                            </Grid></>}

                            {chequeSwapChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Cheque swap charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(chequeSwapCharges)}
                                </Typography>
                            </Grid></>}

                            {exchangeChargesVisible && <><Grid item xs={12} sm={8}>
                            <Typography
                                    variant="body2"
                                    display="block"
                                    gutterBottom 
                                >
                                    {"Exchange charges"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body2" gutterBottom style={{textAlign:"right"}}>
                                    {currencyFormat(exchangeCharges)}
                                </Typography>
                            </Grid></>}
                        </Grid>
                        </div>
                        </Paper>
                    </Grid>
                </Grid>
                </div>
               </div> 
            )}
            
        </>
    );
};




const mapStateToProps = (state) => {
    return {
        applicationDetail: state.customer.application || {},
        applicationVehicle: get(state.customer.application, "vehicle") || {},
        applicationLoan: get(state.customer.application, 'loanDetails') || {},
    };
};

const mapDispatchToProps = {
    getApplicationById
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationDetailInvoice))
);
