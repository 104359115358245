import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import CustomerInformation from './CustomerInformation'
import { getEmploymentTypes, getIndustryType, createProfileEmployment, updateApplicationStatus } from '../../store/actions/customer'

const CustomerInformationContainer = (props: any) => <CustomerInformation {...props} />

const mapStateToProps = (state: any) => ({
    employmentTypeList: state.customer.employmentTypeList || [],
    industryTypeList: state.customer.industryTypeList || [],
    clientInfo: state.customer.clientInfo || {},
    currentUser: get(state.auth, 'user')
})

const mapDispatchToProps = {
    getEmploymentTypes,
    getIndustryType,
    createProfileEmployment,
    updateApplicationStatus
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerInformationContainer))
)
