import { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import { Box, Paper, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTranslation } from "react-i18next";
import Cash from "../../assets/img/cash 1.svg";
import CreditCard from "../../assets/img/credit-cards 1.svg";
import Emi from "../../assets/img/personal 1.svg";
import { getDiscount } from "../../api";
const PaymentComponent = (props) => {
    const { classes, history, getApplicationById, applicationVehicle, getDiscount, discount, updateApplicationStatus} = props;
    const { applicationId, clientId } = props.match.params;
    const {t} = useTranslation();

    useEffect(() => {
        getDiscount(applicationId);
        getApplicationById(applicationId);
    }, [])
    const handlePayment = () => {
        const params = {
            applicationId: applicationId,
            data: {
                note: 'Cash',
                viewed: 0,
            },
        };
        updateApplicationStatus(params);
        history.push(`/application/${applicationId}/cash-payment`)
    }
    const handleEmi = () => {
        history.push(`/client/${clientId}/application/${applicationId}/client-information`)
    }
    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    Select payment
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                        <Paper className={classes.paper} onClick={handlePayment} style={{overflow: 'auto'}}>
                            <Box p="0 25px">
                                <Box display="flex" alignItems="center">
                                    <div style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600' }} id="cashPaymentBtn">{t('CASH')||"Cash"}</div>
                                    <div style={{ 'marginLeft': 'auto' }}>
                                        <p style={{ 'fontSize': '12px' }}> {t('PAY_AMOUNT')||"pay amount"}</p>
                                        <p style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600' }}>৳{discount?.discountedPrice? discount?.discountedPrice : (applicationVehicle.price ? applicationVehicle.price : 0)}</p>
                                    </div>
                                </Box>
                            </Box>
                        </Paper>
                        <Paper className={classes.paper} onClick={handlePayment} style={{overflow: 'auto'}}  id="creditCardPaymentBtn">
                            <Box p="0 25px">
                                <Box display="flex" alignItems="center">
                                    <div style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600' }}>{t('CREDIT_CARD')||"Credit card"}</div>
                                    <div style={{ 'marginLeft': 'auto' }}>
                                        <p style={{ 'fontSize': '12px' }}>{t('PAY_AMOUNT')||"pay amount"}</p>
                                        <p style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600' }}>৳{discount?.discountedPrice? discount.discountedPrice : (applicationVehicle.price ? applicationVehicle.price : 0)}</p>
                                    </div>
                                </Box>
                            </Box>
                        </Paper>
                        <Paper className={classes.paper} onClick={handleEmi} style={{overflow: 'auto'}} id="emiPaymentBtn">
                            <Box p="0 25px">
                                <Box display="flex" alignItems="center">
                                    <div style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600' }}>{t('EMI')||"EMI"}</div>
                                    <div style={{ 'marginLeft': 'auto' }}>
                                        <p style={{ 'fontSize': '12px' }}>{t('LOAN_STARTS_FROM')||"Loan tenure starts from"}</p>
                                        <p style={{ 'fontSize': '16px', 'color': '#212121', 'fontWeight': '600', textAlign: 'right' }}>1 month</p>
                                    </div>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Fragment>
            </div>
        </main>
    );
};

export default withStyles(inputStyles)(PaymentComponent);
