import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Button, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Box from '@mui/material/Box';
import {
    getDocumentList,
    getDocumentCategory,
    getDocumentTypeList,
    updateDocument,
} from "../../store/actions/customer";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        height: "100px",
        backgroundColor: "red",
    },
    resize: {
        fontSize: 50,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DocumentList = (props) => {
    const {
        classes,
        documentList,
        getDocumentList,
        getDocumentCategory,
        categoryList,
        getDocumentTypeList,
        documentTypeList,
        updateDocument,
        updatedDocument,
        t
    } = props;
    const { applicationId } = props.match.params;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        getDocumentList(applicationId);
        getDocumentCategory();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        getDocumentList(applicationId);
    };

    useEffect(() => {
        if (updatedDocument && updatedDocument.id && open) {
            setOpen(false);
            getDocumentList(applicationId);
        }
    }, [updatedDocument]);

    const [documentOpen, setDocumentOpen] = useState(false);
    const handleDocumentClose = () => {
        setDocumentOpen(false);
    };
    const [docData, setDocData] = React.useState("");
    const handleDocumentOpen = (data) => {
        setDocumentOpen(true);
        setDocData(data);
    };
    const [category, setCategory] = React.useState("");
    const [documentType, setDocumentType] = React.useState("");
    const [note, setNote] = React.useState("");
    const [fileUrl, setFileUrl] = React.useState("");
    const [file, setFile] = React.useState("");
    const changeCategory = (e) => {
        setCategory(e.target.value);
        getDocumentTypeList(e.target.value);
    };

    const changeDocType = (e) => {
        setDocumentType(e.target.value);
    };
    const changeNote = (e) => {
        setNote(e.target.value);
    };
    const [docName, setDocName] = React.useState("");
    const [docShow, setDocShow] = React.useState(false);
    const handleFileChange = (e) => {
        setDocName(e.target.files[0].name);
        setDocShow(true);
        setFileUrl(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    };
    const removeFile = (e) => {
        setDocName("");
        setDocShow(false);
        setFileUrl("");
        setFile("");
    };

    const handleUpdateDocument = (e) => {
        let data = {
            categoryId: category,
            documentTypeId: documentType,
            note,
            document: fileUrl,
            status: "active",
            createdBy: "",
            applicationId,
        };
        updateDocument(data);
    };

    return (
        <div>
            <Box component="span" sx={{ p: 2, border: '1px dashed', flexGrow: 1 }} style={{ color: "#5F56A6" }} >
            <Button
                variant="text"
                onClick={handleClickOpen}
                // className={classes.documentButton}
                fullWidth
                style={{ color: "#5F56A6", maxWidth: "95%", }}
            >
                <AddCircleOutlineRoundedIcon/> {" "}
                {t('ADD_DOCUMENT')||"Add Document"}
            </Button>
            </Box>

            {map(documentList, (item, key) => (
                <DocumentItem
                    key={key}
                    handleDocumentOpen={handleDocumentOpen}
                    classes={classes}
                    data={item}
                    t={t}
                />
            
            ))}

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"xs"}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    style={{ marginLeft: "10px" }}
                    onClose={handleClose}
                >
                    {t('DOCUMENT')||"Document"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>{t('DOCUMENT_CATEGORY')||"Document Category"}</InputLabel>
                            <Select
                                name="category"
                                value={category}
                                onChange={changeCategory}
                                required
                            >
                                {map(categoryList, (item, key) => (
                                    <MenuItem key={key} value={item.id}>
                                        {item.category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>{t('DOCUMENT_TYPE')||"Document Type"}</InputLabel>
                            <Select
                                name="documentType"
                                value={documentType}
                                onChange={changeDocType}
                                required
                            >
                                {map(documentTypeList, (item, key) => (
                                    <MenuItem key={key} value={item.id}>
                                        {item.type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            inputProps={{ style: { fontSize: 14 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            style={{ marginBottom: "15px" }}
                            id="note"
                            label={t('NOTE')||"Note"}
                            onChange={changeNote}
                        />
                    </Grid>
                        <Grid item xs={12} sm={12}>
                            {file.length < 1 && (
                                <div className="file-input-inline">
                                    <input
                                        type="file"
                                        id="file"
                                        className="file"
                                        name="file"
                                        onChange={handleFileChange}
                                    />
                                    <label
                                        htmlFor="file"
                                        className={classes.inlineUpload}
                                    >
                                        <AddPhotoAlternateIcon />
                                        <Typography
                                            style={{
                                                marginLeft: "8px",
                                            }}
                                        >
                                            {t('TAP_TO_UPLOAD_DOCUMENT')||"Tap to upload document"}
                                        </Typography>
                                    </label>
                                </div>
                            )}
                            {docShow && (
                                <div className={classes.docContainer}>
                                    <p
                                        style={{
                                            display: "inline",
                                        }}
                                    >
                                        {docName}
                                    </p>
                                    <DeleteOutlineIcon
                                        onClick={removeFile}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                onClick={handleClose}
                            >
                                {t('CANCEL')||"Cancel"}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                type="submit"
                                onClick={handleUpdateDocument}
                            >
                                {t('ADD')||"Add"}
                            </Button>
                        </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <DocumentDialog
                classes={classes}
                open={documentOpen}
                onClose={handleDocumentClose}
                handleDocumentOpen={handleDocumentOpen}
                data={docData}
                t={t}
            />
        </div>
    );
};

const DocumentItem = (props) => {
    const { classes, data, t } = props;

    const handleDocumentOpen = () => {
        props.handleDocumentOpen(data);
    };
    return (
        <Link to={"#"} onClick={handleDocumentOpen} className="noDecorate">
            <Paper className={classes.paper} style={{marginTop: '20px'}}>
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('DOCUMENT_CATEGORY')||"Document category"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.documentCategory.category}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('DOCUMENT_TYPE')||"Document Type"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.documentType.documentType}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('FILE')||"File"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.type}.{data.documentUrl.split(".")[4]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('STATUS')||"Status"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.status}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('NOTE')||"Note"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.note}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Link>
    );
};

const DocumentDialog = (props) => {
    const { classes, open, data, onClose, t } = props;
    const handleSubmit = () => {
        onClose();
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                >
                    {t('DOCUMENT_DETAILS')||"Document Details"}
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12}>
                                    {data.documentUrl &&
                                    data.documentUrl.split(".")[4] === "pdf" ? (
                                        <a
                                            href={data.documentUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            {t('DOWNLOAD_FILE')||"Download File"}
                                        </a>
                                    ) : (
                                        <img
                                            src={data.documentUrl}
                                            className={classes.docImage}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('DOCUMENT_CATEGORY')||"Document category"}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {data.documentCategory &&
                                            data.documentCategory.category}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('DOCUMENT_TYPE')||"Document Type"}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {data.documentType &&
                                            data.documentType.documentType}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('STATUS')||"Status"}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {data.status}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('UPDATED_BY')||"Updated By"}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                    ></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('NOTE')||"Note"}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </DialogContent>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        style={{ color: "white", backgroundColor: "#5f56a6" }}
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                    >
                        {t('OK')||"Ok"}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    documentList: state.customer.documentList || [],
    categoryList: state.customer.categoryList || [],
    documentTypeList: state.customer.documentTypeList || [],
    updatedDocument: state.customer.updatedDocument || "",
});

const mapDispatchToProps = {
    getDocumentCategory,
    getDocumentList,
    getDocumentTypeList,
    updateDocument,
};

export default withStyles(inputStyles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentList))
);
