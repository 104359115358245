import { useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TextField from "@material-ui/core/TextField";
import { Box, Card } from "@material-ui/core";
import moment from 'moment'
import find from 'lodash/find'
import {
    RELATIONSHIP_OPTIONS,
  } from "../../constants/appConstants";
import { map } from "lodash";
import GuarantorUpdateComponent from "../application_update/guarantor_update/GuarantorUpdateComponent";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black", // (default alpha is 0.38)
        },
    },
})(TextField);

const ApplicationDetailGuarantor = (props) => {
    const { classes, history, guarantorList, status, confirmSubmit, handleChangeTab, updateApplicationGuarantor, getApplicationById, getGuarantorsById, t,
        updateIdentityDocument, updateAddressProof, calculateCde,
        isLoadingCalculateCde,} = props;

    const [open, setOpen] = useState(false);

    const [index, setIndex] = useState(0);

    const getRelationShipByValue = value => {
        const relationShip = find(RELATIONSHIP_OPTIONS, function(o) { return o.value == value; })
        return relationShip ? relationShip.label : ''
      }

    const changeToTwo =() => setIndex(1);

    const changeToOne = () => setIndex(0);

    return (
        <>
            {(status === "Pending" || status ==="Inquiry") && (
                <GuarantorUpdateComponent
                    handleChangeTab={handleChangeTab}
                    confirmSubmit={confirmSubmit}
                    guarantorList={guarantorList}
                    updateApplicationGuarantor={updateApplicationGuarantor}
                    getApplicationById={getApplicationById}
                    getGuarantorsById={getGuarantorsById}
                    updateIdentityDocument={updateIdentityDocument}
                    updateAddressProof={updateAddressProof}
                    calculateCde={calculateCde}
                    isLoadingCalculateCde={isLoadingCalculateCde}
                    t={t}
                />
            )}
            {(status !== "Pending" && status !== "Inquiry") && (
                <Fragment>
                <Box
                    display="flex"
                    flexDirection="row"
                    
                    mb={3}
                    style={{
                        backgroundColor:"#EFEFEF"
                    }}
                >
                    {index === 0 ? (
                        <Box p={1}>
                            <Button
                                style={{
                                    backgroundColor:"#5F56A6"
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.guarantorButton}
                                
                            >
                                {t('GUARANTOR')||"Guarantor"}#1
                            </Button>
                        </Box>
                    ) : (
                        index === 1 && (
                            <Box p={1} onClick={changeToOne} className='b-link'>
                                Guarantor#1
                            </Box>
                        )
                    )}
                    {index === 0
                            ? guarantorList[1] !== undefined && (
                        <Box p={1} onClick={changeToTwo} className='b-link'>
                            {t('GUARANTOR')||"Guarantor"}#2
                        </Box>
                    ) : (
                        index === 1 &&
                        guarantorList[1] !== undefined && (
                            <Box p={1}>
                                <Button
                                style={{
                                    backgroundColor:"#5F56A6"
                                }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.guarantorButton}
                                >
                                    {t('GUARANTOR')||"Guarantor"}#2
                                </Button>
                            </Box>
                        )
                    )}
                </Box>
                {/* {map(guarantorList, (item, key) => (
                    <Box key={key} p={1} onClick={changeToTwo} className='b-link'>
                        {}
                    </Box>
                ))} */}
                <Typography
                   className={classes.titleLabel}>
                   {t('PROFILE')||"Profile"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('GUARANTOR_PROFILE')||"Guarantor Profile"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                container
                                alignItems="center"
                                direction="column"
                            >
                                <img
                                    src={
                                        guarantorList[index] &&
                                        guarantorList[index].identityDocument &&
                                        guarantorList[index].identityDocument
                                            .selfPhoto
                                    }
                                    style={{
                                        width: "180px",
                                        margin: "0px auto",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="relationship"
                                        label={t('RELATIONSHIP')||"Relationship With Applicant"}
                                        value={
                                            guarantorList[index] &&
                                            getRelationShipByValue(guarantorList[index].relationShip)
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="salutation"
                                        label={t('SALUTATION')||"Salutation"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index].salutation
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                    name="name"
                                    label={t('FULL_NAME')||"Full Name(same as ID)"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index].name
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DarkerDisabledTextField
                                    name="birthday"
                                    label={t('DOB')||"Date of Birth"}
                                    value={
                                        guarantorList[index] &&
                                        moment(guarantorList[index].birthday).format('DD/MM/YYYY')
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="gender"
                                        label={t('GENDER')||"Gender"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index].gender
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                    name="phone"
                                    label={t('MOBILE')||"Mobile Number"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index].phone
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                    name="email"
                                    label={t('EMAIL')||"Email(optional)"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index].email
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                    name="country"
                                    label={t('COUNTRY')||"Country"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index]
                                            .addressProofDocument.country
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DarkerDisabledTextField
                                    name="postalCode"
                                    label={t('POSTAL_CODE')||"Postal code"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index]
                                            .addressProofDocument.postalCode
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="province"
                                        label={t('DISTRICT_PROVINCE')||"District / Province"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index]
                                                .addressProofDocument.province
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="city"
                                        label={t('CITY_TOWN')||"City / Town"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index]
                                                .addressProofDocument.city
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <DarkerDisabledTextField
                                    name="address"
                                    label={t('ADDRESS')||"Address"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index]
                                            .addressProofDocument.address
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="employmentType"
                                        label={t('EMPLOYMENT_TYPE')||"Employment type"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index].employmentType &&
                                            guarantorList[index].employmentType.employmentType
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="industryType"
                                        label={t('INDUSTRY_BUSINESS_TYPE')||"Industry / Business type"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index].industry
                                                .industry
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                    name="employerName"
                                    label={t('EMPLOYER_COMPANY_NAME')||"Employer &amp; Company name"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index].companyName
                                    }
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                   className={classes.titleLabel}>
                   {t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="idType"
                                        label="ID type"
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index]
                                                .identityDocument.idType
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DarkerDisabledTextField
                                    name="idNumber"
                                    label={t('ID_NUMBER')||"ID number"}
                                    value={
                                        guarantorList[index] &&
                                        guarantorList[index].identityDocument
                                            .idNumber
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{ marginBottom: "8px" }}>
                                    {t('FRONT_ID')||"Front side of ID"}
                                </Typography>

                                <img
                                    src={
                                        guarantorList[index] &&
                                        guarantorList[index].identityDocument &&
                                        guarantorList[index].identityDocument
                                            .idFrontImg
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{ marginBottom: "8px" }}>
                                    {t('BACK_ID')||"Back side of ID"}
                                </Typography>

                                <img
                                    src={
                                        guarantorList[index] &&
                                        guarantorList[index].identityDocument &&
                                        guarantorList[index].identityDocument
                                            .idBackImg
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                   className={classes.titleLabel}>
                   {t('GUARANTOR_ADDRESS_PROOF')||"Guarantor Address Proof"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle
                        classes={classes}
                        text={t('GUARANTOR_ADDRESS_PROOF')||"Gurantor Address Proof"}
                    /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <DarkerDisabledTextField
                                        name="documentType"
                                        label={t('DOCUMENT_TYPE')||"Document type"}
                                        value={
                                            guarantorList[index] &&
                                            guarantorList[index]
                                                .addressProofDocument
                                                .documentType
                                        }
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            {guarantorList?.[0]?.addressProofDocument &&
                                                guarantorList?.[0]?.addressProofDocument.addressProofDocument &&
                         <Grid item xs={12} sm={6}>
                                <div className={classes.fileContainer}>
                                    <Typography
                                        style={{
                                            marginLeft: "8px",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <Link
                                            href={
                                                guarantorList[index] &&
                                                guarantorList[index]
                                                    .addressProofDocument &&
                                                guarantorList[index]
                                                    .addressProofDocument
                                                    .addressProofDocument
                                            }
                                        >
                                            {t('VIEW_DOCUMENT')||"View Document"}
                                        </Link>
                                    </Typography>
                                    {/* <DeleteOutlineIcon /> */}
                                </div>
                            </Grid>}
                        </Grid>
                    </div>
                </Paper>
            </Fragment>
    
            )}
        </>
        );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(ApplicationDetailGuarantor);
