import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Gauge from "react-svg-gauge";
import get from "lodash/get";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { getApplicationById, calculateCde } from "../../store/actions/customer";
import { currencyFormat } from "../../utils/helper";
import LoaderComponent from "../connected_components/Loader";
import { includes } from "lodash";
import TextField from "@material-ui/core/TextField";
import Avatar from '@mui/material/Avatar';
import { blue } from "@material-ui/core/colors";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { AppBar, Toolbar } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Hidden } from '@mui/material';
import {
    CircularInput,
    CircularTrack,
    CircularProgress,
    CircularThumb,
    useCircularInputContext
  } from "react-circular-input";
import { style } from "@mui/system";
import { STATUS_COLOR_CODING, APP_CONFIGS} from "../../constants/appConstants";

const ApplicationDetailGeneral = (props) => {
    const {
        classes,
        applicationDetail,
        calculateCde,
        applicationId,
        cdeInfo,
        isLoadingCalculateCde,
        getApplicationById,
        addRemarks,
        getRemarks,
        currentUser,
        remarksHistory,
        handleChangeTab,
        isLoadingRemarksHistory,
        updateApplicationStatus,
        confirmSubmit,
        history,
        applicant,
        t
    } = props;
    const { status } = applicationDetail
    //const [remarks, setRemarks] = useState("");
    const [allValues, setAllValues] = useState({
        remarks:""
    })
    const {remarks}=allValues;
    const [historyOfRemarks, setHistoryOfRemarks] = useState([]);
    const [cdeScore, setCdeScore] = useState(0);
    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        calculateCde({ applicationId });
    }, [applicationId]);

    useEffect(()=>{
        getApplicationById(applicationId);
    },[applicationId]);

    useEffect(()=>{
        getRemarks(applicationId);
    },[]);

    useEffect(() => {
        if(!isLoadingRemarksHistory && (remarksHistory.length>=historyOfRemarks.length)){
        setHistoryOfRemarks(remarksHistory)
        }
    }, [remarksHistory]);

    useEffect(() => {
        if(cdeInfo && cdeInfo.critical_comments && applicationDetail){
        const { score } = cdeInfo;
        const databaseScore = applicationDetail.cdeScore;
        if ((score !== databaseScore)) {
            const params = {
                applicationId: applicationId,
                data: {
                    cdeScore: score!==0?score:null,
                },
            };
            updateApplicationStatus(params);
        }
    } else if(cdeInfo && cdeInfo.criteria_failure && applicationDetail){
        if(applicationDetail.cdeScore){
        const params = {
            applicationId: applicationId,
            data: {
                cdeScore: null,
            },
        };
        updateApplicationStatus(params);
    }
    }
    },[cdeInfo])

    const renderCDERecommendation = () => {
        let content = [];
        if (cdeInfo && cdeInfo.critical_comments) {
            for (const [key, value] of Object.entries(
                cdeInfo.critical_comments
            )) {
                content.push(
                    <div key={key}>
                        <p className="f-12 c-grey mb-4">{key}</p>
                        <p className="f-14 mt-0">{value}</p>
                    </div>
                );
            }
        }
        return content;
    };
    function stringAvatar(name) {
        return {
          sx: { bgcolor:blue[900], width: 50, height: 50 },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
    const renderRemarksHistory = () =>{
        let content = [];
        if (historyOfRemarks.length>0) {
            for (const [key, item] of Object.entries(
                historyOfRemarks
            )) {
                content.push(
                    <Grid container spacing={6}>
                         <Grid item xs={1} sm={1}>
                                <Tooltip title={item.createdBy} placement="left-start">
                                <Avatar style={{backgroundColor:'#5F56A6'}} {...stringAvatar(item.createdBy)} />
                                </Tooltip>
                          </Grid>
                          {/* <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                   {item.createdBy}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {moment(item.createdAt).format('DD/MM/YYYY hh:mm a')}
                            </Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={11}>

                            <TextField
                                         name="remarks"
                                          //id="myfilled-name"
                                          id="filled-multiline-static"
                                          multiline
                                          //rows={1}
                                          label={item.createdBy+' '+moment(item.createdAt).format('DD/MM/YYYY hh:mm a')}
                                          variant="filled"
                                          InputProps={{ disableUnderline: true}}
                                          value={item.remarks}
                                        
                                        />
                        
                            </Grid>
                            </Grid>
                       
                );
            }
        }
        return content.reverse();
    }
    const renderPercentageUtilization = () => {
        const { loanDetails } = applicationDetail;
        const { flatRate, reducingRate } = loanDetails;
        const percent =
            parseFloat((flatRate || reducingRate) * 100).toFixed(1) + "%";
        return percent;
    };
    const renderPercentageUtilizationType = () => {
        const { loanDetails } = applicationDetail;
        const { flatRate, reducingRate } = loanDetails;
        const type = reducingRate > 0 ? "Reducing" : "Flat";
        return type;
    };
    const renderAmount = () => {
        const { loanDetails } = applicationDetail;
        const { loanAmount } = loanDetails;
        return currencyFormat(loanAmount);
    };
    const renderRepaymentAmount = () => {
        const { loanDetails } = applicationDetail;
        const { loanAmount, totalInterest } = loanDetails;
        return currencyFormat(loanAmount+totalInterest);
    };
    const { createdUser } = applicationDetail;
    const renderReviewedUser = () => {
        const { reviewedUser } = applicationDetail;
        if (reviewedUser && reviewedUser.firstName) {
            return reviewedUser.firstName + " " + reviewedUser.lastName;
        }
        return "-";
    };

    const handleSave = async () =>{
        console.log("remarks id : "+remarks);
        var history = remarksHistory;
        var params = {
            applicationId: applicationId,
            remarks: remarks,
            createdBy: currentUser.firstName+" "+currentUser.lastName
        }
         await addRemarks(params);
        setAllValues({
            ...allValues,
            remarks:''
        })
        params.createdAt = new Date().toISOString();
        history.unshift(params);
        setHistoryOfRemarks(history);
        console.log(history.reverse());
        await getRemarks(applicationId);
    }

    const handleSubmit = () => {
        confirmSubmit();
    };

    const handleInquiryPurchase = () =>{
        history.push("/client/"+applicant.id+"/selectVehicle/"+applicationId+"/continue")
           
    }

    const handleSaveInquiry = () =>{
        history.push("/dashboard");
    }
    return (
        <>
            <LoaderComponent isLoading={isLoadingCalculateCde} />
            {applicationDetail && applicationDetail.id && (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperGeneralCde} style={{overflow: 'auto'}}>
                    <Typography
                            //gutterBottom
                            className={classes.paperLabel}
                        >
                            {t('CDE_SCORE')||"CDE Score"}
                        </Typography>
                    <div className={classes.paperContent} style={{alignItems:'centre'}}>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <CdeGauge cdeInfo={cdeInfo} applicationDetail={applicationDetail}/>
                        </div>
                        <div style={{paddingTop:"8px"}}>
                            {cdeInfo &&
                                cdeInfo.critical_comments &&
                                renderCDERecommendation()}
                        </div>
                        </div>
                    </Paper> 
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Paper className={classes.paperGeneral} style={{overflow: 'auto'}}>
                        <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('ID')||"ID"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {applicationDetail.applicationStringId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('BRANCH')||"Branch"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {createdUser.branchName}
                                </Typography>
                            </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('CUSTOMER_NAME')||"Customer name"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {applicationDetail.customer
                                        ? applicationDetail.customer.name
                                        : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('ACCOUNTANT')||"Accountant"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {createdUser.firstName}{" "}
                                    {createdUser.lastName}
                                </Typography>
                            </Grid>
                           
                        </Grid>
                        <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('MOBILE')||"Mobile number"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {applicationDetail.customer.phone}
                                </Typography>
                            </Grid>
                           
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('LOAN_MANAGER')||"Loan manager"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {renderReviewedUser()}
                                </Typography>
                            </Grid>
                            
                        </Grid>
                        </div>
                        </Paper>
                        <Paper className={classes.paperGeneral} style={{overflow: 'auto'}}>
                        <div className={classes.paperContent}>
                        
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('LOAN_AMOUNT')||"Loan amount"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {APP_CONFIGS.CURRENCY}{renderAmount()}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('TOTAL_REPAYMENT_AMOUNT')||"Updated on"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                {APP_CONFIGS.CURRENCY}{renderRepaymentAmount()}
                                </Typography>
                            </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('FUND_UTILIZATION_RATE')||"Fund utilization rate"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {renderPercentageUtilization()}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('FUND_UTILIZATION_TYPE')||"Fund utilization type"}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {renderPercentageUtilizationType()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr/>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    {t('STATUS')||"Status"}
                                </Typography>

                                <Typography variant="body2" 
                                style={{
                                    color: STATUS_COLOR_CODING[`${applicationDetail.status}`]
                                }}
                                gutterBottom>
                                    {applicationDetail.status!=='Inquiry'?applicationDetail.status:"Enquiry"}
                                </Typography>
                            </Grid>
                        {includes(['Approved', 'Accepted', 'Active', 'Rejected', 'Withdrawn'], applicationDetail.status) && (
                                // <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {status == 'Approved' ? t('APPROVAL_REASON') : t('REASON')}
                                    </Typography>
    
                                    <Typography variant="body2" gutterBottom>
                                        {applicationDetail.note}
                                    </Typography>
                                </Grid>
                            // </Grid>
                            )}
                            </Grid>
                            {/* <Paper className={classes.paperRemarks}> */}
                            {/* <div>
                                <Grid container spacing={4}>
                                <Grid item xs={24} sm={12}>
                                <Typography variant="caption" gutterBottom>
                                    Remarks
                                </Typography>
                                <TextField
                                         name="remarks"
                                          id="outlined-basic"
                                          //id="filled-multiline-static"
                                          multiline
                                          //rows={1}
                                          variant="outlined"
                                          value={remarks}
                                          onChange={changeHandler}
                                          placeholder="Write down the remarks here..."
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container
                                    justifyContent="flex-end"
                                     spacing={4}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            onClick={handleSave}
                                            // className={classes.actionBtn}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div> */}
                        {/* </Paper> */}
                    </div>
                    </Paper>
                    </Grid>
                    <Grid container spacing={3} style={{marginLeft:'2px',marginRight:'2px'}}>
                    <Grid item xs={12} sm={12} style={{paddingTop: "1px"}}>
                        <Paper className={classes.paperRemarks} style={{overflow: 'auto'}}>
                        <Typography
                                className={classes.remarksTitle}
                                component="h2"
                                variant="h4"
                            >
                                {t('REMARKS')||"Remarks"}
                            </Typography>
                        <div className={classes.paperContent}>
                                <Grid container spacing={2}>
                                <Grid item sm={1} sx={{
                                    display: { xs: "none", lg: "block" }
                                }}>
                                <Tooltip title={currentUser.firstName+' '+currentUser.lastName}>
                                <Avatar style={{backgroundColor:'#5F56A6'}} sx={{ width: 50, height: 50 }}>
                                    {currentUser.firstName[0]+currentUser.lastName[0]}
                                </Avatar>
                                </Tooltip>
                                
                                </Grid>
                                <Grid item xs={12} sm={11}>
                                <TextField
                                         name="remarks"
                                          id="outlined-basic"
                                          //id="filled-multiline-static"
                                        //   multiline
                                          //rows={1}
                                          variant="filled"
                                          autoComplete='off'
                                          InputProps={{ disableUnderline: true, form: {
                                            autocomplete: false
                                          } }}
                                          value={remarks}
                                          onChange={changeHandler}
                                          label={t('WRITE_DOWN_REMARKS_HERE')||"Write down the remarks here..."}
                                          
                                        />
                                    </Grid>
                                    
                                    </Grid>
                                    <Grid container
                                    justifyContent="flex-end"
                                     spacing={2}>
                                    <Grid item>
                                        <Button
                                            style={{backgroundColor:'#5F56A6', color:'#F8F8F8'}}
                                            variant="contained"
                                            size="large"
                                            // color="primary"
                                            onClick={handleSave}
                                            // className={classes.actionBtn}
                                        >
                                            {t('ADD')||"Add"}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: `15px` }}>
                                <LoaderComponent isLoading={isLoadingRemarksHistory} />
                            {remarksHistory.length>0 &&
                                renderRemarksHistory()}
                        </div>
                            </div>
                         </Paper>
                    </Grid>
                    </Grid>
                </Grid>
                
            )}
            {includes(['Pending', 'Inquiry'], applicationDetail.status) && (<ApplicationAdminstration
                    classes={classes}
                    handleSubmit={handleSubmit}
                    handleSave={handleSaveInquiry}
                    handleInquiryPurchase={handleInquiryPurchase}
                    status={applicationDetail.status}
                    t={t}
                    
                />)}
        </>
    );
};

const CdeGauge = (props) => {
    const { cdeInfo, applicationDetail } = props;
    //const { score } = cdeInfo;
    let score= applicationDetail.cdeScore? applicationDetail.cdeScore : (cdeInfo && cdeInfo.score ? cdeInfo.score : 0)
    const getColor = () => {
        if (score > 649) {
            return "#39B542";
        } else if (score > 500 && score < 650) {
            return "#ED9807";
        } else {
            return "#E22B16";
        }
    };
    const value = score>0?(score-300)/600 : 0;
    return (
        <CircularInput radius={70} value={value}>
        <CircularTrack  />
        <CircularProgress 
        stroke={`${getColor()}`}/>
        <text x={70} y={70} textAnchor="middle" dy="0.3em">
		 {score>0?score : 'N.A.'}
		</text>
        </CircularInput>
    )
    return (
        <Gauge
            value={score}
            width={158}
            height={125}
            min={300}
            max={900}
            
            color={getColor()}
            topLabelStyle={{ display: "none" }}
            minMaxLabelStyle={{ fontSize: "12px" }}
            valueLabelStyle={{ fontSize: "16px", color: "#ED9807" }}
            valueFormatter={(value) => {
                return value ? value : "N.A";
            }}
        />
    );
};


const ApplicationAdminstration = (props) => {
    const { classes, handleSubmit, handleSave, status, handleInquiryPurchase, t } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.rm64}
                >
                    <Box p={1}>
                        <Button
                            id = "save"
                            variant="outlined"
                            color="primary"
                            type="submit"
                            className={classes.staffActionBtn}
                            onClick={handleSave}
                        >
                            {t('SAVE_AND_EXIT')||"Save &amp; Exit"}
                        </Button>
                    </Box>
                    {status==="Pending"? (<Box p={1}>
                        <Button
                            style={{
                                backgroundColor: "#5F56A6"
                            }}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </Box>):(<Box p={1}>
                    <Button
                    style={{
                        backgroundColor: "#5F56A6"
                    }}
                    className={classes.staffActionBtn}
                    variant="contained"
                    color="primary"
                    onClick={handleInquiryPurchase}
                >
                    {t('CONTINUE_PURCHASE')||"Continue purchase"}
                </Button></Box>)}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => {
    return {
        applicationDetail: state.customer.application || {},
        applicationVehicle: get(state.customer.application, "vehicle") || {},
        customer: get(state.customer.application, "customer") || {},
        cdeInfo: get(state.customer.cdeInfo, "data") || {},
        status: get(state.customer.application, "status"),
        isLoadingCalculateCde: state.customer.isLoadingCalculateCde,
        remarksHistory: get(state.customer.remarks,'data') ||[],
        isLoadingRemarksHistory: state.customer.isLoadingRemarksHistory
    };
};

const mapDispatchToProps = {
    getApplicationById,
    calculateCde,
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationDetailGeneral))
);
