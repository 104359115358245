import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import LoanOffer from './LoanOffer'
import { getCities } from '../../store/actions/common'
import {
    updateApplicationStatus,
    updateDocument,
    createAdditionalInfo
  } from "../../store/actions/customer";

const LoanOfferContainer = (props: any) => <LoanOffer {...props} />

const mapStateToProps = (state: any) => ({
    cityList: get(state.common.cityList, 'data'),
    applicationDetail: state.customer.application||{},
    status: get(state.customer.application,'status'),
})

const mapDispatchToProps = {
    getCities,
    updateApplicationStatus,
    updateDocument,
    createAdditionalInfo
}


export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(LoanOfferContainer))
)
