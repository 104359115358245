import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import map from "lodash/map";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    RESIDENTIAL_OPTIONS,
    ID_TYPE_OPTIONS,
    YEARS_CURRENT_ADDRESS_OPTIONS,
    MARITAL_STATUS_OPTIONS,
    DEPENDENT_OPTIONS,
    YEARS_CURRENT_WORK_OPTIONS,
    YEARS_TOTAL_WORK_OPTIONS,
    NO_OF_LOANS_OPTIONS,
    INCOME_PROOF_OPTIONS,
    YEARS_OPTIONS,
    ADDRESS_PROOF_OPTIONS,
    INTEREST_STATUS,
    APP_CONFIGS
} from "../../../constants/appConstants";
import filter from "lodash/filter";
import RadioGroup from "@material-ui/core/RadioGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/Person";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {
    validationForTextField,
    validationForVehicle,
    validationForNumber,
    validationForDate,
} from "../../../utils/formValidation";
import FormHelperText from "@material-ui/core/FormHelperText";
import { mapDistrictsOptions, mapCityOptions } from "../../../utils/helper";
import { Box, AppBar, Toolbar } from "@material-ui/core";
import get from "lodash/get";
import {
    getApplicationById,
    getEmploymentTypes,
    getIndustryType,
    calculateCde
} from "../../../store/actions/customer";
import { getCities } from "../../../store/actions/common";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LoaderComponent from "../../connected_components/Loader";

const DarkerFormControlLabel = withStyles({
    root: {
        marginRight: 8,
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: "black",
        },
    },
})(FormControlLabel);

const ApplicantUpdateComponent = (props) => {
    const {
        classes,
        applicant,
        getApplicationById,
        getEmploymentTypes,
        industryTypeList,
        cityList,
        employmentTypeList,
        getIndustryType,
        getCities,
        applicationLoan,
        confirmSubmit,
        changeTab,
        createProfileEmployment,
        createCustomerDocument,
        status,
        updateProfile,
        updateEmployment,
        history,
        createIdentityDocument,
        createIncomeProof,
        createAddressProof,
        updateIdentityDocument, 
        updateIncomeProof,
        updateAddressProof,
        updateCustomerData,
        createAdditionalInfo,
        getAdditionalInfo, 
        updateAdditionalInfo, 
        additionalInfo,
        isLoadingCalculateCde,
        calculateCde, t
    } = props;

    const { applicationId } = props.match.params;
    const [errorForEntrustedApplicant, setErrorForEntrustedApplicant] =
        useState(false);
    const [errorForRelatedTerm, setErrorForRelatedTerm] = useState(false);
    const [postalCode, setPostalCode] = useState("");
    const [permanentPostalCode, setPermanentPostalCode] = useState("");
    const [businessPostalCode, setBusinessPostalCode] = useState("");
    const [employmentTypeId, setEmploymentTypeId] = useState("");
    const [addressProofDocumentUrl, setAddressProofDocumentUrl] = useState("");
    const [idFrontImgUrl, setidFrontImgUrl] = useState("");
    const [idBackImgUrl, setidBackImgUrl] = useState("");
    const [selfPhotoUrl, setSelfPhotoUrl] = useState("");
    const [incomeProofUrl, setIncomeProofUrl] = useState("");
    const [imageValues, setImageValues] = useState({
        selfPhoto: "",
        idFrontImg: "",
        idBackImg: "",
        addressProof: "",
        incomeProof: "",
    });
    const { selfPhoto, idFrontImg, idBackImg, addressProof, incomeProof } =
        imageValues;
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [changedId, setChangedId] = useState(false);
    const [changedIncomeDoc, setChangedIncomeDoc] = useState(false);
    const [changedAddressDoc, setChangedAddressDoc] = useState(false);
    const [maxFollowUpDate,setMaxFollowUpDate] = useState("");

    useEffect(() => {
        getEmploymentTypes();
        getApplicationById(applicationId);
        getAdditionalInfo(applicationId);
        getCities();
        const tmp = new Date();
        setMaxFollowUpDate(tmp.setDate(tmp.getDate() + 1));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const handleSelfImageChange = (e) => {
        setSelfPhotoUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setChangedId(true);
    };

    const changePermanentPostalCode = (e) => {
        setPermanentPostalCode(e.target.value);

        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    permanentProvince: item[0].state,
                    permanentCity: item[0].city,
                });
            }
        }
    };
    const [dialogOpen, setDialogOpen] = useState(false);

    const [fileIndex, setFileIndex] = useState(0);
    const removeSelfPhoto = (e) => {
        setFileIndex(1);
        setDialogOpen(true);
    };
    const removeFrontPhoto = (e) => {
        setFileIndex(2);
        setDialogOpen(true);
    };
    const removeBackPhoto = (e) => {
        setFileIndex(3);
        setDialogOpen(true);
    };
    const removeInComeFile = (e) => {
        setFileIndex(4);
        setDialogOpen(true);
    };
    const removeAddressFile = (e) => {
        setFileIndex(5);
        setDialogOpen(true);
    };

    function closeDialog() {
        setDialogOpen(false);
    }
    const removeFile = (index) => {
        if (index === 1) {
            setSelfPhotoUrl("");
            setImageValues({ ...imageValues, selfPhoto: "" });
        } else if (index === 2) {
            setidFrontImgUrl("");
            setImageValues({ ...imageValues, idFrontImg: "" });
        } else if (index == 3) {
            setidBackImgUrl("");
            setImageValues({ ...imageValues, idBackImg: "" });
        } else if (index == 4) {
            setIncomeProofUrl("");
            setImageValues({ ...imageValues, incomeProof: "" });
            setIncomeDocShow(false);
        } else if (index == 5) {
            setAddressProofDocumentUrl("");
            setImageValues({ ...imageValues, addressProof: "" });
            setAddressDocShow(false);
        }
        setDialogOpen(false);
    };
    const handleFrontImageChange = (e) => {
        setidFrontImgUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setChangedId(true);
    };
    const handleBackImageChange = (e) => {
        setidBackImgUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setChangedId(true);
    };
    const [incomeDocShow, setIncomeDocShow] = useState(false);
    const [incomeDocName, setIncomeDocName] = useState("");
    const handleIncomeProofChange = (e) => {
        setIncomeDocName(e.target.files[0].name);
        setIncomeDocShow(true);
        setIncomeProofUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setChangedIncomeDoc(true);
    };

    const [addressDocShow, setAddressDocShow] = useState(false);
    const [addressDocName, setAddressDocName] = useState("");
    const handleAddressProofDocumentChange = (e) => {
        setAddressDocName(e.target.files[0].name);
        setAddressDocShow(true);
        setAddressProofDocumentUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setChangedAddressDoc(true);
    };
    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };

    
    const changeBusinessPostalCode = (e) => {
        setBusinessPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    businessProvince: item[0].state,
                    businessCity: item[0].city,
                });
            }
        }
    };
    const [allValues, setAllValues] = useState({
        salutation: "",
        name: "",
        birthday: "",
        gender: "",
        maritalStatus: "",
        dependents: "",
        phone: "",
        homePhone: "",
        officePhone: "",
        email: "",
        idTypeId: "1",
        idType: "",
        idNumber: "",
        facebookId: "",
        existingVehicle: "",
        residentalStatus: "",
        yearsLiving: "",
        province: "",
        permanentProvince: "",
        city: "",
        address: "",
        countryCode: APP_CONFIGS.COUNTRY_CODE,
        country: APP_CONFIGS.COUNTRY,
        businessCountry: APP_CONFIGS.COUNTRY,
        businessCity: "",
        businessProvince: "",
        permanentCountry: APP_CONFIGS.COUNTRY,
        permanentCity: "",
        permanentAddress: "",
        businessAddress: "",
        isEntrustedPPF: "false",
        isNaturealRelatedPPF: "false",
        incomeProofTypeId: "",
        employerName: "",
        monthlyLoanPayment: "",
        industryId: "",
        workingYears: "",
        totalWorkingYears: "",
        totalYearsOfBusiness: "",
        existingLoan: "",
        monthlyIncome: "",
        isEntrustedPPF: "false",
        isNaturealRelatedPPF: "false",
        addressProofTypeId: "",
        interestStatus: "",
        followUpDate: ""
    });
    const {
        salutation,
        name,
        gender,
        birthday,
        maritalStatus,
        dependents,
        phone,
        homePhone,
        officePhone,
        email,
        isEntrustedPPF,
        isNaturealRelatedPPF,
        idType,
        idTypeId,
        idNumber,
        facebookId,
        existingVehicle,
        residentalStatus,
        yearsLiving,
        province,
        permanentProvince,
        city,
        address,
        country,
        permanentCountry,
        businessCountry,
        countryCode,
        permanentCity,
        permanentAddress,
        businessAddress,
        employerName,
        monthlyLoanPayment,
        industryId,
        totalYearsOfBusiness,
        workingYears,
        incomeProofTypeId,
        totalWorkingYears,
        existingLoan,
        monthlyIncome,
        businessCity,
        businessProvince,
        addressProofTypeId,
        interestStatus,
        followUpDate
    } = allValues;

    useEffect(()=>{
        if(city.length>0){
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if(item.length>0){
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    },[city,province])

    useEffect(()=>{
        if(permanentCity.length>0){
            const item = filter(
                cityList,
                (item) => item.city === permanentCity
            );
            if(item.length>0){
                setPermanentPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    permanentProvince: item[0].state
                })
            }
        }

    },[permanentCity,permanentProvince])

    useEffect(()=>{
        if(businessCity?.length>0){
            const item = filter(
                cityList,
                (item) => item.city === businessCity
            );
            if(item.length>0){
                setBusinessPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    businessProvince: item[0].state
                })
            }
        }

    },[businessCity,businessProvince])

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const handleDateChange = (date) => {
        setAllValues({ ...allValues, birthday: date });
    };

    const handleFollowUpDateChange = (date) => {
        setAllValues({ ...allValues, followUpDate: date });
    };

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidIdNumber", (value) => {
            if (value) {
                return validationForNumber(value, 13) || true;
            }
            return true
        });
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return  true;
        });

        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value) || true;
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value) || true;
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, 8) || validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidExistingVehicle", (value) => {
            return validationForVehicle(value) || true;
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value) || true;
        });
    });
    const [
        helperTextForEntrustedApplicant,
        setHelperTextForEntrustedApplicant,
    ] = useState("");
    const [helperTextForRelatedTerm, setHelperTextForRelatedTerm] =
        useState("");

    const onChangeRadioGroup = (e, type) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });

        if (type === "entrustedApplicant") {
            setHelperTextForEntrustedApplicant("");
            setErrorForEntrustedApplicant(false);
        } else {
            setErrorForRelatedTerm(false);
            setHelperTextForRelatedTerm("");
        }
    };
    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);
    useEffect(() => {
        if (applicant.salutation !== undefined && applicant !== null) {
            setAllValues({
                ...allValues,
                salutation: applicant.salutation,
                name: applicant.name,
                birthday: moment(applicant.birthday).format("YYYY-MM-DD"),
                gender: applicant.gender,
                interestStatus: applicant.interestStatus,
                followUpDate: moment(applicant.followUpDate).format("YYYY-MM-DD"),
                maritalStatus:
                    applicant.profile && applicant.profile.maritalStatus,
                dependents: applicant.profile && applicant.profile.dependents,
                phone: applicant.phone,
                idType: applicant.identity.idType.idType,
                idNumber: applicant.identity.idNumber,
                residentalStatus: applicant.address.residentalStatus,
                yearsLiving: applicant.address.yearsLiving,
                province: applicant.address.province,
                city: applicant.address.city,
                address: applicant.address.address,
                postalCode: applicant.address.postalCode,
                permanentProvince: applicant.permanentAddress.province,
                permanentCity: applicant.permanentAddress.city,
                permanentAddress: applicant.permanentAddress.address,
                industryId: applicant.employment.industry.id,
                workingYears: applicant.employment.workingYears,
                totalWorkingYears: applicant.employment.totalWorkingYears || applicant.employment.totalYearsOfBusiness,
                monthlyLoanPayment: applicant.employment.monthlyLoanPayment,
                incomeProofTypeId: applicant.incomeProof.incomeProofType.id,
                monthlyIncome: applicant.incomeProof.monthlyIncome || applicant.employment.monthlyIncome,
                existingLoan: applicant.employment.existingLoan,
                employerName: applicant.employment.employerName,
                businessCountry: applicant.employment.businessCountry || APP_CONFIGS.COUNTRY,
                businessCity: applicant.employment.businessCity,
                businessProvince: applicant.employment.businessProvince,
                businessAddress: applicant.employment.businessAddress,
                totalYearsOfBusiness: applicant.employment.totalYearsOfBusiness,
                addressProofTypeId: applicant.addressProof.addressProofType.id,
                homePhone: additionalInfo.homePhoneNumber,
                officePhone: additionalInfo.officePhoneNumber,
                email: additionalInfo.email,
                facebookId: additionalInfo.facebookId,
                
            });
            setImageValues({
                ...imageValues,
                selfPhoto: applicant.identity.selfPhoto || "",
                idFrontImg: applicant.identity.idFrontImg || "",
                idBackImg: applicant.identity.idBackImg || "",
                addressProof: applicant.addressProof.addressProofDocument || "",
                incomeProof: applicant.incomeProof.incomeProofDocument || "",
            });
            applicant.addressProof.addressProofDocument &&
                setAddressDocShow(true);
            applicant.incomeProof.incomeProofDocument &&
                setIncomeDocShow(true);

              applicant.incomeProof.incomeProofDocument &&  setIncomeDocName(applicant.incomeProof.incomeProofDocument);
            setAddressDocName(applicant.addressProof.addressProofDocument);
            setPostalCode(applicant.address.postalCode);
            setPermanentPostalCode(applicant.permanentAddress.postalCode);
            setEmploymentTypeId(applicant.employment.employmentType.id);
            getIndustryType(applicant.employment.employmentType.id);
            setBusinessPostalCode(applicant.employment.postalCode)
        }
    }, [applicant]);

    const [workYearsDisabled, setWorkYearsDisabled] = useState(false);
    const [workingYearsRequired, setWorkingYearsRequired] = useState(true);
    const [totalWorkYearsDisabled, setTotalWorkYearsDisabled] = useState(false);
    const [totalWorkingYearsRequired, setTotalWorkingYearsRequired] =
        useState(true);
    const changeEmploymentType = (e) => {
        if (e.target.value === 3 || e.target.value === 5 || e.target.value === 7 || e.target.value === 6) {
            setAllValues({ ...allValues, workingYears: "" });
            setWorkYearsDisabled(true);
            setWorkingYearsRequired(false);
        } else {
            setWorkYearsDisabled(false);
            setWorkingYearsRequired(true);
        }
        if (e.target.value === 7 || e.target.value === 6) {
            setAllValues({ ...allValues, totalWorkingYears: "" });
            setTotalWorkingYearsRequired(true);
            setTotalWorkYearsDisabled(false);
        }
        if (e.target.value === 8) {
            setAllValues({ ...allValues, totalWorkingYears: "" });
            setTotalWorkingYearsRequired(false);
            setTotalWorkYearsDisabled(true);
        } else {
            setTotalWorkingYearsRequired(true);
            setTotalWorkYearsDisabled(false);
        }

        setEmploymentTypeId(e.target.value);
        getIndustryType(e.target.value);
    };

    const handleSubmit = () => {
        confirmSubmit();
    };

    const handleInquiryPurchase = () =>{
        history.push("/client/"+applicant.id+"/selectVehicle/"+applicationId+"/continue")
           
    }

    const handleSave = async() => {
        console.log(allValues);
        if(applicant.id){
            const params = {
                clientId: applicant.id,
                data : {
                    salutation: salutation,
                    name: name,
                    birthday: birthday,
                    gender: gender,
                    phone: phone,
                    interestStatus: interestStatus,
                    followUpDate: followUpDate,
                    address: {
                        residentalStatus: residentalStatus,
                        yearsLiving: yearsLiving,
                        country: country,
                        postalCode: postalCode,
                        province: province,
                        city: city,
                        address: address,
                    },
                    permanentAddress: {
                        country: permanentCountry,
                        postalCode: permanentPostalCode,
                        province: permanentProvince,
                        city: permanentCity,
                        address: permanentAddress,
                    },
                }
            }
           await updateCustomerData(params);
        }
        if(applicant.profile.id){
            const params = {
                profileId: applicant.profile.id,
                data :{
                    maritalStatus: maritalStatus,
                    dependents: dependents
                }
            }
            await updateProfile(params);
        }
    
        if(applicant.employment.id){
            const params = {
                employmentId: applicant.employment.id,
                data :{
                    employmentTypeId,
                    industryId,
                    workingYears,
                    totalWorkingYears,
                    totalYearsOfBusiness,
                    monthlyIncome,
                    existingLoan,
                    emiPaid: monthlyLoanPayment,
                    monthlyLoanPayment,
                    employerName,
                    businessCountry,
                    postalCode: businessPostalCode,
                    businessCity,
                    businessProvince,
                    businessAddress
                }
            }
    
           await updateEmployment(params);
        }

        if(changedId){ //indicated Id change
            if(applicant.identity.id){
                console.log("Need to update identity Doc");
                const params = {
                    identityId:applicant.identity.id,
                    data: {
                        idTypeId: idTypeId || 1,
                        selfPhoto,
                        selfPhotoUrl,
                        idFrontImg,
                        idFrontImgUrl,
                        idBackImg,
                        idBackImgUrl,
                        idNumber
                    }
                }
              await  updateIdentityDocument(params);
            } else {
                console.log("Need to create identity doc");
                const params = {
                    clientId:applicant.id,
                    data: {
                        idTypeId: idTypeId || 1,
                        selfPhoto,
                        selfPhotoUrl,
                        idFrontImg,
                        idFrontImgUrl,
                        idBackImg,
                        idBackImgUrl,
                        idNumber
                    }
                }
               await createIdentityDocument(params);
            }
        }

        if(changedIncomeDoc){ //indicated Id change
            if(applicant.incomeProof.id){
                console.log("Need to update income proof");
                const params = {
                    incomeProofId:applicant.incomeProof.id,
                    data: {
                        incomeProof,
                        incomeProofUrl,
                        incomeProofTypeId,
                        monthlyIncome,
                    }
                }
             await   updateIncomeProof(params);
            } else {
                console.log("Need to create income proof");
                const params = {
                    clientId:applicant.id,
                    data: {
                        incomeProof,
                        incomeProofUrl,
                        incomeProofTypeId,
                        monthlyIncome,
                    }
                }
             await   createIncomeProof(params);
            }
        }

        if(changedAddressDoc){
            if(applicant.addressProof.id){
                console.log("Need to update address proof");
                const params = {
                    addressProofId:applicant.addressProof.id,
                    data: {
                        addressProofTypeId,
                        addressProof,
                        country,
                        postalCode,
                        province,
                        city,
                        address,
                        addressProofDocumentUrl
                    }
                }
                updateAddressProof(params);
            } else {
                const params = {
                    clientId:applicant.id,
                    data: {
                        addressProofTypeId,
                        addressProof,
                        country,
                        postalCode,
                        province,
                        city,
                        address,
                        addressProofDocumentUrl
                    }
                }
                createAddressProof(params);
                console.log("Need to create address proof");
            }
        }
        
        if((!applicant.profile.id || !applicant.employment.id) && (isEntrustedPPF || isEntrustedPPF)){
        if (!isEntrustedPPF) {
            setErrorForEntrustedApplicant(true);
            setHelperTextForEntrustedApplicant("Please select an option.");
        }

        if (!isNaturealRelatedPPF) {
            setErrorForRelatedTerm(true);
            setHelperTextForRelatedTerm("Please select an option.");
        }
        if (isNaturealRelatedPPF && isEntrustedPPF) {
            const data = {
                clientId: applicant.id,
                maritalStatus,
                dependents,
                employmentTypeId,
                industryId,
                workingYears,
                totalWorkingYears,
                totalYearsOfBusiness,
                monthlyIncome,
                isEntrustedPPF,
                isNaturealRelatedPPF,
                existingLoan,
                monthlyLoanPayment,
                employerName,
                businessCountry,
                postalCode,
                businessCity,
                businessProvince,
                businessAddress
            };
            //console.log(data);
           await createProfileEmployment(data);
        }
    }else {
        const data = {
            clientId:applicant.id,
            maritalStatus: maritalStatus,
            idTypeId: "1",
            idNumber: idNumber,
            selfPhoto: selfPhoto || applicant.identity.selfPhoto,
            selfPhotoUrl: selfPhotoUrl || applicant.identity.selfPhoto,
            idFrontImg: idFrontImg || applicant.identity.idFrontImg,
            idFrontImgUrl: idFrontImgUrl || applicant.identity.idFrontImg,
            idBackImg: idBackImg || applicant.identity.idBackImg,
            addressProofTypeId : addressProofTypeId || applicant.addressProof.addressProofType.id,
            country: APP_CONFIGS.COUNTRY,
            postalCode,
            province,
            city,
            address,
            addressProof: addressProof || applicant.addressProof.addressProofDocument,
            addressProofDocumentUrl: addressProofDocumentUrl || applicant.addressProof.addressProofDocument,
            idBackImgUrl: idBackImgUrl || applicant.identity.idBackImg,
            incomeProof: incomeProof || applicant.incomeProof.incomeProofDocument,
            incomeProofUrl: incomeProofUrl || applicant.incomeProof.incomeProofDocument,
            incomeProofTypeId: incomeProofTypeId || applicant.incomeProof.incomeProofType.id,
            monthlyIncome,
            dependents,
            clientId:applicant.id
        };
        console.log(data);
        if(homePhone || officePhone || facebookId || email){
            const params = {
                applicationId: applicationId,
                homePhoneNumber: homePhone,
                officePhoneNumber: officePhone,
                email: email,
                facebookId: facebookId
            }

            if(additionalInfo.id){
              await  updateAdditionalInfo(params)
            } else {
             await   createAdditionalInfo(params)
            }
        }
        //createCustomerDocument(data);
    }
    calculateCde({ applicationId });
        //changeTab(0);
    };

    return (
        <Fragment>
            <CssBaseline />
            <LoaderComponent isLoading={isLoadingCalculateCde} />
            <ValidatorForm onSubmit={handleSave}>
            <Typography
                className={classes.titleLabel}>
                    {t('PROFILE')||"Profile"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('PROFILE')||"Profile"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                alignItems="center"
                                direction="column"
                            >
                                {selfPhoto.length < 1 && (
                                    <div className="file-input-column">
                                        <input
                                            type="file"
                                            id="selfPhoto"
                                            className="file"
                                            name="selfPhoto"
                                            onChange={handleSelfImageChange}
                                        />
                                        <label htmlFor="selfPhoto">
                                            <PersonIcon
                                                style={{
                                                    height: "100px",
                                                    width: "68px",
                                                    display: "block",
                                                    color:"#CCCCE5"
                                                }}
                                            />
                                            <span
                                                style={{
                                                    display: "block",
                                                    color:"#CCCCE5"
                                                }}
                                            >
                                                {t('TAP_TO_UPLOAD_PHOTO')||"Tap to upload photo"}
                                            </span>
                                        </label>
                                    </div>
                                )}
                                <div className={classes.photoContainer}>
                                    <img
                                        src={selfPhoto}
                                        className={classes.selfPhotoImage}
                                    />
                                    {selfPhoto && (
                                        <DeleteOutlineIcon
                                            className={classes.photoIcon}
                                            onClick={removeSelfPhoto}
                                        />
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('SALUTATION')||"Salutation"}</InputLabel>
                                    <Select
                                        name="salutation"
                                        value={salutation}
                                        onChange={changeHandler}
                                        // required
                                    >
                                        {map(
                                            SALUTATION_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.label}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    name="name"
                                    value={name}
                                    label={t('FULL_NAME')||"Full name"}
                                    fullWidth
                                    onChange={changeHandler}
                                    validators={["isValidFullName", "required"]}
                                    errorMessages={[
                                        "Please enter a valid full name",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            
                            <Grid item xs={6} style={{padingTop:'1px'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                                // margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB')||"Date of Birth"}
                                                format="dd/MM/yyyy"
                                                name="birthday"
                                                value={birthday}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                            </MuiPickersUtilsProvider>
                            </Grid>
                            
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('GENDER')||"Gender"}</InputLabel>
                                    <Select
                                        name="gender"
                                        value={gender}
                                        onChange={changeHandler}
                                        // required
                                    >
                                        {map(GENDER_OPTIONS, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.label}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('MARITAL_STATUS')||"Marital status"}</InputLabel>
                                    <Select
                                        name="maritalStatus"
                                        value={maritalStatus}
                                        onChange={changeHandler}
                                        required
                                    >
                                        {map(
                                            MARITAL_STATUS_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.label}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('NO_OF_DEPENDENTS')||"Number of dependants"}
                                    </InputLabel>
                                    <Select
                                        name="dependents"
                                        value={dependents}
                                        onChange={changeHandler}
                                        //required
                                    >
                                        {map(DEPENDENT_OPTIONS, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    name="phone"
                                    value={phone}
                                    label={t('MOBILE')||"Mobile number"}
                                    fullWidth
                                    onChange={changeHandler}
                                    type="number"
                                    validators={[
                                        "isValidMobileNumber",
                                        "required",
                                    ]}
                                    errorMessages={[
                                        "Please enter a valid mobile number",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="homePhone"
                                    value={homePhone}
                                    label={t('HOME_PHONE')||"Home phone number(optional)"}
                                    fullWidth
                                    onChange={changeHandler}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="officePhone"
                                    value={officePhone}
                                    label={t('OFFICE_PHONE')||"Office phone number(optional)"}
                                    fullWidth
                                    onChange={changeHandler}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    label={t('EMAIL')||"Email address(optional)"}
                                    className={classes.fullWidth}
                                    onChange={changeHandler}
                                    name="email"
                                    validators={["isEmail"]}
                                    errorMessages={[
                                        "Please enter a valid email",
                                    ]}
                                    value={email}
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    label={t('FACEBOOK_ID')||"FaceBookID(optional)"}
                                    className={classes.fullWidth}
                                    onChange={changeHandler}
                                    name="facebookId"
                                    value={facebookId}
                                  
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <TextValidator
                                    label="Existing vehicle"
                                    className={classes.fullWidth}
                                    onChange={changeHandler}
                                    name="existingVehicle"
                                    value={existingVehicle}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={12}>
                                <p className="textLink">
                                    {t('IS_ENTRUSTED_PPF_SHORT')||"Is the applicant or has the applicant been entrusted with a "}
                                    <Link href="#" underline="always" style={{color:"#5F56A6"}}>
                                        {t('PROMINENT_PUBLIC_FUNCTION')||"prominent public function"}
                                    </Link>
                                    ?
                                </p>
                                <FormControl
                                    component="fieldset"
                                    error={errorForEntrustedApplicant}
                                >
                                    <RadioGroup
                                        row
                                        aria-label="isEntrustedPPF"
                                        name="isEntrustedPPF"
                                        value={isEntrustedPPF}
                                        onChange={(e) =>
                                            onChangeRadioGroup(
                                                e,
                                                "entrustedApplicant"
                                            )
                                        }
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio style={{color:"#5F56A6"}}/>}
                                            label={t('YES')||"Yes"}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio style={{color:"#5F56A6"}}/>}
                                            label={t('NO')||"No"}
                                        />
                                    </RadioGroup>
                                    <FormHelperText>
                                        {helperTextForEntrustedApplicant}
                                    </FormHelperText>
                                </FormControl>
                                <p className="textLink">
                                    {t('IS_RELATED_PPF_SHORT')||"Is the applicant related in terms of "}
                                    <Link href="#" underline="always" style={{color:"#5F56A6"}}>
                                        {t('IMMEDIATE_FAMILY_MEMBER')||"immediate family member "}
                                    </Link>{" "}
                                    or{" "}
                                    <Link href="#" underline="always" style={{color:"#5F56A6"}}>
                                        {t('CLOSE_ASSOCIATE')||"close associate"}
                                    </Link>{" "}
                                    {t('IS_RELATED_REMAINING')||"to a natureal person who is or has been entrusted with a "}
                                    <Link href="#" underline="always" style={{color:"#5F56A6"}}>
                                        {t('PROMINENT_PUBLIC_FUNCTION')||"prominent public function"}
                                    </Link>
                                    ?
                                </p>
                                <FormControl
                                    component="fieldset"
                                    error={errorForRelatedTerm}
                                >
                                    <RadioGroup
                                        row
                                        aria-label="isNaturealRelatedPPF"
                                        name="isNaturealRelatedPPF"
                                        value={isNaturealRelatedPPF}
                                        onChange={onChangeRadioGroup}
                                        //required
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio style={{color:"#5F56A6"}}/>}
                                            label={t('YES')||"Yes"}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio style={{color:"#5F56A6"}} />}
                                            label={t('NO')||"No"}
                                        />
                                    </RadioGroup>
                                    <FormHelperText>
                                        {helperTextForRelatedTerm}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography className={classes.titleLabel} >
                                {t("Customer Interest Optional")}
                            </Typography>
                            <Paper className={classes.paper}>
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                                style={{ marginTop: "16px" }}
                                            >
                                                <InputLabel>
                                                    {t("Interest Level")}
                                                </InputLabel>
                                                <Select
                                                    id="interestStatusSelect"
                                                    name="interestStatus"
                                                    value={interestStatus}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        INTEREST_STATUS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label={t("Follow-up Date")}
                                                    format="dd/MM/yyyy"
                                                    minDate={maxFollowUpDate}
                                                    name="followUpDate"
                                                    value={followUpDate}
                                                    onChange={handleFollowUpDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label":
                                                            "change date",
                                                    }}
                                                    
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('ID_PROOF')||"ID proof"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('ID_PROOF')||"ID Proof"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>{t('ID_TYPE')||"ID type"}</InputLabel>
                                        <Select
                                            name="idTypeId"
                                            value={idTypeId}
                                            onChange={changeHandler}
                                            fullWidth
                                            //required
                                        >
                                            {map(
                                                ID_TYPE_OPTIONS,
                                                (item, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    name="idNumber"
                                    label={t('ID_NUMBER')||"ID number"}
                                    value={idNumber}
                                    onChange={changeHandler}
                                    fullWidth
                                    type="number"
                                    validators={["isValidIdNumber"]}
                                    errorMessages={[
                                        "Please enter a valid id number",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{ marginBottom: "8px" }}>
                                    {t('FRONT_ID')||"Front side of ID"}
                                </Typography>
                                {idFrontImg.length < 1 && (
                                    <div className="file-input">
                                        <input
                                            type="file"
                                            id="idFrontImg"
                                            className="file"
                                            name="idFrontImg"
                                            onChange={handleFrontImageChange}
                                        />
                                        <label htmlFor="idFrontImg">
                                            <AddCircleOutlineIcon
                                                style={{
                                                    height: "100px",
                                                    width: "64px",
                                                    color:"#CCCCE5"
                                                }}
                                            />
                                        </label>
                                    </div>
                                )}
                                <div className={classes.photoContainer}>
                                    <img
                                        src={idFrontImg}
                                        className={classes.bfPhoto}
                                    />
                                    {idFrontImg && (
                                        <DeleteOutlineIcon
                                            className={classes.photoIcon}
                                            onClick={removeFrontPhoto}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{ marginBottom: "8px" }}>
                                    {t('BACK_ID')||"Back side of ID"}
                                </Typography>
                                {idBackImg.length < 1 && (
                                    <div className="file-input">
                                        <input
                                            type="file"
                                            id="idBackImg"
                                            className="file"
                                            name="idBackImg"
                                            onChange={handleBackImageChange}
                                        />
                                        <label htmlFor="idBackImg">
                                            <AddCircleOutlineIcon
                                                style={{
                                                    height: "100px",
                                                    width: "64px",
                                                    color:"#CCCCE5"
                                                }}
                                            />
                                        </label>
                                    </div>
                                )}
                                <div className={classes.photoContainer}>
                                    <img
                                        src={idBackImg}
                                        className={classes.bfPhoto}
                                    />
                                    {idBackImg && (
                                        <DeleteOutlineIcon
                                            className={classes.photoIcon}
                                            onClick={removeBackPhoto}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('CURRENT_ADDRESS')||"Current Address"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle
                        classes={classes}
                        text={t('CURRENT_ADDRESS')||"Current Address & Proof"}
                    /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('RESIDENTIAL_STATUS')||"Residential status"}</InputLabel>
                                    <Select
                                        name="residentalStatus"
                                        value={residentalStatus}
                                        onChange={changeHandler}
                                        required
                                    >
                                        {map(
                                            RESIDENTIAL_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.label}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('YEARS_AT_CURRENT_ADDRESS')||"Years at current address"}
                                    </InputLabel>
                                    <Select
                                        name="yearsLiving"
                                        value={yearsLiving}
                                        onChange={changeHandler}
                                        required
                                    >
                                        {map(
                                            YEARS_CURRENT_ADDRESS_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.label}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="country"
                                    label={t('COUNTRY')||"Country"}
                                    value={country}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextValidator
                                    name="postalCode"
                                    label={t('POSTAL_CODE')||"Postal code"}
                                    type="number"
                                    value={postalCode}
                                    onChange={changePostalCode}
                                    inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                    fullWidth
                                    validators={[
                                        "isValidPostalCode",
                                        "required",
                                    ]}
                                    errorMessages={[
                                        "Please enter a valid postal code",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('DISTRICT_PROVINCE')||"District / Province"}</InputLabel>
                                    <Select
                                        name="province"
                                        value={province}
                                        onChange={changeHandler}
                                        required
                                    >
                                        {map(districtOptions, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('CITY_TOWN')||"City / Town"}</InputLabel>
                                    <Select
                                        name="city"
                                        value={city}
                                        onChange={changeHandler}
                                        required
                                    >
                                        {map(cityOptions, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextValidator
                                    name="address"
                                    label={t('ADDRESS')||"Address"}
                                    value={address}
                                    onChange={changeHandler}
                                    fullWidth
                                    validators={["isValidAddress", "required"]}
                                    errorMessages={[
                                        "Please enter a valid address",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('DOCUMENT_TYPE')||"Document type"}</InputLabel>
                                    <Select
                                        name="addressProofTypeId"
                                        value={addressProofTypeId}
                                        onChange={changeHandler}
                                        //required
                                    >
                                        {map(
                                            ADDRESS_PROOF_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                {addressProof.length < 1 && (
                                    <div className="file-input-inline">
                                        <input
                                            type="file"
                                            id="addressProof"
                                            className="file"
                                            name="addressProof"
                                            onChange={
                                                handleAddressProofDocumentChange
                                            }
                                        />
                                        <label
                                            htmlFor="addressProof"
                                            className={classes.inlineUpload}
                                        >
                                            <AddPhotoAlternateIcon style={{color:"#5F56A6"}} />
                                            <Typography
                                                style={{
                                                    marginLeft: "8px",
                                                    color: "#5F56A6"
                                                }}
                                            >
                                                {t('TAP_TO_UPLOAD_DOCUMENT')||"Tap to upload document"}
                                            </Typography>
                                        </label>
                                    </div>
                                )}

                                {addressDocShow && (
                                    <div className={classes.docContainer}>
                                        <a href={addressDocName} target="_blank">View Document</a>
                                        <DeleteOutlineIcon
                                            onClick={removeAddressFile}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('PERMANENT_ADDRESS')||"Permanent Address"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('PERMANENT_ADDRESS')||"Permanent Address"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            {/* <Grid item xs={12} sm={12}>
                                <div className={classes.fileContainer}>
                                    <Typography>
                                        Same as current address?
                                    </Typography>
                                    <div>
                                        <DarkerFormControlLabel
                                            value="yes"
                                            control={
                                                <Radio
                                                    value="Yes"
                                                    name="radio-button-demo"
                                                    inputProps={{
                                                        "aria-label": "Yes",
                                                    }}
                                                    disabled
                                                />
                                            }
                                            label="Yes"
                                        />
                                        <DarkerFormControlLabel
                                            value="no"
                                            control={
                                                <Radio
                                                    value="Yes"
                                                    name="radio-button-demo"
                                                    inputProps={{
                                                        "aria-label": "Yes",
                                                    }}
                                                    disabled
                                                />
                                            }
                                            label="No"
                                            labelStyle={{ color: "black" }}
                                        />
                                    </div>
                                </div>
                            </Grid> */}

                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="permanentCountry"
                                        label={t('COUNTRY')||"Country"}
                                        value={permanentCountry}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidator
                                        name="permanentPostalCode"
                                        label={t('POSTAL_CODE')||"Postal code"}
                                        type="number"
                                        value={permanentPostalCode}
                                        onChange={changePermanentPostalCode}
                                        inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                        fullWidth
                                        validators={[
                                            "isValidPostalCode",
                                            "required",
                                        ]}
                                        errorMessages={[
                                            "Please enter a valid postal code",
                                            "this field is required",
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>
                                            {t('DISTRICT_PROVINCE')||"District / Province"}
                                        </InputLabel>
                                        <Select
                                            name="permanentProvince"
                                            value={permanentProvince}
                                            onChange={changeHandler}
                                            //required
                                        >
                                            {map(
                                                districtOptions,
                                                (item, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>{t('CITY_TOWN')||"City / Town"}</InputLabel>
                                        <Select
                                            name="permanentCity"
                                            value={permanentCity}
                                            onChange={changeHandler}
                                            required
                                        >
                                            {map(cityOptions, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextValidator
                                        name="permanentAddress"
                                        label={t('ADDRESS')||"Address"}
                                        value={permanentAddress}
                                        onChange={changeHandler}
                                        fullWidth
                                        validators={[
                                            "isValidAddress",
                                            "required",
                                        ]}
                                        errorMessages={[
                                            "Please enter a valid address",
                                            "this field is required",
                                        ]}
                                    />
                                </Grid>
                            </>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('EMPLOYMENT_FINANCE')||"Employment & Finance"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle
                        classes={classes}
                        text={t('EMPLOYMENT_FINANCE')||"Employment & Finance"}
                    /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('EMPLOYMENT_TYPE')||"Employment type"}</InputLabel>
                                    <Select
                                        name="employmentTypeId"
                                        value={employmentTypeId}
                                        onChange={changeEmploymentType}
                                        //required
                                    >
                                        {map(
                                            employmentTypeList,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.id}
                                                >
                                                    {item.employmentType}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('INDUSTRY_BUSINESS_TYPE')||"Industry / Business type"}
                                    </InputLabel>
                                    <Select
                                        name="industryId"
                                        value={industryId}
                                        onChange={changeHandler}
                                        //required
                                    >
                                        {map(industryTypeList, (item, key) => (
                                            <MenuItem key={key} value={item.id}>
                                                {item.industry}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('CURRENT_WORKING_YEARS')||"Working years in current company"}
                                    </InputLabel>
                                    <Select
                                        name="workingYears"
                                        value={workingYears}
                                        onChange={changeHandler}
                                        //required={workingYearsRequired}
                                        disabled={workYearsDisabled}
                                    >
                                        {map(
                                            YEARS_CURRENT_WORK_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('TOTAL_WORKING_YEARS')||"Total working years"}</InputLabel>
                                    <Select
                                        name="totalWorkingYears"
                                        value={totalWorkingYears}
                                        onChange={changeHandler}
                                        //required={totalWorkingYearsRequired}
                                        disabled={totalWorkYearsDisabled}
                                    >
                                        {map(
                                            YEARS_TOTAL_WORK_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('EXISTING_LOANS')||"Number of existing loans"}
                                    </InputLabel>
                                    <Select
                                        name="existingLoan"
                                        value={existingLoan}
                                        onChange={changeHandler}
                                        //required
                                    >
                                        {map(
                                            NO_OF_LOANS_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.label}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="monthlyLoanPayment"
                                    label={t('MONTHLY_REPAYMENT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                    value={monthlyLoanPayment}
                                    onChange={changeHandler}
                                    fullWidth
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('INCOME_PROOF')||"income proof"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle classes={classes} text={t('INCOME_PROOF')||"Income Proof"} /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('DOCUMENT_TYPE')||"Document type"}</InputLabel>
                                    <Select
                                        name="incomeProofTypeId"
                                        value={incomeProofTypeId}
                                        onChange={changeHandler}
                                        //required
                                    >
                                        {map(
                                            INCOME_PROOF_OPTIONS,
                                            (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    name="monthlyIncome"
                                    label={t('MONTHLY_INCOME')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                    value={monthlyIncome}
                                    onChange={changeHandler}
                                    fullWidth
                                    type="number"
                                    validators={[
                                        "isValidMonthlyIncome",
                                        "required",
                                    ]}
                                    errorMessages={[
                                        "Please enter a valid monthly income",
                                        "this field is required",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                {incomeProof.length < 1 && (
                                    <div className="file-input-inline">
                                        <input
                                            type="file"
                                            id="incomeProof"
                                            className="file"
                                            name="incomeProof"
                                            onChange={handleIncomeProofChange}
                                        />
                                        <label
                                            htmlFor="incomeProof"
                                            className={classes.inlineUpload}
                                        >
                                            <AddPhotoAlternateIcon style={{color:"#5F56A6"}} />
                                            <Typography
                                                style={{
                                                    marginLeft: "8px",
                                                    color: "#5F56A6"
                                                }}
                                            >
                                                {t('TAP_TO_UPLOAD_DOCUMENT')||"Tap to upload document"}
                                            </Typography>
                                        </label>
                                    </div>
                                )}
                                {incomeDocShow && (
                                    <div className={classes.docContainer}>
                                        {/* <Link
                                            style={{
                                                marginTop: "10px",
                                            }}
                                            underline="always"
                                        >
                                            {incomeDocName}
                                        </Link> */}
                                        <a href={incomeDocName} target="_blank">View Document</a>
                                        <DeleteOutlineIcon
                                            onClick={removeInComeFile}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Typography
                className={classes.titleLabel}>
                    {t('EMPLOYER_COMPANY_INFO')||"Employer & Company Info"}
                </Typography>
                <Paper className={classes.paper}>
                    {/* <PaperTitle
                        classes={classes}
                        text={t('EMPLOYER_COMPANY_INFO')||"Employer & Company Info (optional)"}
                    /> */}
                    <div className={classes.paperContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="employerName"
                                    label={t('EMPLOYER_COMPANY_NAME')||"Employer &amp; Company name"}
                                    value={employerName}
                                    onChange={changeHandler}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>
                                        {t('YEARS_OF_BUSINESS')||"Total years of business"}
                                    </InputLabel>
                                    <Select
                                        name="totalYearsOfBusiness"
                                        value={totalYearsOfBusiness}
                                        onChange={changeHandler}
                                    >
                                        {map(YEARS_OPTIONS, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="businessCountry"
                                    label={t('COUNTRY')||"Country"}
                                    value={businessCountry}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    name="businessPostalCode"
                                    label={t('POSTAL_CODE')||"Postal code"}
                                    type="number"
                                    value={businessPostalCode}
                                    onChange={changeBusinessPostalCode}
                                    inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                    fullWidth
                                    // validators={[
                                    //     "isValidPostalCode"
                                    // ]}
                                    // errorMessages={[
                                    //     "Please enter a valid postal code"
                                    // ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('DISTRICT_PROVINCE')||"District / Province"}</InputLabel>
                                    <Select
                                        name="businessProvince"
                                        value={businessProvince}
                                        onChange={changeHandler}
                                    >
                                        {map(districtOptions, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('CITY_TOWN')||"City / Town"}</InputLabel>
                                    <Select
                                        name="businessCity"
                                        value={businessCity}
                                        onChange={changeHandler}
                                    >
                                        {map(cityOptions, (item, key) => (
                                            <MenuItem
                                                key={key}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    name="businessAddress"
                                    label={t('ADDRESS')||"Address"}
                                    value={businessAddress}
                                    onChange={changeHandler}
                                    fullWidth
                                    // validators={["isValidAddress"]}
                                    // errorMessages={[
                                    //     "Please enter a valid address"
                                    // ]}
                                />
                            </Grid>
                        </Grid>
                        <SelfAlertDialog
                            open={dialogOpen}
                            closeDialog={closeDialog}
                            removeFile={removeFile}
                            fileIndex={fileIndex}
                            t={t}
                        />
                    </div>
                </Paper>
                <ApplicationAdminstration
                    classes={classes}
                    handleSubmit={handleSubmit}
                    handleSave={handleSave}
                    handleInquiryPurchase={handleInquiryPurchase}
                    status={status}
                    t={t}
                />
            </ValidatorForm>
        </Fragment>
    );
};

const ApplicationAdminstration = (props) => {
    const { classes, handleSubmit, handleSave, status, handleInquiryPurchase,t } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.rm64}
                >
                    <Box p={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            className={classes.staffActionBtn}
                            onClick={handleSave}
                        >
                            {t('SAVE')||"Save &amp; Exit"}
                        </Button>
                    </Box>
                    {status==="Pending"? (<Box p={1}>
                        <Button
                            style={{backgroundColor:"#5F56A6"}}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </Box>):(<Box p={1}>
                    <Button
                    className={classes.staffActionBtn}
                    variant="contained"
                    color="primary"
                    onClick={handleInquiryPurchase}
                >
                    {t('CONTINUE_PURCHASE')||"Continue purchase"}
                </Button></Box>)}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

function SelfAlertDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const { removeFile, fileIndex ,t} = props;

    const handleClose = () => {
        props.closeDialog();
    };
    const handleRemove = () => {
        props.removeFile(props.fileIndex);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete file"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('DELETE_MESSAGE')||"Do You want to delete this file now?"}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        {t('NO')||"No"}
                    </Button>
                    <Button
                    style={{
                        backgroundColor:"#5F56A6"
                    }}
                        variant="contained"
                        onClick={handleRemove}
                        color="primary"
                        autoFocus
                    >
                        {t('YES')||"Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

const mapStateToProps = (state) => ({
    applicant: get(state.customer.application, "customer") || {},
    employmentTypeList: state.customer.employmentTypeList || [],
    industryTypeList: state.customer.industryTypeList || [],
    cityList: get(state.common.cityList, "data") || [],
    applicationLoan: get(state.customer.application, "loanDetails") || {},
    additionalInfo: get(state.customer.additionalInfo,'data') ||{},
});

const mapDispatchToProps = {
    getEmploymentTypes,
    getIndustryType,
    getApplicationById,
    getCities,
    calculateCde
};

export default withStyles(inputStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(ApplicantUpdateComponent))
);
