import { useState } from "react";
import { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Select from "@material-ui/core/Select";
import map from "lodash/map";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Grid, CssBaseline, Paper, FormControl, Typography } from "@material-ui/core";
import {
    LOAN_MONTH_OPTIONS,
    BANK_OPTIONS,
    REPAYMENT_METHOD_OPTIONS,
    EMI_OPTIONS,
    APP_CONFIGS
} from "../../constants/appConstants";
import LoanUpdateComponent from "../application_update/loan_update/LoanUpdateComponent";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black", // (default alpha is 0.38)
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#6d6d6d",
        },
    },
})(TextField);

const ApplicationDetailLoan = (props) => {
    const { classes, applicationLoan, status, confirmSubmit, handleChangeTab, updateApplicationLoan, totalCost1, createLoan, 
        getApplicationById, createFees,updateFees, fees, discount, getDiscount, isLoadingCalculateCde, t } = props;

    const {
        downPayment,
        loanYears,
        flatRate,
        reducingRate,
        loanAmount,
        totalInterest,
        monthlyPayment,
        processingFee,
        paymentMethod,
        bankName,
        otherMethod
    } = applicationLoan;
    const flatValue = flatRate * 100;
    const reducingValue = reducingRate * 100;
    const isFlat = flatValue > 0 ? true : false;
    const [allValues, setAllValues] = useState({
        downPayment: applicationLoan.downPayment,
        bankName: applicationLoan.bankName,
        paymentMethod: applicationLoan.paymentMethod,
        processingFee: applicationLoan.processingFee,
        loanAmount: applicationLoan.loanAmount,
        loanYears: applicationLoan.numberOfPayments,
        monthlyPayment: applicationLoan.monthlyPayment,
        totalInterest: applicationLoan.totalInterest,
        totalCost:applicationLoan.totalCost,
        otherMethod:applicationLoan.otherMethod
    });

    const [feesValue,setFeesValue] = useState({
        earlySettlementFees: fees.processingFees||0.0,
        additionalCharges: fees.additionalCharges||0.0,
        documentationFees: fees.documentationFees || 0.0,
        stampDutyCharges: fees.stampDutyCharges||0.0,
        loanRebookCharges: fees.loanRebookCharges||0.0,
        chequeBounceCharges: fees.chequeBounceCharges||0.0,
        chequeSwapCharges: fees.chequeSwapCharges||0.0,
        exchangeCharges: fees.exchangeCharges||0.0
    })

    const [visibleFees, setVisibleFees] = useState({
        processingFeeVisible: true,
        earlySettlementFeeVisible: fees.earlySettlementFees > 0.0?true:false,
        additionalChargesVisible: fees.additionalCharges > 0.0?true:false,
        documentationFeesVisible: true,
        stampDutyChargesVisible: fees.stampDutyCharges > 0.0?true:false,
        loanRebookChargesVisible: fees.loanRebookCharges > 0.0?true:false,
        chequeBounceChargesVisible: fees.chequeBounceCharges > 0.0?true:false,
        chequeSwapChargesVisible: fees.chequeSwapCharges > 0.0?true:false,
        exchangeChargesVisible: fees.exchangeCharges > 0.0?true:false
    })

    const {
        processingFeeVisible,
        earlySettlementFeeVisible,
        additionalChargesVisible,
        documentationFeesVisible,
        stampDutyChargesVisible,
        loanRebookChargesVisible,
        chequeBounceChargesVisible,
        chequeSwapChargesVisible,
        exchangeChargesVisible
    } = visibleFees;

    const {
        earlySettlementFees,
        additionalCharges,
        documentationFees,
        stampDutyCharges,
        loanRebookCharges,
        chequeBounceCharges,
        chequeSwapCharges,
        exchangeCharges
    } = feesValue;

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };
    return (
        <>
            {(status == "Pending" || status=="Inquiry") && (
                <LoanUpdateComponent
                    handleChangeTab={handleChangeTab}
                    confirmSubmit={confirmSubmit}
                    applicationLoan={applicationLoan}
                    updateApplicationLoan={updateApplicationLoan}
                    totalCost1={totalCost1}
                    createLoan={createLoan}
                    getApplicationById={getApplicationById}
                    createFees={createFees}
                    updateFees={updateFees}
                    discount={discount}
                    fees={fees}
                    getDiscount={getDiscount}
                    isLoadingCalculateCde={isLoadingCalculateCde}
                    t={t}
                />
            )}
            {(status !== "Pending" && status !== "Inquiry") && (
                <main className={classes.layout}>
                <Typography
                   className={classes.titleLabel}>
                   {t('REPAYMENT')||"Repayment"}
            </Typography>
                <Fragment>
                        <CssBaseline />
                        <Paper className={classes.paper}>
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <DarkerDisabledTextField
                                                name="downPayment"
                                                label={t('DOWNPAYMENT') + " "+`(${APP_CONFIGS.CURRENCY})`}
                                                value={downPayment}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <DarkerDisabledTextField
                                                name="loanYears"
                                                label={t('TENURE')||"Tenure(months)"}
                                                value={loanYears}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <DarkerDisabledTextField
                                                name="rateType"
                                                label={t('FUND_UTILIZATION_TYPE')||"Fund utilization type"}
                                                value={isFlat ? "Flat" : "Reducing"}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <DarkerDisabledTextField
                                                name="loanYears"
                                                label={t('FUND_UTILIZATION_RATE')||"Reducing fund utilization rate (%)"}
                                                value={
                                                    isFlat
                                                        ? flatValue
                                                        : reducingValue
                                                }
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_LOAN_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.boldLabel}
                                        >
                                            {Number(loanAmount).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_FUND_UTILIZATION_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.boldLabel}
                                        >
                                            {Number(totalInterest).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('MONTHLY_REPAYMENT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.boldLabel}
                                        >
                                            {Number(monthlyPayment).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_REPAYMENT_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.boldLabel}
                                        >
                                            {Number(loanAmount + totalInterest).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                    </div>
                                </Paper>
                                <Typography
                                        className={classes.titleLabel}>
                                            {t('REPAYMENT_METHOD')||"Repayment method"}
                                    </Typography>
                                    <Paper className={classes.paper}>
                                        {/* <PaperTitle
                                            classes={classes}
                                            text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                        /> */}
                                        <div className={classes.paperContent}>
                                            <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                            <FormControl
                                                    className={classes.formControl}
                                                >
                                                <Select
                                                    name="paymentMethod"
                                                    value={paymentMethod}
                                                    onChange={changeHandler}
                                                    required
                                                    disabled
                                                >
                                                    {map(
                                                        REPAYMENT_METHOD_OPTIONS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={item.value}
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                 </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <FormControl
                                            className={classes.formControl}
                                        >
                                            <Select
                                            name="bankName"
                                            value={bankName}
                                            onChange={changeHandler}
                                            required
                                            disabled
                                        >
                                            {map(BANK_OPTIONS, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    {paymentMethod==9 && <Grid item xs={12} sm={6}>
                                        <DarkerDisabledTextField
                                            name="otherMethod"
                                            value={otherMethod}
                                            label={t('Specify Other')}
                                            fullWidth
                                        />
                                    </Grid>}
                                            </Grid>
                                        </div>
                                    </Paper>
                                    <Typography
                                        className={classes.titleLabel}>
                                            {t('PROCESSING_DETAILS')||"Processing Details"}
                                    </Typography>
                                    <Paper className={classes.paper}>
                                        {/* <PaperTitle
                                            classes={classes}
                                            text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                        /> */}
                                        <div className={classes.paperContent}>
                                            <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <DarkerDisabledTextField
                                                name="processingFee"
                                                label={t('PROCESSING_FEE')}
                                                value={processingFee}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    {earlySettlementFeeVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="earlySettlementFees"
                                        label={"Early Settlement Fee"}
                                        value={earlySettlementFees}
                                        disabled 
                                    />
                                </Grid>}
                                {additionalChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="additionalCharges"
                                        label={"Additional Charges"}
                                        value={additionalCharges}
                                        disabled
                                    />
                                    </Grid>}
                                   {documentationFeesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="documentationFees"
                                        label={"Documentation Fees"}
                                        value={documentationFees}
                                        disabled
                                    />
                                    </Grid>}
                                  { stampDutyChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="stampDutyCharges"
                                        label={"Stamp Duty Charges"}
                                        value={stampDutyCharges}
                                        disabled
                                    />
                                    </Grid>}
                                   {loanRebookChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="loanRebookCharges"
                                        label={"Loan Rebook Charges"}
                                        value={loanRebookCharges}
                                        disabled
                                    />
                                    </Grid>}
                                    {chequeBounceChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="chequeBounceCharges"
                                        label={"Cheque Bounce Charges"}
                                        value={chequeBounceCharges}
                                        disabled
                                    />
                                    </Grid>}
                                    {chequeSwapChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="chequeSwapCharges"
                                        label={"Cheque Swap Charges"}
                                        value={chequeSwapCharges}
                                        disabled
                                    />
                                    </Grid>}
                                    {exchangeChargesVisible && <Grid item xs={12} sm={6}>
                                <DarkerDisabledTextField
                                        name="exchangeCharges"
                                        label={"Exchange Charges"}
                                        value={exchangeCharges}
                                        disabled
                                    />
                                    </Grid>}
                                </Grid>
                            </div>
                        </Paper>
                    </Fragment>
            </main>
            )}
        </>
    );
};
export default withStyles(inputStyles)(ApplicationDetailLoan);
