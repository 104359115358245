import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const AccessControl = (props: any) => {
    return React.cloneElement(props.children)
}

const mapStateToProps = (state: any) => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(withRouter(AccessControl))
