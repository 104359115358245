import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import EmiComponent from './EmiComponent'
import { calculateEmiRequest, getApplicationById, createLoan, calculateCde, updateApplicationStatus, getDiscount, createFees } from '../../store/actions/customer'

const EmiContainer = (props: any) => <EmiComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        isLoadingCalculateEmi: state.customer.isLoadingCalculateEmi,
        isLoadingCalculateCde: state.customer.isLoadingCalculateCde,
        isLoadingCreateLoan: state.customer.isLoadingCreateLoan,
        emiSummary: state.customer.summary,
        applicationDetail: state.customer.application,
        totalCost: state.customer.application ? state.customer.application.vehicle.price : 0,
        applicationVehicle: get(state.customer.application, 'vehicle') || {},
        loanInfo: state.customer.loanInfo,
        cdeInfo: get(state.customer.cdeInfo, 'data') || {},
        currentUser: get(state.auth, 'user'),
        discount: get(state.customer.discount,'data') ||{},
    }
}

const mapDispatchToProps = {
    calculateEmiRequest,
    getApplicationById,
    createLoan,
    calculateCde,
    updateApplicationStatus,
    getDiscount,
    createFees
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(EmiContainer))
)