export const transactionTypes = [
    "Cash",	
    "Cheque",	
    "Credit Card",
    "Debit Card",	
    "Bank - EFT",
    "Online Pay (Bkash)",	
    "Online Pay (Other)",	
    "Nagad (Collection Agent)",
    "Other"	
];

export const transactionMethodList = [
    "EMI Payment (Inward)",	
    "Other Fees (Inward)",
    "Payment adjustment (Inward)",
    "Payment adjustment (Outward)"
];

export const transactionRecords = [
    {
        transactionDate: new Date(),
        transactionAmount: 1000678.98777,
        transactionType: "EMI payment",
        transactionMethod: "Bank transfer",
    },
    {
        transactionDate: new Date(),
        transactionAmount: 19002118,
        transactionType: "EMI payment",
        transactionMethod: "Bank transfer",
    }
];

export const formatNumber = (num) => {
    return num.toLocaleString('en-US', { maximumFractionDigits: 2 });
}
