import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import ManagementDashboardComponent from './ManagementDashboardComponent'
import { getCustomers, getApplicationInfo, clearData, getCurrentMonthCustomerStats, getApplicationByMonthsStats } from '../../store/actions/customer'
import { getOutlets } from '../../store/actions/common'
import { isManager } from '../../utils/helper'

const ManagementDashboardContainer = (props: any) => <ManagementDashboardComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
        isManager: state.auth.user ? isManager(state.auth.user) : false,
        isLoadingGetCustomers: state.auth.isLoadingGetCustomers,
        customerCount: state.auth.list.length,
        applicationInfo: state.customer.applicationInfo || {},
        customerStats: state.customer.customerStats || {},
        monthlyStats: state.customer.monthlyStats,
        outletList: state.common.outletList || []
    }
}

const mapDispatchToProps = {
    getOutlets, getCustomers, getApplicationInfo, clearData, getCurrentMonthCustomerStats, getApplicationByMonthsStats
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ManagementDashboardContainer))
)
