const inputStyles = {
    appBar: {
        position: "relative",
        backgroundColor: '#212121'
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor:'#EFEFEF'
    },
    menuButton: {
        marginLeft: "auto"
    },
    appTitle: {
        color: '#fff',
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle:'normal',
        fontSize: '32px',
        marginBottom: '24px',
        fontWeight: '500',
        marginTop:'60px'
    },
    titleLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '32px',
        color: '#212121',
        padding: '0px 0px 16px 4px',
        borderBottom: '1px solid #DCDEDF'
    },
    stepLabel: {
        fontSize: '16px',
        marginTop: '16px'
    },
    paper: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '8px',
        marginBottom: '24px'
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    inlineUpload: {
        display: 'flex',
        color: '#212121',
        cursor: 'pointer'
    },
    button: {
        marginTop: '28px',
        // marginLeft: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    margin24: {
        marginRight: '24px',
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    mainContent: {
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto'
    },
    paperLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        padding: '16px 0px 8px 32px',
        borderBottom: '1px solid #DCDEDF'
    },
    paperContent: {
        padding: '32px'
    },
    actionBtn: {
        marginRight: '24px',
        textTransform: 'none',
        width: '250px'
    },
    test: {
        width: '40px !important',
        border: 'none',
        borderBottom: '1px solid #0486E2',
        marginRight: '10px'
    },
    photoContainer: {
        position: "relative",
        color:'#5F56A6'
      },
      selfPhotoImage: {
        width: "180px",
        margin: "0px auto",
        display: "block",
      },
      photoIcon: {
        position: "absolute",
        bottom: 0,
        right: 0,
        cursor: "pointer",
      },
      bfPhoto: {
        display: "block",
        width: "100%",
      },
      docContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "centre",
        alignItems: "center",
        display: "inline-flex",
      },
}

export default inputStyles
