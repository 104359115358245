import { useState, Fragment, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import map from "lodash/map";
import filter from "lodash/filter";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import OtpInput from "react-otp-input";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    RESIDENTIAL_OPTIONS,
    YEARS_CURRENT_ADDRESS_OPTIONS,
    COUNTRY_CODE,
    APP_CONFIGS,
    INTEREST_STATUS
} from "../../constants/appConstants";
import {
    validationForTextField,
    validationForVehicle,
    validationForNumber,
    validationForDate,
} from "../../utils/formValidation";
import { mapDistrictsOptions, mapCityOptions } from "../../utils/helper";
import LoaderComponent from "../connected_components/Loader";
import { useTranslation } from "react-i18next";

const CreateCustomer = (props) => {
    const {
        classes,
        history,
        createCustomer,
        createdUser,
        getCities,
        cityList,
        generateOtp,
        verifyOtp,
        otpVerified,
        isLoadingGenerateOtp,
        isLoadingVerifyOtp,
        isLoadingCreateCustomer,
        currentUser
    } = props;
    const [open, setOpen] = useState(false);
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [permanentPostalCode, setPermanentPostalCode] = useState("");
    const [isSame, setIsSame] = useState("yes");
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const { t } = useTranslation();
    const [allValues, setAllValues] = useState({
        salutation: "",
        name: "",
        gender: "",
        phone: "",
        existingVehicle: "",
        residentalStatus: "",
        yearsLiving: "",
        province: "",
        permanentProvince: "",
        city: "",
        address: "",
        country: APP_CONFIGS.COUNTRY,
        permanentCountry: APP_CONFIGS.COUNTRY,
        permanentCity: "",
        permanentAddress: "",
        interestStatus: ""
    });
    const [followUpDate,setFollowUpDate] = useState("");
    const [maxFollowUpDate,setMaxFollowUpDate] = useState("");
    const handleCloseDialog = () => setOpen(false);

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };
    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };


    const changePermanentPostalCode = (e) => {
        if (isSame === "no") {
            setPermanentPostalCode(e.target.value);
        }
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    permanentProvince: item[0].state,
                    permanentCity: item[0].city,
                });
            }
        }
    };
    const {
        salutation,
        name,
        gender,
        phone,
        existingVehicle,
        residentalStatus,
        yearsLiving,
        province,
        permanentProvince,
        city,
        address,
        country,
        permanentCountry,
        permanentCity,
        permanentAddress,
        interestStatus
    } = allValues;

    useEffect(() => {
        if (city.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if (item.length > 0) {
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    }, [city, province])

    useEffect(() => {
        if (permanentCity.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === permanentCity
            );
            if (item.length > 0) {
                setPermanentPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    permanentProvince: item[0].state
                })
            }
        }

    }, [permanentCity, permanentProvince])

    const handleSubmit = () => {
        const tel = COUNTRY_CODE + phone;
        setOpen(true);
        generateOtp({ userId: currentUser.id, phoneNumber: tel });
    };

    const handleCancel = () => {
        history.push(`/dashboard`);
    };

    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const isFirstRun = useRef(true);

    useEffect(() => {
        // prevent the useEffect from running in initial render
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (otpVerified == "true") {
            setOpen(false);
            requestCreateCustomer();
        }
    }, [otpVerified]);

    useEffect(() => {
        if (createdUser && createdUser.id) {
            history.push(`/client/${createdUser.id}/select-vehicle`);
        }
    }, [createdUser, history]);

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE);
        });

        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidExistingVehicle", (value) => {
            return validationForVehicle(value);
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
    });

    const handleOtpChange = (value) => {
        const tel = COUNTRY_CODE + phone;
        verifyOtp({ userId: currentUser.id, phoneNumber: tel, otp: value });
    };

    const handleSkipOtp = () => {
        setOpen(false);
        requestCreateCustomer();
    }

    const requestCreateCustomer = () => {
        const { outlet } = currentUser
        const { branchId } = outlet
        const data = {
            salutation: salutation,
            name: name,
            birthday: birthday,
            gender: gender,
            phone: phone,
            existingVehicle: existingVehicle,
            verifyOtp: verifiedOtp ? 1 : 0,
            branchId: branchId,
            interestStatus: interestStatus,
            followUpDate: followUpDate,
            address: {
                residentalStatus: residentalStatus,
                yearsLiving: yearsLiving,
                country: country,
                postalCode: postalCode,
                province: province,
                city: city,
                address: address,
            },
            permanentAddress: {
                country: permanentCountry,
                postalCode: permanentPostalCode,
                province: permanentProvince,
                city: permanentCity,
                address: permanentAddress,
            },
        };
        createCustomer(data);
    }

    const handleRadioChange = (event) => {
        setIsSame(event.target.value);
        if (event.target.value === "yes") {
            setAllValues({
                ...allValues,
                permanentCountry: "",
                permanentPostalCode: "",
                permanentProvince: "",
                permanentAddress: "",
                permanentCity: "",
            });
            setPermanentPostalCode("");
        }
    };
    const [maxDate, setMaxDate] = useState("");
    const [birthday, setBirthday] = useState("");

    useEffect(() => {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        let day = new Date().getDate();
        let date = new Date(year - 18, month, day);
        setMaxDate(date);
        setBirthday(date);
        setFollowUpDate(new Date(year,month,day+1));
        const tmp = new Date();
        setMaxFollowUpDate(tmp.setDate(tmp.getDate() + 1));
    }, []);

    const handleDateChange = (date) => {
        setBirthday(date);
    };

    const handleFollowUpDateChange = (date) => {
        setFollowUpDate(date);
    };

    const resendOtp = () => {
        const tel = COUNTRY_CODE + phone;
        generateOtp({ userId: currentUser.id, phoneNumber: tel });
    };

    return (
        <>
            <LoaderComponent
                isLoading={isLoadingCreateCustomer || isLoadingGenerateOtp || isLoadingVerifyOtp}
            />
            <main className={classes.layout}>
                <div className={classes.mainContent}>
                    <Typography
                        className={classes.title}
                        component="h3"
                        variant="h4"
                    >
                        {t('CREATE_CUSTOMER_PROFILE') || "Creating customer profile"}
                    </Typography>
                    <Typography
                        className={classes.titleLabel}

                    >
                        {t('BASIC_INFO') || "Basic Info"}
                    </Typography>
                    <Fragment>
                        <CssBaseline />
                        <ValidatorForm onSubmit={handleSubmit}>
                            <Paper className={classes.paper}>
                                {/* <PaperTitle
                                    classes={classes}
                                    text={t('BASIC_INFO') || "Basic Info"}
                                /> */}
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('SALUTATION') || "Salutation"}
                                                </InputLabel>
                                                <Select
                                                    id="salutationOptionInput"
                                                    name="salutation"
                                                    value={salutation}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        SALUTATION_OPTIONS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                name="name"
                                                value={name}
                                                label={t('FULL_NAME') || "Full name"}
                                                fullWidth
                                                onChange={changeHandler}
                                                validators={[
                                                    "isValidFullName",
                                                    "required",
                                                ]}
                                                errorMessages={[
                                                    "Please enter a valid full name",
                                                    "this field is required",
                                                ]}
                                            />
                                        </Grid>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label={t('DOB') || "Date of Birth"}
                                                    format="dd/MM/yyyy"
                                                    maxDate={maxDate}
                                                    name="birthday"
                                                    value={birthday}
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label":
                                                            "change date",
                                                    }}
                                                    
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                                style={{ marginTop: "16px" }}
                                            >
                                                <InputLabel InputLabelProps={{ required: false }}>{t('GENDER') || "Gender"}</InputLabel>
                                                <Select
                                                    id="genderOptionSelect"
                                                    name="gender"
                                                    value={gender}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        GENDER_OPTIONS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                name="phone"
                                                value={phone}
                                                label={t('MOBILE') || "Mobile number"}
                                                fullWidth
                                                onChange={changeHandler}
                                                type="number"
                                                validators={[
                                                    "isValidMobileNumber",
                                                    "required",
                                                ]}
                                                errorMessages={[
                                                    "Please enter a valid mobile number",
                                                    "this field is required",
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                id="state"
                                                name="existingVehicle"
                                                label={t('EXISTING_VEHICLE_OPTIONAL') || "Existing vehicle (optional)"}
                                                value={existingVehicle}
                                                onChange={changeHandler}
                                                fullWidth
                                                validators={[
                                                    "isValidExistingVehicle",
                                                ]}
                                                errorMessages={[
                                                    "Please enter a existing vehicle under 255 characters",
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <Typography className={classes.titleLabel} >
                                {t('CURRENT_ADDRESS') || "Current Address"}
                            </Typography>
                            <Paper className={classes.paper}>
                                {/* <PaperTitle
                                    classes={classes}
                                    text={t('CURRENT_ADDRESS')||"Current Address"}
                                /> */}
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('RESIDENTIAL_STATUS') || "Residential status"}
                                                </InputLabel>
                                                <Select
                                                    id="residentialStatusSelect"
                                                    name="residentalStatus"
                                                    value={residentalStatus}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        RESIDENTIAL_OPTIONS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('YEARS_AT_CURRENT_ADDRESS') || "Years at current address"}
                                                </InputLabel>
                                                <Select
                                                    id="yearsLivingSelect"
                                                    name="yearsLiving"
                                                    value={yearsLiving}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        YEARS_CURRENT_ADDRESS_OPTIONS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="country"
                                                label={t('COUNTRY') || "Country"}
                                                value={country}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextValidator
                                                name="postalCode"
                                                label={t('POSTAL_CODE') || "Postal code"}
                                                type="number"
                                                value={postalCode}
                                                onChange={changePostalCode}
                                                inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                                fullWidth
                                                validators={[
                                                    "isValidPostalCode",
                                                    "required",
                                                ]}
                                                errorMessages={[
                                                    "Please enter a valid postal code",
                                                    "this field is required",
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('DISTRICT_PROVINCE') || "District / Province"}
                                                </InputLabel>
                                                <Select
                                                    id="provinceSelect"
                                                    name="province"
                                                    value={province}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        districtOptions,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('CITY_TOWN') || "City / Town"}
                                                </InputLabel>
                                                <Select
                                                    id="citySelect"
                                                    name="city"
                                                    value={city}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        cityOptions,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextValidator
                                                name="address"
                                                label={t('ADDRESS') || "Address"}
                                                value={address}
                                                onChange={changeHandler}
                                                fullWidth
                                                validators={[
                                                    "isValidAddress",
                                                    "required",
                                                ]}
                                                errorMessages={[
                                                    "Please enter a valid address",
                                                    "this field is required",
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <Typography className={classes.titleLabel} >
                                {t('PERMANENT_ADDRESS') || "Current Address"}
                            </Typography>
                            <Paper className={classes.paper}>
                                {/* <PaperTitle
                                    classes={classes}
                                    text={t('PERMANENT_ADDRESS') || "Permanent Address"}
                                /> */}
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={12}>
                                            <div
                                                className={
                                                    classes.radioContainer
                                                }
                                            >
                                                <Typography>
                                                    {t('SAME_AS_CURRENT_ADDRESS') || "Same as current address?"}
                                                </Typography>

                                                <RadioGroup
                                                    style={{
                                                        marginLeft: "20px",
                                                    }}
                                                    name="isSame"
                                                    value={isSame}
                                                    onChange={handleRadioChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="yes"
                                                        control={
                                                            <Radio
                                                                id="yesBtn"
                                                                value="yes"
                                                                name="radio-button-demo"
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Yes",
                                                                }}
                                                                style={{
                                                                    color: '#5F56A6',
                                                                    '&.Mui-checked': {
                                                                        color: '#5F56A6',
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={t('YES') || "Yes"}
                                                    />
                                                    <FormControlLabel
                                                        value="no"
                                                        control={
                                                            <Radio
                                                                id="noBtn"
                                                                value="no"
                                                                name="radio-button-demo"
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Yes",
                                                                }}
                                                                style={{
                                                                    color: '#5F56A6',
                                                                    '&.Mui-checked': {
                                                                        color: '#5F56A6',
                                                                    },
                                                                }}

                                                            />
                                                        }
                                                        label={t('NO') || "No"}
                                                    />
                                                </RadioGroup>
                                            </div>
                                        </Grid>
                                        {isSame === "no" && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="permanentCountry"
                                                    label={t('COUNTRY') || "Country"}
                                                    value={permanentCountry}
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                        )}
                                        {isSame === "no" && (
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="permanentPostalCode"
                                                    label={t('POSTAL_CODE') || "Postal code"}
                                                    type="number"
                                                    value={permanentPostalCode}
                                                    onChange={
                                                        changePermanentPostalCode
                                                    }
                                                    inputProps={{
                                                        maxLength: APP_CONFIGS.VALID_POSTAL_CODE,
                                                    }}
                                                    fullWidth
                                                    validators={[
                                                        "isValidPostalCode",
                                                        "required",
                                                    ]}
                                                    errorMessages={[
                                                        "Please enter a valid postal code",
                                                        "this field is required",
                                                    ]}
                                                />
                                            </Grid>
                                        )}
                                        {isSame === "no" && (
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('DISTRICT_PROVINCE') || "District / Province"}
                                                    </InputLabel>
                                                    <Select
                                                        id="permanentProvinceSelect"
                                                        name="permanentProvince"
                                                        value={
                                                            permanentProvince
                                                        }
                                                        onChange={changeHandler}
                                                        required
                                                    >
                                                        {map(
                                                            districtOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {isSame === "no" && (
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('CITY_TOWN') || "City / Town"}
                                                    </InputLabel>
                                                    <Select
                                                        id="permanentCitySelect"
                                                        name="permanentCity"
                                                        value={permanentCity}
                                                        onChange={changeHandler}
                                                        required
                                                    >
                                                        {map(
                                                            cityOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {isSame === "no" && (
                                            <Grid item xs={12} sm={12}>
                                                <TextValidator
                                                    name="permanentAddress"
                                                    label={t('ADDRESS') || "Address"}
                                                    value={permanentAddress}
                                                    onChange={changeHandler}
                                                    fullWidth
                                                    validators={[
                                                        "isValidAddress",
                                                        "required",
                                                    ]}
                                                    errorMessages={[
                                                        "Please enter a valid address",
                                                        "this field is required",
                                                    ]}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </Paper>
                            <Typography className={classes.titleLabel} >
                                {t("Customer Interest Optional")}
                            </Typography>
                            <Paper className={classes.paper}>
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                                style={{ marginTop: "16px" }}
                                            >
                                                <InputLabel>
                                                    {t("Interest Level")}
                                                </InputLabel>
                                                <Select
                                                    id="interestStatusSelect"
                                                    name="interestStatus"
                                                    value={interestStatus}
                                                    onChange={changeHandler}
                                                    required
                                                >
                                                    {map(
                                                        INTEREST_STATUS,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.label
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label={t("Follow-up Date")}
                                                    format="dd/MM/yyyy"
                                                    minDate={maxFollowUpDate}
                                                    name="followUpDate"
                                                    value={followUpDate}
                                                    onChange={handleFollowUpDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label":
                                                            "change date",
                                                    }}
                                                    
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </Paper>
                            <div className={classes.buttonWrapper}>
                                <Button
                                    id="cancelBtn"
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    className={classes.actionBtn}
                                    onClick={handleCancel}
                                >
                                    {t('CANCEL') || "Cancel"}
                                </Button>
                                <Button
                                    id="submitBtn"
                                    style={{
                                        backgroundColor: "#5F56A6"
                                    }}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    className={classes.actionBtn}
                                    type="submit"
                                >
                                    {t('DONE') || "Done"}
                                </Button>
                            </div>
                        </ValidatorForm>
                    </Fragment>
                    <CustomizedDialogs
                        classes={classes}
                        open={open}
                        phone={phone}
                        onClose={handleCloseDialog}
                        checkOtp={handleOtpChange}
                        resendOtp={resendOtp}
                        handleSkipOtp={handleSkipOtp}
                        t={t}
                    />
                </div>
            </main>
        </>
    );
};

const CustomizedDialogs = (props) => {
    const { classes, open, onClose, checkOtp, phone, resendOtp, handleSkipOtp, t } = props;

    const [otp, setOtp] = useState("");
    const [counter, setCounter] = useState(120);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (value) => {
        setOtp(value);
    };

    useEffect(() => {
        setCounter(120);
    }, [open])

    // useEffect(() => {
    //     if (seconds > 0 && open) {
    //       setTimeout(() => setSeconds(seconds - 1), 1000);
    //     }
    //   });

    useEffect(() => {
        if (otp.length === 6) {
            checkOtp(otp);
        }
    }, [otp]);


    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleResendOtp = () => {
        setCounter(120)
        resendOtp()
    }
    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    className={classes.dialogTitle}
                    onClose={handleClose}
                >
                    {t('OTP_VERIFICATION') || "OTP Verification"}
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Typography>
                        {t('ENTER_OTP') || "Enter the OTP code sent to the applicant’s mobile number"} {" "}
                        {COUNTRY_CODE} {phone}.
                    </Typography>
                    <div className="otp-wrapper">
                        <OtpInput
                            className="otp-input"
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            separator={<span></span>}
                        />
                    </div>
                    {counter > 0 && (
                        <Typography align={"center"}>
                            {t('EXPIRY') || "The code will be expired in"}{" "}
                            <span style={{ color: "#0486E2" }}>{counter}</span>{" "}
                            {t('SECONDS') || "seconds."}
                        </Typography>
                    )}
                    {counter < 1 && (
                        <Typography
                            className="pointer"
                            align={"center"}
                            onClick={handleResendOtp}
                        >
                            {t('RESEND_OTP') || "Resend OTP"}
                        </Typography>
                    )}
                    <Typography
                        id="skipOtp"
                        className='c-red pt-10 pointer'
                        align={"center"}
                        onClick={handleSkipOtp}
                    >
                        {t('VERIFY_LATER') || "Verify mobile number later"}
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const DialogTitle = withStyles(inputStyles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({}))(MuiDialogContent);

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(CreateCustomer);
