import { Fragment, useState, useEffect, useRef } from "react";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import MenuIcon from "@material-ui/icons/Menu";

const BranchFilter = (props) => {
    const { outletList, filterByBranch } = props
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    const handleMenuItemClick = (option) => {
        filterByBranch(option)
        setOpen(false)
    };

    return (
        <Stack direction="row" spacing={2}>
            <div
                className="flex item-center pointer"
                ref={anchorRef}
                onClick={handleToggle}
            >
                <Typography variant="overline">All branches</Typography>
                <KeyboardArrowDownSharpIcon color="inherit">
                    <MenuIcon />
                </KeyboardArrowDownSharpIcon>
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {outletList.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            onClick={() =>
                                                handleMenuItemClick(option)
                                            }
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Stack>
    );
};

export default BranchFilter