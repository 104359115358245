import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import CustomerReference from './CustomerReference'
import { getCities } from '../../store/actions/common'
import { createProfileReference } from '../../store/actions/customer'
import get from 'lodash/get'

const CustomerReferenceContainer = (props: any) => <CustomerReference {...props} />

const mapStateToProps = (state: any) => {
    return {
        isLoadingCreateReference: state.customer.isLoadingCreateReference,
        cityList: get(state.common.cityList, 'data'),
        referenceInfo: state.customer.referenceInfo,
        currentUser: get(state.auth, 'user')
    }
}

const mapDispatchToProps = {
    getCities,
    createProfileReference
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerReferenceContainer))
)