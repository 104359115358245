import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import { Paper, Button, Typography, FormControl, Select, InputLabel, MenuItem, Grid, CssBaseline } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/Person";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import filter from "lodash/filter";
import map from "lodash/map";
import {
    ID_TYPE_OPTIONS,
    INCOME_PROOF_OPTIONS,
    ADDRESS_PROOF_OPTIONS,
    APP_CONFIGS
} from "../../constants/appConstants";
import {
    validationForTextField,
    validationForNumber,
} from "../../utils/formValidation";
import { mapDistrictsOptions, mapCityOptions } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
const SupportingDocument = (props) => {
    const {
        classes,
        history,
        documentInfo,
        createCustomerDocument,
        getCities,
        cityList,
    } = props;
    const { clientId, applicationId } = props.match.params;
    const [idFrontImgUrl, setidFrontImgUrl] = useState("");
    const [idBackImgUrl, setidBackImgUrl] = useState("");
    const [selfPhotoUrl, setSelfPhotoUrl] = useState("");
    const [incomeProofUrl, setIncomeProofUrl] = useState("");
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [addressProofDocumentUrl, setAddressProofDocumentUrl] = useState("");
    const [customerChecked, setCustomerChecked] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getCities();
    }, []);
    useEffect(() => {
        ValidatorForm.addValidationRule("isValidIdNumber", (value) => {
            return validationForNumber(value, 13);
        });
        ValidatorForm.addValidationRule("isValidMonthlyIncome", (value) => {
            return value > 0;
        });
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE);
        });
        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
    });
    const handleCheckedChange = (e) => {
        setCustomerChecked(e.target.checked);
    };

    const [imageValues, setImageValues] = useState({
        selfPhoto: "",
        idFrontImg: "",
        idBackImg: "",
        addressProof: "",
        incomeProof: "",
    });
    const handleSelfImageChange = (e) => {
        setSelfPhotoUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
    };
    const [dialogOpen, setDialogOpen] = useState(false);

    const [fileIndex, setFileIndex] = useState(0);
    const removeSelfPhoto = (e) => {
        setFileIndex(1);
        setDialogOpen(true);
    };
    const removeFrontPhoto = (e) => {
        setFileIndex(2);
        setDialogOpen(true);
    };
    const removeBackPhoto = (e) => {
        setFileIndex(3);
        setDialogOpen(true);
    };
    const removeInComeFile = (e) => {
        setFileIndex(4);
        setDialogOpen(true);
    };
    const removeAddressFile = (e) => {
        setFileIndex(5);
        setDialogOpen(true);
    };

    function closeDialog() {
        setDialogOpen(false);
    }
    const removeFile = (index) => {
        if (index === 1) {
            setSelfPhotoUrl("");
            setImageValues({ ...imageValues, selfPhoto: "" });
        } else if (index === 2) {
            setidFrontImgUrl("");
            setImageValues({ ...imageValues, idFrontImg: "" });
        } else if (index == 3) {
            setidBackImgUrl("");
            setImageValues({ ...imageValues, idBackImg: "" });
        } else if (index == 4) {
            setIncomeProofUrl("");
            setImageValues({ ...imageValues, incomeProof: "" });
            setIncomeDocShow(false);
        } else if (index == 5) {
            setAddressProofDocumentUrl("");
            setImageValues({ ...imageValues, addressProof: "" });
            setAddressDocShow(false);
        }
        setDialogOpen(false);
    };
    const handleFrontImageChange = (e) => {
        setidFrontImgUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
    };
    const handleBackImageChange = (e) => {
        setidBackImgUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
    };
    const [incomeDocShow, setIncomeDocShow] = useState(false);
    const [incomeDocName, setIncomeDocName] = useState("");
    const handleIncomeProofChange = (e) => {
        setIncomeDocName(e.target.files[0].name);
        setIncomeDocShow(true);
        setIncomeProofUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
    };

    const [addressDocShow, setAddressDocShow] = useState(false);
    const [addressDocName, setAddressDocName] = useState("");
    const handleAddressProofDocumentChange = (e) => {
        setAddressDocName(e.target.files[0].name);
        setAddressDocShow(true);
        setAddressProofDocumentUrl(e.target.files[0]);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
    };

    const { selfPhoto, idFrontImg, idBackImg, addressProof, incomeProof } =
        imageValues;

    const [allValues, setAllValues] = useState({
        maritalStatus: "",
        dependents: "",
        idTypeId: "1",
        idNumber: "",
        incomeProofTypeId: "",
        addressProofTypeId: "",
        monthlyIncome: "",
        province: "",
        city: "",
        address: "",
    });

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };
    const {
        dependents,
        maritalStatus,
        idNumber,
        idTypeId,
        incomeProofTypeId,
        monthlyIncome,
        province,
        city,
        address,
        addressProofTypeId,
    } = allValues;

    const handleSubmit = () => {
        const data = {
            clientId,
            maritalStatus: maritalStatus,
            idTypeId: "1",
            idNumber: idNumber,
            selfPhoto: selfPhoto,
            selfPhotoUrl,
            idFrontImg: idFrontImg,
            idFrontImgUrl,
            idBackImg: idBackImg,
            addressProofTypeId,
            country: APP_CONFIGS.COUNTRY,
            postalCode,
            province,
            city,
            address,
            addressProof,
            addressProofDocumentUrl,
            idBackImgUrl,
            incomeProof,
            incomeProofUrl,
            incomeProofTypeId,
            monthlyIncome,
            dependents,
            clientId,
        };
        createCustomerDocument(data);
    };
    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };

    useEffect(() => {
        if (city.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if (item.length > 0) {
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    }, [city, province])

    useEffect(() => {
        if (documentInfo && documentInfo.success === true) {
            history.push(`/client/${clientId}/application/${applicationId}/additional-guarantor`);
        }
    }, [documentInfo]);
    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);
    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    id = "SupportingDoc"
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    {t('SUPPORTING_DOCUMENTS') || "Supporting Documents"}
                </Typography>
                <Typography
                    className={classes.titleLabel}
                >
                    {t('APPLICANT_PHOTO') || "Applicant’s photo"}
                </Typography>
                <Fragment>
                    <CssBaseline />

                    <ValidatorForm onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('APPLICANT_PHOTO')||"Applicant’s photo"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="center"
                                    direction="column"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {selfPhoto.length < 1 && (
                                            <div className="file-input-column">
                                                <input
                                                    type="file"
                                                    id="selfPhoto"
                                                    className="file"
                                                    name="selfPhoto"
                                                    onChange={
                                                        handleSelfImageChange
                                                    }
                                                />
                                                <label htmlFor="selfPhoto">
                                                    <PersonIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "68px",
                                                            display: "block",
                                                            color: "#CCCCE5"
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            display: "block",
                                                            color: "#CCCCE5"
                                                        }}
                                                    >
                                                        {t('TAP_TO_UPLOAD_PHOTO') || "Tap to upload photo"}
                                                    </span>
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer}>
                                            <img
                                                src={selfPhoto}
                                                className={
                                                    classes.selfPhotoImage
                                                }
                                            />
                                            {selfPhoto && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeSelfPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}
                        >
                            {t('ID_PROOF') || "ID Proof"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle classes={classes} text={t('ID_PROOF')||"ID Proof"} /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('ID_TYPE') || "ID type"}</InputLabel>
                                            <Select
                                                name="idTypeId"
                                                value={idTypeId}
                                                onChange={changeHandler}
                                                fullWidth
                                                required
                                            >
                                                {map(
                                                    ID_TYPE_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="idNumber"
                                            label={t('ID_NUMBER') || "ID number"}
                                            value={idNumber}
                                            onChange={changeHandler}
                                            fullWidth
                                            type="number"
                                        // validators={[
                                        //     "isValidIdNumber",
                                        //     "required",
                                        // ]}
                                        // errorMessages={[
                                        //     "Please enter a valid id number",
                                        //     "this field is required",
                                        // ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={{ marginBottom: "8px" }}
                                        >
                                            {t('FRONT_ID') || "Front side of ID"}
                                        </Typography>
                                        {idFrontImg.length < 1 && (
                                            <div className="file-input">
                                                <input
                                                    type="file"
                                                    id="idFrontImg"
                                                    className="file"
                                                    name="idFrontImg"
                                                    onChange={
                                                        handleFrontImageChange
                                                    }
                                                />
                                                <label htmlFor="idFrontImg">
                                                    <AddCircleOutlineIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "64px",
                                                            color: "#CCCCE5"
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer}>
                                            <img
                                                src={idFrontImg}
                                                className={classes.bfPhoto}
                                            />
                                            {idFrontImg && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeFrontPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={{ marginBottom: "8px" }}
                                        >
                                            {t('BACK_ID') || "Back side of ID"}
                                        </Typography>
                                        {idBackImg.length < 1 && (
                                            <div className="file-input">
                                                <input
                                                    type="file"
                                                    id="idBackImg"
                                                    className="file"
                                                    name="idBackImg"
                                                    onChange={
                                                        handleBackImageChange
                                                    }
                                                />
                                                <label htmlFor="idBackImg">
                                                    <AddCircleOutlineIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "64px",
                                                            color: "#CCCCE5"
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer}>
                                            <img
                                                src={idBackImg}
                                                className={classes.bfPhoto}
                                            />
                                            {idBackImg && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeBackPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}
                        >
                            {t('INCOME_PROOF') || "Income Proof"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('INCOME_PROOF')||"Income Proof"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DOCUMENT_TYPE') || "Document type"}
                                            </InputLabel>
                                            <Select
                                                name="incomeProofTypeId"
                                                value={incomeProofTypeId}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    INCOME_PROOF_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            name="monthlyIncome"
                                            label={t('MONTHLY_INCOME') || "Monthly income (৳)"}
                                            value={monthlyIncome}
                                            onChange={changeHandler}
                                            fullWidth
                                            type="number"
                                            validators={[
                                                "isValidMonthlyIncome",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid monthly income",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                        <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                            {incomeProof.length < 1 && (
                                                <div className="file-input-inline">
                                                    <input
                                                        type="file"
                                                        id="incomeProof"
                                                        className="file"
                                                        name="incomeProof"
                                                        onChange={
                                                            handleIncomeProofChange
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="incomeProof"
                                                        className={
                                                            classes.inlineUpload
                                                        }
                                                    >
                                                        <AddPhotoAlternateIcon style={{ color: "#5F56A6" }} />
                                                        <Typography
                                                            style={{
                                                                marginLeft: "8px",
                                                                color: "#5F56A6"
                                                            }}
                                                        >
                                                            {t('TAP_TO_UPLOAD_DOCUMENT') || "Tap to upload document"}
                                                        </Typography>
                                                    </label>
                                                </div>
                                            )}
                                            {incomeDocShow && (
                                                <div
                                                    className={classes.docContainer}
                                                >
                                                    <Link
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                        underline="always"
                                                    >
                                                        {incomeDocName}
                                                    </Link>
                                                    <DeleteOutlineIcon
                                                        onClick={removeInComeFile}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}
                        >
                            {t('CURRENT_ADDRESS') || "Current Address Proof"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('CURRENT_ADDRESS')||"Current Address Proof"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DOCUMENT_TYPE') || "Document type"}
                                            </InputLabel>
                                            <Select
                                                name="addressProofTypeId"
                                                value={addressProofTypeId}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    ADDRESS_PROOF_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="country"
                                            label={t('COUNTRY') || "Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            name="postalCode"
                                            label={t('POSTAL_CODE') || "Postal code"}
                                            type="number"
                                            value={postalCode}
                                            onChange={changePostalCode}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                            fullWidth
                                            validators={[
                                                "isValidPostalCode",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE') || "District / Province"}
                                            </InputLabel>
                                            <Select
                                                name="province"
                                                value={province}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN') || "City / Town"}</InputLabel>
                                            <Select
                                                name="city"
                                                value={city}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                            name="address"
                                            label={t('ADDRESS') || "Address"}
                                            value={address}
                                            onChange={changeHandler}
                                            fullWidth
                                            validators={[
                                                "isValidAddress",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                            {addressProof.length < 1 && (
                                                <div className="file-input-inline">
                                                    <input
                                                        type="file"
                                                        id="addressProof"
                                                        className="file"
                                                        name="addressProof"
                                                        onChange={
                                                            handleAddressProofDocumentChange
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="addressProof"
                                                        className={
                                                            classes.inlineUpload
                                                        }
                                                    >
                                                        <AddPhotoAlternateIcon style={{ color: "#5F56A6" }} />
                                                        <Typography
                                                            style={{
                                                                marginLeft: "8px",
                                                                color: "#5F56A6"
                                                            }}
                                                        >
                                                            {t('TAP_TO_UPLOAD_DOCUMENT') || "Tap to upload document"}
                                                        </Typography>
                                                    </label>
                                                </div>
                                            )}

                                            {addressDocShow && (
                                                <div
                                                    className={classes.docContainer}
                                                >
                                                    <Link
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                        underline="always"
                                                    >
                                                        {addressDocName}
                                                    </Link>
                                                    <DeleteOutlineIcon
                                                        onClick={removeAddressFile}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <div className={classes.flexWrapper}>
                            <div
                                style={{
                                    marginBottom: "48px",
                                    marginTop: "8px",
                                    maxWidth: "80%",
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={customerChecked}
                                            onChange={handleCheckedChange}
                                            style={{ color: "#5F56A6" }}
                                        />
                                    }
                                    label={t('DECLARE_VERIFIED_DOCS') || "Declare that all documents are verified before submitting."}
                                />
                            </div>
                        </div>
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                disabled={!customerChecked}
                            >
                                {t('SAVE_AND_EXIT') || "Save & Exit"}
                            </Button>
                            <Button
                                id="submitBtn"
                                style={{
                                    backgroundColor: "#5F56A6"
                                }}
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                disabled={!customerChecked}
                                type="submit"
                            >
                                {t('SUBMIT') || "Submit"}
                            </Button>
                        </div>
                        <SelfAlertDialog
                            open={dialogOpen}
                            closeDialog={closeDialog}
                            removeFile={removeFile}
                            fileIndex={fileIndex}
                            t={t}
                        />
                    </ValidatorForm>
                </Fragment>
            </div>
        </main>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

function SelfAlertDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const { removeFile, fileIndex, t } = props;

    const handleClose = () => {
        props.closeDialog();
    };
    const handleRemove = () => {
        props.removeFile(props.fileIndex);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('DELETE_FILE') || "Delete file"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('DELETE_MESSAGE') || "Do you want to delete this file now?"}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        {t('NO') || "No"}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleRemove}
                        color="primary"
                        autoFocus
                    >
                        {t('YES') || "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default withStyles(inputStyles)(SupportingDocument);
