import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import SelectVehicle from './SelectVehicle'
import get from 'lodash/get'
import { getVehicles, createApplication, createDiscount } from '../../store/actions/customer'

const SelectVehicleContainer = (props: any) => <SelectVehicle {...props} />

const mapStateToProps = (state: any) => {
    return {
        createdUser: state.customer.createdUser,
        isLoadingCreateApplication: state.customer.isLoadingCreateApplication,
        isLoadingGetVehicles: state.customer.isLoadingGetVehicles,
        vehicleList: state.customer.vehicleList,
        createdApplication: state.customer.createdApplication,
        currentUser: get(state.auth, 'user')
    }
}

const mapDispatchToProps = {
    getVehicles,
    createApplication,
    createDiscount
}


export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectVehicleContainer))
)