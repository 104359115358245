import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import PaymentComponent from './PaymentComponent'
import { getApplicationById, getDiscount, updateApplicationStatus} from '../../store/actions/customer'

const PaymentContainer = (props: any) => <PaymentComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        applicationVehicle: get(state.customer.application, 'vehicle') || {},
        discount: get(state.customer.discount,'data') ||{},
    }
}

const mapDispatchToProps = {
    getApplicationById,
    getDiscount,
    updateApplicationStatus
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentContainer))
)
