import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import storage from '../../utils/storage';
const LangSelector = (props) => {
  const {classes, user, updateUser} = props
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
  const [language, setLanguage] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const lang = storage.getLanguage();
    lang==='bn'?setLanguage('বাংলা'):setLanguage('English');
    setAnchorEl(null)
  }, [storage])

  const handleEnglish = () => {
    setAnchorEl(null);
    setSelectedLang('en');
    setLanguage('English');
    storage.setLanguage('en');
    i18n.changeLanguage('en');
    const params = {
      userId:user.id,
      data:{
        language:'en'
      }
    }
    updateUser(params);
    console.log("Language is : "+'en');
  };

  const handleBangla = () => {
    setAnchorEl(null);
    setSelectedLang('bn');
    setLanguage('বাংলা');
    storage.setLanguage('bn');
    i18n.changeLanguage('bn');
    const params = {
      userId:user.id,
      data:{
        language:'bn'
      }
    }
    updateUser(params);
    console.log("Language is : "+'bn');
  };
//   const changeLanguage = (event) => {
//     setSelectedLang(event.target.value);
//     i18n.changeLanguage(event.target.value);
//   }
const handleClose = () =>{
    setAnchorEl(null);
}

 
  return (
    <div>
      <IconButton color="inherit"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        classes={{label: classes.iconButtonLabel}}
      >
       <LanguageIcon sx={{display: { sxs: "none", lg: "block" }}}/>
       <div> <Typography
                    className={classes.title}
                    component="h4"
                    variant="h4"
                >
                    {language}
                </Typography></div>
                <KeyboardArrowDownIcon/>
      </IconButton>
     
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.menu}
      >
        <MenuItem style={{color: 'white'}} divider={true}
        onMouseEnter={(e) => e.target.style.background = '#5F56A6'}
        onMouseLeave={(e) => e.target.style.background= '#212121'}
        value="en" onClick={handleEnglish} >English</MenuItem>
        
        <MenuItem style={{color: 'white'}}  
        onMouseEnter={(e) => e.target.style.background = '#5F56A6'}
        onMouseLeave={(e) => e.target.style.background= '#212121'}
        value="bn" onClick={handleBangla} >বাংলা (Bengali)</MenuItem>
      </Menu>
      
    </div>
    
  )
}
 
export default LangSelector;