import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import storage from "../../utils/storage";

import * as JWT from "jsonwebtoken";

function getUserFromToken(token) {
    const secret = process.env.JWT_SECRET || "tvs_motor_dev";

    try {
        const decoded = JWT.verify(token.replace("Bearer ", ""), secret);
        if (decoded && decoded.id) {
            return true;
        }
    } catch (err) {
        return false;
    }
    return false;
}

class AuthRequired extends Component {
    componentDidMount() {
        const { history } = this.props;
        const isAuthenticated = getUserFromToken(storage.getToken());
        if (!isAuthenticated) {
            history.push("/login");
        }
    }

    render() {
        if (!storage.isAuthenticated()) {
            return null;
        }
        return React.cloneElement(this.props.children);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

AuthRequired.propTypes = {
    // children: PropTypes.node,
    // getCurrentUser: PropTypes.func,
    // history: PropTypes.object,
    // user: PropTypes.object
};

const ConnectedAuthRequired = connect(mapStateToProps)(AuthRequired);

export default withRouter(ConnectedAuthRequired);
