import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import DashboardComponent from './DashboardComponent'
import { getCustomers, getApplicationInfo, clearData } from '../../store/actions/customer'
import { isManager } from '../../utils/helper'

const DashboardContainer = (props: any) => <DashboardComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
        isManager: state.auth.user ? isManager(state.auth.user) : false,
        isLoadingGetCustomers: state.auth.isLoadingGetCustomers,
        customerCount: state.auth.list.length,
        applicationInfo: state.customer.applicationInfo || {}
    }
}

const mapDispatchToProps = {
    getCustomers, getApplicationInfo, clearData
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContainer))
)
