import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
    getPaymentDetailById
  } from "../../store/actions/customer";
import ApplicationPaymentDetail from './ApplicationSinglePaymentDetail'

const ApplicationSinglePaymentContainer = props => <ApplicationPaymentDetail {...props} />

const mapStateToProps = state => ({
   paymentDetail: state.customer.paymentDetail||{},
})

const mapDispatchToProps = {
    getPaymentDetailById
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationSinglePaymentContainer))
)
