import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    form: {
        '& .MuiTextField-root': {
            margin: "20px 8px",
            width: 223,
        },
    },
    paper: {
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
        padding: "16px",
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        color: "#2A2A3E",
    },
    addTransactionBtn: {
        backgroundColor: "#5F56A6",
        color: "white",
        fontWeight: "500",
        textTransform: 'none',
        fontSize: "16px",
        lineHeight: "24px",
        borderRadius: "8px",
        marginRight: '64px'
    },
    addTransactionBtnOutline: {
        border: "1px solid #5F56A6",
        color: "#5F56A6",
        marginRight: "24px",
        textTransform: "none",
    },
    transactionRecordBtn: {
        width: "158px",
        height: "52px",
    },
    formControl: {
        margin: "20px 8px",
        width: 223,
    },
}));