import { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { AppBar, Link, Toolbar } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { ProcessingFeesDialogBoxConatiner } from "../../static_components";
import { APP_CONFIGS } from "../../../constants/appConstants";
//import { createLoan } from "../../../store/actions/customer";
import LoaderComponent from "../../connected_components/Loader";
import {
    LOAN_MONTH_OPTIONS,
    BANK_OPTIONS,
    REPAYMENT_METHOD_OPTIONS,
    EMI_OPTIONS,
} from "../../../constants/appConstants";
import {
    validationForDownPayment,
    validationForUtilizationRate,
} from "../../../utils/formValidation";

import {
    calculateEmiRequest,
    getApplicationById,
    calculateCde,
} from "../../../store/actions/customer";
import { currencyFormat } from "../../../utils/helper";

const LoanUpdateComponent = (props) => {
    const {
        classes,
        calculateEmiRequest,
        emiSummary,
        getApplicationById,
        loanInfo,
        calculateCde,
        cdeInfo,
        applicationLoan,
        confirmSubmit,
        handleChangeTab,
        updateApplicationLoan,
        totalCost1,
        createLoan,
        createFees,
        updateFees,
        discount,
        fees,
        getDiscount,
        isLoadingCalculateCde,
        t
    } = props;
    const { applicationId } = props.match.params;
    const { id } = applicationLoan;
    const [totalLoanAmount, setTotalLoanAmount] = useState(
        applicationLoan.loanAmount || discount.discountedPrice || totalCost1
    );
    const [vehiclePrice, setVehiclePrice] = useState();
    const [rate, setRate] = useState(
        applicationLoan.flatRate > 0
            ? (applicationLoan.flatRate * 100).toString()
            : (applicationLoan.reducingRate * 100).toString()
    );
    const [rateType, setRateType] = useState(
        applicationLoan.flatRate > 0 ? "flat" : "reducing"
    );
    const [processingFeeDialogOpen, setProcessingFeeDialogOpen] = useState(false);

    const [allValues, setAllValues] = useState({
        downPayment: applicationLoan.downPayment,
        bankName: applicationLoan.bankName,
        paymentMethod: applicationLoan.paymentMethod,
        processingFee: applicationLoan.processingFee,
        loanAmount: applicationLoan.loanAmount,
        loanYears: applicationLoan.numberOfPayments,
        monthlyPayment: applicationLoan.monthlyPayment,
        totalInterest: applicationLoan.totalInterest,
        totalCost:applicationLoan.totalCost || discount.discountedPrice || totalCost1,
        otherMethod: applicationLoan.otherMethod || ""
    });

    const [feesValue,setFeesValue] = useState({
        earlySettlementFees: fees.processingFees||0.0,
        additionalCharges: fees.additionalCharges||0.0,
        documentationFees: fees.documentationFees || 0.0,
        stampDutyCharges: fees.stampDutyCharges||0.0,
        loanRebookCharges: fees.loanRebookCharges||0.0,
        chequeBounceCharges: fees.chequeBounceCharges||0.0,
        chequeSwapCharges: fees.chequeSwapCharges||0.0,
        exchangeCharges: fees.exchangeCharges||0.0
    })

    const [allFees, setAllFees] = useState({
        processingFeeChecked: true,
        earlySettlementFeeChecked: fees.earlySettlementFees > 0.0?true:false,
        additionalChargesChecked: fees.additionalCharges > 0.0?true:false,
        documentationFeesChecked: fees.documentationFees> 0.0?true:false,
        stampDutyChargesChecked: fees.stampDutyCharges > 0.0?true:false,
        loanRebookChargesChecked: fees.loanRebookCharges > 0.0?true:false,
        chequeBounceChargesChecked: fees.chequeBounceCharges > 0.0?true:false,
        chequeSwapChargesChecked: fees.chequeSwapCharges > 0.0?true:false,
        exchangeChargesChecked: fees.exchangeCharges > 0.0?true:false
    })

    const [visibleFees, setVisibleFees] = useState({
        processingFeeVisible: true,
        earlySettlementFeeVisible: fees.earlySettlementFees > 0.0?true:false,
        additionalChargesVisible: fees.additionalCharges > 0.0?true:false,
        documentationFeesVisible: fees.documentationFees> 0.0?true:false,
        stampDutyChargesVisible: fees.stampDutyCharges > 0.0?true:false,
        loanRebookChargesVisible: fees.loanRebookCharges > 0.0?true:false,
        chequeBounceChargesVisible: fees.chequeBounceCharges > 0.0?true:false,
        chequeSwapChargesVisible: fees.chequeSwapCharges > 0.0?true:false,
        exchangeChargesVisible: fees.exchangeCharges > 0.0?true:false
    })

    const {
        processingFeeChecked,
        earlySettlementFeeChecked,
        additionalChargesChecked,
        documentationFeesChecked,
        stampDutyChargesChecked,
        loanRebookChargesChecked,
        chequeBounceChargesChecked,
        chequeSwapChargesChecked,
        exchangeChargesChecked
    } = allFees;

    const {
        processingFeeVisible,
        earlySettlementFeeVisible,
        additionalChargesVisible,
        documentationFeesVisible,
        stampDutyChargesVisible,
        loanRebookChargesVisible,
        chequeBounceChargesVisible,
        chequeSwapChargesVisible,
        exchangeChargesVisible
    } = visibleFees;

    const {
        earlySettlementFees,
        additionalCharges,
        documentationFees,
        stampDutyCharges,
        loanRebookCharges,
        chequeBounceCharges,
        chequeSwapCharges,
        exchangeCharges
    } = feesValue;

    const {
        loanAmount,
        downPayment,
        bankName,
        paymentMethod,
        processingFee,
        totalInterest,
        monthlyPayment,
        loanYears,
        totalCost,
        otherMethod
    } = allValues;

    const handleFees = (e) =>{
        setFeesValue({
            ...feesValue,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdateCheck = (e) =>{
        setAllFees({
            ...allFees,
            [e.target.name]: e.target.checked,
          });
    }


    const handleVisibleFees = () =>{
        setVisibleFees({
            ...visibleFees,
            processingFeeVisible: processingFeeChecked,
            earlySettlementFeeVisible: earlySettlementFeeChecked,
            additionalChargesVisible: additionalChargesChecked,
            documentationFeesVisible: documentationFeesChecked,
            stampDutyChargesVisible: stampDutyChargesChecked,
            loanRebookChargesVisible: loanRebookChargesChecked,
            chequeBounceChargesVisible: chequeBounceChargesChecked,
            chequeSwapChargesVisible: chequeSwapChargesChecked,
            exchangeChargesVisible: exchangeChargesChecked
        })
        setProcessingFeeDialogOpen(false);
    }

    const handleCancelVisibleFees = () => {
        setAllFees({
           ...allFees,
           processingFeeChecked: processingFeeVisible,
           earlySettlementFeeChecked: earlySettlementFeeVisible,
           additionalChargesChecked: additionalChargesVisible,
           documentationFeesChecked: documentationFeesVisible,
           stampDutyChargesChecked: stampDutyChargesVisible,
           loanRebookChargesChecked: loanRebookChargesVisible,
           chequeBounceChargesChecked: chequeBounceChargesVisible,
           chequeSwapChargesChecked: chequeSwapChargesVisible,
           exchangeChargesChecked: exchangeChargesVisible
        })
        setProcessingFeeDialogOpen(false);
    }

    const handleRateTypeChange = (e) => {
        setRateType(e.target.value);
    };

    const handleRateChange = (e) => {
        setRate(e.target.value);
    };

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const changeDownPayment = (e) => {
        const remainingAmount = totalCost - e.target.value;
        setAllValues({ ...allValues, downPayment: e.target.value });
        setTotalLoanAmount(remainingAmount);
    };

    const calcuateEmi = () => {
        const flatRateData = {
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            rate: parseFloat(rate / 100),
            isFlatRate: true,
            downPayment: parseInt(downPayment),
        };
        const reducingRateData = {
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            rate: parseFloat(rate / 100),
            isFlatRate: false,
            downPayment: parseInt(downPayment),
        };

        const data = rateType === "flat" ? flatRateData : reducingRateData;
        calculateEmiRequest(data);
    };

    useEffect(() => {
        getApplicationById(applicationId);
        getDiscount(applicationId);
    }, [])

    useEffect(() => {
        if (loanInfo && loanInfo.id) {
            calculateCde({ applicationId });
        }
    }, [loanInfo]);

    useEffect(() => {
        if (totalLoanAmount && loanYears && rateType && rate.length > 1) {
            calcuateEmi();
        }
    }, [loanYears, rate, downPayment]);

    useEffect(() => {
        if (emiSummary) {
            setAllValues({ ...allValues, totalInterest: emiSummary.totalInterest, monthlyPayment: emiSummary.monthlyPayment });
        }
    }, [emiSummary])

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidDownPayment", (value) => {
            return validationForDownPayment(value, totalCost);
        });

        ValidatorForm.addValidationRule(
            "isValidReducingUtilizationRate",
            (value) => {
                return validationForUtilizationRate(value, rateType);
            }
        );

        ValidatorForm.addValidationRule("isValidProcessingFee", (value) => {
            return processingFee >= 0;
        });
    });

    const handleSubmit = () => {
        confirmSubmit();
        
    };

    const handleSave = async () => {

        let params = {
            applicationId,
            processingFees: processingFee,
            earlySettlementFees,
            additionalCharges,
            documentationFees,
            stampDutyCharges,
            loanRebookCharges,
            chequeSwapCharges,
            chequeBounceCharges,
            exchangeCharges
        }
        console.log(params);
        if(fees.id){
            updateFees(params);
        } else {
           createFees(params);
        }

        const isFlatRate = rateType === "flat" ? true : false;
        if(id){
        const params = {
            loanId: id,
            data: {
                downPayment: parseInt(downPayment),
                loanAmount: parseInt(totalLoanAmount),
                loanYears: parseInt(loanYears),
                processingFee: processingFee,
                paymentMethod: paymentMethod,
                bankName: bankName,
                flatRate: isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
                reducingRate: !isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
                monthlyPayment: monthlyPayment,
                numberOfPayments: loanYears,
                totalInterest: totalInterest,
                totalCost: totalCost,
                otherMethod: otherMethod
            },
        };
       await updateApplicationLoan(params);
    } else{
        const data = {
            applicationId: applicationId,
            downPayment: parseInt(downPayment),
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            processingFee: processingFee,
            paymentMethod: paymentMethod,
            bankName: bankName,
            flatRate: isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            reducingRate: !isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            monthlyPayment: monthlyPayment,
            numberOfPayments: loanYears,
            totalInterest: totalInterest,
            totalCost: totalCost,
            otherMethod: otherMethod
        };
        console.log(data);
       await createLoan(data);
    }
        await calculateCde({ applicationId });
        //handleChangeTab(0);
    };



    return (
        <main className={classes.layout}>
        <LoaderComponent isLoading={isLoadingCalculateCde} />
            <Fragment>
                <CssBaseline />
                <Typography
                className={classes.titleLabel}>
                    {t('REPAYMENT')||"Repayment"}
                </Typography>
                <ValidatorForm onSubmit={handleSave}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="downPayment"
                                        value={downPayment}
                                        type="number"
                                        label={t('DOWNPAYMENT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        fullWidth
                                        onChange={changeDownPayment}
                                        InputLabelProps={{ required: false }}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: totalCost,
                                            },
                                        }}
                                        required
                                        validators={[
                                            "isValidDownPayment",
                                            "required",
                                        ]}
                                        errorMessages={[
                                            "Please enter a valid down payment amount",
                                            "this field is required",
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>{t('TENURE')||"Tenure(months)"}</InputLabel>
                                        <Select
                                            name="loanYears"
                                            value={loanYears}
                                            onChange={changeHandler}
                                            required
                                        >
                                            {map(
                                                LOAN_MONTH_OPTIONS,
                                                (item, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>
                                            {t('FUND_UTILIZATION_TYPE')||"Fund utilization type"}
                                        </InputLabel>
                                        <Select
                                            name="rateType"
                                            value={rateType}
                                            onChange={handleRateTypeChange}
                                            required
                                        >
                                            {map(EMI_OPTIONS, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="rate"
                                        label={t('FUND_UTILIZATION_RATE')||"Reducing fund utilization rate (%)"}
                                        value={rate}
                                        type="number"
                                        onChange={handleRateChange}
                                        fullWidth
                                        InputProps={{
                                            inputProps: {
                                                min:0,
                                                max: 100,
                                            },
                                        }}
                                        validators={[
                                            "isValidReducingUtilizationRate",
                                            "required",
                                        ]}
                                        errorMessages={[
                                            "Please enter a valid fund utilization rate. Minimum = 6% for flat rate,  6% for reducing rate",
                                            "this field is required",
                                        ]}
                                    />
                                </Grid>
                                </Grid>
                                </div>
                                <div className={classes.paperContentColoured}>
                                <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('TOTAL_LOAN_AMOUNT')+ " "+`(${APP_CONFIGS.CURRENCY})`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.labelContent}
                                        style={{textAlign:"right"}}
                                    >
                                        {totalLoanAmount && currencyFormat(totalLoanAmount)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('TOTAL_FUND_UTILIZATION_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.labelContent}
                                        style={{textAlign:"right"}}
                                    >
                                        {totalInterest && currencyFormat(totalInterest)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('MONTHLY_REPAYMENT')  + " "+`(${APP_CONFIGS.CURRENCY})`})
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.labelContent}
                                        style={{textAlign:"right"}}
                                    >
                                        {monthlyPayment && currencyFormat(monthlyPayment)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className={classes.label}
                                    >
                                        {t('TOTAL_REPAYMENT_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.labelContent}
                                        style={{textAlign:"right"}}
                                    >
                                        {totalLoanAmount && totalInterest && currencyFormat(totalLoanAmount + totalInterest)}
                                    </Typography>
                                </Grid>
                                </Grid>
                                </div>
                                </Paper>
                                <Typography
                                        className={classes.titleLabel}>
                                            {t('REPAYMENT_METHOD')||"Repayment method"}
                                    </Typography>
                                    <Paper className={classes.paper}>
                                        {/* <PaperTitle
                                            classes={classes}
                                            text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                        /> */}
                                        <div className={classes.paperContent}>
                                            <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('REPAYMENT_METHOD')||"Repayment method"}
                                            </InputLabel>
                                            <Select
                                                name="paymentMethod"
                                                value={paymentMethod}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    REPAYMENT_METHOD_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('BANK_NAME')||"Bank name"}</InputLabel>
                                            <Select
                                            name="bankName"
                                            value={bankName}
                                            onChange={changeHandler}
                                            disabled={paymentMethod==1 || paymentMethod==9 ?true:false}
                                            //required
                                        >
                                            {map(BANK_OPTIONS, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    {paymentMethod==9 && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="otherMethod"
                                            value={otherMethod}
                                            type="text"
                                            label={t('Specify Other')}
                                            fullWidth
                                            onChange={changeHandler}
                                        />
                                    </Grid>}
                                            </Grid>
                                        </div>
                                    </Paper>
                                    <Typography
                                        className={classes.titleLabel}>
                                            {t('PROCESSING_DETAILS')||"Processing Details"}
                                            <span style={{float:'right',fontSize:'16px'}}><Link onClick={()=> setProcessingFeeDialogOpen(true)}>Configure</Link></span>
                                    </Typography>
                                    
                                    <Paper className={classes.paper}>
                                        <div className={classes.paperContent}>
                                            <Grid container spacing={4}>
                                {processingFeeVisible && <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        id = "processingFee"
                                        name="processingFee"
                                        label={t('PROCESSING_FEE')||"Processing fee (৳)"}
                                        value={processingFee}
                                        onChange={changeHandler}
                                        fullWidth
                                        type="number"
                                        validators={["isValidProcessingFee"]}
                                        errorMessages={[
                                            "Please enter a valid processing fee",
                                        ]}
                                    />
                                </Grid>}
                               {earlySettlementFeeVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="earlySettlementFees"
                                        label={"Early Settlement Fee"}
                                        value={earlySettlementFees}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                </Grid>}
                                {additionalChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="additionalCharges"
                                        label={"Additional Charges"}
                                        value={additionalCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                   {documentationFeesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="documentationFees"
                                        label={"Documentation Fees"}
                                        value={documentationFees}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                  { stampDutyChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="stampDutyCharges"
                                        label={"Stamp Duty Charges"}
                                        value={stampDutyCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                   {loanRebookChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="loanRebookCharges"
                                        label={"Loan Rebook Charges"}
                                        value={loanRebookCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                    {chequeBounceChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="chequeBounceCharges"
                                        label={"Cheque Bounce Charges"}
                                        value={chequeBounceCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                    {chequeSwapChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="chequeSwapCharges"
                                        label={"Cheque Swap Charges"}
                                        value={chequeSwapCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                                    {exchangeChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="exchangeCharges"
                                        label={"Exchange Charges"}
                                        value={exchangeCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                            </Grid>
                            
                        </div>
                    </Paper>
                    <ApplicationAdminstration
                        classes={classes}
                        handleSubmit={handleSubmit}
                        handleSave={handleSave}
                        t={t}
                    />
                </ValidatorForm>
                <ProcessingFeesDialogBoxConatiner open={processingFeeDialogOpen} 
                            handleUpdateCheck={handleUpdateCheck} 
                            handleUpdate={handleVisibleFees} 
                            handleCancel = {handleCancelVisibleFees}
                            allFees={allFees} />
            </Fragment>
        </main>
    );
};

const ApplicationAdminstration = (props) => {
    const { classes, handleSubmit, handleSave, t } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.rm64}
                >
                    <Box p={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            onClick={handleSave}
                            className={classes.staffActionBtn}
                        >
                            {t('SAVE')||"Save &amp; Exit"}
                        </Button>
                    </Box>
                    <Box p={1}>
                        <Button
                        style={{backgroundColor:"#5F56A6"}}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </Box>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => ({
    emiSummary: state.customer.summary,
});

const mapDispatchToProps = {
    calculateEmiRequest,
    getApplicationById,
    calculateCde
};

export default withStyles(inputStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(LoanUpdateComponent))
);
