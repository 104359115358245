import { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import map from "lodash/map";
import filter from "lodash/filter";
import {
    COUNTRY_CODE, APP_CONFIGS
} from "../../constants/appConstants";
import {
    validationForTextField,
    validationForVehicle,
    validationForNumber,
    validationForDate,
    validationForRequired,
} from "../../utils/formValidation";
import { mapDistrictsOptions, mapCityOptions } from "../../utils/helper";
import { useTranslation } from "react-i18next";

const LoanOffer = (props) => {
    const {
        classes,
        getCities,
        cityList,
        updateApplicationStatus,
        status,
        history,
        updateDocument,
        createAdditionalInfo
    } = props;
    const { applicationId } = props.match.params;

    const [signedAgreementUrl, setSignedAgreementUrl] = useState("");
    const [fileName, setFileName] = useState("");
    const [customerChecked, setCustomerChecked] = useState(false);
    const [checkValidation, setCheckValidation] = useState(false);
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [addedLoanAgreement, setAddedLoanAgreement] = useState(false);
    const [addedInfo, setAddedInfo] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const handleCheckedChange = (e) => {
        setCustomerChecked(e.target.checked);
    };

    const [imageValues, setImageValues] = useState({
        signedAgreement: "",
    });

    const handleAddressProofDocumentChange = (e) => {
        setSignedAgreementUrl(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setImageValues({
            ...imageValues,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setAddedLoanAgreement(true);
    };

    const { signedAgreement } = imageValues;

    const [allValues, setAllValues] = useState({
        homePh: "",
        officePh: "",
        email: "",
        fbId: "",
        employerCountry: APP_CONFIGS.COUNTRY,
        province: "",
        city: "",
        address: "",
        signedAgreement: "",
    });

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
        setAddedInfo(true);
    };
    const {
        homePh,
        officePh,
        email,
        fbId,
        employerCountry,
        province,
        city,
        address,
    } = allValues;

    const handleSubmit = () => {
        if(addedLoanAgreement){
            let data = {
                categoryId: 6,
                documentTypeId: 48,
                note: 'Signed loan agreement',
                document: signedAgreementUrl,
                status: "active",
                createdBy: "",
                applicationId,
            };
            updateDocument(data);
        }

        if(setAddedInfo){
            const params = {
                applicationId: applicationId,
                homePhoneNumber: homePh,
                officePhoneNumber: officePh,
                email: email,
                facebookId: fbId
            }
            createAdditionalInfo(params);
        }
        const params = {
            applicationId: applicationId,
            data: {
                note: "",
                viewed: 0,
                status: "Accepted",
            },
        };
        updateApplicationStatus(params);
        history.push('./dashboard');
    };
    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };
    useEffect(() => {
        if (status === "Accepted") {
            history.push(`/application/${applicationId}`);
        }
    }, [status]);

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE);
        });

        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidExistingVehicle", (value) => {
            return validationForVehicle(value);
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
    });

    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h5"
                    variant="h5"
                >
                    {t('ACCEPT_LOAN_OFFER')||"Accept Loan Offer"}
                </Typography>
                <Typography
                    style={{ marginBottom: "20px" }}
                    variant="subtitle1"
                >
                    {t('PROVIDE_INFO')||"Please provide the following information for repayment collection,and upload signed agreement."}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleSubmit}>
                        <div>
                            <Paper className={classes.paper}>
                                <PaperTitle
                                    classes={classes}
                                    text={t('ADDITIONAL_CONTACT_INFO')||"Additional Contact Info"}
                                />
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="homePh"
                                                label={t('HOME_PHONE')||"Home phone number(optional)"}
                                                value={homePh}
                                                onChange={changeHandler}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                name="officePh"
                                                label={t('OFFICE_PHONE')||"Office phone number(optional)"}
                                                value={officePh}
                                                fullWidth
                                                onChange={changeHandler}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="email"
                                                label={t('EMAIL')||"Email address(optional)"}
                                                value={email}
                                                onChange={changeHandler}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="fbId"
                                                label={t('FACEBOOK_ID')||"Facebook ID(optional)"}
                                                value={fbId}
                                                onChange={changeHandler}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <Paper className={classes.paper}>
                                <PaperTitle
                                    classes={classes}
                                    text={t('EMPLOYER_COMPANY_INFO')||"Employer & Company Address"}
                                />
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="employerCountry"
                                                label={t('COUNTRY')||"Country"}
                                                value={employerCountry}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                name="postalCode"
                                                label={t('POSTAL_CODE')||"Postal code"}
                                                type="number"
                                                value={postalCode}
                                                onChange={changePostalCode}
                                                inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                                fullWidth
                                                error={
                                                    checkValidation &&
                                                    !validationForNumber(
                                                        allValues.postalCode,
                                                        4
                                                    )
                                                }
                                                helperText={
                                                    checkValidation &&
                                                    !validationForNumber(
                                                        allValues.postalCode,
                                                        4
                                                    )
                                                        ? "Please enter a valid postal code"
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('DISTRICT_PROVINCE')||"District / Province"}
                                                </InputLabel>
                                                <Select
                                                    name="province"
                                                    value={province}
                                                    onChange={changeHandler}
                                                >
                                                    {map(
                                                        districtOptions,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                <InputLabel>
                                                    {t('CITY_TOWN')||"City / Town"}
                                                </InputLabel>
                                                <Select
                                                    name="city"
                                                    value={city}
                                                    onChange={changeHandler}
                                                >
                                                    {map(
                                                        cityOptions,
                                                        (item, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="address"
                                                label={t('ADDRESS')||"Address"}
                                                value={address}
                                                onChange={changeHandler}
                                                fullWidth
                                                error={
                                                    checkValidation &&
                                                    !validationForTextField(
                                                        address
                                                    )
                                                }
                                                helperText={
                                                    checkValidation &&
                                                    !validationForTextField(
                                                        address
                                                    )
                                                        ? "Please enter a valid address"
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                            <Paper className={classes.paper}>
                                <PaperTitle
                                    classes={classes}
                                    text={t('SIGNED_LOAN_AGGREMENT')||"Signed Loan Agreement"}
                                />
                                <div className={classes.paperContent}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={12}>
                                            {signedAgreement.length < 1 && (
                                                <div className="file-input-inline">
                                                    <input
                                                        type="file"
                                                        id="signedAgreement"
                                                        className="file"
                                                        name="signedAgreement"
                                                        onChange={
                                                            handleAddressProofDocumentChange
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="signedAgreement"
                                                        className={
                                                            classes.inlineUpload
                                                        }
                                                    >
                                                        <AddPhotoAlternateIcon />
                                                        <Typography
                                                            style={{
                                                                marginLeft:
                                                                    "8px",
                                                            }}
                                                        >
                                                            {t('TAP_TO_UPLOAD_DOCUMENT')||"Tap to upload document"}
                                                        </Typography>
                                                    </label>
                                                </div>
                                            )}
                                            <div
                                                className={classes.docContainer}
                                            >
                                                <p
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                    underline="always"
                                                >
                                                    {fileName}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>

                            <div className={classes.flexWrapper}>
                                <div
                                    style={{
                                        marginBottom: "48px",
                                        marginTop: "8px",
                                        maxWidth: "80%",
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={customerChecked}
                                                onChange={handleCheckedChange}
                                            />
                                        }
                                        label={t('DECLARE_VERIFIED_DOCS')||"Declare that all documents are verified before submitting."}
                                    />
                                </div>
                            </div>
                            <div className={classes.buttonWrapper}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    className={classes.actionBtn}
                                    disabled={!customerChecked}
                                >
                                    {t('SAVE_AND_EXIT')||"Save &amp; Exit"}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    className={classes.actionBtn}
                                    type="submit"
                                    disabled={!customerChecked}
                                >
                                    {t('SUBMIT')||"Submit"}
                                </Button>
                            </div>
                        </div>
                    </ValidatorForm>
                </Fragment>
            </div>
        </main>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(LoanOffer);
