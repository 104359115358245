import { useState, useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import filter from "lodash/filter";

import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    RESIDENTIAL_OPTIONS,
    YEARS_CURRENT_ADDRESS_OPTIONS,
    RELATIONSHIP_OPTIONS,
    APP_CONFIGS
} from "../../constants/appConstants";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
    validationForDate,
    validationForNumber,
    validationForTextField,
} from "../../utils/formValidation";
import { mapDistrictsOptions, mapCityOptions } from "../../utils/helper";
import LoaderComponent from "../connected_components/Loader";
import { AddRemarksDialogBoxConatiner } from "../static_components";
import { useTranslation } from "react-i18next";

const ApplicationList = (props) => {
    const {
        classes,
        history,
        cityList,
        getCities,
        createProfileReference,
        referenceInfo,
        isLoadingCreateReference,
        currentUser
    } = props;

    const { clientId, applicationId } = props.match.params;

    useEffect(() => {
        getCities();
    }, []);

    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [postalCodeTwo, setPostalCodeTwo] = useState("");
    const [openRemarks, setOpenRemarks] = useState(false);
    const [saveAndExitLink, setSaveAndExitLink] = useState(`/application/${applicationId}/save-application`);
    const [createAction, setCreateAction] = useState(false);
    const { t } = useTranslation();

    const [allValues, setAllValues] = useState({
        relationship: "",
        relationshipTwo: "",
        salutation: "",
        salutationTwo: "",
        residentalStatus: "",
        residentalStatusTwo: "",
        name: "",
        nameTwo: "",
        birthday: "",
        birthdayTwo: "",
        gender: "",
        genderTwo: "",
        phone: "",
        phoneTwo: "",
        email: "",
        emailTwo: "",
        country: "",
        countryTwo: "",
        province: "",
        provinceTwo: "",
        cityTwo: "",
        city: "",
        address: "",
        addressTwo: "",
        yearsLiving: "",
        yearsLivingTwo: "",
    });

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const handleSubmit = () => {
        if (name.length > 0) {
            const referenceOne = {
                applicationId,
                relationship,
                salutation,
                residentalStatus,
                name,
                birthday,
                gender,
                phone,
                email,
                country,
                postalCode,
                province,
                city,
                address,
                yearsLiving,
            };
            const referenceTwo = {
                applicationId,
                relationship: relationshipTwo,
                salutation: salutationTwo,
                residentalStatus: residentalStatusTwo,
                name: nameTwo,
                birthday: birthdayTwo,
                gender: genderTwo,
                phone: phoneTwo,
                email: emailTwo,
                postalCode: postalCodeTwo,
                country: countryTwo,
                province: provinceTwo,
                city: cityTwo,
                address: addressTwo,
                yearsLiving: yearsLivingTwo,
            };
            createProfileReference({ referenceOne, referenceTwo });
            setCreateAction(true);
        } else {
            history.push(
                `/client/${clientId}/application/${applicationId}/guarantor`
            );
        }
    };

    const handleSaveExit = () => {
        if (name.length > 0) {
            const referenceOne = {
                applicationId,
                relationship,
                salutation,
                residentalStatus,
                name,
                birthday,
                gender,
                phone,
                email,
                country,
                postalCode,
                province,
                city,
                address,
                yearsLiving,
            };
            const referenceTwo = {
                applicationId: applicationId,
                relationship: relationshipTwo,
                salutation: salutationTwo,
                residentalStatus: residentalStatusTwo,
                name: nameTwo,
                birthday: birthdayTwo,
                gender: genderTwo,
                phone: phoneTwo,
                email: emailTwo,
                postalCode: postalCodeTwo,
                country: countryTwo,
                province: provinceTwo,
                city: cityTwo,
                address: addressTwo,
                yearsLiving: yearsLivingTwo,
            };
            setOpenRemarks(true);
            createProfileReference({ referenceOne, referenceTwo });
        } else {
            setOpenRemarks(true);
        }
        //history.push(`/application/${applicationId}/save-application`);
    };

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };

    const changePostalCodeTwo = (e) => {
        setPostalCodeTwo(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    provinceTwo: item[0].state,
                    cityTwo: item[0].city,
                });
            }
        }
    };



    const {
        relationship,
        relationshipTwo,
        salutation,
        salutationTwo,
        residentalStatus,
        residentalStatusTwo,
        name,
        nameTwo,
        birthday,
        birthdayTwo,
        gender,
        genderTwo,
        phone,
        phoneTwo,
        email,
        emailTwo,
        country,
        countryTwo,
        province,
        provinceTwo,
        cityTwo,
        city,
        address,
        addressTwo,
        yearsLiving,
        yearsLivingTwo,
    } = allValues;

    useEffect(() => {
        if (cityTwo.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === cityTwo
            );
            if (item.length > 0) {
                setPostalCodeTwo(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    provinceTwo: item[0].state
                })
            }
        }

    }, [cityTwo, provinceTwo])

    useEffect(() => {
        if (city.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if (item.length > 0) {
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    }, [city, province])

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS) || validationForNumber(value, 0);
        });

        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE) || validationForNumber(value, 0);
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
    }, []);

    useEffect(() => {
        if (referenceInfo && referenceInfo.id && createAction) {
            history.push(
                `/client/${clientId}/application/${applicationId}/guarantor`
            );
        }
    }, [referenceInfo]); // eslint-disable-line react-hooks/exhaustive-deps
    const [maxDate, setMaxDate] = useState("");

    useEffect(() => {
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        var day = new Date().getDate();
        var date = new Date(year - 18, month, day);
        setMaxDate(date);
        setAllValues({ ...allValues, birthday: date, birthdayTwo: date });
    }, []);
    const handleDateChange = (date) => {
        setAllValues({ ...allValues, birthday: date });
    };
    const handleDateChange2 = (date) => {
        setAllValues({ ...allValues, birthdayTwo: date });
    };
    return (
        <main className={classes.layout}>
            <LoaderComponent
                isLoading={isLoadingCreateReference}
            />
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    {t('CAPTURE_REFERENCE_INFO') || "Capture reference information"}
                </Typography>
                <Typography
                    className={classes.titleLabel}>
                    {t('REFERENCE_1')} {APP_CONFIGS.REFERENCE_OPTIONAL ? '(Optional)' : ''}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('REFERENCE_1')||"Reference 1"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RELATIONSHIP') || "Relationship with applicant"}
                                            </InputLabel>
                                            <Select
                                                id="relationshipOne"
                                                name="relationship"
                                                value={relationship}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    RELATIONSHIP_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('SALUTATION') || "Salutation"}</InputLabel>
                                            <Select
                                                id="salutationOne"
                                                name="salutation"
                                                value={salutation}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    SALUTATION_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="nameOne"
                                            name="name"
                                            value={name}
                                            label={t('FULL_NAME') || "Full name"}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidFullName",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid full name",
                                                //"this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB') || "Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthdayOne"
                                                value={birthday}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            style={{ marginTop: "16px" }}
                                        >
                                            <InputLabel>{t('GENDER') || "Gender"}</InputLabel>
                                            <Select
                                                name="gender"
                                                id="genderOne"
                                                value={gender}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    GENDER_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="phone"
                                            id="phoneOne"
                                            value={phone}
                                            label={t('MOBILE') || "Mobile number"}
                                            onChange={changeHandler}
                                            type="number"
                                            validators={[
                                                "isValidMobileNumber",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid mobile number",

                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="email"
                                            id="emailOne"
                                            label={t('EMAIL') || "Email address (optional)"}
                                            value={email}
                                            onChange={changeHandler}
                                            validators={["isEmail"]}
                                            errorMessages={[
                                                "Please enter a valid email address",
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RESIDENTIAL_STATUS') || "Residential status"}
                                            </InputLabel>
                                            <Select
                                                id="residentialStatusOne"
                                                name="residentalStatus"
                                                value={residentalStatus}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    RESIDENTIAL_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('YEARS_AT_CURRENT_ADDRESS') || "Years at current address"}
                                            </InputLabel>
                                            <Select
                                                id="yearsLivingOne"
                                                name="yearsLiving"
                                                value={yearsLiving}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    YEARS_CURRENT_ADDRESS_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="countryOne"
                                            name="country"
                                            label={t('COUNTRY') || "Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            id="postalCodeOne"
                                            name="postalCode"
                                            label={t('POSTAL_CODE') || "Postal code"}
                                            type="number"
                                            value={postalCode}
                                            onChange={changePostalCode}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                            validators={[
                                                "isValidPostalCode",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE') || "District / Province"}
                                            </InputLabel>
                                            <Select
                                                id="provinceOne"
                                                name="province"
                                                value={province}
                                                onChange={changeHandler}
                                            // required
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN') || "City / Town"}</InputLabel>
                                            <Select
                                                id="cityOne"
                                                name="city"
                                                value={city}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                            id="addressOne"
                                            name="address"
                                            label={t('ADDRESS') || "Address"}
                                            value={address}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidAddress",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}>
                            {t('REFERENCE_2')} {APP_CONFIGS.REFERENCE_OPTIONAL ? '(Optional)' : ''}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('REFERENCE_2')||"Reference 2"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RELATIONSHIP') || "Relationship with applicant"}
                                            </InputLabel>
                                            <Select
                                                id="relationshipTwo"
                                                name="relationshipTwo"
                                                value={relationshipTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    RELATIONSHIP_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('SALUTATION') || "Salutation"}</InputLabel>
                                            <Select
                                                id="salutationTwo"
                                                name="salutationTwo"
                                                value={salutationTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    SALUTATION_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="nameTwo"
                                            name="nameTwo"
                                            value={nameTwo}
                                            label={t('FULL_NAME') || "Full name"}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidFullName",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid full name",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB') || "Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthdayTwo"
                                                value={birthdayTwo}
                                                onChange={handleDateChange2}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            style={{ marginTop: "16px" }}
                                        >
                                            <InputLabel>{t('GENDER') || "Gender"}</InputLabel>
                                            <Select
                                                id="genderTwo"
                                                name="genderTwo"
                                                value={genderTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    GENDER_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="phoneTwo"
                                            name="phoneTwo"
                                            value={phoneTwo}
                                            label={t('MOBILE') || "Mobile number"}
                                            onChange={changeHandler}
                                            type="number"
                                            validators={[
                                                "isValidMobileNumber",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid mobile number",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="emailTwo"
                                            name="emailTwo"
                                            label={t('EMAIL') || "Email address (optional)"}
                                            value={emailTwo}
                                            onChange={changeHandler}
                                            validators={["isEmail"]}
                                            errorMessages={[
                                                "Please enter a valid email address",
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RESIDENTIAL_STATUS') || "Residential status"}
                                            </InputLabel>
                                            <Select
                                                id="residentialStatusTwo"
                                                name="residentalStatusTwo"
                                                value={residentalStatusTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    RESIDENTIAL_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('YEARS_AT_CURRENT_ADDRESS') || "Years at current address"}
                                            </InputLabel>
                                            <Select
                                                id="yearsLivingTwo"
                                                name="yearsLivingTwo"
                                                value={yearsLivingTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    YEARS_CURRENT_ADDRESS_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="countryTwo"
                                            name="countryTwo"
                                            label={t('COUNTRY') || "Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            id="postalCodeTwo"
                                            name="postalCodeTwo"
                                            label={t('POSTAL_CODE') || "Postal code"}
                                            type="number"
                                            value={postalCodeTwo}
                                            onChange={changePostalCodeTwo}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                            validators={[
                                                "isValidPostalCode",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE') || "District / Province"}
                                            </InputLabel>
                                            <Select
                                                id="provinceTwo"
                                                name="provinceTwo"
                                                value={provinceTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN') || "City / Town"}</InputLabel>
                                            <Select
                                                id="cityTwo"
                                                name="cityTwo"
                                                value={cityTwo}
                                                onChange={changeHandler}
                                                required={!APP_CONFIGS.REFERENCE_OPTIONAL}
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                            id="addressTwo"
                                            name="addressTwo"
                                            label={t('ADDRESS') || "Address"}
                                            value={addressTwo}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidAddress",
                                                // "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                // "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>

                        <div className={classes.buttonWrapper}>
                            <Button
                                id="saveBtn"
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                onClick={handleSaveExit}
                            >
                                {t('SAVE_AND_EXIT') || "Save & Exit"}
                            </Button>
                            <Button
                                id="nextBtn"
                                style={{
                                    backgroundColor: "#5F56A6"
                                }}
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                type="submit"
                            >
                                {t('NEXT') || "Next"}
                            </Button>
                        </div>
                    </ValidatorForm>
                    <AddRemarksDialogBoxConatiner classes={classes} applicationId={applicationId} open={openRemarks} currentUser={currentUser} link={saveAndExitLink} />
                </Fragment>
            </div>
        </main>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(ApplicationList);
