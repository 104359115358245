import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthenticationContainer from "./components/authentication/AuthenticationContainer";
import AccessControl from "./components/authentication/AccessControl";
import AuthRequired from "./components/authentication/AuthRequired";
import AuthListener from "./components/authentication/AuthListener";
import DashboardContainer from "./components/dashboard/DashboardContainer";
import CreateCustomerContainer from "./components/customer/CreateCustomerContainer";
import EmiContainer from "./components/customer/EmiContainer";
import SelectVehicleContainer from "./components/select_vehicle/SelectVehicleContainer";
import PaymentContainer from "./components/payment/PaymentContainer";
import ConfirmLoanIsSavedContainer from "./components/static_components/ConfirmLoanIsSavedContainer";
import ConfirmAdditionalInformation from "./components/static_components/ConfirmAdditionalInformationContainer";
import ThankyouAfterSelectingPayment from "./components/static_components/ThankyouAfterSelectingPayment";
import CustomerInformationContainer from "./components/customer_information/CustomerInformationContainer";
import SupportingDocumentContainer from "./components/customer_information/SupportingDocumentContainer";
import CustomerReferenceContainer from "./components/customer_reference/CustomerReferenceContainer";
import GuarantorContainer from "./components/application_guarantor/GuarantorContainer";
import { LoanOfferContainer } from "./components/loan_offer";
import { ApplicationListContainer } from "./components/application_list";
import { ApplicationDetailContainer } from "./components/application_detail";
import { SinglePaymentContainer } from "./components/application_payment_detail";
import Layout from "./components/layout/Layout";
import CompleteApplication from "./components/static_components/CompleteApplication";
import LoanApplicationErrorContainer from "./components/static_components/LoanApplicationErrorContainer";
import ManagementDashboardContainer from "./components/management_dashboard/ManagementDashboardContainer";
import { SelectVehicleContainerEnquiry } from "./components/application_detail/SelectVehicleEnquiryCotinuation";
//import {ApplicationUpdateContainer} from "./components/application_update";
const AuthRequiredContainer = () => (
    <AuthRequired>
        <AuthListener>
        <Switch>
            <Layout>
                <AccessControl>
                    <Switch>
                        <Route
                            component={DashboardContainer}
                            path="/dashboard"
                        />
                        <Route
                            component={CreateCustomerContainer}
                            path="/customer/create"
                        />
                        <Route
                            component={SelectVehicleContainer}
                            path="/client/:clientId/select-vehicle"
                        />
                        <Route
                            component={PaymentContainer}
                            path="/client/:clientId/application/:applicationId/payment"
                        />
                        <Route
                            component={ApplicationListContainer}
                            path="/applications"
                        />
                        <Route
                            component={LoanOfferContainer}
                            path="/application/:applicationId/loan-offer"
                        />
                        <Route
                            component={EmiContainer}
                            path="/application/:applicationId/emi"
                        />
                        <Route
                            component={ThankyouAfterSelectingPayment}
                            path="/application/:applicationId/cash-payment"
                        />
                        <Route
                            component={CompleteApplication}
                            path="/application/:applicationId/complete"
                        />
                        <Route
                            component={ConfirmAdditionalInformation}
                            path="/application/:applicationId/ask-additional-infomation"
                        />
                        <Route
                            component={LoanApplicationErrorContainer}
                            path="/application/:applicationId/reject-application"
                        />
                        <Route
                            component={ConfirmLoanIsSavedContainer}
                            path="/application/:applicationId/save-application"
                        />
                        <Route
                            component={SinglePaymentContainer}
                            path="/application/:applicationId/payment-detail/:paymentId"
                        />

                        <Route
                            component={ApplicationDetailContainer}
                            path="/application/:applicationId"
                        />
         
                        <Route
                            component={CustomerInformationContainer}
                            path="/client/:clientId/application/:applicationId/client-information"
                        />
                        <Route
                            component={CustomerReferenceContainer}
                            path="/client/:clientId/application/:applicationId/reference"
                        />
                        <Route
                            component={GuarantorContainer}
                            path="/client/:clientId/application/:applicationId/guarantor"
                        />
                        <Route
                            component={SupportingDocumentContainer}
                            path="/client/:clientId/application/:applicationId/supporting-document"
                        />
                        <Route
                            component={GuarantorContainer}
                            path="/client/:clientId/application/:applicationId/additional-guarantor"
                        />
                        <Route
                            component={ManagementDashboardContainer}
                            path="/management/dashboard"
                        />
                        <Route
                            component={SelectVehicleContainerEnquiry}
                            path="/client/:clientId/selectVehicle/:applicationId/continue"
                        />
                    </Switch>
                </AccessControl>
            </Layout>
        </Switch>
        </AuthListener>
        
    </AuthRequired>
);

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Switch>
                    <Redirect exact from="/" to="/login" />
                    <Route component={AuthenticationContainer} path="/login" />
                    <Route component={AuthRequiredContainer} path="/" />
                </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
