import { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Alert } from "@material-ui/lab";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import {
    LOAN_MONTH_OPTIONS,
    BANK_OPTIONS,
    REPAYMENT_METHOD_OPTIONS,
    EMI_OPTIONS,
    APP_CONFIGS
} from "../../constants/appConstants";
import {
    validationForDownPayment,
    validationForUtilizationRate,
} from "../../utils/formValidation";
import { currencyFormat } from "../../utils/helper";
import LoaderComponent from "../connected_components/Loader";
import { Link } from "@material-ui/core";
import { AddRemarksDialogBoxConatiner } from "../static_components";
import { ProcessingFeesDialogBoxConatiner } from "../static_components";

const EmiComponent = (props) => {
    const {
        classes,
        calculateEmiRequest,
        history,
        emiSummary,
        getApplicationById,
        applicationDetail,
        //totalCost,
        createLoan,
        loanInfo,
        applicationVehicle,
        calculateCde,
        cdeInfo,
        updateApplicationStatus,
        isLoadingCalculateEmi,
        isLoadingCreateLoan,
        isLoadingCalculateCde,
        currentUser,
        getDiscount,
        discount,
        createFees
    } = props;

    const { applicationId } = props.match.params;
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [customerChecked, setCustomerChecked] = useState(false);
    const [openAlert, setInfoAlert] = useState(false);
    const [loanYears, setLoanYears] = useState(6);
    const [rate, setRate] = useState("");
    const [rateType, setRateType] = useState("");
    const [openRemarks, setOpenRemarks] = useState(false);
    const [saveAndExitLink, setSaveAndExitLink] = useState(`/application/${applicationId}/save-application`);
    const [createAction, setCreateAction] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [processingFeeDialogOpen, setProcessingFeeDialogOpen] = useState(false);
    const { t } = useTranslation();

    const [allValues, setAllValues] = useState({
        downPayment: 0,
        bankName: "",
        repaymentmethod: "",
        processingFee: 0.0,
        loanAmount: "",
        numberOfPayments: "",
        monthlyPayment: "",
        totalCost: "",
        otherMethod: ""
    });

    const [feesValue, setFeesValue] = useState({
        earlySettlementFees: 0.0,
        additionalCharges: 0.0,
        documentationFees: 0.0,
        stampDutyCharges: 0.0,
        loanRebookCharges: 0.0,
        chequeBounceCharges: 0.0,
        chequeSwapCharges: 0.0,
        exchangeCharges: 0.0
    })

    const [allFees, setAllFees] = useState({
        processingFeeChecked: true,
        earlySettlementFeeChecked: false,
        additionalChargesChecked: false,
        documentationFeesChecked: true,
        stampDutyChargesChecked: false,
        loanRebookChargesChecked: false,
        chequeBounceChargesChecked: false,
        chequeSwapChargesChecked: false,
        exchangeChargesChecked: false
    })

    const [visibleFees, setVisibleFees] = useState({
        processingFeeVisible: true,
        earlySettlementFeeVisible: false,
        additionalChargesVisible: false,
        documentationFeesVisible: true,
        stampDutyChargesVisible: false,
        loanRebookChargesVisible: false,
        chequeBounceChargesVisible: false,
        chequeSwapChargesVisible: false,
        exchangeChargesVisible: false
    })

    const {
        loanAmount,
        downPayment,
        bankName,
        repaymentmethod,
        processingFee,
        otherMethod
    } = allValues;

    const {
        processingFeeChecked,
        earlySettlementFeeChecked,
        additionalChargesChecked,
        documentationFeesChecked,
        stampDutyChargesChecked,
        loanRebookChargesChecked,
        chequeBounceChargesChecked,
        chequeSwapChargesChecked,
        exchangeChargesChecked
    } = allFees;

    const {
        processingFeeVisible,
        earlySettlementFeeVisible,
        additionalChargesVisible,
        documentationFeesVisible,
        stampDutyChargesVisible,
        loanRebookChargesVisible,
        chequeBounceChargesVisible,
        chequeSwapChargesVisible,
        exchangeChargesVisible
    } = visibleFees;

    const {
        earlySettlementFees,
        additionalCharges,
        documentationFees,
        stampDutyCharges,
        loanRebookCharges,
        chequeBounceCharges,
        chequeSwapCharges,
        exchangeCharges
    } = feesValue;

    const handleFees = (e) => {
        setFeesValue({
            ...feesValue,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdateCheck = (e) => {
        setAllFees({
            ...allFees,
            [e.target.name]: e.target.checked,
        });
    }


    const handleVisibleFees = () => {
        setVisibleFees({
            ...visibleFees,
            processingFeeVisible: processingFeeChecked,
            earlySettlementFeeVisible: earlySettlementFeeChecked,
            additionalChargesVisible: additionalChargesChecked,
            documentationFeesVisible: documentationFeesChecked,
            stampDutyChargesVisible: stampDutyChargesChecked,
            loanRebookChargesVisible: loanRebookChargesChecked,
            chequeBounceChargesVisible: chequeBounceChargesChecked,
            chequeSwapChargesVisible: chequeSwapChargesChecked,
            exchangeChargesVisible: exchangeChargesChecked
        })
        setProcessingFeeDialogOpen(false);
    }

    const handleCancelVisibleFees = () => {
        setAllFees({
            ...allFees,
            processingFeeChecked: processingFeeVisible,
            earlySettlementFeeChecked: earlySettlementFeeVisible,
            additionalChargesChecked: additionalChargesVisible,
            documentationFeesChecked: documentationFeesVisible,
            stampDutyChargesChecked: stampDutyChargesVisible,
            loanRebookChargesChecked: loanRebookChargesVisible,
            chequeBounceChargesChecked: chequeBounceChargesVisible,
            chequeSwapChargesChecked: chequeSwapChargesVisible,
            exchangeChargesChecked: exchangeChargesVisible
        })
        setProcessingFeeDialogOpen(false);
    }

    const handleTenorChange = (e) => {
        setLoanYears(e.target.value);
    };

    const handleRateTypeChange = (e) => {
        setRateType(e.target.value);
    };

    const handleRateChange = (e) => {
        setRate(e.target.value);
    };

    const handleCheckedChange = (e) => {
        setCustomerChecked(e.target.checked);
    };

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const changeDownPayment = (e) => {
        const remainingAmount = totalCost - e.target.value;
        setAllValues({ ...allValues, downPayment: e.target.value });
        setTotalLoanAmount(remainingAmount);
    };

    const handleSubmit = () => {
        const isFlatRate = rateType === "flat" ? true : false;
        const data = {
            applicationId: applicationId,
            downPayment: parseInt(downPayment),
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            processingFee: processingFee,
            paymentMethod: repaymentmethod,
            bankName: bankName,
            flatRate: isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            reducingRate: !isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            monthlyPayment: emiSummary.monthlyPayment
                ? emiSummary.monthlyPayment
                : parseFloat(0),
            numberOfPayments: emiSummary.numberOfPayments
                ? emiSummary.numberOfPayments
                : parseFloat(0),
            totalInterest: emiSummary.totalInterest
                ? emiSummary.totalInterest
                : parseFloat(0),
            totalCost: emiSummary.totalCost
                ? emiSummary.totalCost
                : parseFloat(0),
            otherMethod: otherMethod
        };
        createLoan(data);
        let params = {
            applicationId,
            processingFees: processingFee,
            documentationFees,
            earlySettlementFees,
            additionalCharges,
            stampDutyCharges,
            loanRebookCharges,
            chequeSwapCharges,
            chequeBounceCharges,
            exchangeCharges
        }
        createFees(params);
        setCreateAction(true);
    };

    const calcuateEmi = () => {
        const flatRateData = {
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            rate: parseFloat(rate / 100),
            isFlatRate: true,
            downPayment: parseInt(downPayment),
        };
        const reducingRateData = {
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            rate: parseFloat(rate / 100),
            isFlatRate: false,
            downPayment: parseInt(downPayment),
        };

        const data = rateType === "flat" ? flatRateData : reducingRateData;
        calculateEmiRequest(data);
    };

    useEffect(() => {
        getDiscount(applicationId);
        getApplicationById(applicationId);
    }, [applicationId, getApplicationById]);

    useEffect(() => {
        if (loanInfo && loanInfo.id) {
            calculateCde({ applicationId });
        }
    }, [loanInfo]);

    useEffect(() => {
        if (totalLoanAmount && loanYears && rateType && rate.length > 1) {
            calcuateEmi();
        }
    }, [totalLoanAmount, loanYears, rateType, rate]);

    useEffect(() => {
        if (discount && discount.discountedPrice > 0) {
            setTotalLoanAmount(discount.discountedPrice);
            setTotalCost(discount.discountedPrice);
        } else if (applicationDetail && applicationDetail.totalCost) {
            setTotalLoanAmount(applicationDetail.totalCost);
            setTotalCost(discount.discountedPrice);
        }
    }, [applicationDetail, discount]);

    useEffect(() => {
        if (discount && discount.discountedPrice > 0) {
            setTotalLoanAmount(discount.discountedPrice);
            setTotalCost(discount.discountedPrice);
        } else if (applicationVehicle && applicationVehicle.id) {
            setTotalLoanAmount(applicationVehicle.price);
            setTotalCost(discount.discountedPrice);
        }
    }, [applicationVehicle, discount]);

    const handleInfoAlertClose = () => setInfoAlert(false);

    const handleSaveExit = () => {
        const isFlatRate = rateType === "flat" ? true : false;
        const data = {
            applicationId: applicationId,
            downPayment: parseInt(downPayment),
            loanAmount: parseInt(totalLoanAmount),
            loanYears: parseInt(loanYears),
            processingFee: processingFee,
            paymentMethod: repaymentmethod,
            bankName: bankName || '',
            flatRate: isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            reducingRate: !isFlatRate ? parseFloat(rate / 100) : parseFloat(0),
            monthlyPayment: emiSummary.monthlyPayment
                ? emiSummary.monthlyPayment
                : parseFloat(0),
            numberOfPayments: emiSummary.numberOfPayments
                ? emiSummary.numberOfPayments
                : parseFloat(0),
            totalInterest: emiSummary.totalInterest
                ? emiSummary.totalInterest
                : parseFloat(0),
            totalCost: emiSummary.totalCost
                ? emiSummary.totalCost
                : parseFloat(0),
        };
        setOpenRemarks(true);
        createLoan(data);
        let params = {
            applicationId: applicationId,
            processingFees: processingFee,
            earlySettlementFees,
            additionalCharges,
            documentationFees,
            stampDutyCharges,
            loanRebookCharges,
            chequeSwapCharges,
            chequeBounceCharges,
            exchangeCharges
        }
        createFees(params);
        //history.push(`/application/${applicationId}/save-application`);
    };

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidDownPayment", (value) => {
            return validationForDownPayment(value, totalCost);
        });

        ValidatorForm.addValidationRule(
            "isValidReducingUtilizationRate",
            (value) => {
                return validationForUtilizationRate(value, rateType);
            }
        );

        ValidatorForm.addValidationRule("isValidProcessingFee", (value) => {
            return processingFee >= 0;
        });
    });

    useEffect(() => {
        const { score } = cdeInfo;
        if (score) {
            const params = {
                applicationId: applicationId,
                data: {
                    cdeScore: score,
                },
            };
            updateApplicationStatus(params);
        }

        if (cdeInfo && cdeInfo.score && !isEmpty(cdeInfo.critical_comments) && createAction) {
            history.push(
                `/application/${applicationId}/ask-additional-infomation`
            );
        } else if (
            cdeInfo &&
            cdeInfo.score &&
            isEmpty(cdeInfo.critical_comments) && createAction
        ) {
            history.push(
                `/application/${applicationId}/ask-additional-infomation`
            );
        } else if (
            cdeInfo &&
            !cdeInfo.score &&
            !isEmpty(cdeInfo.criteria_failure) && createAction
        ) {
            history.push(`/application/${applicationId}/reject-application`);
        }
    }, [cdeInfo]);

    return (
        <main className={classes.layout}>
            {openAlert && (
                <div className="info-alert">
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={handleInfoAlertClose}
                    >
                        There is error to calculate CDE score
                    </Alert>
                </div>
            )}
            <LoaderComponent isLoading={isLoadingCreateLoan || isLoadingCalculateCde} />
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    {t('SET_UP_REPAYMENT') || "Set up repayment"}
                </Typography>
                <Typography
                    className={classes.titleLabel}>
                    {t('REPAYMENT') || "Repayment"}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                        InputLabelProps={{ required: false }}
                                            name="downPayment"
                                            value={downPayment}
                                            type="number"
                                            label={t('DOWNPAYMENT')+" "+`(${APP_CONFIGS.CURRENCY})`}
                                            fullWidth
                                            onChange={changeDownPayment}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: totalCost,
                                                },
                                            }}
                                            required
                                            validators={[
                                                "isValidDownPayment",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid down payment amount",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} id="loanMonths">
                                        <FormControl
                                            className={classes.formControl}
                                            id="loanMonths"
                                        >
                                            <InputLabel>
                                                {t('TENURE') || "Tenure(months)"}
                                            </InputLabel>
                                            <Select
                                                name="loanYears"
                                                value={loanYears}
                                                onChange={handleTenorChange}
                                                required
                                            >
                                                {map(
                                                    LOAN_MONTH_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('FUND_UTILIZATION_TYPE') || "Fund utilization type"}
                                            </InputLabel>
                                            <Select
                                                id="rateType"
                                                name="rateType"
                                                value={rateType}
                                                onChange={handleRateTypeChange}
                                                required
                                            >
                                                {map(
                                                    EMI_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="rate"
                                            name="rate"
                                            label={t('FUND_UTILIZATION_RATE') || "Reducing fund utilization rate (%)"}
                                            value={rate}
                                            type="number"
                                            onChange={handleRateChange}
                                            fullWidth
                                            InputProps={{
                                                inputProps: {
                                                    min: 6,
                                                    max: 100,
                                                },
                                            }}
                                            validators={[
                                                "isValidReducingUtilizationRate",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid fund utilization rate. Minimum = 12% for flat rate,  12% for reducing rate",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    </Grid>
                                    </div>
                                    <div className={classes.paperContentColoured}>
                                    <Grid container spacing={4}>
                                    <Grid item xs={12} sm={8}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_LOAN_AMOUNT') + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                            style={{textAlign:"right"}}
                                        >
                                            {currencyFormat(totalLoanAmount)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_FUND_UTILIZATION_AMOUNT')+ " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                            style={{textAlign:"right"}}
                                        >
                                            {emiSummary && emiSummary.totalInterest
                                                ? currencyFormat(
                                                    emiSummary.totalInterest
                                                )
                                                : 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('MONTHLY_REPAYMENT') + " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                            style={{textAlign:"right"}}
                                        >
                                            {emiSummary && emiSummary.monthlyPayment
                                                ? currencyFormat(
                                                    emiSummary.monthlyPayment
                                                )
                                                : 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('TOTAL_REPAYMENT_AMOUNT')+ " "+`(${APP_CONFIGS.CURRENCY})`}
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                            style={{textAlign:"right"}}
                                        >
                                            {emiSummary && emiSummary.totalInterest
                                                ? currencyFormat(
                                                    totalLoanAmount +
                                                    emiSummary.totalInterest
                                                )
                                                : 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}>
                            {t('REPAYMENT_METHOD') || "Repayment method"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                            classes={classes}
                                            text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                        /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('REPAYMENT_METHOD') || "Repayment method"}
                                            </InputLabel>
                                            <Select
                                                id="repaymentMethod"
                                                name="repaymentmethod"
                                                value={repaymentmethod}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    REPAYMENT_METHOD_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('BANK_NAME') || "Bank name"}</InputLabel>
                                            <Select
                                                id="bankName"
                                                name="bankName"
                                                value={bankName}
                                                onChange={changeHandler}
                                                disabled={repaymentmethod==1 || repaymentmethod==9 ?true:false}
                                                //required
                                            >
                                                {map(
                                                    BANK_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {repaymentmethod==9 && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="otherMethod"
                                            value={otherMethod}
                                            label={t('Specify other')}
                                            type="text"
                                            fullWidth
                                            onChange={changeHandler}
                                        />
                                    </Grid>}
                                            </Grid>
                                        </div>
                                    </Paper>
                                    <Typography
                                        className={classes.titleLabel}>
                                            {t('PROCESSING_DETAILS')||"Processing Details"}
                                            <span style={{float:'right',fontSize:'16px'}}><Link onClick={()=> setProcessingFeeDialogOpen(true)}>Configure</Link></span>
                                    </Typography>
                                    <Paper className={classes.paper}>
                                        {/* <PaperTitle
                                            classes={classes}
                                            text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                        /> */}
                                        <div className={classes.paperContent}>
                                            <Grid container spacing={4}>

                                            {processingFeeVisible && <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        name="processingFee"
                                        label={t('PROCESSING_FEE')||"Processing fee"}
                                        value={processingFee}
                                        onChange={changeHandler}
                                        fullWidth
                                        type="number"
                                        validators={["isValidProcessingFee"]}
                                        errorMessages={[
                                            "Please enter a valid processing fee",
                                        ]}
                                    />
                                </Grid>}
                                {documentationFeesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="documentationFees"
                                        label={"Documentation Fees"}
                                        value={documentationFees}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                    </Grid>}
                               {earlySettlementFeeVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="earlySettlementFees"
                                        label={"Early Settlement Fee"}
                                        value={earlySettlementFees}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                    />
                                </Grid>}
                                {additionalChargesVisible && <Grid item xs={12} sm={6}>
                                <TextValidator
                                        name="additionalCharges"
                                        label={"Additional Charges"}
                                        value={additionalCharges}
                                        onChange={handleFees}
                                        fullWidth
                                        type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                    {stampDutyChargesVisible && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="stampDutyCharges"
                                            label={"Stamp Duty Charges"}
                                            value={stampDutyCharges}
                                            onChange={handleFees}
                                            fullWidth
                                            type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                    {loanRebookChargesVisible && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="loanRebookCharges"
                                            label={"Loan Rebook Charges"}
                                            value={loanRebookCharges}
                                            onChange={handleFees}
                                            fullWidth
                                            type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                    {chequeBounceChargesVisible && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="chequeBounceCharges"
                                            label={"Cheque Bounce Charges"}
                                            value={chequeBounceCharges}
                                            onChange={handleFees}
                                            fullWidth
                                            type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                    {chequeSwapChargesVisible && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="chequeSwapCharges"
                                            label={"Cheque Swap Charges"}
                                            value={chequeSwapCharges}
                                            onChange={handleFees}
                                            fullWidth
                                            type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                    {exchangeChargesVisible && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="exchangeCharges"
                                            label={"Exchange Charges"}
                                            value={exchangeCharges}
                                            onChange={handleFees}
                                            fullWidth
                                            type="number"
                                        // required
                                        // validators={["isValidProcessingFee"]}
                                        // errorMessages={[
                                        //     "Please enter a valid processing fee",
                                        // ]}
                                        />
                                    </Grid>}
                                </Grid>
                            </div>
                        </Paper>
                        <div className={classes.flexWrapper}>
                            <div
                                style={{
                                    marginBottom: "48px",
                                    marginTop: "8px",
                                    maxWidth: "80%",

                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="customerChecked"
                                            checked={customerChecked}
                                            onChange={handleCheckedChange}
                                            style={{ color: '#5F56A6' }}
                                        />
                                    }
                                    label={t('INFORMATION_DECLAIMED') || "Customer has declaimed that all information is correct before submitting this loan application."}
                                />
                            </div>
                        </div>

                        <div className={classes.buttonWrapper}>
                            <Button
                                id="saveBtn"
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                disabled={!customerChecked}
                                onClick={handleSaveExit}
                            >
                                {t('SAVE_AND_EXIT') || "Save & Exit"}
                            </Button>
                            <Button
                                id="submitBtn"
                                style={{
                                    backgroundColor: "#5F56A6"
                                }}
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                type="submit"
                                disabled={!customerChecked}
                            >
                                {t('SUBMIT') || "Submit"}
                            </Button>
                        </div>
                    </ValidatorForm>
                    <AddRemarksDialogBoxConatiner classes={classes} applicationId={applicationId} open={openRemarks} currentUser={currentUser} link={saveAndExitLink} />
                    <ProcessingFeesDialogBoxConatiner open={processingFeeDialogOpen}
                        handleUpdateCheck={handleUpdateCheck}
                        handleUpdate={handleVisibleFees}
                        handleCancel={handleCancelVisibleFees}
                        allFees={allFees} />
                </Fragment>
            </div>
        </main>
    );
};

export default withStyles(inputStyles)(EmiComponent);
