import { Fragment, useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Card, Box, Toolbar } from "@material-ui/core";
import moment from "moment";
import LoanApplicationStatus from "./LoanApplicationStatus";
import BranchFilter from './BranchFilter'

const CreateApplication = (props) => {
    const {
        classes,
        applicationInfo,
        getCurrentMonthCustomerStats,
        customerStats,
        getApplicationByMonthsStats,
        getApplicationInfo,
        monthlyStats,
        getOutlets,
        outletList
    } = props;

    useEffect(() => {
        getCurrentMonthCustomerStats();
        getApplicationByMonthsStats();
        getApplicationInfo();
        getOutlets();
    }, []);

    const filterByBranch = (option) => {
        const param = { branchId: option.branchId};
        getCurrentMonthCustomerStats(param)
    }

    const filterLoanByBranch = option => {
        const param = { branchId: option.branchId};
        getApplicationInfo(param);
    }

    const { Cash, Credit, Loan, Count } = customerStats;
    return (
        <main className={classes.layout}>
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h2"
                    variant="h4"
                >
                    Management Dashboard
                </Typography>
                <Typography
                    className={classes.subTitle}
                    component="h6"
                    gutterBottom
                >
                    As of today, {moment().format("DD MMMM YYYY")} at{" "}
                    {moment().format("h:ma")}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <Paper className={classes.paper}>
                        <PaperTitle
                            classes={classes}
                            text={"Current Month Customer Visits "}
                            outletList={outletList}
                            filterByBranch={filterByBranch}
                        />
                        <div className={classes.paperContent}>
                            <Grid container spacing={4}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Card className={classes.grayCard}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            Newly recorded customers
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.count}
                                        >
                                            {Count || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Card className={classes.grayCard}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            Customers paid by cash
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.count}
                                        >
                                            {Cash || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Card className={classes.grayCard}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            Customers paid by credit card
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.count}
                                        >
                                            {Credit || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.greyBox}
                                >
                                    <Card className={classes.grayCard}>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            Customers applied loan
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.count}
                                        >
                                            {Loan || 0}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <LoanApplicationStatus
                        applicationInfo={applicationInfo}
                        monthlyStats={monthlyStats}
                        classes={classes}
                        outletList={outletList}
                        filterByBranch={filterLoanByBranch}
                    />
                </Fragment>
            </div>
        </main>
    );
};

const PaperTitle = (props) => {
    const { classes, text, outletList, filterByBranch } = props;
    return (
        <Toolbar className={classes.managementBox}>
            <Typography className={classes.boxLabel}>{text}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <BranchFilter classes={classes} outletList={outletList} filterByBranch={filterByBranch} />
        </Toolbar>
    );
};


export default withStyles(inputStyles)(CreateApplication);
