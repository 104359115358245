import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PersonIcon from "@material-ui/icons/Person";
import Link from "@material-ui/core/Link";
import { map, filter } from "lodash";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ID_TYPE_OPTIONS } from "../../../constants/appConstants";
import {
    validationForTextField,
    validationForNumber,
    validationForDate,
    validationForVehicle,
} from "../../../utils/formValidation";
import { AppBar, Toolbar } from "@material-ui/core";
import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    ADDRESS_PROOF_OPTIONS,
    RELATIONSHIP_OPTIONS,
    APP_CONFIGS
} from "../../../constants/appConstants";
import { mapDistrictsOptions, mapCityOptions } from "../../../utils/helper";
import { Box, Card } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import get from "lodash/get";
import {
    getEmploymentTypes,
    getEmploymentTypes1,
    getIndustryType,
    createGuarantor,
    getIndustryType1,
    calculateCde
} from "../../../store/actions/customer";
import { getCities } from "../../../store/actions/common";
import { createCustomerDocument } from "../../../store/actions/customer";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import LoaderComponent from "../../connected_components/Loader";

const GuarantorUpdateComponent = (props) => {
    const {
        classes,
        getCities,
        cityList,
        employmentTypeList,
        employmentTypeList1,
        getEmploymentTypes,
        getEmploymentTypes1,
        getIndustryType,
        getIndustryType1,
        industryTypeList,
        industryTypeList1,
        guarantorList,
        handleChangeTab,
        confirmSubmit,
        updateApplicationGuarantor,
        createGuarantor,
        getApplicationById,
        getGuarantorsById,
        updateIdentityDocument,
        updateAddressProof,
        calculateCde,
        isLoadingCalculateCde,
        t
    } = props;
    const [index, setIndex] = useState(1);
    const { applicationId } = props.match.params;
    const urlKeyArr = props.match.path.split("/");
    const urlKey = urlKeyArr[urlKeyArr.length - 1];
    const [idFrontImg, setidFrontImg] = useState("");
    const [idFrontImgUrl, setIdFrontImgUrl] = useState("");
    const [idBackImg, setidBackImg] = useState("");
    const [idBackImgUrl, setIdBackImgUrl] = useState("");
    const [selfPhoto, setSelfPhoto] = useState("");
    const [selfPhotoUrl, setSelfPhotoUrl] = useState("");
    const [employmentTypeId, setEmploymentType] = useState("");
    const [addressProof, setAddressProof] = useState("");
    const [addressProofUrl, setAddressProofUrl] = useState("");
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [docNameShow, setDocNameShow] = useState(false);
    const [docName, setDocName] = useState("");
    const [idFrontImg1, setidFrontImg1] = useState("");
    const [idFrontImgUrl1, setIdFrontImgUrl1] = useState("");
    const [idBackImg1, setidBackImg1] = useState("");
    const [idBackImgUrl1, setIdBackImgUrl1] = useState("");
    const [selfPhoto1, setSelfPhoto1] = useState("");
    const [selfPhotoUrl1, setSelfPhotoUrl1] = useState("");
    const [employmentTypeId1, setEmploymentType1] = useState("");
    const [addressProof1, setAddressProof1] = useState("");
    const [addressProofUrl1, setAddressProofUrl1] = useState("");
    const [districtOptions1, setDistrictOption1] = useState([]);
    const [cityOptions1, setCityOptions1] = useState([]);
    const [postalCode1, setPostalCode1] = useState("");
    const [docNameShow1, setDocNameShow1] = useState(false);
    const [docName1, setDocName1] = useState("");
    const [maxDate, setMaxDate] = useState("");
    const [changedId, setChangedId] = useState(false);
    const [changedIncomeDoc, setChangedIncomeDoc] = useState(false);
    const [changedAddressDoc, setChangedAddressDoc] = useState(false);

    const changeEmploymentType = (e) => {
        setEmploymentType(e.target.value);
        getIndustryType(e.target.value);
    };
    const changeEmploymentType1 = (e) => {
        setEmploymentType1(e.target.value);
        getIndustryType1(e.target.value);
    };
    const handleImageChange = (e) => {
        setidFrontImg(URL.createObjectURL(e.target.files[0]));
        setIdFrontImgUrl(e.target.files[0]);
        setChangedId(true);
    };

    const handleImageChange1 = (e) => {
        setidFrontImg1(URL.createObjectURL(e.target.files[0]));
        setIdFrontImgUrl1(e.target.files[0]);
    };
    const handleBackImageChange = (e) => {
        setidBackImg(URL.createObjectURL(e.target.files[0]));
        setIdBackImgUrl(e.target.files[0]);
        setChangedId(true);
    };
    const handleBackImageChange1 = (e) => {
        setidBackImg1(URL.createObjectURL(e.target.files[0]));
        setIdBackImgUrl1(e.target.files[0]);
    };
    const handleSelfImageChange = (e) => {
        setSelfPhoto(URL.createObjectURL(e.target.files[0]));
        setSelfPhotoUrl(e.target.files[0]);
        setChangedId(true);
    };
    const handleSelfImageChange1 = (e) => {
        setSelfPhoto1(URL.createObjectURL(e.target.files[0]));
        setSelfPhotoUrl1(e.target.files[0]);
    };
    const handleAddressProof = (e) => {
        setDocName(e.target.files[0].name);
        setDocNameShow(true);

        setAddressProof(URL.createObjectURL(e.target.files[0]));
        setAddressProofUrl(e.target.files[0]);
        setChangedAddressDoc(true);
    };
    const handleAddressProof1 = (e) => {
        setDocName1(e.target.files[0].name);
        setDocNameShow1(true);

        setAddressProof1(URL.createObjectURL(e.target.files[0]));
        setAddressProofUrl1(e.target.files[0]);
    };

    const handleDateChange = (date) => {
        setAllValues({ ...allValues, birthday: date });
    };

    const [allValues, setAllValues] = useState({
        relationship: "",
        salutation: "",
        name: "",
        maritalStatus: "",
        dependents: "",
        idTypeId: "1",
        idNumber: "",
        incomeProofTypeId: "",
        monthlyIncome: "",
        birthday: "",
        gender: "",
        phone: "",
        email: "",
        country: APP_CONFIGS.COUNTRY,
        province: "",
        city: "",
        address: "",
        industryId: "",
        employerName: "",
        addressProof: "",
        addressProofTypeId: "",
    });

    const [allValues1, setAllValues1] = useState({
        relationship1: "",
        salutation1: "",
        name1: "",
        maritalStatus1: "",
        dependents1: "",
        idTypeId1: "1",
        idNumber1: "",
        incomeProofTypeId1: "",
        monthlyIncome1: "",
        birthday1: "",
        gender1: "",
        phone1: "",
        email1: "",
        country1: APP_CONFIGS.COUNTRY,
        province1: "",
        city1: "",
        address1: "",
        industryId1: "",
        employerName1: "",
        addressProof1: "",
        addressProofTypeId1: "",
    });

    useEffect(() => {
        getGuarantorsById(applicationId);
    }, []);
    useEffect(() => {
        if (guarantorList !== undefined && guarantorList.length > 0) {
            if (guarantorList[0] !== undefined) {
                setEmploymentType(
                    guarantorList[0].employmentType &&
                        guarantorList[0].employmentType.id
                );
                getIndustryType(
                    guarantorList[0].employmentType &&
                        guarantorList[0].employmentType.id
                );
                setAllValues({
                    ...allValues,
                    id: guarantorList[0].id && guarantorList[0].id,
                    relationship:
                        guarantorList[0].relationShip &&
                        guarantorList[0].relationShip,
                    salutation:
                        guarantorList[0].salutation &&
                        guarantorList[0].salutation,
                    name: guarantorList[0].name && guarantorList[0].name,
                    gender: guarantorList[0].gender && guarantorList[0].gender,
                    phone: guarantorList[0].phone && guarantorList[0].phone,
                    email: guarantorList[0].email && guarantorList[0].email,
                    country: APP_CONFIGS.COUNTRY,
                    birthday: guarantorList[0].birthday && guarantorList[0].birthday,
                    province:
                        guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument.province &&
                        guarantorList[0].addressProofDocument.province,
                    city:
                        guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument.city &&
                        guarantorList[0].addressProofDocument.city,
                    address:
                        guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument.address &&
                        guarantorList[0].addressProofDocument.address,
                    industryId:
                        guarantorList[0].industry &&
                        guarantorList[0].industry.id,
                    idTypeId:
                        guarantorList[0].identityDocument &&
                        guarantorList[0].identityDocument.idTypeId,
                    idNumber:
                        guarantorList[0].identityDocument &&
                        guarantorList[0].identityDocument.idNumber,
                    employerName:
                        guarantorList[0].companyName &&
                        guarantorList[0].companyName,
                    addressProofTypeId:
                        guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument
                            .addressProofTypeId,
                });

                setSelfPhoto(
                    guarantorList[0].identityDocument ?
                        guarantorList[0].identityDocument.selfPhoto : "" 
                );
                setidFrontImg(
                    guarantorList[0].identityDocument ?
                        guarantorList[0].identityDocument.idFrontImg: ""
                );
                setidBackImg(
                    guarantorList[0].identityDocument ?
                        guarantorList[0].identityDocument.idFrontImg: ""
                );
                setPostalCode(
                    guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument.postalCode &&
                        guarantorList[0].addressProofDocument.postalCode
                );
                setAddressProof(
                    guarantorList[0].addressProofDocument.addressProofDocument ?
                        guarantorList[0].addressProofDocument
                            .addressProofDocument: ""
                );
                setDocName(
                    guarantorList[0].addressProofDocument ?
                        guarantorList[0].addressProofDocument
                            .addressProofDocument : ""
                );
                guarantorList[0].addressProofDocument &&
                        guarantorList[0].addressProofDocument
                            .addressProofDocument && setDocNameShow(true);
            }
            if (guarantorList[1] !== undefined) {
                setEmploymentType(
                    guarantorList[1].employmentType &&
                        guarantorList[1].employmentType.id
                );
                setAllValues1({
                    ...allValues1,
                    id1: guarantorList[1].id && guarantorList[1].id,
                    relationship1:
                        guarantorList[1].relationShip &&
                        guarantorList[1].relationShip,
                    salutation1:
                        guarantorList[1].salutation &&
                        guarantorList[1].salutation,
                    name1: guarantorList[1].name && guarantorList[1].name,
                    gender1: guarantorList[1].gender && guarantorList[1].gender,
                    phone1: guarantorList[1].phone && guarantorList[1].phone,
                    email1: guarantorList[1].email && guarantorList[1].email,
                    country1: APP_CONFIGS.COUNTRY,
                    province1:
                        guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument.province &&
                        guarantorList[1].addressProofDocument.province,
                    city1:
                        guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument.city &&
                        guarantorList[1].addressProofDocument.city,
                    address1:
                        guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument.address &&
                        guarantorList[1].addressProofDocument.address,
                    industryId1:
                        guarantorList[1].industry &&
                        guarantorList[1].industry.id,
                    idTypeId1:
                        guarantorList[1].identityDocument &&
                        guarantorList[1].identityDocument.idTypeId,
                    idNumber1:
                        guarantorList[1].identityDocument &&
                        guarantorList[1].identityDocument.idNumber,
                    employerName1:
                        guarantorList[1].companyName &&
                        guarantorList[1].companyName,
                    addressProofTypeId1:
                        guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument
                            .addressProofTypeId,
                });

                setSelfPhoto1(
                    (guarantorList[1].identityDocument &&
                        guarantorList[1].identityDocument.selfPhoto) || ""
                );
                setidFrontImg1(
                    guarantorList[1].identityDocument &&
                        guarantorList[1].identityDocument.idFrontImg
                );
                setidBackImg1(
                    guarantorList[1].identityDocument &&
                        guarantorList[1].identityDocument.idFrontImg 
                );
                setPostalCode1(
                    guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument.postalCode &&
                        guarantorList[1].addressProofDocument.postalCode
                );
                setEmploymentType1(
                    guarantorList[1].employmentType &&
                        guarantorList[0].employmentType.id
                );
                getIndustryType1(
                    guarantorList[1].employmentType &&
                        guarantorList[0].employmentType.id
                );
                setAddressProof1(
                    guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument
                            .addressProofDocument
                );
                setDocName1(
                    guarantorList[1].addressProofDocument &&
                        guarantorList[1].addressProofDocument
                            .addressProofDocument
                );
                setDocNameShow1(true);
            }
        }
    }, [guarantorList]);

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };
    const changeHandler1 = (e) => {
        setAllValues1({ ...allValues1, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, 4);
        });
        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });
        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });
        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });
        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });
        ValidatorForm.addValidationRule("isValidExistingVehicle", (value) => {
            return validationForVehicle(value);
        });
        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
        ValidatorForm.addValidationRule("isValidEmployerName", (value) => {
            return validationForTextField(value);
        });
        ValidatorForm.addValidationRule("isValidIdNumber", (value) => {
            return validationForNumber(value, 13);
        });
    });
    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        getEmploymentTypes();
        getEmploymentTypes1();
    }, []);

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    useEffect(() => {
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        var day = new Date().getDate();
        var date = new Date(year - 18, month, day);

        setMaxDate(date);
    }, []);

    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };
    const changePostalCode1 = (e) => {
        setPostalCode1(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues1({
                    ...allValues,
                    province1: item[0].state,
                    city1: item[0].city,
                });
            }
        }
    };
    const {
        id,
        name,
        relationship,
        birthday,
        gender,
        phone,
        email,
        province,
        idNumber,
        idTypeId,
        salutation,
        address,
        city,
        industryId,
        employerName,
        addressProofTypeId,
    } = allValues;
    const {
        id1,
        name1,
        relationship1,
        birthday1,
        gender1,
        phone1,
        email1,
        province1,
        idNumber1,
        idTypeId1,
        salutation1,
        address1,
        city1,
        industryId1,
        employerName1,
        addressProofTypeId1,
    } = allValues1;

    useEffect(()=>{
        if(city.length>0){
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if(item.length>0){
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    },[city,province])

    useEffect(()=>{
        if(city1.length>0){
            const item = filter(
                cityList,
                (item) => item.city === city1
            );
            if(item.length>0){
                setPostalCode1(item[0].postalCode);
                setAllValues1({
                    ...allValues1,
                    province1: item[0].state
                })
            }
        }

    },[city1,province1])

    const handleSubmit = () => {
        confirmSubmit();
    };

    function closeDialog() {
        setDialogOpen(false);
    }
    function closeDialog1() {
        setDialogOpen1(false);
    }
    function changeToTwo() {
        setIndex(2);
    }
    function changeToOne() {
        setIndex(1);
    }
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpen1, setDialogOpen1] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);
    const [fileIndex1, setFileIndex1] = useState(0);
    const removeSelfPhoto = (e) => {
        setFileIndex(1);
        setDialogOpen(true);
    };
    const removeSelfPhoto1 = (e) => {
        setFileIndex1(1);
        setDialogOpen1(true);
    };
    const removeFile = (index) => {
        if (index === 1) {
            setSelfPhotoUrl("");
            setSelfPhoto("");
        } else if (index === 2) {
            setIdFrontImgUrl("");
            setidFrontImg("");
        } else if (index == 3) {
            setIdBackImgUrl("");
            setidBackImg("");
        } else if (index == 4) {
            setAddressProofUrl("");
            setAddressProof("");
            setDocNameShow(false);
        }
        setDialogOpen(false);
    };
    const removeFile1 = (index) => {
        if (index === 1) {
            setSelfPhotoUrl1("");
            setSelfPhoto1("");
        } else if (index === 2) {
            setIdFrontImgUrl1("");
            setidFrontImg1("");
        } else if (index == 3) {
            setIdBackImgUrl1("");
            setidBackImg1("");
        } else if (index == 4) {
            setAddressProofUrl1("");
            setAddressProof1("");
            setDocNameShow1(false);
        }
        setDialogOpen1(false);
    };
    const removeFrontPhoto = (e) => {
        setFileIndex(2);
        setDialogOpen(true);
    };
    const removeFrontPhoto1 = (e) => {
        setFileIndex1(2);
        setDialogOpen1(true);
    };
    const removeBackPhoto = (e) => {
        setFileIndex(3);
        setDialogOpen(true);
    };
    const removeBackPhoto1 = (e) => {
        setFileIndex1(3);
        setDialogOpen1(true);
    };
    const removeAddressFile = (e) => {
        setFileIndex(4);
        setDialogOpen(true);
    };
    const removeAddressFile1 = (e) => {
        setFileIndex1(4);
        setDialogOpen1(true);
    };

    const handleSave = async () => {
        if(guarantorList[0]){
        const firstGuarantor = {
            guarantorId: guarantorList[0].id,
            data: {
                relationShip:relationship,
                salutation,
                applicationId,
                name,
                birthday,
                gender,
                phone,
                email,
                employmentTypeId,
                industryId,
                companyName: employerName,
                // identityId: idTypeId,
                // addressProofId: addressProofTypeId,
            }
        }
        let secondGuarantor
        if(guarantorList[1]){
        secondGuarantor = {
            guarantorId: guarantorList[1].id,
            data: {
                relationShip: relationship1,
                salutation: salutation1,
                applicationId,
                name: name1,
                birthday: birthday1,
                gender: gender1,
                phone: phone1,
                email: email1,
                employmentTypeId: employmentTypeId1,
                industryId: industryId1,
                companyName: employerName1,
                // identityId: idTypeId1,
                // addressProofId: addressProofTypeId1,
            }
        }
    }
        if(changedId){
            if(guarantorList[0]?.identityDocument?.id){
                console.log("Need to update identity Doc");
                const params = {
                    identityId:guarantorList[0]?.identityDocument?.id,
                    data: {
                        idTypeId: idTypeId || 1,
                        selfPhoto,
                        selfPhotoUrl,
                        idFrontImg,
                        idFrontImgUrl,
                        idBackImg,
                        idBackImgUrl,
                        idNumber
                    }
                }
                updateIdentityDocument(params);
        }
    }
    if(changedAddressDoc){
        if(guarantorList[0]?.addressProofDocument?.id){
            console.log("Need to update address proof");
            const params = {
                addressProofId:guarantorList[0]?.addressProofDocument?.id,
                data: {
                    addressProofTypeId,
                    addressProof,
                    country:APP_CONFIGS.COUNTRY,
                    postalCode,
                    province,
                    city,
                    address,
                    addressProofDocumentUrl:addressProofUrl
                }
            }
            updateAddressProof(params);
        }
    }
        const params = {
            data: guarantorList.length > 1 ? [firstGuarantor, secondGuarantor] : [firstGuarantor]
        };
        updateApplicationGuarantor(params)
    } else {
        const data = {
            relationShip:relationship,
            salutation,
            applicationId,
            name,
            birthday,
            gender,
            phone,
            email,
            employmentTypeId,
            industryId,
            companyName: employerName,
            idTypeId,
            idNumber,
            selfPhoto: selfPhoto,
            selfPhotoUrl,
            idFrontImg: idFrontImg,
            idFrontImgUrl,
            idBackImg: idBackImg,
            idBackImgUrl,
            addressProofTypeId,
            country:APP_CONFIGS.COUNTRY,
            postalCode,
            province,
            city,
            address,
            addressProof,
            addressProofUrl,
            identityId: idTypeId,
            addressProofId: addressProofTypeId,
        };
        createGuarantor(data);
    }
        await calculateCde({ applicationId });
        //handleChangeTab(0);
    };
    return (
        <main className={classes.layout}>
                <Fragment>
                <LoaderComponent isLoading={isLoadingCalculateCde} />
                    <Box
                        display="flex"
                        flexDirection="row"
                        // p={1}
                         mb={3}
                        style={{
                            backgroundColor:"#EFEFEF"
                        }}
                    >
                        {index === 1 ? (
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{backgroundColor:"#5F56A6"}}
                                    color="primary"
                                    className={classes.guarantorButton}
                                >
                                    {t('GUARANTOR')||"Guarantor"}#1
                                </Button>
                            </Box>
                        ) : (
                            index === 2 && (
                                <Box p={1} onClick={changeToOne}>
                                    {t('GUARANTOR')||"Guarantor"}#1
                                </Box>
                            )
                        )}
                        {index === 1
                            ? guarantorList[1] !== undefined && (
                                  <Box p={1} onClick={changeToTwo}>
                                      {t('GUARANTOR')||"Guarantor"}#2
                                  </Box>
                              )
                            : index === 2 &&
                              guarantorList[1] !== undefined && (
                                  <Box p={1}>
                                      <Button
                                          variant="contained"
                                          size="small"
                                          color="primary"
                                          className={classes.guarantorButton}
                                      >
                                          {t('GUARANTOR')||"Guarantor"}#2
                                      </Button>
                                  </Box>
                              )}
                    </Box>
                    <ValidatorForm onSubmit={handleSave}>
                        {index == 1 && (
                            <div>
                                <Typography
                                className={classes.titleLabel}>
                                    {t('PROFILE')||"Guarantor Profile"}
                                </Typography>
                                <Paper className={classes.paper}>
                                    {/* <PaperTitle
                                        classes={classes}
                                        text={t('GUARANTOR_PROFILE')||"Guarantor Profile"}
                                    /> */}
                                    <div className={classes.paperContent}>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {selfPhoto.length < 1 && (
                                                    <div className="file-input-column">
                                                        <input
                                                            type="file"
                                                            id="selfPhoto"
                                                            className="file"
                                                            name="selfPhoto"
                                                            onChange={
                                                                handleSelfImageChange
                                                            }
                                                        />
                                                        <label htmlFor="selfPhoto">
                                                            <PersonIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "68px",
                                                                    display:
                                                                        "block",
                                                                    color:"#CCCCE5"
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                    color:"#CCCCE5"
                                                                }}
                                                            >
                                                                {t('TAP_TO_UPLOAD_PHOTO')||"Tap to upload photo"}
                                                            </span>
                                                        </label>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={selfPhoto}
                                                        className={
                                                            classes.selfPhotoImage
                                                        }
                                                    />
                                                    {selfPhoto && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeSelfPhoto
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('RELATIONSHIP')||"Relationship With Applicant"}
                                                    </InputLabel>
                                                    <Select
                                                        name="relationship"
                                                        value={relationship}
                                                        onChange={changeHandler}
                                                    >
                                                        {map(
                                                            RELATIONSHIP_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}></Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('SALUTATION')||"Salutation"}
                                                    </InputLabel>
                                                    <Select
                                                        name="salutation"
                                                        value={salutation}
                                                        onChange={changeHandler}
                                                        //required
                                                    >
                                                        {map(
                                                            SALUTATION_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.label
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="name"
                                                    value={name}
                                                    label={t('FULL_NAME')||"Full name"}
                                                    fullWidth
                                                    onChange={changeHandler}
                                                    // validators={[
                                                    //     "isValidFullName",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid full name",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                // margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB')||"Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthday"
                                                value={birthday || maxDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('GENDER')||"Gender"}
                                                    </InputLabel>
                                                    <Select
                                                        name="gender"
                                                        value={gender}
                                                        onChange={changeHandler}
                                                        //required
                                                    >
                                                        {map(
                                                            GENDER_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.label
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="phone"
                                                    value={phone}
                                                    label={t('MOBILE')||"Mobile number"}
                                                    fullWidth
                                                    onChange={changeHandler}
                                                    type="number"
                                                    // validators={[
                                                    //     "isValidMobileNumber",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid mobile number",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    label={t('EMAIL')||"Email address(optional)"}
                                                    className={
                                                        classes.fullWidth
                                                    }
                                                    onChange={changeHandler}
                                                    name="email"
                                                    validators={["isEmail"]}
                                                    errorMessages={[
                                                        "Please enter a valid email",
                                                    ]}
                                                    value={email}
                                                    type="email"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="country"
                                                    label={t('COUNTRY')||"Country"}
                                                    value={APP_CONFIGS.COUNTRY}
                                                    disabled
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextValidator
                                                    name="postalCode"
                                                    label={t('POSTAL_CODE')||"Postal code"}
                                                    type="number"
                                                    value={postalCode}
                                                    onChange={changePostalCode}
                                                    inputProps={{
                                                        maxLength: APP_CONFIGS.VALID_POSTAL_CODE,
                                                    }}
                                                    fullWidth
                                                    // validators={[
                                                    //     "isValidPostalCode"
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid postal code",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('DISTRICT_PROVINCE')||"District / Province"}
                                                    </InputLabel>
                                                    <Select
                                                        name="province"
                                                        value={province}
                                                        onChange={changeHandler}
                                                        //required
                                                    >
                                                        {map(
                                                            districtOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('CITY_TOWN')||"City / Town"}
                                                    </InputLabel>
                                                    <Select
                                                        name="city"
                                                        value={city}
                                                        onChange={changeHandler}
                                                        //required
                                                    >
                                                        {map(
                                                            cityOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextValidator
                                                    name="address"
                                                    label={t('ADDRESS')||"Address"}
                                                    value={address}
                                                    onChange={changeHandler}
                                                    fullWidth
                                                    // validators={[
                                                    //     "isValidAddress",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid address",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('EMPLOYMENT_TYPE')||"Employment type"}
                                                    </InputLabel>
                                                    <Select
                                                        name="employmentTypeId"
                                                        value={employmentTypeId}
                                                        onChange={
                                                            changeEmploymentType
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            employmentTypeList,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.employmentType
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('INDUSTRY_BUSINESS_TYPE')||"Industry / Business type"}
                                                    </InputLabel>
                                                    <Select
                                                        name="industryId"
                                                        value={industryId}
                                                        onChange={changeHandler}
                                                        required
                                                    >
                                                        {map(
                                                            industryTypeList,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.industry
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="employerName"
                                                    label={t('EMPLOYER_COMPANY_NAME')||"Employer &amp; Company name"}
                                                    value={employerName}
                                                    onChange={changeHandler}
                                                    fullWidth
                                                    validators={[
                                                        "isValidEmployerName",
                                                        "required",
                                                    ]}
                                                    errorMessages={[
                                                        "Please enter a valid employer name",
                                                        "this field is required",
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Typography
                                    className={classes.titleLabel}>
                                    {t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                </Typography>
                                <Paper className={classes.paper}>
                                    {/* <PaperTitle
                                        classes={classes}
                                        text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                                    /> */}
                                    <div className={classes.paperContent}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('ID_TYPE')||"ID type"}
                                                    </InputLabel>
                                                    <Select
                                                        name="idTypeId"
                                                        value={idTypeId}
                                                        onChange={changeHandler}
                                                        //required
                                                    >
                                                        {map(
                                                            ID_TYPE_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="idNumber"
                                                    label={t('ID_NUMBER')||"ID number"}
                                                    value={idNumber}
                                                    onChange={changeHandler}
                                                    fullWidth
                                                    type="number"
                                                    // validators={[
                                                    //     "isValidIdNumber",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid id number",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    style={{
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    {t('FRONT_ID_OPTIONAL')||"Front side of ID (Optional)"}
                                                </Typography>
                                                {idFrontImg.length < 1 && (
                                                    <div className="file-input">
                                                        <input
                                                            type="file"
                                                            id="idFrontImg"
                                                            className="file"
                                                            name="idFrontImg"
                                                            onChange={
                                                                handleImageChange
                                                            }
                                                        />
                                                        <label htmlFor="idFrontImg">
                                                            <AddCircleOutlineIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "64px",
                                                                    color: "#CCCCE5"
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}

                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={idFrontImg}
                                                        className={
                                                            classes.bfPhoto
                                                        }
                                                    />
                                                    {idFrontImg && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeFrontPhoto
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    style={{
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    {t('BACK_ID_OPTIONAL')||"Back side of ID (Optional)"}
                                                </Typography>
                                                {idBackImg.length < 1 && (
                                                    <div className="file-input">
                                                        <input
                                                            type="file"
                                                            id="idBackImg"
                                                            className="file"
                                                            name="idBackImg"
                                                            onChange={
                                                                handleBackImageChange
                                                            }
                                                        />
                                                        <label htmlFor="idBackImg">
                                                            <AddCircleOutlineIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "64px",
                                                                    color: "#CCCCE5"
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={idBackImg}
                                                        className={
                                                            classes.bfPhoto
                                                        }
                                                    />
                                                    {idBackImg && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeBackPhoto
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Typography
                                className={classes.titleLabel}>
                                    {t('GUARANTOR_ADDRESS_PROOF')||"Guarantor Address Proof"}
                                </Typography>
                                <Paper className={classes.paper}>
                                    {/* <PaperTitle
                                        classes={classes}
                                        text={t('GUARANTOR_ADDRESS_PROOF')||"Gurantor Address Proof (Optional)"}
                                    /> */}
                                    <div className={classes.paperContent}>
                                        <Grid
                                            container
                                            
                                            spacing={4}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        {t('DOCUMENT_TYPE')||"Document type"}
                                                    </InputLabel>
                                                    <Select
                                                        name="addressProofTypeId"
                                                        value={
                                                            addressProofTypeId
                                                        }
                                                        onChange={changeHandler}
                                                        required
                                                    >
                                                        {map(
                                                            ADDRESS_PROOF_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12}
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}>
                                                <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                                {
                                                    addressProof.length < 1 && (
                                                        <div className="file-input-inline">
                                                            <input
                                                                type="file"
                                                                id="addressProof"
                                                                className="file"
                                                                name="addressProof"
                                                                onChange={
                                                                    handleAddressProof
                                                                }
                                                            />
                                                            <label
                                                                htmlFor="addressProof"
                                                                className={
                                                                    classes.inlineUpload
                                                                }
                                                            >
                                                                <AddPhotoAlternateIcon style={{color:"#5F56A6"}} />
                                                                <Typography
                                                                    style={{
                                                                        marginLeft:
                                                                            "8px",
                                                                        color:"#5F56A6"
                                                                    }}
                                                                >
                                                                    {t('TAP_TO_UPLOAD_DOCUMENT')||"Tap to upload document"}
                                                                </Typography>
                                                            </label>
                                                        </div>
                                                    )}
                                                {docNameShow && (
                                                    <div
                                                        className={
                                                            classes.docContainer
                                                        }
                                                    >
                                                        <a
                                                        href={docName}
                                                            target="_blank"
                                                            underline="always"
                                                        >
                                                            {t('VIEW_DOCUMENT')||"View Document"}
                                                        </a>
                                                        <DeleteOutlineIcon
                                                            onClick={
                                                                removeAddressFile
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </div>
                        )}

                        {index == 2 && guarantorList[1] !== undefined && (
                            <div>
                                <Paper className={classes.paper}>
                                    <PaperTitle
                                        classes={classes}
                                        text={t('GUARANTOR_PROFILE')||"Guarantor Profile"}
                                    />
                                    <div className={classes.paperContent}>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {selfPhoto1.length < 1 && (
                                                    <div className="file-input-column">
                                                        <input
                                                            type="file"
                                                            id="selfPhoto1"
                                                            className="file"
                                                            name="selfPhoto1"
                                                            onChange={
                                                                handleSelfImageChange1
                                                            }
                                                        />
                                                        <label htmlFor="selfPhoto1">
                                                            <PersonIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "68px",
                                                                    display:
                                                                        "block",
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {t('TAP_TO_UPLOAD_PHOTO')||"Tap to upload photo"}
                                                            </span>
                                                        </label>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={selfPhoto1}
                                                        className={
                                                            classes.selfPhotoImage
                                                        }
                                                    />
                                                    {selfPhoto1 && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeSelfPhoto1
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Relationship With
                                                        Applicant
                                                    </InputLabel>
                                                    <Select
                                                        name="relationship1"
                                                        value={relationship1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            RELATIONSHIP_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}></Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Salutation
                                                    </InputLabel>
                                                    <Select
                                                        name="salutation1"
                                                        value={salutation1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            SALUTATION_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.label
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="name1"
                                                    value={name1}
                                                    label="Full name"
                                                    fullWidth
                                                    onChange={changeHandler1}
                                                    // validators={[
                                                    //     "isValidFullName",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid full name",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    label="Date of Birth"
                                                    type="date"
                                                    name="birthday1"
                                                    value={birthday1}
                                                    onChange={changeHandler1}
                                                    className={
                                                        classes.textField
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // validators={[
                                                    //     "isValidDate",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid date of birth",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Gender
                                                    </InputLabel>
                                                    <Select
                                                        name="gender1"
                                                        value={gender1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        //required
                                                    >
                                                        {map(
                                                            GENDER_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.label
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="phone1"
                                                    value={phone1}
                                                    label="Mobile number"
                                                    fullWidth
                                                    onChange={changeHandler1}
                                                    type="number"
                                                    // validators={[
                                                    //     "isValidMobileNumber",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid mobile number",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    label="Email address(optional)"
                                                    className={
                                                        classes.fullWidth
                                                    }
                                                    onChange={changeHandler1}
                                                    name="email1"
                                                    // validators={["isEmail"]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid email",
                                                    // ]}
                                                    value={email1}
                                                    type="email"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="country1"
                                                    label="Country"
                                                    value={APP_CONFIGS.COUNTRY}
                                                    disabled
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextValidator
                                                    name="postalCode1"
                                                    label="Postal code"
                                                    type="number"
                                                    value={postalCode1}
                                                    onChange={changePostalCode1}
                                                    inputProps={{
                                                        maxLength: APP_CONFIGS.VALID_POSTAL_CODE,
                                                    }}
                                                    fullWidth
                                                    // validators={[
                                                    //     "isValidPostalCode",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid postal code",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        District / Province
                                                    </InputLabel>
                                                    <Select
                                                        name="province1"
                                                        value={province1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            districtOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        City / Town
                                                    </InputLabel>
                                                    <Select
                                                        name="city"
                                                        value={city1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            cityOptions,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextValidator
                                                    name="address1"
                                                    label="Address"
                                                    value={address1}
                                                    onChange={changeHandler1}
                                                    fullWidth
                                                    // validators={[
                                                    //     "isValidAddress",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid address",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Employment type
                                                    </InputLabel>
                                                    <Select
                                                        name="employmentTypeId1"
                                                        value={
                                                            employmentTypeId1
                                                        }
                                                        onChange={
                                                            changeEmploymentType1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            employmentTypeList1,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.employmentType
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Industry / Business type
                                                    </InputLabel>
                                                    <Select
                                                        name="industryId1"
                                                        value={industryId1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            industryTypeList1,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.industry
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="employerName1"
                                                    label="Employer &amp; Company name"
                                                    value={employerName1}
                                                    onChange={changeHandler1}
                                                    fullWidth
                                                    // validators={[
                                                    //     "isValidEmployerName",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid employer name",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <PaperTitle
                                        classes={classes}
                                        text={"Guarantor ID Proof"}
                                    />
                                    <div className={classes.paperContent}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        ID type
                                                    </InputLabel>
                                                    <Select
                                                        name="idTypeId1"
                                                        value={idTypeId1}
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            ID_TYPE_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    name="idNumber1"
                                                    label="ID number"
                                                    value={idNumber1}
                                                    onChange={changeHandler1}
                                                    fullWidth
                                                    type="number"
                                                    // validators={[
                                                    //     "isValidIdNumber",
                                                    //     "required",
                                                    // ]}
                                                    // errorMessages={[
                                                    //     "Please enter a valid id number",
                                                    //     "this field is required",
                                                    // ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    style={{
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    Front side of ID
                                                </Typography>
                                                {idFrontImg1.length < 1 && (
                                                    <div className="file-input">
                                                        <input
                                                            type="file"
                                                            id="idFrontImg1"
                                                            className="file"
                                                            name="idFrontImg1"
                                                            onChange={
                                                                handleImageChange1
                                                            }
                                                        />
                                                        <label htmlFor="idFrontImg1">
                                                            <AddCircleOutlineIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "64px",
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}

                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={idFrontImg1}
                                                        className={
                                                            classes.bfPhoto
                                                        }
                                                    />
                                                    {idFrontImg1 && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeFrontPhoto1
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    style={{
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    Back side of ID
                                                </Typography>
                                                {idBackImg1.length < 1 && (
                                                    <div className="file-input">
                                                        <input
                                                            type="file"
                                                            id="idBackImg1"
                                                            className="file"
                                                            name="idBackImg1"
                                                            onChange={
                                                                handleBackImageChange1
                                                            }
                                                        />
                                                        <label htmlFor="idBackImg1">
                                                            <AddCircleOutlineIcon
                                                                style={{
                                                                    height: "100px",
                                                                    width: "64px",
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        classes.photoContainer
                                                    }
                                                >
                                                    <img
                                                        src={idBackImg1}
                                                        className={
                                                            classes.bfPhoto
                                                        }
                                                    />
                                                    {idBackImg1 && (
                                                        <DeleteOutlineIcon
                                                            className={
                                                                classes.photoIcon
                                                            }
                                                            onClick={
                                                                removeBackPhoto1
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                                <Paper className={classes.paper}>
                                    <PaperTitle
                                        classes={classes}
                                        text={"Gurantor Address Proof"}
                                    />
                                    <div className={classes.paperContent}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={4}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel>
                                                        Document type
                                                    </InputLabel>
                                                    <Select
                                                        name="addressProofTypeId1"
                                                        value={
                                                            addressProofTypeId1
                                                        }
                                                        onChange={
                                                            changeHandler1
                                                        }
                                                        required
                                                    >
                                                        {map(
                                                            ADDRESS_PROOF_OPTIONS,
                                                            (item, key) => (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {addressProof1.length < 1 && (
                                                    <div className="file-input-inline">
                                                        <input
                                                            type="file"
                                                            id="addressProof1"
                                                            className="file"
                                                            name="addressProof1"
                                                            onChange={
                                                                handleAddressProof1
                                                            }
                                                        />
                                                        <label
                                                            htmlFor="addressProof1"
                                                            className={
                                                                classes.inlineUpload
                                                            }
                                                        >
                                                            <AddPhotoAlternateIcon />
                                                            <Typography
                                                                style={{
                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            >
                                                                Tap to upload
                                                                document
                                                            </Typography>
                                                        </label>
                                                    </div>
                                                )}
                                                {docNameShow1 && (
                                                    <div
                                                        className={
                                                            classes.docContainer
                                                        }
                                                    >
                                                        <a
                                                            href={docName1}
                                                            target="_blank"
                                                            underline="always"
                                                        >
                                                            View Document
                                                        </a>
                                                        <DeleteOutlineIcon
                                                            onClick={
                                                                removeAddressFile1
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </div>
                        )}
                        <ApplicationAdminstration
                            classes={classes}
                            handleSubmit={handleSubmit}
                            handleSave={handleSave}
                            t={t}
                        />
                    </ValidatorForm>
                </Fragment>
                <SelfAlertDialog
                    open={dialogOpen}
                    closeDialog={closeDialog}
                    removeFile={removeFile}
                    fileIndex={fileIndex}
                    t={t}
                />
                <SelfAlertDialog
                    open={dialogOpen1}
                    closeDialog={closeDialog1}
                    removeFile={removeFile1}
                    fileIndex={fileIndex1}
                    t={t}
                />
           
        </main>
    );
};
function SelfAlertDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const { removeFile, fileIndex, t } = props;

    const handleClose = () => {
        props.closeDialog();
    };
    const handleRemove = () => {
        props.removeFile(props.fileIndex);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete file"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('DELETE_MESSAGE')||"Do You want to delete this file now?"}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        {t('NO')||"No"}
                    </Button>
                    <Button
                    style={{
                        backgroundColor:"#5F56A6"
                    }}
                        variant="contained"
                        onClick={handleRemove}
                        color="primary"
                        autoFocus
                    >
                        {t('YES')||"Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

const ApplicationAdminstration = (props) => {
  const { classes, handleSave, t } = props;
  return (
      <AppBar className={classes.appBar} color="inherit" elevation={5}>
          <Toolbar>
              <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.rm64}
              >
                  <Box p={1}>
                      <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleSave}
                          className={classes.staffActionBtn}
                      >
                          {t('SAVE')||"Save &amp; Exit"}
                      </Button>
                  </Box>
                  <Box p={1}>
                      <Button
                      style={{backgroundColor:"#5F56A6"}}
                          className={classes.staffActionBtn}
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                          {t('SUBMIT')||"Submit"}
                      </Button>
                  </Box>
              </Grid>
          </Toolbar>
      </AppBar>
  );
};

const mapStateToProps = (state) => ({
    cityList: get(state.common.cityList, "data"),
    employmentTypeList: state.customer.employmentTypeList || [],
    industryTypeList: state.customer.industryTypeList || [],
    employmentTypeList1: state.customer.employmentTypeList || [],
    industryTypeList1: state.customer.industryTypeList1 || [],
    guarantorInfo: state.customer.guarantorInfo || "",
});

const mapDispatchToProps = {
    createCustomerDocument,
    getCities,
    getEmploymentTypes,
    getEmploymentTypes1,
    getIndustryType,
    createGuarantor,
    getIndustryType1,
    calculateCde
};

export default withStyles(inputStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(GuarantorUpdateComponent))
);
