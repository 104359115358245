const inputStyles = () => ({
    layout: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "120px",
        height: "100%",
        backgroundColor:'#EFEFEF'
    },
    appBar: {
        marginBottom: '32px',
        backgroundColor: '#212121'
    },
    appTitle: {
        color: '#fff',
        textDecoration: 'none',
        fontWeight: "400px",
        //fontSize: "16px"
    },
    mainContent: {
        paddingTop: '96px',
        backgroundColor:'#EFEFEF',
        marginBottom:'0px',
        height:'100%'
        
    },
    paper: {
        padding: '32px',
        marginBottom: '48px',
        marginTop: "64px"
    },
    dialogContent: {
        marginTop: '16px',
        borderBottom: '1px solid #BCBCBC'
    },
    loginBtnWrapper: {
        marginTop: "16px",
        display: "flex",
        justifyContent: "flex-end",
    },
    loginBtn: {
        backgroundColor: "#5F56A6",
        color: "white",
        //textTransform: 'none',
        '&:hover': {
            backgroundColor: "#5F56A6",
        }
    },
    actionBtn: {
        //textTransform: 'none',
        width: '250px',
        background: '#5F56A6'
    },
    dialogAction: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px 0px'
    },
    termChecked: {
        paddingLeft: '16px'
    },
    errorLabel: {
        marginLeft: '32px',
        color: '#E22B16',
        marginTop: '-8px'
    },
    loginField: {
        width: '100%',
        marginBottom: '40px'
    },
    menu: {
        "& .MuiPaper-root": {
          backgroundColor: "#212121",
           padding: 8,
        }
      },
      iconButtonLabel: {
        display: 'flex',
        flexDirection: 'row',
      },
      title: {
        fontSize: '16px',
        fontWeight: '500'
    },
    // containerRoot: {
    //     height: '100%',
    // overflow: 'hidden',
    // }
    
  });

export default inputStyles
