import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { addRemarks } from '../../store/actions/customer'

import AddRemarksDialogBox from './AddRemarksDialogBox'

const AddRemarksDialogBoxContainer = (props: any) => <AddRemarksDialogBox {...props} />

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = {
    addRemarks
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(AddRemarksDialogBoxContainer))
)
