import { useState, useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import filter from "lodash/filter";
import get from "lodash/get";
import find from "lodash/find";
import { AppBar, Toolbar, Box } from "@material-ui/core";
import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    RESIDENTIAL_OPTIONS,
    YEARS_CURRENT_ADDRESS_OPTIONS,
    RELATIONSHIP_OPTIONS,
    APP_CONFIGS
} from "../../../constants/appConstants";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
    validationForDate,
    validationForNumber,
    validationForTextField,
} from "../../../utils/formValidation";
import { mapDistrictsOptions, mapCityOptions } from "../../../utils/helper";
import {
    getApplicationById,
    getGuarantorsById,
    getReferenceById,
    updateApplicationStatus,
    getPaymentListById,
    activateApplication,
    calculateCde
} from "../../../store/actions/customer";
import { getCities } from "../../../store/actions/common";
import LoaderComponent from "../../connected_components/Loader";
import moment from "moment";

const ReferenceUpdateComponent = (props) => {
    const {
        classes,
        history,
        cityList,
        getCities,
        createProfileReference,
        referenceInfo,
        referenceList,
        handleChangeTab,
        confirmSubmit,
        updateApplicationReference,
        getApplicationById,
        getReferenceById,
        calculateCde,
        isLoadingCalculateCde,
        t
    } = props;
    const { applicationId } = props.match.params;
    const getRelationShipByValue = (value) => {
        const relationShip = find(RELATIONSHIP_OPTIONS, function (o) {
            return o.value == value;
        });
        return relationShip ? relationShip.label : "";
    };
    useEffect(() => {
        getCities();
    }, []);

    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState(
        referenceList[0] && (referenceList[0].postalCode || "")
    );
    const [postalCodeTwo, setPostalCodeTwo] = useState(
        referenceList[1] && (referenceList[1] && referenceList[1].postalCode || "")
    );

    const [allValues, setAllValues] = useState({
        relationship: referenceList[0] && referenceList[0].relationship || "",
        relationshipTwo: referenceList[1] && referenceList[1].relationship || "",
        salutation: referenceList[0] && referenceList[0].salutation || "",
        salutationTwo: referenceList[1] && referenceList[1].salutation || "",
        residentalStatus: referenceList[0] && referenceList[0].residentalStatus || "",
        residentalStatusTwo: referenceList[1] && referenceList[1].residentalStatus || "",
        name: referenceList[0] && referenceList[0].name || "",
        nameTwo: referenceList[1] && referenceList[1].name || "",
        birthday: referenceList[0] && referenceList[0].birthday || "",
        birthdayTwo: referenceList[1] && referenceList[1].birthday || "",
        gender: referenceList[0] && referenceList[0].gender || "",
        genderTwo: referenceList[1] && referenceList[1].gender || "",
        phone: referenceList[0] && referenceList[0].phone || "",
        phoneTwo: referenceList[1] && referenceList[1].phone || "",
        email: referenceList[0] && referenceList[0].email || "",
        emailTwo: referenceList[1] && referenceList[1].email || "",
        country: referenceList[0] && referenceList[0].country || "",
        countryTwo: referenceList[1] && referenceList[1].country || "",
        province: referenceList[0] && referenceList[0].province || "",
        provinceTwo: referenceList[1] && referenceList[1].province || "",
        cityTwo: referenceList[1] && referenceList[1].city || "",
        city: referenceList[1] && referenceList[1].city || "",
        address: referenceList[0] && referenceList[0].address || "",
        addressTwo: referenceList[1] && referenceList[1].address || "",
        yearsLiving: referenceList[0] && referenceList[0].yearsLiving || "",
        yearsLivingTwo: referenceList[1] && referenceList[1].yearsLiving || "",
    });
    
    useEffect(() => {
        getReferenceById(applicationId);
    }, []);

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const handleSubmit = () => {
        confirmSubmit();
    };

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };

    const changePostalCodeTwo = (e) => {
        setPostalCodeTwo(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    provinceTwo: item[0].state,
                    cityTwo: item[0].city,
                });
            }
        }
    };

    const {
        relationship,
        relationshipTwo,
        salutation,
        salutationTwo,
        name,
        nameTwo,
        birthday,
        birthdayTwo,
        gender,
        genderTwo,
        phone,
        phoneTwo,
        email,
        emailTwo,
        country,
        countryTwo,
        province,
        provinceTwo,
        cityTwo,
        city,
        address,
        addressTwo,
    } = allValues;
    useEffect(() => {
        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE);
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });
    }, []);

    useEffect(()=>{
        if(city.length>0){
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if(item.length>0){
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    },[city,province])

    useEffect(()=>{
        if(cityTwo.length>0){
            const item = filter(
                cityList,
                (item) => item.city === cityTwo
            );
            if(item.length>0){
                setPostalCodeTwo(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    provinceTwo: item[0].state
                })
            }
        }

    },[cityTwo,provinceTwo])

    const [maxDate, setMaxDate] = useState("");

    useEffect(() => {
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        var day = new Date().getDate();
        var date = new Date(year - 18, month, day);

        setMaxDate(date);
    }, []);
    const handleDateChange = (date) => {
        setAllValues({ ...allValues, birthday: date });
    };
    const handleDateChange2 = (date) => {
        setAllValues({ ...allValues, birthdayTwo: date });
    };

    const handleSave = async () => {
        if(referenceList[0] && referenceList[0].id && referenceList[1] && referenceList[1].id){
        const params = {
            data: [
                {
                    referenceId: referenceList[0] && referenceList[0].id,
                    data: {
                        relationship:relationship,
                        salutation,
                        name,
                        birthday,
                        gender,
                        phone,
                        email,
                        country,
                        postalCode,
                        province,
                        city,
                        address,
                    },
                },
                {
                    referenceId: referenceList[1] && referenceList[1].id,
                    data: {
                        relationship: relationshipTwo,
                        salutation: salutationTwo,
                        name: nameTwo,
                        birthday: birthdayTwo,
                        gender: genderTwo,
                        phone: phoneTwo,
                        email: emailTwo,
                        postalCode: postalCodeTwo,
                        country: countryTwo,
                        province: provinceTwo,
                        city: cityTwo,
                        address: addressTwo,
                    },
                },
            ],
        };
        updateApplicationReference(params);}
        else{
            const referenceOne = {
                applicationId,
                relationship,
                salutation,
                name,
                birthday,
                gender,
                phone,
                email,
                country,
                postalCode,
                province,
                city,
                address,
            };
            const referenceTwo = {
                applicationId,
                relationship: relationshipTwo,
                salutation: salutationTwo,
                name: nameTwo,
                birthday: birthdayTwo,
                gender: genderTwo,
                phone: phoneTwo,
                email: emailTwo,
                postalCode: postalCodeTwo,
                country: countryTwo,
                province: provinceTwo,
                city: cityTwo,
                address: addressTwo,
            };
            createProfileReference({ referenceOne, referenceTwo });
        }
       await calculateCde({ applicationId });
        //handleChangeTab(0);
    };

    return (
        <main className={classes.layout}>
            <LoaderComponent isLoading={isLoadingCalculateCde} />
            <Typography
                className={classes.titleLabel}>
                    {t('REFERENCE_1')||"REFERENCE 1"}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    
                    <ValidatorForm onSubmit={handleSave}>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('REFERENCE_1')||"Reference 1"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RELATIONSHIP')||"Relationship with applicant"}
                                            </InputLabel>
                                            <Select
                                                name="relationship"
                                                value={relationship}
                                                onChange={changeHandler}
                                                //placeholder={getRelationShipByValue(relationship)}
                                                required
                                            >
                                                {map(
                                                    RELATIONSHIP_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('SALUTATION')||"Salutation"}</InputLabel>
                                            <Select
                                                name="salutation"
                                                value={salutation}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    SALUTATION_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="name"
                                            value={name}
                                            label={t('FULL_NAME')||"Full name"}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidFullName",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid full name",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB')||"Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthday"
                                                value={birthday || maxDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            style={{ marginTop: "16px" }}
                                        >
                                            <InputLabel>{t('GENDER')||"Gender"}</InputLabel>
                                            <Select
                                                name="gender"
                                                value={gender}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    GENDER_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="phone"
                                            value={phone}
                                            label={t('MOBILE')||"Mobile number"}
                                            onChange={changeHandler}
                                            type="number"
                                            validators={[
                                                "isValidMobileNumber",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid mobile number",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="email"
                                            label={t('EMAIL')||"Email address (optional)"}
                                            value={email}
                                            onChange={changeHandler}
                                            validators={["isEmail"]}
                                            errorMessages={[
                                                "Please enter a valid email address",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="country"
                                            label={t('COUNTRY')||"Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            name="postalCode"
                                            label={t('POSTAL_CODE')||"Postal code"}
                                            type="number"
                                            value={postalCode}
                                            onChange={changePostalCode}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                            validators={[
                                                "isValidPostalCode",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE')||"District / Province"}
                                            </InputLabel>
                                            <Select
                                                name="province"
                                                value={province}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN')||"City / Town"}</InputLabel>
                                            <Select
                                                name="city"
                                                value={city}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                            name="address"
                                            label={t('ADDRESS')||"Address"}
                                            value={address}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidAddress",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}>
                                {t('REFERENCE_2')||"Reference 2"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('REFERENCE_2')||"Reference 2"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RELATIONSHIP')||"Relationship with applicant"}
                                            </InputLabel>
                                            <Select
                                                name="relationshipTwo"
                                                value={relationshipTwo}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    RELATIONSHIP_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('SALUTATION')||"Salutation"}</InputLabel>
                                            <Select
                                                name="salutationTwo"
                                                value={salutationTwo}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    SALUTATION_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="nameTwo"
                                            value={nameTwo}
                                            label={t('FULL_NAME')||"Full name"}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidFullName",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid full name",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB')||"Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthdayTwo"
                                                value={birthdayTwo || maxDate}
                                                onChange={handleDateChange2}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            style={{ marginTop: "16px" }}
                                        >
                                            <InputLabel>{t('GENDER')||"Gender"}</InputLabel>
                                            <Select
                                                name="genderTwo"
                                                value={genderTwo}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    GENDER_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="phoneTwo"
                                            value={phoneTwo}
                                            label={t('MOBILE')||"Mobile number"}
                                            onChange={changeHandler}
                                            type="number"
                                            validators={[
                                                "isValidMobileNumber",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid mobile number",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="emailTwo"
                                            label={t('EMAIL')||"Email address (optional)"}
                                            value={emailTwo}
                                            onChange={changeHandler}
                                            validators={["isEmail"]}
                                            errorMessages={[
                                                "Please enter a valid email address",
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="countryTwo"
                                            label={t('COUNTRY')||"Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                            name="postalCodeTwo"
                                            label={t('POSTAL_CODE')||"Postal code"}
                                            type="number"
                                            value={postalCodeTwo}
                                            onChange={changePostalCodeTwo}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE }}
                                            validators={[
                                                "isValidPostalCode",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE')||"District / Province"}
                                            </InputLabel>
                                            <Select
                                                name="provinceTwo"
                                                value={provinceTwo}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN')||"City / Town"}</InputLabel>
                                            <Select
                                                name="cityTwo"
                                                value={cityTwo}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                            name="addressTwo"
                                            label={t('ADDRESS')||"Address"}
                                            value={addressTwo}
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidAddress",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                "this field is required",
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <ApplicationAdminstration
                            classes={classes}
                            handleSubmit={handleSubmit}
                            handleSave={handleSave}
                            t={t}
                        />
                    </ValidatorForm>
                </Fragment>
            
        </main>
    );
};

const ApplicationAdminstration = (props) => {
    const { classes, handleSubmit, t } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.rm64}
                >
                    <Box p={1}>
                        <Button
                            id = "save"
                            variant="outlined"
                            color="primary"
                            type="submit"
                            className={classes.staffActionBtn}
                        >
                            {t('SAVE')||"Save &amp; Exit"}
                        </Button>
                    </Box>
                    <Box p={1}>
                        <Button
                        style={{backgroundColor:"#5F56A6"}}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </Box>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

const mapStateToProps = (state) => ({
    isLoadingGetApplications: state.auth.isLoadingGetApplications,
    referenceInfo: state.customer.referenceInfo,
    referenceList: state.customer.reference || [],
    cityList: get(state.common.cityList, "data"),
});

const mapDispatchToProps = {
    getCities,
    getReferenceById,
    calculateCde
};

export default withStyles(inputStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(ReferenceUpdateComponent))
);
