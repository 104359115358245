import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography, AppBar, Toolbar, Button } from "@material-ui/core";
import PropTypes from 'prop-types'
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import { useTranslation } from "react-i18next";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        height: "100px",
        backgroundColor: "red",
    },
    resize: {
        fontSize: 50,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const RemarksDialogItem = (props) => {
    const { classes, addRemarks, applicationId, currentUser, open, history, link} = props;
    const [value, setValue] = React.useState("Controlled");
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setIsOpen(open)
    }, [open]);

    const handleSave =() =>{
        const params = {
            applicationId: applicationId,
            remarks: value,
            createdBy: currentUser.firstName+" "+currentUser.lastName
        }
         addRemarks(params);
         setIsOpen(false);
         if(link && link.length>0){
            history.push(link);
         }else{
         history.push("/dashboard");}
    }

    const handleClose = () =>{
        setIsOpen(false);
        history.push("/dashboard");
    }
    
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={handleClose}
            >
                {t('REMARKS')||"Remarks"}
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={4}
                            variant="filled"
                            placeholder={t('WRITE_DOWN_REMARKS_HERE')||"Write down the remarks here..."}
                            onChange={handleChange}
                        />
                    </Grid>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={handleClose}
                        >
                            {t('SKIP')||"Skip"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleSave}
                        >
                            {t('ADD')||"Add"}
                        </Button>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
RemarksDialogItem.propTypes = {
    applicationId: PropTypes.string,
    currentUser:PropTypes.string,
    open:PropTypes.bool,
    link:PropTypes.string,
}

export default withStyles(inputStyles)(RemarksDialogItem);