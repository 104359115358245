import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import { AppBar, Toolbar, Box } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { TYPE_OPTIONS, DISCOUNT_TYPE, APP_CONFIGS } from "../../../constants/appConstants";
import {
    getVehicles,
    updateApplicationStatus,
    calculateCde
} from "../../../store/actions/customer";
import { validationForDiscount } from "../../../utils/formValidation";
import LoaderComponent from "../../connected_components/Loader";

const UpdateVehicle = (props) => {
    const {
        classes,
        confirmSubmit,
        getVehicles,
        vehicleList,
        applicationVehicle,
        updateApplicationStatus,
        handleChangeTab,
        getApplicationById,
        history,
        status,
        applicant,
        createDiscount,
        updateDiscount,
        discount,
        applicationLoan,
        updateApplicationLoan,
        calculateCde,
        isLoadingCalculateCde,
        t
    } = props;
    const { applicationId } = props.match.params;
    const { insuranceType } = applicationVehicle;
    const [color, setColor] = useState(applicationVehicle.color || "");
    const [model, setModel] = useState("");
    const [type, setType] = useState(insuranceType || "");
    const [price, setPrice] = useState("");
    const [colorOptions, setColorOptions] = useState([]);
    const [vehicleId, setVehicleId] = useState('');
    const [discountType, setDiscountType] = useState(discount.discountFlat>0? 'FLAT': (discount.discountPercentage>0?'PERCENTAGE':''));
    const [discountValue, setDiscountValue] = useState(discount.discountFlat || discount.discountPercentage || 0.0)
    const [discountedPrice, setDiscountedPrice] = useState(discount.discountedPrice || "");
    const [discountAmount, setDiscountAmount] = useState(discount.vehiclePrice-discount.discountedPrice);
    const [priceModified, setPriceModified] = useState(false);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        getApplicationById(applicationId);
    }, []);

    useEffect(() => {
        getVehicles();
    }, []);

    useEffect(() => {
        if (
            applicationVehicle &&
            applicationVehicle.model &&
            vehicleList.length > 0
        ) {
            const modelId = applicationVehicle.model.id;
            setModel(modelId);

            const colorOptionGroup = filter(vehicleList, function (vehicle) {
                return vehicle.model.id == modelId;
            });
            const options = map(colorOptionGroup, (item) => {
                return {
                    label: item.color.name,
                    value: item.color.id,
                };
            });
            setColorOptions(options);
            applicationVehicle.color && setColor(applicationVehicle.color.id);
            applicationVehicle.insuranceType &&
                setType(applicationVehicle.insuranceType);
            applicationVehicle.price && setPrice(applicationVehicle.price);
        }
    }, [applicationVehicle, vehicleList]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
        //get vehicle price
        const vehicle = find(vehicleList, function (o) {
            return o.model.id == model && o.color.id == event.target.value;
        });
        setPrice(vehicle.price);
        setVehicleId(vehicle.id)
    };
    const handleModelChange = (event) => {
        setModel(event.target.value);
        getColorByModel(event.target.value);
        setPrice("");
        setColor("");
        setDiscountType("");
        setDiscountValue(0.0);
        setPriceModified(true);
    };

    useEffect(()=>{
        if(discountType==="FLAT" && Number(price)>0 && discountValue>0.0){
            setDiscountedPrice(price - discountValue);
            setDiscountAmount(parseFloat(discountValue).toFixed(2));
        } else if(discountType==="PERCENTAGE" && Number(price)>0 && discountValue>0.0){
            setDiscountedPrice(price*(1-(discountValue/100)));
            setDiscountAmount(parseFloat(price*discountValue/100).toFixed(2));
        } else {
            setDiscountedPrice(price)
        }
        
    },[discountValue, discountType, price])


    useEffect(()=>{
        if(reset){
            //await getApplicationById(applicationId);
             calculateCde({ applicationId });
        }
    },[reset]);

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidDiscount", (value) => {
            return validationForDiscount(value, price, discountType);
        });

    });

    const getColorByModel = (modelId) => {
        const colorOptionGroup = filter(vehicleList, function (vehicle) {
            return vehicle.model.id == modelId;
        });
        const options = map(colorOptionGroup, (item) => {
            return {
                label: item.color.name,
                value: item.color.id,
            };
        });
        setColorOptions(options);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleDiscountTypeChange = (event) =>{
        setDiscountType(event.target.value);
        setPriceModified(true);
        setDiscountValue(0.0);
    }

   const handleDiscountChange = (event) =>{
       setDiscountValue(event.target.value);
       setPriceModified(true);
   }

    const getVehiclesModel = () => {
        const vehicleModels = map(vehicleList, (item) => {
            return {
                label: item.model.name,
                value: item.model.id,
            };
        });
        const uniqueList = Array.from(
            new Set(vehicleModels.map((a) => a.label))
        ).map((label) => {
            return vehicleModels.find((a) => a.label === label);
        });
        return uniqueList;
    };

    const handleSubmit = () => {
        confirmSubmit();
    };

    const handleSave =async () => {
        if(vehicleId.length>0)
        {let params = {
            applicationId: applicationId,
            data: {
                vehicleId: vehicleId,
            },
        };
        updateApplicationStatus(params);
    } 
    let params = {
        applicationId: applicationId,
        data: {
            insuranceType: insuranceType,
        },
    };
    updateApplicationStatus(params);

        if(discount.id){
            let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            };
            updateDiscount(params);
        } else {
           let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            }
            console.log(params);
            createDiscount(params);
        }
        if(applicationLoan.id && priceModified){
            let params = {
            loanId: applicationLoan.id,
            data: {
                downPayment: null,
                loanAmount: parseInt(discountedPrice),
                loanYears: null,
                processingFee: "",
                paymentMethod: "",
                bankName: "",
                flatRate: parseFloat(0),
                reducingRate: parseFloat(0),
                monthlyPayment: "",
                numberOfPayments: 0,
                totalInterest: 0,
                totalCost: parseInt(discountedPrice),
            },
        };
      await updateApplicationLoan(params);
      calculateCde({ applicationId });
    //    await getApplicationById(applicationId);

        setReset(true);
    }
        //handleChangeTab(0);
    };

    const handleInquiryPurchase = () =>{
        history.push("/client/"+applicant.id+"/selectVehicle/"+applicationId+"/continue")
           
    }

    return (
        <>
            <ValidatorForm onSubmit={handleSave}>
                <main className={classes.layout}>
                <LoaderComponent isLoading={isLoadingCalculateCde} />
                    <Fragment>
                        <CssBaseline />
                        <Paper className={classes.paper}>
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('VEHICLE_MODEL')||"Vehicle model"}
                                            </InputLabel>
                                            <Select
                                                value={model}
                                                onChange={handleModelChange}
                                                required
                                            >
                                                {map(
                                                    getVehiclesModel(),
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('VEHICLE_COLOR')||"Vehicle color"}
                                            </InputLabel>
                                            <Select
                                                value={color}
                                                onChange={handleColorChange}
                                                required
                                            >
                                                {map(
                                                    colorOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InputLabel>
                                                {t('DISCOUNT_TYPE')||"Discount type"}
                                            </InputLabel>
                                            <Select
                                                value={discountType}
                                                onChange={handleDiscountTypeChange}
                                                fullWidth
                                                required
                                            >
                                                {map(
                                                    DISCOUNT_TYPE,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                   {(discountType==="FLAT" || discountType==="PERCENTAGE") && <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            name="discount"
                                            label={"Discount" + (discountType==="FLAT"? " "+`(${APP_CONFIGS.CURRENCY})`:'(%)')}
                                            fullWidth
                                            onChange={handleDiscountChange}
                                            value={discountValue}
                                            validators={[
                                                "isValidDiscount",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid discount",
                                            ]}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InputLabel>
                                                {t('INSURANCE')||"Insurance type"}
                                            </InputLabel>
                                            <Select
                                                value={type}
                                                onChange={handleTypeChange}
                                                fullWidth
                                                required
                                            >
                                                {map(
                                                    TYPE_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12}>
                                    <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('VEHICLE_PRICE')||"Vehicle price + " "+`(${APP_CONFIGS.CURRENCY})`} 
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                        >
                                            {discountedPrice}
                                        </Typography>
                                        </Grid> */}
                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="price"
                                            label={t('VEHICLE_PRICE')||"Vehicle price + " "+`(${APP_CONFIGS.CURRENCY})`}
                                            fullWidth
                                            value={discountedPrice}
                                            disabled
                                        />
                                    </Grid> */}
                                </Grid>
                            </div>
                            <div className={classes.paperContentLower}>
                            <Grid container spacing={4}>
                             <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('VEHICLE_PRICE')  + " "+`(${APP_CONFIGS.CURRENCY})`} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.labelContent}
                                style={{textAlign:"right"}}
                            >
                                {parseFloat(price).toFixed(2)}
                            </Typography>
                            </Grid>
                            </Grid>
                            </div>
                           {(discountType==="FLAT" || discountType==="PERCENTAGE") &&  <div className={classes.paperContentColoured}>
                            <Grid container spacing={4}>
                            {(discountType==="FLAT" || discountType==="PERCENTAGE") &&<> <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {"Discounted Amount "  + " "+`(${APP_CONFIGS.CURRENCY})`} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body2"
                                //gutterBottom
                                className={classes.labelContent}
                                style={{textAlign:"right"}}
                            >
                                {parseFloat(discountAmount).toFixed(2)}
                            </Typography>
                            </Grid></>}
                            {(discountType==="FLAT" || discountType==="PERCENTAGE") &&<> <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {"Vehicle Price after Discount"  + " "+`(${APP_CONFIGS.CURRENCY})`} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body2"
                                //gutterBottom
                                className={classes.labelContent}
                                style={{textAlign:"right"}}
                            >
                                {parseFloat(discountedPrice).toFixed(2)}
                            </Typography>
                            </Grid></>}
                            </Grid>
                            </div>}
                        </Paper>
                    </Fragment>
                </main>
                <ApplicationAdminstration
                    classes={classes}
                    handleSubmit={handleSubmit}
                    handleSave={handleSave}
                    handleInquiryPurchase={handleInquiryPurchase}
                    status={status}
                    t={t}
                />
            </ValidatorForm>
        </>
    );
};

const ApplicationAdminstration = (props) => {
    const { classes, handleSubmit, handleSave, handleInquiryPurchase, status, t } = props;
    return (
        <AppBar className={classes.appBar} color="inherit" elevation={5}>
            <Toolbar>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.rm64}
                >
                    <Box p={1}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleSave}
                            className={classes.staffActionBtn}
                        >
                            {t('SAVE')||"Save &amp; Exit"}
                        </Button>
                    </Box>
                    {status==="Pending" ? (<Box p={1}>
                        <Button
                            style={{backgroundColor:"#5F56A6"}}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('SUBMIT')||"Submit"}
                        </Button>
                    </Box>): (<Box p={1}>
                        <Button
                            style={{backgroundColor:"#5F56A6"}}
                            className={classes.staffActionBtn}
                            variant="contained"
                            color="primary"
                            onClick={handleInquiryPurchase}
                        >
                            {t('CONTINUE_PURCHASE')||"Continue purchase"}
                        </Button>
                    </Box>)}
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => ({
    vehicleList: state.customer.vehicleList || [],
    categoryList: state.customer.categoryList || [],
    documentTypeList: state.customer.documentTypeList || [],
    updatedDocument: state.customer.updatedDocument || "",
});

const mapDispatchToProps = {
    getVehicles,
    updateApplicationStatus,
    calculateCde
};

export default withStyles(inputStyles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateVehicle))
);
