const inputStyles = {
    appBar: {
        position: "relative",
        backgroundColor: '#212121'
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    menuButton: {
        marginLeft: "auto"
    },
    appTitle: {
        color: '#fff',
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle:'normal',
        fontSize: '32px',
        marginBottom: '12px',
        fontWeight: '500',
    },
    titleLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '24px',
        color: '#212121',
        padding: '0px 0px 16px 4px',
       // borderBottom: '1px solid #DCDEDF'
    },
    subTitle: {
        marginBottom: '24px',
        fontSize: '12px',
        marginTop: '8px',
        fontWeight: '300'
    },
    stepLabel: {
        fontSize: '16px',
        marginTop: '16px'
    },
    paper: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '4px',
        marginBottom: '24px'
    },
    paperStatus: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '4px',
        marginBottom: '24px',
        //backgroundColor: 'E5E5E5',
        padding: '0px'
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: '28px',
        // marginLeft: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    margin24: {
        marginRight: '24px',
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    mainContent: {
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto',
        marginTop: '80px'
    },
    paperLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        padding: '16px 0px 8px 32px',
        borderBottom: '1px solid #DCDEDF'
    },
    paperContent: {
        padding: '32px'
    },
    paperContentStatus: {
        paddingTop: '16px'
    },
    actionBtn: {
        textTransform: 'none',
        width: '100%'
    },
    customerCount: {
        fontSize: '32px',
        fontWeight: '700'
    },
    tvsLoading: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    greyBox: {
      //  background: '#F8F8F8',
      //  borderRadius: '4px',
        //paddingRight: '32px'
    },
    applicationStatus: {
        background: '#F8F8F8',
        borderRadius: '4px'
    },
    label:{
        fontSize:12,
        marginTop:5,
        marginLeft:10
    },
    count: {
        fontSize: '32px',
        fontWeight: '700',
        marginLeft:10
    },
    blueCard:{
        backgroundColor:"#F8F8F8",
        borderRadius: "4px",
        padding:"12px",
    
    },
    grayCard:{
        backgroundColor:'#E4E4E4',
        borderRadius: "4px",
        padding: "12px",
        
    }
}

export default inputStyles
