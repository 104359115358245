import React, { useState, Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from '@mui/material/Box';
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PersonIcon from "@material-ui/icons/Person";
import Link from "@material-ui/core/Link";
import { map, filter } from "lodash";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ID_TYPE_OPTIONS } from "../../constants/appConstants";
import {
    validationForTextField,
    validationForNumber,
    validationForDate,
    validationForVehicle,
} from "../../utils/formValidation";
import {
    GENDER_OPTIONS,
    SALUTATION_OPTIONS,
    ADDRESS_PROOF_OPTIONS,
    RELATIONSHIP_OPTIONS,
    APP_CONFIGS
} from "../../constants/appConstants";
import { mapDistrictsOptions, mapCityOptions } from "../../utils/helper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LoaderComponent from "../connected_components/Loader";
import { AddRemarksDialogBoxConatiner } from "../static_components";
import { useTranslation } from "react-i18next";
const Guarantor = (props) => {
    const {
        classes,
        history,
        guarantorInfo,
        createGuarantor,
        getCities,
        cityList,
        employmentTypeList,
        getEmploymentTypes,
        getIndustryType,
        industryTypeList,
        isLoadingCreateGuarantor,
        currentUser
    } = props;
    const { applicationId } = props.match.params;
    const urlKeyArr = props.match.path.split("/");
    const urlKey = urlKeyArr[urlKeyArr.length - 1];
    const [idFrontImg, setidFrontImg] = useState("");
    const [idFrontImgUrl, setIdFrontImgUrl] = useState("");
    const [idBackImg, setidBackImg] = useState("");
    const [idBackImgUrl, setIdBackImgUrl] = useState("");
    const [selfPhoto, setSelfPhoto] = useState("");
    const [selfPhotoUrl, setSelfPhotoUrl] = useState("");
    const [employmentTypeId, setEmploymentType] = useState("");
    const [addressProof, setAddressProof] = useState("");
    const [addressProofUrl, setAddressProofUrl] = useState("");
    const [districtOptions, setDistrictOption] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalCode, setPostalCode] = useState("");
    const [docNameShow, setDocNameShow] = useState(false);
    const [docName, setDocName] = useState("");
    const [openRemarks, setOpenRemarks] = useState(false);
    const [saveAndExitLink, setSaveAndExitLink] = useState(`/application/${applicationId}/save-application`);
    const [createAction, setCreateAction] = useState(false);
    const { t } = useTranslation();
    const changeEmploymentType = (e) => {
        setEmploymentType(e.target.value);
        getIndustryType(e.target.value);
    };
    const handleImageChange = (e) => {
        setidFrontImg(URL.createObjectURL(e.target.files[0]));
        setIdFrontImgUrl(e.target.files[0]);
    };

    const handleBackImageChange = (e) => {
        setidBackImg(URL.createObjectURL(e.target.files[0]));
        setIdBackImgUrl(e.target.files[0]);
    };

    const handleSelfImageChange = (e) => {
        setSelfPhoto(URL.createObjectURL(e.target.files[0]));
        setSelfPhotoUrl(e.target.files[0]);
    };
    const handleAddressProof = (e) => {
        setDocNameShow(true);
        setDocName(e.target.files[0].name);
        setAddressProof(URL.createObjectURL(e.target.files[0]));
        setAddressProofUrl(e.target.files[0]);
    };

    const [allValues, setAllValues] = useState({
        relationShip: "",
        salutation: "",
        name: "",
        maritalStatus: "",
        dependents: "",
        idTypeId: "1",
        idNumber: "",
        incomeProofTypeId: "",
        monthlyIncome: "",
        birthday: "",
        gender: "",
        phone: "",
        email: "",
        country: APP_CONFIGS.COUNTRY,
        province: "",
        city: "",
        address: "",
        industryId: "",
        employerName: "",
        addressProof: "",
        addressProofTypeId: "",
    });

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        ValidatorForm.addValidationRule("isValidPostalCode", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_POSTAL_CODE);
        });

        ValidatorForm.addValidationRule("isValidFullName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidDate", (value) => {
            return validationForDate(value);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidMobileNumber", (value) => {
            return validationForNumber(value, APP_CONFIGS.VALID_PHONE_DIGITS);
        });

        ValidatorForm.addValidationRule("isValidExistingVehicle", (value) => {
            return validationForVehicle(value);
        });

        ValidatorForm.addValidationRule("isValidAddress", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidEmployerName", (value) => {
            return validationForTextField(value);
        });

        ValidatorForm.addValidationRule("isValidIdNumber", (value) => {
            return validationForNumber(value, 13);
        });
    });
    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        if (guarantorInfo && guarantorInfo.id && urlKey == "guarantor" && createAction) {
            history.push(`/application/${applicationId}/emi`);
        } else if (
            guarantorInfo &&
            guarantorInfo.id &&
            urlKey == "additional-guarantor"
        ) {
            history.push(`/application/${applicationId}/complete`);
        }
    }, [guarantorInfo]);

    useEffect(() => {
        getEmploymentTypes();
    }, []);

    useEffect(() => {
        if (cityList && cityList.length > 0) {
            setDistrictOption(mapDistrictsOptions(cityList));
            setCityOptions(mapCityOptions(cityList));
        }
    }, [cityList]);

    const changePostalCode = (e) => {
        setPostalCode(e.target.value);
        if (e.target.value.length == 4) {
            const item = filter(
                cityList,
                (item) => item.postalCode === e.target.value
            );
            if (item.length > 0) {
                setAllValues({
                    ...allValues,
                    province: item[0].state,
                    city: item[0].city,
                });
            }
        }
    };



    const {
        name,
        relationShip,
        birthday,
        gender,
        phone,
        email,
        country,
        province,
        idNumber,
        idTypeId,
        salutation,
        address,
        city,
        industryId,
        employerName,
        addressProofTypeId,
    } = allValues;


    useEffect(() => {
        if (city.length > 0) {
            const item = filter(
                cityList,
                (item) => item.city === city
            );
            if (item.length > 0) {
                setPostalCode(item[0].postalCode);
                setAllValues({
                    ...allValues,
                    province: item[0].state
                })
            }
        }

    }, [city, province])

    const handleSaveExit = () => {
        const data = {
            relationShip,
            salutation,
            applicationId,
            name,
            birthday,
            gender,
            phone,
            email,
            employmentTypeId,
            industryId,
            companyName: employerName,
            idTypeId,
            idNumber,
            selfPhoto: selfPhoto,
            selfPhotoUrl,
            idFrontImg: idFrontImg,
            idFrontImgUrl,
            idBackImg: idBackImg,
            idBackImgUrl,
            addressProofTypeId,
            country,
            postalCode,
            province,
            city,
            address,
            addressProof,
            addressProofUrl,
            identityId: idTypeId,
            addressProofId: addressProofTypeId,
        };
        setOpenRemarks(true);
        createGuarantor(data);
        // history.push(`/application/${applicationId}/save-application`);
    };

    const handleSubmit = () => {
        const data = {
            relationShip,
            salutation,
            applicationId,
            name,
            birthday,
            gender,
            phone,
            email,
            employmentTypeId,
            industryId,
            companyName: employerName,
            idTypeId,
            idNumber,
            selfPhoto: selfPhoto,
            selfPhotoUrl,
            idFrontImg: idFrontImg,
            idFrontImgUrl,
            idBackImg: idBackImg,
            idBackImgUrl,
            addressProofTypeId,
            country,
            postalCode,
            province,
            city,
            address,
            addressProof,
            addressProofUrl,
            identityId: idTypeId,
            addressProofId: addressProofTypeId,
        };
        createGuarantor(data);
        setCreateAction(true);
    };
    const [dialogOpen, setDialogOpen] = useState(false);

    const [fileIndex, setFileIndex] = useState(0);
    const removeSelfPhoto = (e) => {
        setFileIndex(1);
        setDialogOpen(true);
    };
    function closeDialog() {
        setDialogOpen(false);
    }
    const removeFile = (index) => {
        if (index === 1) {
            setSelfPhotoUrl("");
            setSelfPhoto("");
        } else if (index === 2) {
            setIdFrontImgUrl("");
            setidFrontImg("");
        } else if (index == 3) {
            setIdBackImgUrl("");
            setidBackImg("");
        } else if (index == 4) {
            setAddressProofUrl("");
            setAddressProof("");
            setDocNameShow(false);
        }
        setDialogOpen(false);
    };
    const removeFrontPhoto = (e) => {
        setFileIndex(2);
        setDialogOpen(true);
    };
    const removeBackPhoto = (e) => {
        setFileIndex(3);
        setDialogOpen(true);
    };
    const removeAddressFile = (e) => {
        setFileIndex(4);
        setDialogOpen(true);
    };
    const [maxDate, setMaxDate] = useState("");

    useEffect(() => {
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        var day = new Date().getDate();
        var date = new Date(year - 18, month, day);
        setMaxDate(date);
        setAllValues({ ...allValues, birthday: date });
    }, []);
    const handleDateChange = (date) => {
        setAllValues({ ...allValues, birthday: date });
    };
    return (
        <main className={classes.layout}>
            <LoaderComponent
                isLoading={isLoadingCreateGuarantor}
            />
            <div className={classes.mainContent}>
                <Typography
                    className={classes.title}
                    component="h3"
                    variant="h4"
                >
                    {t('CAPTURE_GUARANTOR_INFO') || "Capture guarantor information"}
                </Typography>
                <Typography
                    className={classes.titleLabel}>
                    {t('GUARANTOR_PROFILE') || "Guarantor Profile"}
                </Typography>
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('GUARANTOR_PROFILE')||"Guarantor Profile"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            color: '#5F56A6'
                                        }}
                                    >
                                        {selfPhoto.length < 1 && (
                                            <div className="file-input-column" style={{ color: '#5F56A6' }}>
                                                <input
                                                    type="file"
                                                    id="selfPhoto"
                                                    style={{ color: '#5F56A6' }}
                                                    className="file"
                                                    name="selfPhoto"
                                                    onChange={
                                                        handleSelfImageChange
                                                    }
                                                //required
                                                />
                                                <label htmlFor="selfPhoto" style={{ color: '#5F56A6' }}>
                                                    <PersonIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "68px",
                                                            display: "block",
                                                            color: '#CCCCE5'
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            display: "block",
                                                            color: '#CCCCE5'
                                                        }}
                                                    >
                                                        {t('TAP_TO_UPLOAD_PHOTO') || "Tap to upload photo"}
                                                    </span>
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer} style={{ color: '#5F56A6' }}>
                                            <img
                                                src={selfPhoto}
                                                className={
                                                    classes.selfPhotoImage
                                                }
                                            />
                                            {selfPhoto && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeSelfPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('RELATIONSHIP') || "Relationship With Applicant"}
                                            </InputLabel>
                                            <Select
                                                id="relationship"
                                                name="relationShip"
                                                value={relationShip}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    RELATIONSHIP_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}></Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('SALUTATION') || "Salutation"}</InputLabel>
                                            <Select
                                                id="salutation"
                                                name="salutation"
                                                value={salutation}
                                                onChange={changeHandler}
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required",
                                                ]}
                                                required
                                            >
                                                {map(
                                                    SALUTATION_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            id="name"
                                            name="name"
                                            value={name}
                                            InputLabelProps={{ required: false }}
                                            label={t('FULL_NAME')||"Full name"}
                                            fullWidth
                                            onChange={changeHandler}
                                            validators={[
                                                "isValidFullName",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid full name",
                                                "this field is required",
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={t('DOB') || "Date of Birth"}
                                                format="dd/MM/yyyy"
                                                maxDate={maxDate}
                                                name="birthday"
                                                value={birthday}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            //required
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                            style={{ marginTop: "16px" }}
                                        >
                                            <InputLabel>{t('GENDER') || "Gender"}</InputLabel>
                                            <Select
                                                id="gender"
                                                name="gender"
                                                value={gender}
                                                onChange={changeHandler}
                                                required
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required",
                                                ]}
                                                required
                                            >
                                                {map(
                                                    GENDER_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.label}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                        InputLabelProps={{ required: false }}
                                            name="phone"
                                            value={phone}
                                            label={t('MOBILE') || "Mobile number"}
                                            fullWidth
                                            onChange={changeHandler}
                                            type="number"
                                            validators={[
                                                "isValidMobileNumber",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid mobile number",
                                                "this field is required",
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                        id="email"
                                            label={t('EMAIL') || "Email address(optional)"}
                                            className={classes.fullWidth}
                                            onChange={changeHandler}
                                            name="email"
                                            validators={["isEmail"]}
                                            errorMessages={[
                                                "Please enter a valid email",
                                            ]}
                                            value={email}
                                            type="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                        id="country"
                                            name="country"
                                            label={t('COUNTRY') || "Country"}
                                            value={APP_CONFIGS.COUNTRY}
                                            disabled
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator
                                        InputLabelProps={{ required: false }}
                                            name="postalCode"
                                            label={t('POSTAL_CODE') || "Postal code"}
                                            type="number"
                                            value={postalCode}
                                            onChange={changePostalCode}
                                            inputProps={{ maxLength: APP_CONFIGS.VALID_POSTAL_CODE}}
                                            fullWidth
                                            validators={[
                                                "isValidPostalCode",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid postal code",
                                                "this field is required",
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DISTRICT_PROVINCE') || "District / Province"}
                                            </InputLabel>
                                            <Select
                                            id="province"
                                                name="province"
                                                value={province}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    districtOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('CITY_TOWN') || "City / Town"}</InputLabel>
                                            <Select
                                            id="city"
                                                name="city"
                                                value={city}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    cityOptions,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextValidator
                                        InputLabelProps={{ required: false }}
                                            name="address"
                                            label={t('ADDRESS') || "Address"}
                                            value={address}
                                            onChange={changeHandler}
                                            fullWidth
                                            validators={[
                                                "isValidAddress",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid address",
                                                "this field is required",
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('EMPLOYMENT_TYPE') || "Employment type"}
                                            </InputLabel>
                                            <Select
                                            id="employmentType"
                                                name="employmentTypeId"
                                                value={employmentTypeId}
                                                onChange={changeEmploymentType}
                                                required
                                            >
                                                {map(
                                                    employmentTypeList,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.id}
                                                        >
                                                            {
                                                                item.employmentType
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('INDUSTRY_BUSINESS_TYPE') || "Industry / Business type"}
                                            </InputLabel>
                                            <Select
                                            id="industry"
                                                name="industryId"
                                                value={industryId}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    industryTypeList,
                                                    (item, key) => (
                                                        <MenuItem
                                                        id="industryMenuOption"
                                                            key={key}
                                                            value={item.id}
                                                        >
                                                            {item.industry}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                        InputLabelProps={{ required: false }}
                                            name="employerName"
                                            label={t('EMPLOYER_COMPANY_NAME') || "Employer &amp; Company name"}
                                            value={employerName}
                                            onChange={changeHandler}
                                            fullWidth
                                            validators={[
                                                "isValidEmployerName",
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Please enter a valid employer name",
                                                "this field is required",
                                            ]}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}>
                            {t('GUARANTOR_ID_PROOF') || "Guarantor ID Proof"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('GUARANTOR_ID_PROOF')||"Guarantor ID Proof"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>{t('ID_TYPE') || "ID type"}</InputLabel>
                                            <Select
                                            id="idType"
                                                name="idTypeId"
                                                value={idTypeId}
                                                onChange={changeHandler}
                                                required
                                            >
                                                {map(
                                                    ID_TYPE_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                        id="idNumber"
                                            name="idNumber"
                                            label={t('ID_NUMBER') || "ID number"}
                                            value={idNumber}
                                            onChange={changeHandler}
                                            fullWidth
                                            type="number"
                                        // validators={[
                                        //     "isValidIdNumber",
                                        //     "required",
                                        // ]}
                                        // errorMessages={[
                                        //     "Please enter a valid id number",
                                        //     "this field is required",
                                        // ]}
                                        //required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={{ marginBottom: "8px" }}
                                        >
                                            {t('FRONT_ID_OPTIONAL') || "Front side of ID (Optional)"}
                                        </Typography>
                                        {idFrontImg.length < 1 && (
                                            <div className="file-input" style={{ borderColor: '#5F56A6' }}>
                                                <input
                                                    type="file"
                                                    id="idFrontImg"
                                                    className="file"
                                                    name="idFrontImg"
                                                    onChange={handleImageChange}
                                                    style={{ borderColor: '#5F56A6' }}
                                                //required
                                                />
                                                <label htmlFor="idFrontImg">
                                                    <AddCircleOutlineIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "64px",
                                                            color: '#CCCCE5'
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer}>
                                            <img
                                                src={idFrontImg}
                                                className={classes.bfPhoto}
                                            />
                                            {idFrontImg && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeFrontPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={{ marginBottom: "8px" }}
                                        >
                                            {t('BACK_ID_OPTIONAL') || "Back side of ID (Optional)"}
                                        </Typography>
                                        {idBackImg.length < 1 && (
                                            <div className="file-input" style={{ color: '#5F56A6' }}>
                                                <input
                                                    type="file"
                                                    id="idBackImg"
                                                    className="file"
                                                    name="idBackImg"
                                                    onChange={
                                                        handleBackImageChange
                                                    }
                                                //required
                                                />
                                                <label htmlFor="idBackImg">
                                                    <AddCircleOutlineIcon
                                                        style={{
                                                            height: "100px",
                                                            width: "64px",
                                                            color: '#CCCCE5'
                                                        }}

                                                    />
                                                </label>
                                            </div>
                                        )}
                                        <div className={classes.photoContainer}>
                                            <img
                                                src={idBackImg}
                                                className={classes.bfPhoto}
                                            />
                                            {idBackImg && (
                                                <DeleteOutlineIcon
                                                    className={
                                                        classes.photoIcon
                                                    }
                                                    onClick={removeBackPhoto}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography
                            className={classes.titleLabel}>
                            {t('GUARANTOR_ADDRESS_PROOF') || "Gurantor Address Proof (Optional)"}
                        </Typography>
                        <Paper className={classes.paper}>
                            {/* <PaperTitle
                                classes={classes}
                                text={t('GUARANTOR_ADDRESS_PROOF')||"Gurantor Address Proof (Optional)"}
                            /> */}
                            <div className={classes.paperContent}>
                                <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="left"
                                    spacing={4}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel>
                                                {t('DOCUMENT_TYPE') || "Document type"}
                                            </InputLabel>
                                            <Select
                                            id="documentType"
                                                name="addressProofTypeId"
                                                value={addressProofTypeId}
                                                onChange={changeHandler}
                                            //required
                                            >
                                                {map(
                                                    ADDRESS_PROOF_OPTIONS,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Box component="span" sx={{ p: 2, border: '1px dashed #5F56A6' }}>
                                            {addressProof.length < 1 && (
                                                <div className="file-input-inline">
                                                    <input
                                                        type="file"
                                                        id="addressProof"
                                                        className="file"
                                                        name="addressProof"
                                                        onChange={
                                                            handleAddressProof
                                                        }
                                                        style={{ color: '#5F56A6' }}
                                                    //required
                                                    />
                                                    <label
                                                        htmlFor="addressProof"
                                                        className={
                                                            classes.inlineUpload
                                                        }
                                                    >
                                                        <AddPhotoAlternateIcon style={{ color: '#5F56A6' }} />
                                                        <Typography
                                                            style={{
                                                                marginLeft: "8px",
                                                            }}
                                                            style={{ color: '#5F56A6' }}
                                                        >
                                                            {t('TAP_TO_UPLOAD_DOCUMENT') || "Tap to upload document"}
                                                        </Typography>
                                                    </label>
                                                </div>
                                            )}
                                            {docNameShow && (
                                                <div
                                                    className={classes.docContainer}

                                                >
                                                    <Link
                                                        href={addressProof}
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                        underline="always"
                                                    >
                                                        {docName}
                                                    </Link>
                                                    <DeleteOutlineIcon

                                                        onClick={removeAddressFile}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Box>
                                    </Grid>

                                </Grid>
                            </div>
                        </Paper>
                        <div className={classes.buttonWrapper}>
                            <Button
                            id="saveBtn"
                                variant="outlined"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                onClick={handleSaveExit}
                            >
                                {t('SAVE_AND_EXIT') || "Save &amp; Exit"}
                            </Button>
                            <Button
                                id="submitBtn"
                                style={{
                                    backgroundColor: "#5F56A6"
                                }}
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.actionBtn}
                                type="submit"
                            >
                                {t('NEXT') || "Next"}
                            </Button>
                        </div>
                    </ValidatorForm>
                    <AddRemarksDialogBoxConatiner classes={classes} applicationId={applicationId} open={openRemarks} currentUser={currentUser} link={saveAndExitLink} />
                </Fragment>
                <SelfAlertDialog
                    open={dialogOpen}
                    closeDialog={closeDialog}
                    removeFile={removeFile}
                    fileIndex={fileIndex}
                    t={t}
                />
            </div>
        </main>
    );
};
function SelfAlertDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const { removeFile, fileIndex, t } = props;

    const handleClose = () => {
        props.closeDialog();
    };
    const handleRemove = () => {
        props.removeFile(props.fileIndex);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('DELETE_FILE') || "Delete file"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('DELETE_MESSAGE') || "Do You want to delete this file now?"}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        {t('NO') || "No"}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleRemove}
                        color="primary"
                        autoFocus
                    >
                        {t('YES') || "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default withStyles(inputStyles)(Guarantor);
