import { useState, useEffect, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Alert } from '@material-ui/lab';
import { TYPE_OPTIONS, DISCOUNT_TYPE } from "../../../constants/appConstants";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import { useLocation } from "react-router-dom";

import { ValidatorForm } from 'react-material-ui-form-validator';
import { AddRemarksDialogBoxConatiner } from "../../static_components";
import { useTranslation } from "react-i18next";
const SelectVehicle = (props) => {
    const {
        classes,
        history,
        noHeader,
        getVehicles,
        vehicleList,
        createApplication,
        createdApplication,
        createdUser,
        currentUser,
        applicationVehicle,
        applicationDetail,
        getApplicationById,
        updateApplicationStatus,
        discount,
        createDiscount,
        getDiscount,
        updateDiscount
    } = props;
    const { match } = props;
    const location = useLocation();
    const { clientId, applicationId } = match.params;
    const [color, setColor] = useState("");
    const [model, setModel] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState("");
    const [colorOptions, setColorOptions] = useState([]);
    const [openAlert, setInfoAlert] = useState(false)
    const [vehicleId, setVehicleId] = useState('')
    const [remarksOpen, setRemarksOpen] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState(0.0);
    const [discountedPrice, setDiscountedPrice] = useState("");
    const [discountAmount, setDiscountAmount]= useState(0.0);
    const {t} = useTranslation();
    
    //const [applicationId, setApplicationId] = useState(null);
    const [saveAndExitLink, setSaveAndExitLink] = useState(`/dashboard`);

    const handleNext = async () => {
        const params = {
            applicationId: applicationId,
            data: {
                vehicleId: vehicleId,
                insuranceType: type,
                status:"Inquiry"
            },
        };
        await updateApplicationStatus(params);
        getApplicationById(applicationId);
        if(discount.id){
            let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            };
            updateDiscount(params);
        } else {
           let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            }
            console.log(params);
            createDiscount(params);
        }
        console.log("vehicle id : "+vehicleId);
        history.push(`/client/${clientId}/application/${applicationId}/payment`);
    };
    const handleCancel = () => {
        const params = {
            applicationId: applicationId,
            data: {
                vehicleId: vehicleId,
                insuranceType: type,
                status:"Inquiry"
            },
        };
        updateApplicationStatus(params);
        if(discount.id){
            let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            };
            updateDiscount(params);
        } else {
           let params = {
                applicationId: applicationId,
                discountFlat: discountType==='FLAT'? Number(discountValue):0,
                discountPercentage: discountType==='PERCENTAGE'? Number(discountValue):0,
                vehiclePrice: price,
                discountedPrice: discountedPrice
            }
            console.log(params);
            createDiscount(params);
        }
        console.log("vehicle id : "+vehicleId);
        setRemarksOpen(true);
    };

    const handleInfoAlertClose = () => setInfoAlert(false)

    useEffect(() => {
        getDiscount(applicationId);
        getVehicles();
        getApplicationById(applicationId);
    }, []);

    useEffect(()=>{
        setDiscountType(discount?.discountFlat>0? 'FLAT': (discount?.discountPercentage>0?'PERCENTAGE':''));
        setDiscountValue(discount?.discountFlat || discount?.discountPercentage || 0.0);
        setDiscountedPrice(discount.discountedPrice || "")
    },[discount])

    useEffect(() => {
        if (
            applicationVehicle &&
            applicationVehicle.model &&
            vehicleList.length > 0
        ) {
            const modelId = applicationVehicle.model.id;
            setModel(modelId);

            const colorOptionGroup = filter(vehicleList, function (vehicle) {
                return vehicle.model.id == modelId;
            });
            const options = map(colorOptionGroup, (item) => {
                return {
                    label: item.color.name,
                    value: item.color.id,
                };
            });
            setColorOptions(options);
            applicationVehicle.color && setColor(applicationVehicle.color.id);
            applicationVehicle.insuranceType &&
                setType(applicationVehicle.insuranceType);
            applicationVehicle.price && setPrice(applicationVehicle.price);
            setVehicleId(applicationVehicle.id);
        }
    }, [applicationVehicle, vehicleList]);

    useEffect(() => {
        if(!type)
        {
            setType(applicationVehicle.insuranceType || applicationDetail.insuranceType);
        }
        
    }, [applicationDetail,applicationVehicle])

    useEffect(()=>{
        if(discountType==="FLAT" && Number(price)>0 && discountValue>0.0){
            setDiscountedPrice(price - discountValue);
            setDiscountAmount(parseFloat(discountValue).toFixed(2));
        } else if(discountType==="PERCENTAGE" && Number(price)>0 && discountValue>0.0){
            setDiscountedPrice(price*(1-(discountValue/100)));
            setDiscountAmount(parseFloat(price*discountValue/100).toFixed(2));
        } else {
            setDiscountedPrice(price)
        }
        
    },[discountValue, discountType, price])

    // useEffect(() => {
    //     if (
    //         createdApplication &&
    //         createdApplication.id &&
    //         createdApplication.status == "Pending"
    //     ) {
    //         history.push(`/client/${clientId}/application/${createdApplication.id}/payment`);
    //     }

    //     if (
    //         createdApplication &&
    //         createdApplication.id &&
    //         createdApplication.status == "Inquiry"
    //     ) {
    //         //history.push(`/dashboard`);``
    //         setApplicationId(createdApplication.id);
    //         setRemarksOpen(true);
    //     }

    // }, [createdApplication]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
        //get vehicle price
        const vehicle = find(vehicleList, function (o) {
            return o.model.id == model && o.color.id == event.target.value;
        });
        setPrice(vehicle.price);
        setVehicleId(vehicle.id)
    };
    const handleModelChange = (event) => {
        setModel(event.target.value);
        getColorByModel(event.target.value);
        setPrice("");
        setColor("");
        setVehicleId('');
        setDiscountType("");
        setDiscountValue(0.0);
    };

    const getColorByModel = (modelId) => {
        const colorOptionGroup = filter(vehicleList, function (vehicle) {
            return vehicle.model.id == modelId;
        });
        const options = map(colorOptionGroup, (item) => {
            return {
                label: item.color.name,
                value: item.color.id,
            };
        });
        setColorOptions(options);
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleDiscountTypeChange = (event) =>{
        setDiscountType(event.target.value);
        setDiscountValue(0.0);
    }

   const handleDiscountChange = (event) =>{
       setDiscountValue(event.target.value);
   }

    const getVehiclesModel = () => {
        const vehicleModels = map(vehicleList, (item) => {
            return {
                label: item.model.name,
                value: item.model.id,
            };
        });
        const uniqueList = Array.from(
            new Set(vehicleModels.map((a) => a.label))
        ).map((label) => {
            return vehicleModels.find((a) => a.label === label);
        });
        return uniqueList;
    };
    return (
        <main className={classes.layout}>
            <CssBaseline />
            {openAlert && (
                <div className='info-alert'>
                <Alert variant="filled" severity="info" onClose={handleInfoAlertClose}>Customer profile is successfully created.</Alert>
            </div>
            )}
            <div className={classes.mainContent}>
                
                    <Typography
                        className={classes.title}
                        component="h3"
                        variant="h4"
                    >
                        {t('SELECT_VEHICLE')||"Select vehicle"}
                    </Typography>
            
                <Fragment>
                    <CssBaseline />
                    <ValidatorForm onSubmit={handleNext}>
                    <Paper className={classes.paper}>
                        <div className={classes.paperContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>{t('VEHICLE_MODEL')||"Vehicle model"}</InputLabel>
                                        <Select
                                            value={model}
                                            onChange={handleModelChange}
                                            required
                                        >
                                            {map(
                                                getVehiclesModel(),
                                                (item, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel>{t('VEHICLE_COLOR')||"Vehicle color"}</InputLabel>
                                        <Select
                                            value={color}
                                            onChange={handleColorChange}
                                            required
                                        >
                                            {map(colorOptions, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        <FormControl
                                            id = "Discount"
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <InputLabel>
                                                {t('DISCOUNT_TYPE')||"Discount type"}
                                            </InputLabel>
                                            <Select
                                                value={discountType}
                                                onChange={handleDiscountTypeChange}
                                                fullWidth
                                                required
                                            >
                                                {map(
                                                    DISCOUNT_TYPE,
                                                    (item, key) => (
                                                        <MenuItem
                                                            key={key}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                   {(discountType==="FLAT" || discountType==="PERCENTAGE") && <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="discount"
                                            label={"Discount " + (discountType==="FLAT"?'(৳)':'(%)')}
                                            fullWidth
                                            onChange={handleDiscountChange}
                                            value={discountValue}
                                            
                                        />
                                    </Grid>}
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel>{t('INSURANCE')||"Insurance type"}</InputLabel>
                                        <Select
                                            value={type}
                                            onChange={handleTypeChange}
                                            fullWidth
                                            required
                                        >
                                            {map(TYPE_OPTIONS, (item, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={item.value}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} sm={12}>
                                <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className={classes.label}
                                        >
                                            {t('VEHICLE_PRICE')||"Vehicle price (৳)"} 
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            className={classes.labelContent}
                                        >
                                            {discountedPrice}
                                        </Typography>
                                </Grid> */}
                            </Grid>
                        </div>
                        <div className={classes.paperContentLower}>
                            <Grid container spacing={4}>
                             <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('VEHICLE_PRICE')||"Vehicle price (৳)"} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body4"
                                //gutterBottom
                                className={classes.labelContent}
                            >
                                {parseFloat(price).toFixed(2)}
                            </Typography>
                            </Grid>
                            </Grid>
                            </div>
                           {(discountType==="FLAT" || discountType==="PERCENTAGE") &&  <div className={classes.paperContentColoured}>
                            <Grid container spacing={4}>
                            {(discountType==="FLAT" || discountType==="PERCENTAGE") &&<> <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {"Discounted Amount (৳)"} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body4"
                                //gutterBottom
                                className={classes.labelContent}
                            >
                                {parseFloat(discountAmount).toFixed(2)}
                            </Typography>
                            </Grid></>}
                            {(discountType==="FLAT" || discountType==="PERCENTAGE") &&<> <Grid item xs={12} sm={8}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {"Vehicle Price after Discount (৳)"} 
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body4"
                                //gutterBottom
                                className={classes.labelContent}
                            >
                                {parseFloat(discountedPrice).toFixed(2)}
                            </Typography>
                            </Grid></>}
                            </Grid>
                            </div>}
                    </Paper>
                    <div className={classes.buttonWrapper}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={handleCancel}
                        >
                            {t('SAVE_AND_EXIT')||"Save &amp; exit"}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                        >
                            {t('NEXT')||"Next"}
                        </Button>
                    </div>
                    </ValidatorForm>
                    <AddRemarksDialogBoxConatiner classes={classes} applicationId={applicationId} open={remarksOpen} currentUser={currentUser} saveAndExitLink={saveAndExitLink} />
                </Fragment>
            </div>
        </main>
    );
};
export default withStyles(inputStyles)(SelectVehicle);
