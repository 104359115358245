import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography, AppBar, Toolbar, Button, FormGroup } from "@material-ui/core";
import PropTypes from 'prop-types'
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import { useTranslation } from "react-i18next";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        height: "100px",
        backgroundColor: "red",
    },
    resize: {
        fontSize: 50,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


const ProcessingFeesDialogItem = (props) => {
    const { classes, open, handleUpdateCheck, handleUpdate,handleCancel, allFees} = props;
    const [value, setValue] = React.useState("Controlled");
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const {
        processingFeeChecked,
        earlySettlementFeeChecked,
        additionalChargesChecked,
        documentationFeesChecked,
        stampDutyChargesChecked,
        loanRebookChargesChecked,
        chequeBounceChargesChecked,
        chequeSwapChargesChecked,
        exchangeChargesChecked
    } = allFees;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setIsOpen(open)
    }, [open]);

    const handleSave =() =>{
       handleUpdate();
    }

    const handleClose = () =>{
        handleCancel()
    }
    
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            fullWidth={true}
            //maxWidth={"s"}
        >
            <DialogTitle
                id="customized-dialog-title"
                style={{ marginLeft: "10px" }}
                onClose={handleClose}
            >
                {"Processing Fees Configuration"}
            </DialogTitle>
            <DialogContent>
                <FormGroup>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={processingFeeChecked}
                    onChange={handleUpdateCheck}
                    name="processingFeeChecked"
                     />} label="Processing Fees" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={earlySettlementFeeChecked}
                    onChange={handleUpdateCheck}
                    name="earlySettlementFeeChecked"
                     />} label="Early Settlement Fees" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={additionalChargesChecked}
                    onChange={handleUpdateCheck}
                    name="additionalChargesChecked"
                     />} label="Additional Charges" />
                    </Grid>
                    </Grid>

                    
                    {/* <Divider orientation="vertical" flexItem /> */}
                    
                    <hr style={{
                        color: '#000000',
                        //backgroundColor: '#000000',
                         height: .5,
                        borderColor : '#000000'
                    }}/>

                    <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={documentationFeesChecked}
                    onChange={handleUpdateCheck}
                    name="documentationFeesChecked"
                     />} label="Documentation Fees" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={stampDutyChargesChecked}
                    onChange={handleUpdateCheck}
                    name="stampDutyChargesChecked"
                     />} label="Stamp Duty Charges" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={loanRebookChargesChecked}
                    onChange={handleUpdateCheck}
                    name="loanRebookChargesChecked"
                     />} label="Loan Rebook Charges" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={chequeBounceChargesChecked}
                    onChange={handleUpdateCheck}
                    name="chequeBounceChargesChecked"
                     />} label="Cheque Bounce Charges" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={chequeSwapChargesChecked}
                    onChange={handleUpdateCheck}
                    name="chequeSwapChargesChecked"
                     />} label="Cheque Swap Charges" />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                    <FormControlLabel control={<Checkbox 
                    checked={exchangeChargesChecked}
                    onChange={handleUpdateCheck}
                    name="exchangeChargesChecked"
                     />} label="Exchange Charges" />
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <div className={classes.actionButtonWrapper}>
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={classes.actionBtn}
                            onClick={handleClose}
                        >
                            {t('CANCEL')||"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.actionBtn}
                            type="submit"
                            onClick={handleSave}
                        >
                            {t('UPDATE')||"Save"}
                        </Button>
                    </div>
                    </Grid>
                </FormGroup>
            </DialogContent>
        </Dialog>
    );
};
ProcessingFeesDialogItem.propTypes = {
    handleUpdate:PropTypes.func,
    open:PropTypes.bool,
    allFees:PropTypes.any,
    handleUpdateCheck: PropTypes.func,
    handleCancel: PropTypes.func
}

export default withStyles(inputStyles)(ProcessingFeesDialogItem);