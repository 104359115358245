import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import CreateCustomer from './CreateCustomer'
import { createCustomer } from '../../store/actions/customer'
import { getCities, generateOtp, verifyOtp } from '../../store/actions/common'

const CreateCustomerContainer = (props: any) => <CreateCustomer {...props} />

const mapStateToProps = (state: any) => {
    return {
        createdUser: state.customer.createdUser,
        isLoadingCreateCustomer: state.customer.isLoadingCreateCustomer,
        isLoadingGenerateOtp: state.common.isLoadingGenerateOtp,
        isLoadingVerifyOtp: state.common.isLoadingVerifyOtp,
        cityList: get(state.common.cityList, 'data'),
        otpVerified: get(state.common.otpVerified, 'data'),
        currentUser: get(state.auth, 'user')
    }
}

const mapDispatchToProps = {
    createCustomer,
    getCities,
    generateOtp,
    verifyOtp
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateCustomerContainer))
)