import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Card, IconButton, Box, Toolbar } from "@material-ui/core";
import { Divider } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { mapValues, omit, filter, groupBy } from "lodash";
import BranchFilter from "./BranchFilter";

const LoanApplicationStatus = (props) => {
    const {
        classes,
        applicationInfo,
        monthlyStats,
        outletList,
        filterByBranch,
    } = props;

    return (
        <Paper className={classes.paper}>
            <PaperTitle
                classes={classes}
                text={"Loan Application Status "}
                outletList={outletList}
                filterByBranch={filterByBranch}
            />
            <div className={classes.paperContent}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Card className={classes.grayCard}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Customer accepted
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.count}
                            >
                                {applicationInfo && applicationInfo.Accepted
                                    ? applicationInfo.Accepted
                                    : 0}
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Card className={classes.grayCard}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Approved
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.count}
                            >
                                {applicationInfo && applicationInfo.Approved
                                    ? applicationInfo.Approved
                                    : 0}
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Card className={classes.grayCard}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Rejected
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.count}
                            >
                                {applicationInfo && applicationInfo.Rejected
                                    ? applicationInfo.Rejected
                                    : 0}
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Card className={classes.grayCard}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Pending to review
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.count}
                            >
                                {applicationInfo && applicationInfo.Review
                                    ? applicationInfo.Review
                                    : 0}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Card className={classes.grayCard}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Pending to submit
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.count}
                            >
                                {applicationInfo && applicationInfo.Pending
                                    ? applicationInfo.Pending
                                    : 0}
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.greyBox}>
                        <Link
                            className="no-decoration"
                            to="/applications?filter=withdrawn"
                        >
                            <Card className={classes.grayCard}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    Withdrawn
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    className={classes.count}
                                >
                                    {applicationInfo?.Withdrawn
                                        ? applicationInfo.Withdrawn
                                        : 0}
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
                <Divider className="box-divider" />
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                        {monthlyStats && (
                            <GroupedBar monthlyStats={monthlyStats} />
                        )}
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
};

const GroupedBar = (props) => {
    const { monthlyStats } = props;
    const grouped = mapValues(groupBy(monthlyStats, "month"), (clist) =>
        clist.map((car) => omit(car, "month"))
    );
    const monthLabel = Object.keys(grouped);
    const reviewList = filter(monthlyStats, function (o) {
        return o.status == "Review";
    });
    const approveList = filter(monthlyStats, function (o) {
        return o.status == "Approved";
    });
    const rejectList = filter(monthlyStats, function (o) {
        return o.status == "Rejected";
    });
    const approveGrouped = mapValues(groupBy(approveList, "month"), (clist) =>
        clist.map((car) => omit(car, "month"))
    );
    const reviewGrouped = mapValues(groupBy(reviewList, "month"), (clist) =>
        clist.map((car) => omit(car, "month"))
    );
    const rejectedGrouped = mapValues(groupBy(rejectList, "month"), (clist) =>
        clist.map((car) => omit(car, "month"))
    );
    
    const reviewGroupedObjKeys = Object.keys(reviewGrouped)
    const approveGroupedObjKeys = Object.keys(approveGrouped)
    const rejectedGroupedObjKeys = Object.keys(rejectedGrouped)
    
    const data = {
        labels: monthLabel,
        datasets: [
            {
                label: "Newly submitted",
                data: [
                    reviewGroupedObjKeys && reviewGrouped[reviewGroupedObjKeys[0]] ? reviewGrouped[reviewGroupedObjKeys[0]].length : 0,
                    reviewGroupedObjKeys && reviewGrouped[reviewGroupedObjKeys[1]] ? reviewGrouped[reviewGroupedObjKeys[1]].length : 0,
                    reviewGroupedObjKeys && reviewGrouped[reviewGroupedObjKeys[2]] ? reviewGrouped[reviewGroupedObjKeys[2]].length : 0
                ],
                backgroundColor: "#0486E2",
            },
            {
                label: "Approved",
                data: [
                    approveGroupedObjKeys && approveGrouped[approveGroupedObjKeys[0]] ? approveGrouped[approveGroupedObjKeys[0]].length : 0,
                    approveGroupedObjKeys && approveGrouped[approveGroupedObjKeys[1]] ? approveGrouped[approveGroupedObjKeys[1]].length : 0,
                    approveGroupedObjKeys && approveGrouped[approveGroupedObjKeys[2]] ? approveGrouped[approveGroupedObjKeys[2]].length : 0
                ],
                backgroundColor: "#39B542",
            },
            {
                label: "Rejected",
                data: [0, 0, 0],
                backgroundColor: "#ED9807",
            },
            {
                label: "Withdrawn",
                data: [0, 0, 0],
                backgroundColor: "#BCBCBC",
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <>
            <Typography variant="subtitle1" gutterBottom component="div">
                Monthly trend
            </Typography>
            <Bar data={data} options={options} />
        </>
    );
};

const PaperTitle = (props) => {
    const { classes, text, outletList, filterByBranch } = props;
    return (
        <Toolbar className={classes.managementBox}>
            <Typography className={classes.boxLabel}>{text}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <BranchFilter
                classes={classes}
                outletList={outletList}
                filterByBranch={filterByBranch}
            />
        </Toolbar>
    );
};

export default withStyles(inputStyles)(LoanApplicationStatus);
