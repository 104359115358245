import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { createLogger } from 'redux-logger'
import languageListener from '../middlewares/languageListener'
import initData from '../middlewares/initData'
import { rootEpic } from '../middlewares/epics'
import commonReducer from './reducers/common'
import authReducer from './reducers/auth'
import customerReducer from './reducers/customers'
import userReducer from './reducers/users'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
    customer: customerReducer,
    user: userReducer
})

const logger = createLogger({
    level: 'info',
    duration: true,
    collapsed: true
})

const epicMiddleware = createEpicMiddleware()
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(languageListener, epicMiddleware, logger, initData))
)

epicMiddleware.run(rootEpic)

export default store
