import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import SupportingDocument from './SupportingDocument'
import { createCustomerDocument } from '../../store/actions/customer'
import { getCities } from '../../store/actions/common'

const SupportingDocumentContainer = (props: any) => <SupportingDocument {...props} />

const mapStateToProps = (state: any) => ({
    cityList: get(state.common.cityList, 'data'),
    documentInfo:state.customer.documentInfo && state.customer.documentInfo.data||{}  
})

const mapDispatchToProps = {
    createCustomerDocument,
    getCities
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SupportingDocumentContainer))
)
