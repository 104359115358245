import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import ApplicationComponent from './ApplicationListComponent'
import { getApplicationList } from '../../store/actions/customer'
import { isManager } from '../../utils/helper'

const ApplicationListContainer = props => <ApplicationComponent {...props} />

const mapStateToProps = state => ({
    isManager: state.auth.user ? isManager(state.auth.user) : false,
    isLoadingGetApplications: state.auth.isLoadingGetApplications,
    applicationList: state.auth.applicationList
})

const mapDispatchToProps = {
    getApplicationList
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationListContainer))
)