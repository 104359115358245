import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ApplicationDetailComponent from "./ApplicationDetailComponent";
import {
  getApplicationById,
  getGuarantorsById,
  getReferenceById,
  updateApplicationStatus,
  getPaymentListById,
  calculateCde,
  activateApplication,
  updateApplicationLoan,
  updateApplicationReference,
  updateApplicationGuarantor,
  getTransctionsByStringId,
  createTransction,
  createLoan,
  createProfileReference,
  createGuarantor,
  createProfileEmployment,
  createCustomerDocument,
  addRemarks,
  getRemarks,
  addVehicleDetails,
  getVehicleDetails,
  updateVehicleDetails,
  updateProfile,
  updateEmployment,
  createIdentityDocument,
  createAddressProof,
  createIncomeProof,
  updateIdentityDocument,
  updateAddressProof,
  updateIncomeProof,
  updateCustomerData,
  createAdditionalInfo,
  getAdditionalInfo,
  updateAdditionalInfo,
  getDocumentList,
  createApplication,
  updateDocument,
  createDiscount,
  getDiscount,
  updateDiscount,
  getFees,
  createFees,
  updateFees
} from "../../store/actions/customer";
import get from 'lodash/get'
import { isManager } from '../../utils/helper'

const ApplicationDetailContainer = (props) => (
  <ApplicationDetailComponent {...props} />
);

const mapStateToProps = state => ({
    isManager: state.auth.user ? isManager(state.auth.user) : false,
    applicationDetail: state.customer.application||{},
    totalCost: state.customer.application ? state.customer.application.vehicle.price : 0,
    applicationVehicle: get(state.customer.application, 'vehicle') || {},
    customer: get(state.customer.application, 'customer') || {},
    applicationLoan: get(state.customer.application, 'loanDetails') || {},
    guarantorList:state.customer.guarantorList||[],
    updateInfo:state.customer.updateInfo||{},
    reference: state.customer.reference || [],
    paymentList:state.customer.paymentList||[],
    activateInfo: state.customer.activateInfo || [],
    status: get(state.customer.application, 'status'),
    currentUser: get(state.auth, 'user'),
    transactionList: get(state.customer, 'transactionList') || [],
    branch: get(state.auth.user.outlet, 'branchId') || '',
    transactionInfo: state.customer.transactionInfo,
    remarksHistory: state.customer.remarks || [],
    vehicleDetails: get(state.customer.vehicleDetails,'data') ||{},
    additionalInfo: get(state.customer.additionalInfo,'data') ||{},
    createdApplication: state.customer.createdApplication,
    documentList: state.customer.documentList || [],
    cdeInfo: get(state.customer.cdeInfo, "data") || {},
    discount: get(state.customer.discount,'data') ||{},
    fees: get(state.customer.fees,'data') ||{},
    isLoadingCalculateCde: state.customer.isLoadingCalculateCde,
})

const mapDispatchToProps = {
    getGuarantorsById,
    getApplicationById,
    updateApplicationStatus,
    getReferenceById,
    getPaymentListById,
    calculateCde,
    activateApplication,
    updateApplicationLoan,
    updateApplicationReference,
    updateApplicationGuarantor,
    getTransctionsByStringId,
    createTransction,
    createLoan,
    createProfileReference,
    createGuarantor,
    createProfileEmployment,
    createCustomerDocument,
    addRemarks,
    getRemarks,
    addVehicleDetails,
    getVehicleDetails,
    updateVehicleDetails,
    updateProfile,
    updateEmployment,
    createIdentityDocument,
    createIncomeProof,
    createAddressProof,
    updateIdentityDocument,
    updateIncomeProof,
    updateAddressProof,
    updateCustomerData,
    createAdditionalInfo,
    getAdditionalInfo,
    updateAdditionalInfo,
    getDocumentList,
    createApplication,
    updateDocument,
    createDiscount,
    getDiscount,
    updateDiscount,
    createFees,
    updateFees,
    getFees
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ApplicationDetailContainer))
);
