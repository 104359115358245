import { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ConfirmComponentContainer from "./ConfirmComponentContainer";
import Documents from "../../assets/img/documents.png";
import { useTranslation } from "react-i18next";

const ConfirmAdditionalInformation = (props) => {
    const { history, getApplicationById, applicationStringId, clientId } = props;
    const { applicationId } = props.match.params;
    const { t } = useTranslation();

    useEffect(() => {
        getApplicationById(applicationId)
    }, [applicationStringId])

    const handleExit = () => {
        history.push('/dashboard');
    }

    const handleNext = () => {
        history.push(`/client/${clientId}/application/${applicationId}/supporting-document`); // Todo: change route when implement this step
    };
    const content = <Box mb="16px" mt="52px" color="#212121" fontSize="24px">
        <Box mb="16px">
            {t('ADDITIONAL_INFO_REQUIRED') || "Additional information and documents are required before approving this loan application."}
        </Box>
        <Box>
            {t('APPLICATION_ID') || "The application ID is "}<b style={{ color: "#70b0ed" }}>#{applicationStringId}</b>.
        </Box>
    </Box >

    const buttons = <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="45%">
            <Button
                fullWidth
                variant="outlined"
                onClick={handleExit}
                type="submit"
                style={{ font: "16px", textTransform: "none", border: "1px solid #5F56A6", color: "#5F56A6", padding: "10px 0" }}
            >
                {t('EXIT') || "Exit"}
            </Button>
        </Box>

        <Box width="45%">
            <Button
                id="submitBtn"
                fullWidth
                variant="contained"
                onClick={handleNext}
                type="submit"
                style={{ backgroundColor: "#5F56A6", font: "16px", textTransform: "none", color: "white", padding: "10px 0" }}
            >
                {t('CONTINUE') || "Continue"}
            </Button>
        </Box>
    </Box>
    return (
        <ConfirmComponentContainer
            icon={Documents}
            content={content}
            buttons={buttons}
        />
    );
};

const PaperTitle = (props) => {
    const { classes, text } = props;
    return <Typography className={classes.paperLabel}>{text}</Typography>;
};

export default ConfirmAdditionalInformation;
