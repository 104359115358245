const inputStyles = {
    appBar: {
        bottom: "0",
        top: "auto",
    },
    appBottomBar: {
        bottom: "0",
        top: "auto",
        maxWidth: "768px",
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    menuButton: {
        marginLeft: "auto",
    },
    appTitle: {
        color: "#fff",
    },
    title: {
        fontSize: "28px",
        marginBottom: "1px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
    },
    titleLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '32px',
        color: '#212121',
        padding: '0px 0px 16px 4px',
        
    },
    subTitle: {
        marginBottom: '24px',
        fontSize: '16px',
        marginTop: '6px'
    },
    remarksTitle: {
        fontSize: "20px",
        marginBottom: "4px",
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto',
        paddingBottom: '0px',
        fontWeight: "600",
    },
    stepLabel: {
        fontSize: "16px",
        marginTop: "16px",
    },
    paper: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "8px",
        marginBottom: "24px",
    },
    paperPayemnt: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "6px",
        padding:"32px"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: "28px"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
    },
    actionButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        marginBottom: '10px'
    },
    margin24: {
        marginRight: "24px",
        textTransform: "none",
    },
    formControl: {
        width: "100%",
    },
    mainContent: {
        padding: "32px",
        maxWidth: "768px",
        margin: "0px auto",
        marginTop: "96px",
    },
    bottomContent: {
        padding: "32px",
        maxWidth: "768px",
        backgroundColor: "yellow",
    },
    paperLabel: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#212121",
        padding: "16px 0px 8px 32px",
        borderBottom: "1px solid #DCDEDF",
    },
    paperContent: {
        padding: "32px",
    },
    actionBtn: {
        marginRight: "24px",
        textTransform: "none",
        width: "150px",
    },
    label: {
        fontWeight: "300",
    },
    contentHeader: {
        marginBottom: "24px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    tabLabel: {
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "none",
        color: "black"
    },
    tabBarHeader: {
        boxShadow: "none",
        backgroundColor: "#fafafa",
        marginBottom: "24px",
        backgroundColor:'#EFEFEF'
    },
    approveButton: {
        backgroundColor: "#39b542",
        textTransform: "none",
        width: "150px",
        color: "white",
    },
    rejectButton: {
        backgroundColor: "#e22b16",
        textTransform: "none",
        width: "150px",
        color: "white",
    },
    resubmissionButton: {
        textTransform: "none",
        width:"180 px"
    },
    documentButton: {
        backgroundColor: "#e6e2fa",
        color: "black",
        marginBottom: 20,
        textTransform: "none",
    },
    fileInline: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    inlineUpload: {
        display: "flex",
        color: "#212121",
        cursor: "pointer",

        appBar: {
            position: "relative",
            backgroundColor: "#212121",
        },
        layout: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        menuButton: {
            marginLeft: "auto",
        },
        appTitle: {
            color: "#fff",
        },
        title: {
            fontSize: "20px",
            marginBottom: "8px",
        },
        stepLabel: {
            fontSize: "16px",
            marginTop: "16px",
        },
        paper: {
            boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
            borderRadius: "8px",
            marginBottom: "24px",
        },
        buttons: {
            display: "flex",
            justifyContent: "flex-end",
        },
        button: {
            marginTop: "28px",
            // marginLeft: theme.spacing(1),
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        margin24: {
            marginRight: "24px",
            textTransform: "none",
        },
        formControl: {
            width: "100%",
        },
        mainContent: {
            padding: "32px",
            maxWidth: "768px",
            margin: "0px auto",
        },
        paperLabel: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#212121",
            padding: "16px 0px 8px 32px",
            borderBottom: "1px solid #DCDEDF",
        },
        paperContent: {
            padding: "32px",
        },
        actionBtn: {
            marginRight: "24px",
            textTransform: "none",
            width: "250px",
        },
        label: {
            fontWeight: "300",
        },
        contentHeader: {
            marginBottom: "24px",
        },
        tabLabel: {
            fontSize: "12px",
            fontWeight: "400",
            textTransform: "none",
        },
        tabBarHeader: {
            
            //backgroundColor: "#fafafa",
            marginBottom: "24px",
        },
    },
    closeIcon: {
        fontSize: "37px",
        cursor: 'pointer'
    },
    boldLabel: {
        color: "#212121",
        fontWeight: "bold",
        fontSize: "27px",
    },
    docContainer: {
        display: "flex",
        alignItems: "center",
    },
    greenBtn: {
        backgroundColor: '#39B542',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
    rm64 : {
        paddingRight: '110px'
    },
    customerCount: {
        fontSize: '32px',
        fontWeight: '700'
    },
    paperRemarks: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "6px",
        marginBottom: "24px",
        maxHeight: "300px"
    },
    paperGeneral: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "6px",
        marginBottom: "24px",
        maxHeight: 270,
        height: 270,
        alignItems: "centre"
    },
    paperGeneralCde: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "6px",
        //marginBottom: "24px",
        maxHeight: 565 ,
        height: 565,
        alignItems: "centre"
    },
    staffActionBtn: {
        textTransform: 'unset',
        width: '158px',
        height: '44px',
        // backgroundColor: '#5F56A6'
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
       },
    paperContentNew: {
        padding: "32px",
        justifyContent: "space-between"
    },

    paperInvoice: {
        boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
        borderRadius: "6px",
        marginBottom: "24px",
        alignItems: "centre"
    },
  };
  



export default inputStyles;
