import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import { APP_CONFIGS } from "../../constants/appConstants";

const PaymentDetail = (props) => {
    const { classes, getPaymentDetailById, paymentDetail } = props;
    const { paymentId } = props.match.params;

    useEffect(() => {
        getPaymentDetailById(paymentId);
    }, [paymentId]);
    return (
        <main className={classes.layout}>
            <CssBaseline />
            <ApplicationHeader classes={classes} />
            <div className={classes.mainContent}>
                <div className={classes.contentHeader}>
                    <Typography
                        className={classes.title}
                        component="h3"
                        variant="h4"
                    >
                        Installment #1 Detail
                    </Typography>
                    <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.label}
                    >
                        {moment(paymentDetail.updatedAt).format("DD/MM/YYYY")}
                    </Typography>
                </div>
                <Fragment>
                    <ApplicationItem
                        paymentDetail={paymentDetail}
                        classes={classes}
                    />
                </Fragment>
            </div>
        </main>
    );
};

const ApplicationItem = (props) => {
    const { classes, paymentDetail } = props;
    return (
        <div>
            <Paper className={classes.paper}>
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Principle(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                24,667.00
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Fund utilization Fee(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                333.00
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ color: "#2495e6" }}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                EMI(৳)
                            </Typography>
                            <Typography
                                display="block"
                                component="h3"
                                gutterBottom
                            >
                                {paymentDetail.emiAmount}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Fee1(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {paymentDetail.fee1}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Fee2(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {paymentDetail.fee2}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Fee3(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {paymentDetail.fee3}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Rebate(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {paymentDetail.rebate}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div class={classes.blueBox}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.label}
                                >
                                    Due amount(৳)
                                </Typography>
                                <Typography component="h3" gutterBottom>
                                    23900
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Due Date
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {moment(paymentDetail.dueDate).format(
                                    "DD/MM/YYYY"
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Paid amount(৳)
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {paymentDetail.paidAmount}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Payment date
                            </Typography>
                            <Typography component="h3" gutterBottom>
                                {moment(paymentDetail.dueDate).format(
                                    "DD/MM/YYYY"
                                )}
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                Note
                            </Typography>
                            <Typography
                                component="h3"
                                gutterBottom
                            ></Typography>
                            <Divider />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <div className={classes.buttonWrapper}>
                <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    className={classes.cancelBtn}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    className={classes.updateBtn}
                    type="submit"
                >
                    Update
                </Button>
            </div>
        </div>
    );
};

const ApplicationHeader = (props) => {
    const { classes } = props;
    return (
        <AppBar className={classes.appBar} position="static">
            <Toolbar>
                <Typography variant="h6" className={classes.appTitle}>
                    {APP_CONFIGS.CLIENT_NAME}
                </Typography>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default withStyles(inputStyles)(PaymentDetail);
