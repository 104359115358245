const inputStyles = {
    
    appBar: {
        position: "relative",
        backgroundColor: '#212121'
    },
    layout: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    menuButton: {
        marginLeft: "auto"
    },
    appTitle: {
        color: '#fff',
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle:'normal',
        fontSize: '32px',
        marginBottom: '12px',
        fontWeight: '500',
    },
    stepLabel: {
        fontSize: '16px',
        marginTop: '16px'
    },
    paper: {
        boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
        borderRadius: '8px',
        marginBottom: '24px'
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: '28px',
        // marginLeft: theme.spacing(1),
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    margin24: {
        marginRight: '24px',
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    mainContent: {
        padding: '32px',
        maxWidth: '768px',
        margin: '0px auto',
        marginTop: '80px'
    },
    paperLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        padding: '16px 0px 8px 32px',
        borderBottom: '1px solid #DCDEDF'
    },
    paperContent: {
        padding: '32px'
    },
    actionBtn: {
        marginRight: '24px',
        textTransform: 'none',
        width: '250px'
    },
    label: {
        fontWeight: '300'
    },
    contentHeader: {
        marginBottom: '24px'
    },
    redicon:{
        top: '10px',
        right: '10px',
        backgroundColor: 'red',
        height: '7px',
        width: '7px',
        borderRadius: '50%',
        float:'right'
      },
      pagenum:{
        marginRight: '20px',
        float:'right'
      },
      filterContainer:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex'
      },
      filterLabel:{
          marginLeft:'5px'
      },
      filterListContainer:{
        flex: 1,
        marginLeft:'5px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#5F56A6',
        display: 'inline-flex',
        color: '#F8F8F8'
      },
      paginationContainer: {
        display: "flex",
        justifyContent: "center",
      },
      icon:{
        color: '#212121',
        cursor: 'pointer'
      },
      closeIcon:{
        color: '#F8F8F8',
        cursor: 'pointer'
      },
      searchWrapper: {
          marginTop: '24px',
          marginBottom: '24px'
      }
}

export default inputStyles
