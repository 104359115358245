import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import inputStyles from "./inputStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import map from "lodash/map";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Card from "@material-ui/core/Card";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import moment from "moment";
import find from "lodash/find";
import LoaderComponent from "../connected_components/Loader";
import { currencyFormat } from "../../utils/helper";
import { includes } from "lodash";
import { useTranslation } from "react-i18next";
import { STATUS_COLOR_CODING } from "../../constants/appConstants";

const ApplicationList = (props) => {
    const {
        classes,
        getApplicationList,
        applicationList,
        isLoadingGetApplications,
        isManager
    } = props;
    const [searchWord, setSearchWord] = useState("");
    const [option, setOption] = useState("");
    const [pageNum, setPageNum] = useState(1);
    const { location } = props;
    const { t } = useTranslation();

    useEffect(() => {
        let param = {
            searchWord,
            option,
            pageNum,
        };
        const filterUrl = location.search;
        const filterArr = filterUrl.split("=");
        if (filterArr.length > 0) {
            const filterStatus = filterArr[filterArr.length - 1];
            setOption(filterStatus);
            param = {
                searchWord,
                option: filterStatus,
                pageNum,
            };
        }
        getApplicationList(param);
    }, [location]);

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            setSearchWord(e.target.value);
            const param = {
                pageNum: 1,
                searchWord: e.target.value,
                option: option,
            };
            getApplicationList(param);
        }
    };

    const handleChange = (event, value) => {
        const param = {
            pageNum: value,
            searchWord: searchWord,
            option: option,
        };
        getApplicationList(param);
    };

    const onSearchClick = () => {
        const param = {
            pageNum: 1,
            searchWord,
            option: option,
        };

        getApplicationList(param);
    };

    const filter = (option) => {
        setOption(option);
        const param = {
            pageNum: 1,
            searchWord,
            option: option,
        };
        getApplicationList(param);
    };

    const onSearchChange = (e) => {
        setSearchWord(e.target.value);
    };

    return (
        <main className={classes.layout}>
            <CssBaseline />
            <LoaderComponent isLoading={isLoadingGetApplications} />
            <div className={classes.mainContent}>
                <div className={classes.contentHeader}>
                    <Typography
                        className={classes.title}
                        component="h3"
                        variant="h4"
                    >
                        {t('LOAN_APPLICATION_LIST') || "Loan Application List"}
                    </Typography>
                    <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.label}
                    >
                        {t('TODAY') || "As of today,"} {moment().format("DD/MM/YYYY")} {" "}
                        {moment().format("h:ma")}
                    </Typography>
                    <SearchComponent
                        onSearchChange={onSearchChange}
                        keyPress={keyPress}
                        classes={classes}
                        onSearchClick={onSearchClick}
                        t={t}
                    />
                    <FilterComponent
                        classes={classes}
                        filter={filter}
                        option={option}
                        t={t}
                    />
                    <Typography
                        id="applicationCount"
                        variant="body2"
                        display="block"
                        gutterBottom
                        className={classes.pagenum}
                    >
                        {applicationList &&
                            applicationList.applications &&
                            applicationList.applications.length < 10
                            ? applicationList.applications.length
                            : 10}{" "}
                        of {applicationList.totalCount}
                    </Typography>
                </div>
                <Fragment>
                    {map(applicationList.applications, (item, key) => (
                        <ApplicationItem
                            key={key}
                            classes={classes}
                            data={item}
                            applications={item.applications}
                            isManager={isManager}
                            t={t}
                        />
                    ))}
                </Fragment>
                <Box my={4} className={classes.paginationContainer}>
                    <Pagination
                        count={applicationList.totalPageCount}
                        variant="outlined"
                        color="secondary"
                        shape="rounded"
                        onChange={handleChange}
                    />
                </Box>
            </div>
        </main>
    );
};

const ApplicationItem = (props) => {
    const { classes, data, isManager, t } = props;
    const renderPercentageUtilization = () => {
        const { loanDetails } = data;
        const { flatRate, reducingRate } = loanDetails;
        const percent =
            parseFloat((flatRate || reducingRate) * 100).toFixed(2) + "%";
        const utilType = reducingRate > 0 ? "(Reducing)" : "(Flat)";
        return `${percent} ${utilType}`;
    };
    return (
        <Link to={`/application/${data.id}`} className="noDecorate">
            <Paper className={classes.paper}>
                <div className={classes.paperContent}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('ID') || "ID"}
                            </Typography>
                            <Typography id="applicationId" variant="body2" gutterBottom>
                                {data.applicationStringId}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('CUSTOMER_NAME') || "Customer name"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.customer.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('MOBILE') || "Mobile number"}
                            </Typography>
                            <Typography id="customerPhoneNumber" variant="body2" gutterBottom>
                                {data.customer.phone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {isManager && includes(['Accepted', 'Review'], data.status) && data.viewed == '0' && (
                                <div className={classes.redicon} />
                            )}
                            {!isManager && includes(['Approved'], data.status) && data.viewed == '0' && (
                                <div className={classes.redicon} />
                            )}
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('STATUS') || "Status"}
                            </Typography>

                            <Typography
                                id="statusType"
                                variant="body2"
                                style={{ color: STATUS_COLOR_CODING[`${data.status}`] }}
                                gutterBottom
                            >
                                {data.status !== "Inquiry" ? data.status : "Enquiry"}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ color: "#FF0000" }}
                                gutterBottom
                            >
                                {data.status === "Pending" && data.note === "Resubmission" && "(Resubmissison)"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('LOAN_AMOUNT') || "Loan amount"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.status === 'Inquiry' ? 'N.A.' : (data.loanDetails.loanAmount
                                    ? `৳${currencyFormat(
                                        data.loanDetails.loanAmount
                                    )}`
                                    : "")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('FUND_UTILIZATION_RATE') || "Fund utilization rate"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.status === 'Inquiry' ? 'N.A.' : renderPercentageUtilization()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('CDE_SCORE') || "CDE score"}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={data.status === 'Inquiry' ? {} : { color: "#ed9807" }}
                                gutterBottom
                            >
                                {data.status === 'Inquiry' ? 'N.A.' : data.cdeScore}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.label}
                            >
                                {t('UPDATED_ON') || "Updated on"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.updatedAt
                                    ? moment(data.updatedAt).format(
                                        "DD-MMM-YY, h:mma"
                                    )
                                    : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Link>
    );
};

const SearchComponent = (props) => {
    const { classes, t } = props;
    const [searchWord, setSearchWord] = React.useState("");

    const onSearchClick = (e) => {
        props.onSearchClick(searchWord);
    };
    return (
        <Grid container className={classes.searchWrapper}>
            <Grid item xs={12} sm={12}>
                <Card>
                    <TextField
                        id="searchField"
                        variant="standard"
                        value={props.value}
                        onChange={props.onSearchChange}
                        onKeyDown={props.keyPress}
                        placeholder={t('SEARCH_PLACEHOLDER') || "Search by ID,customer name or mobile number"}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <SearchIcon
                                    onClick={onSearchClick}
                                    className={classes.icon}
                                    fontSize="small"
                                />
                            ),
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{
                                        visibility: props.value
                                            ? "visible"
                                            : "hidden",
                                    }}
                                    onClick={props.clearSearch}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
const FilterComponent = (props) => {
    const { classes, option, t } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selection, setSelection] = useState("");

    const options = [
        { label: "Customer Accepted", value: "Accepted" },
        { label: "Rejected", value: "Rejected" },
        { label: "Pending to Review", value: "Review" },
        { label: "Withdrawn", value: "Withdrawn" },
        { label: "Approved", value: "Approved" },
        { label: "Pending to submit", value: "Pending" },
        { label: "Active", value: "Active" },
        { label: "Closed", value: "Closed" },
        { label: "Enquiry", value: "Inquiry" },
        { label: "Matured", value: "Matured" }
    ];

    useEffect(() => {
        if (option) {
            const flterKeyword =
                option.charAt(0).toUpperCase() + option.slice(1);
            const selectedOption = find(options, function (o) {
                return o.value == flterKeyword;
            });
            setSelection(selectedOption);
        }
    }, [option]);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index, option) => {
        setSelection(option);
        setAnchorEl(null);
        props.filter(option.value);
    };
    const removeFilter = () => {
        setSelection("");
        props.filter("");
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <div className={classes.filterContainer}>
                    <FilterListIcon
                        id="filterMenuIcon"
                        fontSize="small"
                        className={classes.icon}
                        onClick={handleClickListItem}
                    />
                    <Typography className={classes.filterLabel}>
                        {t('FILTER') || "Filter"}
                    </Typography>
                    <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {options.map((option, index) => (
                            <MenuItem
                                key={option.value}
                                onClick={() =>
                                    handleMenuItemClick(index, option)
                                }
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Menu>

                    {selection != "" && (
                        <div className={classes.filterListContainer}>
                            <Typography className={classes.filterLabel}>
                                Status :&nbsp;{selection.label}&nbsp;
                            </Typography>
                            <ClearIcon
                                id="closeIcon"
                                fontSize="small"
                                className={classes.closeIcon}
                                onClick={removeFilter}
                            />
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default withStyles(inputStyles)(ApplicationList);
