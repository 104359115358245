import {
    Box, Typography, Button, Dialog, DialogContent,
    IconButton, DialogTitle, FormControl, Select, MenuItem, InputLabel
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';

import { useState } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import clsx from "clsx";
import { transactionTypes, transactionMethodList } from "./mockData";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { APP_CONFIGS } from "../../../constants/appConstants";

const AddTransactionDialog = (props) => {
    const { handleClose, open, createTransction, applicationStringId, branch } = props;

    const [transactionDate, setTransactionDate] = useState("");
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [transactionType, setTransactionType] = useState("");
    const [transactionMethod, setTransactionMethod] = useState("");
    const [note, setNote] = useState("");
    const {t} = useTranslation();

    const classes = useStyles();

    const onSubmit = () => {
        handleClose();
        const data = {
            applicationStringId,
            branch,
            amount: transactionAmount,
            transactionDate: transactionDate,
            transactionMethod,
            paymentType: transactionType,
            remarks: note,
        }
        createTransction(data)
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <Box mb="32px">
                    <DialogTitle id="form-dialog-title">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{t('TRANSACTION_RECORD')||"Transaction Record"}</Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                            <Box display="flex" justifyContent="space-between">
                                <TextValidator
                                    label={t('TRANSACTION_DATE')||"Transaction date"}
                                    type="date"
                                    name="transactionDate"
                                    value={transactionDate}
                                    onChange={(e) => setTransactionDate(e.target.value)}
                                    autoFocus
                                    required
                                />
                                <TextValidator
                                    label={t('TRANSACTION_AMOUNT')  + " "+`(${APP_CONFIGS.CURRENCY})`}
                                    type="number"
                                    name="transactionAmount"
                                    value={transactionAmount}
                                    onChange={(e) => setTransactionAmount(e.target.value)}
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('TRANSACTION_TYPE')||"Transaction Type"}</InputLabel>
                                    <Select
                                        value={transactionType}
                                        onChange={(e) => setTransactionType(e.target.value)}
                                        required
                                    >
                                        {transactionTypes.map((type) => {
                                            return (
                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>{t('TRANSACTION_METHOD')||"Transaction Method"}</InputLabel>
                                    <Select
                                        value={transactionMethod}
                                        onChange={(e) => setTransactionMethod(e.target.value)}
                                        required
                                    >
                                        {transactionMethodList.map((type) => {
                                            return (
                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <TextValidator
                                    style={{ width: "500px" }}
                                    label="Note"
                                    name="note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" width="100%" mt="40px">
                                <Button onClick={handleClose} color="primary" variant="outlined"
                                    className={clsx(classes.addTransactionBtnOutline, classes.transactionRecordBtn)}>
                                    {t('CANCEL')||"Cancel"}
                                </Button>
                                <Button
                                    color="primary" variant="contained"
                                    className={clsx(classes.addTransactionBtn, classes.transactionRecordBtn)}
                                    type="submit">
                                    {t('ADD')||"Add"}
                                </Button>
                            </Box>
                        </ValidatorForm>
                    </DialogContent>
                </Box>
            </Dialog>
        </>
    )
}

export default AddTransactionDialog;
